// Extend / Background Image

%background-image-positions{
  &.left-top{
    background-position: left top;
  }
  
  &.left-center{
    background-position: left center;
  }
  
  &.left-bottom{
    background-position: left bottom;
  }

  &.right-top{
    background-position: right top;
  }

  &.right-center{
    background-position: right center;
  }

  &.right-bottom{
    background-position: right bottom;
  }

  &.center-top{
    background-position: center top;
  }

  &.center-center{
    background-position: center center;
  }

  &.center-bottom{
    background-position: center bottom;
  }
}

@include breakpoint($phone){
  %background-image-positions{
    &.phone-left-top{
      background-position: left top;
    }
    
    &.phone-left-center{
      background-position: left center;
    }
    
    &.phone-left-bottom{
      background-position: left bottom;
    }
  
    &.phone-right-top{
      background-position: right top;
    }
  
    &.phone-right-center{
      background-position: right center;
    }
  
    &.phone-right-bottom{
      background-position: right bottom;
    }
  
    &.phone-center-top{
      background-position: center top;
    }
  
    &.phone-center-center{
      background-position: center center;
    }
  
    &.phone-center-bottom{
      background-position: center bottom;
    }
  }
}
