// Fonts
@font-face {
  src: url(../../assets/fonts/graphik/Graphik-Light.otf),
       url(../../assets/fonts/graphik/eot/graphik-light-webfont.eot) format("embedded-opentype"), 
       url(../../assets/fonts/graphik/Graphik-Light.otf) format("opentype");
  font-display:swap;
  font-family:Graphik;
  font-weight:300;
  font-style:normal;
}

@font-face {
  src: url(../../assets/fonts/graphik/Graphik-Regular.otf),
       url(../../assets/fonts/graphik/eot/graphik-regular-webfont.eot) format("embedded-opentype"), 
       url(../../assets/fonts/graphik/Graphik-Regular.otf) format("opentype");
  font-display:swap;
  font-family:Graphik;
  font-weight:400;
  font-style:normal;
}

@font-face {
  src: url(../../assets/fonts/graphik/Graphik-RegularItalic.otf),
       url(../../assets/fonts/graphik/eot/graphik-regularitalic-webfont.eot) format("embedded-opentype"), 
       url(../../assets/fonts/graphik/Graphik-RegularItalic.otf) format("opentype");
  font-display:swap;
  font-family:Graphik;
  font-weight:400;
  font-style:italic;
}

@font-face {
  src: url(../../assets/fonts/graphik/Graphik-Medium.otf),
       url(../../assets/fonts/graphik/eot/graphik-medium-webfont.eot) format("embedded-opentype"), 
       url(../../assets/fonts/graphik/Graphik-Medium.otf) format("opentype");
  font-display:swap;
  font-family:Graphik;
  font-weight:500;
  font-style:normal;
}

@font-face {
  src: url(../../assets/fonts/graphik/Graphik-Semibold.otf),
       url(../../assets/fonts/graphik/eot/graphik-semibold-webfont.eot) format("embedded-opentype"), 
       url(../../assets/fonts/graphik/Graphik-Semibold.otf) format("opentype");
  font-display:swap;
  font-family:Graphik;
  font-weight:600;
  font-style:normal;
}

@font-face {
  src: url(../../assets/fonts/graphik/Graphik-Bold.otf),
       url(../../assets/fonts/graphik/eot/graphik-bold-webfont.eot) format("embedded-opentype"), 
       url(../../assets/fonts/graphik/Graphik-Bold.otf) format("opentype");
  font-display:swap;
  font-family:Graphik;
  font-weight:700;
  font-style:normal;
}

@font-face {
  font-family: "acumin-pro-extra-condensed";
  src: url(../../assets/fonts/acumin/acumin.woff2) format("woff2"),
       url(../../assets/fonts/acumin/acumin.woff) format("woff"),
       url(../../assets/fonts/acumin/acumin.otf) format("opentype");
  font-display:swap;
  font-style: normal;
  font-weight: 400;
}

.tk-acumin-pro-extra-condensed {
  font-family: "acumin-pro-extra-condensed", sans-serif;
}
