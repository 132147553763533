// Icon

.ext_icon_base{
  display:inline-block;
  background-repeat:no-repeat;
  background-position:center;
}

.nav_icon{
  position:relative;
  top:-2px;
  vertical-align:middle;
  margin-right:3px;
  
  &.icon_ethereum{
    top:-3px;
  }
}

.button_icon{
  position:absolute;
  left:10px;
  top:6px;
  display:inline-block;
  width:18px;
  height:18px;
}

.icon_circle{
  position:relative;
  top:-1px;
  display:inline-block;
  vertical-align:middle;
  width:20px;
  height:20px;
  border-radius:20px;
  background-color:$blue;
  margin-left:7px;
  
  &.blue{
    background-color:$blue;
  }
  
  &.white{
    background-color:$white;
  }
  
  &.outline{
    background-color:transparent;
    border:2px solid $button-outline-grey;
  }
  
  div{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
  }
}

.icon_check{
  @extend .ext_icon_base;
  width:18px;
  height:18px;
  background-image:url(../../assets/images/icon/check.svg);
  
  &.white{
    background-image:url(../../assets/images/icon/check-white.svg);
  }
  
  &.blue{
    background-image:url(../../assets/images/icon/check-blue.svg);
  }
}

.icon_check_small{
  @extend .ext_icon_base;
  width:10px;
  height:10px;
  background-image:url(../../assets/images/icon/check.svg);
  
  &.white{
    background-image:url(../../assets/images/icon/check-white.svg);
  }
  
  &.blue{
    background-image:url(../../assets/images/icon/check-blue.svg);
  }
}

.icon_arrow{
  @extend .ext_icon_base;
  width:20px;
  height:20px;
  background-image:url(../../assets/images/icon/arrow.svg);
  
  &.white{
    background-image:url(../../assets/images/icon/arrow-white.svg);
  }
}

.icon_caution{
  @extend .ext_icon_base;
  width:20px;
  height:20px;
  background-image:url(../../assets/images/icon/caution.svg);
  
  &.white{
    background-image:url(../../assets/images/icon/caution-white.svg);
  }
  
  &.yellow{
    background-image:url(../../assets/images/icon/caution-yellow.svg);
  }
  
  &.blue{
    background-image:url(../../assets/images/icon/caution-blue.svg);
  }
}

.icon_ethereum{
  @extend .ext_icon_base;
  width:20px;
  height:20px;
  background-image:url(../../assets/images/icon/ethereum.svg);
  
  &.white{
    background-image:url(../../assets/images/icon/ethereum-white.svg);
  }
}

.icon_time{
  @extend .ext_icon_base;
  width:20px;
  height:20px;
  background-image:url(../../assets/images/icon/time.svg);
  
  &.white{
    background-image:url(../../assets/images/icon/time-white.svg);
  }
  
  &.blue_dark{
    background-image:url(../../assets/images/icon/time-blue-dark.svg);
  }
  
}

.icon_retry{
  @extend .ext_icon_base;
  width:20px;
  height:20px;
  background-image:url(../../assets/images/icon/retry.svg);
  
  &.white{
    background-image:url(../../assets/images/icon/retry-white.svg);
  }
  
  &.yellow{
    background-image:url(../../assets/images/icon/retry-yellow.svg);
  }
}


.icon_practitioner{
  background-image: url(../../assets/images/icon/course-practitioner.svg);
  width:60px;
  height:60px;
  margin-bottom:15px;
  
}
