// Forms / Fields

label{
  font-weight:300;
}

.locked{
  position: fixed;
  top: -1000px;
  left: -1000px;
}

input[type=text],
input[type=email],
input[type=password],
input[type=url],
textarea{
  transition-duration:0.3s;
  transition-property:all;
  @include font-sans;
  
  display:block;
  width: 100%;
  border:1px solid $input-border-color;
  background-color:$white;
  box-shadow:3px 3px 0 0 rgba(0,0,0,0.05);
  font-size:15px;
  line-height:19px;
  padding:11px 15px 10px 15px;
  margin:0 0 12px 0;
  color:$black;
  border-radius:3px;
  outline:0;
  -webkit-appearance: none;
  -moz-appearance: none;
  
  &::placeholder{
    color:$input-placeholder-color;
  }
  
  &:hover{
    border-color:$input-border-hover-color;
  }
  
  &:focus{
    transition-duration:0.1s;
    border-color:$input-border-focus-color;
  }
}

textarea{
  height:180px;
  line-height:27px;
  margin:0;
  max-width:100%;
}

select{
  transition-duration:0.3s;
  transition-property:all;
  @include font-sans;
  
  margin-top:5px;
  display:block;
  width:100%;
  max-width:300px;
  height:45px;
  padding:0.5rem 0.75rem;
  text-transform:capitalize;
  outline:0;
  border-radius:3px;
  border:1px solid $input-border-color;
  background-color:$white;
  box-shadow:3px 3px 0 0 rgba(0,0,0,0.05);
  
  // Circle and arrow on select
  -webkit-appearance: none;
  -moz-appearance: none;
  
  background-image:
      linear-gradient(45deg, transparent 50%, $grey-med 50%),
      linear-gradient(135deg, $grey-med 50%, transparent 50%);
  background-position:
      calc(100% - 22px) 20px,
      calc(100% - 17px) 20px;
  background-size:
      5px 5px,
      5px 5px,
      1.5em 1.5em;
  background-repeat: no-repeat;
  
  &:hover{
    border-color:$input-border-hover-color;
  }
  
  &:focus{
    border-color:$input-border-focus-color;
  }
  
  &::placeholder{
    content:'Please choose...';
  }
}

.message-alert{
  display:flex;
  padding-top:23px;
  padding-left:5px;
  font-size:15px;
  line-height:19px;
  font-weight:500;
  color:$grey-text;
  vertical-align:middle;

  div{
    position:relative;
    top:-2px;
    margin-right:11px;
  }
}

@include breakpoint($tablet-portrait){
  input[type=text],
  input[type=email],
  input[type=password],
  input[type=url],
  textarea{
    padding:11px 11px 10px 11px;
  }
  
  select{
    height:41px;
    
    background-position:
        calc(100% - 22px) 17px,
        calc(100% - 17px) 17px;
  }
}

@include breakpoint($phone){
  select{
    max-width:100%;
  }
}