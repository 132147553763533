// Partials / Blog Title

.blog-title{
  // background-color:red;
  //@extend %title;

  color: $blue;
  display:inline-block;
  text-transform: capitalize;
  font-size:29px;
  line-height:37px;
  font-weight:600;
  padding-top:200px;
  padding-left:184px;
  padding-bottom: 44px;

  a{
    color: $blue-grey-med;
    text-decoration:none;
    font-weight:400;
    display:inline-block;
    margin-right:10px;
  }

  h1,
  span{
    display:inline-block;
    text-transform: capitalize;
    font-weight:400;
  }

  & + .blog{
    & > .inner {
      padding-top: 0;
    }
  }
}

.blog-hero + .blog-title{
  padding-top:44px;
}

@include breakpoint($laptop){
  .blog-title{
    padding-left:85px;
  }
}

@include breakpoint($tablet-landscape){
  .blog-title{
    padding-top:160px;
    font-size:21px;
    line-height:29px;
    
    a{
      display:block;
    }
  }
}

@include breakpoint($tablet-portrait){
  .blog-title{
    padding-left:50px;
  }
}

@include breakpoint($phone){
  .blog-title{
    padding-top:140px;
    padding-bottom:35px;
    padding-left:30px;
    font-size:19px;
    line-height:25px;

    a{
      display:block;
      
    }

  }
}