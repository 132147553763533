// Partials / Background

.full-background{
  &.clouds{
    background-image: url(../../assets/images/texture/clouds-blue-fade-to-blue@2x.jpg);
    background-size:100% auto;
    background-repeat:no-repeat;
    background-color:#184d8e;
    background-color:#014b94;
  }
}

@include breakpoint($tablet-portrait){
  .full-background{
    &.clouds{
      background-size:150% auto;
      background-position: right top;
    }
  }
}

@include breakpoint($phone){
  .full-background{
    &.clouds{
      background-size:230% auto;
      background-position: right top;
    }
  }
}
