// Buttons
.button{
  cursor:pointer;
  position:relative;
  transition-duration:0.2s;
  transition-property:border color background;
  display:inline-block;
  border:1px solid $blue;
  color:$white;
  background-color:$blue;
  text-decoration:none;
  border-radius:30px;
  
  font-family:Graphik, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;;
  font-size:14px;
  line-height:19px;
  font-weight:500;
  padding:14px 22px 12px 22px;
  
  &.disabled{
    pointer-events:none;
    
  }
  
  &:focus{
    outline:0;
  }
  
  &.small{
    padding:12px 18px 11px 18px;
    font-size:14px;
    font-weight:400;
    line-height:17px;
    margin-right:12px;
    
    &:last-child{
      margin-right:0;
    }
    
    .icon_check{
      width:18px;
      height:18px;
    }
    
    .icon_retry{
      width:18px;
      height:18px;
    }
  }
  
  // Solid
  
  &.red{
    border-color:$red-light;
    background-color:$red-light;
    color:$white;
  }
  
  &.blue{
    border-color:$blue;
    background-color:$blue;
    color:$white;
  }
  
  &.blue_dark{
    border-color:$blue-dark;
    background-color:$blue-dark;
    color:$white;
  }
  
  &.blue_med{
    border-color:$blue-med;
    background-color:$blue-med;
    color:$white;
  }
  
  &.blue_light{
    border-color:$blue-light;
    background-color:$blue-light;
    color:$white;
  }

  &.black{
    border-color:$blue_black;
    background-color:$blue_black;
    color:$white;
  }
  
  &.yellow{
    border-color:$yellow;
    background-color:$yellow;
    color:$blue-dark;
  }
  
  &.white{
    background-color:$white;
    border-color:$white;
    color:$blue;
  }
  
  // Hover
  
  &.hover_red{
    &:hover{
      border-color:$red;
      background-color:$red;
      color:$white;
    }
  }
  
  &.hover_blue{
    &:hover{
      border-color:$blue;
      background-color:$blue;
      color:$white;
    }
  }
  
  &.hover_blue_dark{
    &:hover{
      border-color:$blue-dark;
      background-color:$blue-dark;
      color:$white;
    }
  }

  &.hover_black{
    &:hover{
      border-color:$blue-black;
      background-color:$blue-black;
      color:$white;
    }
  }
  
  &.hover_blue_med{
    &:hover{
      border-color:$blue-med;
      background-color:$blue-med;
      color:$white;
    }
  }
  
  &.hover_blue_light{
    &:hover{
      border-color:$blue-light;
      background-color:$blue-light;
      color:$white;
    }
  }
  
  &.hover_yellow{
    &:hover{
      border-color:$yellow;
      background-color:$yellow;
      color:$blue-dark;
    }
  }
  
  &.hover_white,
  &.hover_blue_on_white{
    &:hover{
      background-color:$white;
      border-color:$white;
      color:$blue;
    }
  }

  &.hover_red_on_white{
    &:hover{
      background-color:$white;
      border-color:$white;
      color:$red;
    }
  }
  
  // Outline
  
  &.outline{
    border-width:1px;
    background-color:transparent;
    color:$blue;
    border-color:#c1e7fc;
  }
  
  &.outline_blue_light{
    border-width:1px;
    background-color:transparent;
    color:$white;
    border-color:$blue-light;
  }
  
  // Buttons with icons
  &.has_icon_check{
    padding-left:35px;
    
    &:before{
      content:'';
      @extend .icon_check;
      background-color:$white;
      position:absolute;
      top:6px;
      left:12px;
    }
  }
  
}

.button_and_info{
  cursor:pointer;
  transition-duration:0.2s;
  transition-property:border color background;
  display:inline-block;
  text-decoration:none;
  font-size:13px;
  line-height:17px;
  margin-right:12px;
  
  &.yellow{
    .button_status{
      border-color:$yellow-dark;
      background-color:$yellow-dark;
    }
    
    .button_info{
      border-color:$yellow-dark;
      color:$yellow-dark;
    }
    
    &:hover{
      .button_status{
        border-color:$yellow;
        background-color:$yellow;
      }
    
      .button_info{
        border-color:$yellow;
        color:$yellow;
      }
    }
  }
  
  &.blue{
    &:hover{
      .button_status{
        border-color:$blue-light;
        background-color:$blue-light;
      }
      
      .button_info{
        border-color:$blue-light;
        color:$blue-light;
      }
    }
  }
  
  .button_status{
    transition-duration:0.2s;
    position:relative;
    z-index:1;
    vertical-align:middle;
    display:inline-block;
    border:1.5px solid $blue;
    color:$white;
    background-color:$blue;
    border-radius:30px;
    padding:7px 14px 7px 34px;
    
  
  }
    
  .button_info{
    position:relative;
    margin-left:-30px;
    display:inline-block;
    vertical-align:middle;
    border:1.5px solid $blue;
    background-color:$white;
    color:$blue;
    border-radius:0 30px 30px 0;
    padding:7px 14px 7px 34px;
    font-weight:600;
  }
  
}

@include breakpoint($laptop-tablet){
  .button{
    font-size:15px;
    line-height:19px;
    padding:10px 18px;
  }
}

@include breakpoint($tablet-portrait){
  .button{
    &.small{
      padding:6px 12px;
      font-size:13px;
      line-height:15px;
      
      &.has_icon_check{
        padding-left:32px;
        
        &:before{
          top:4px;
          left:10px;
          transform:scale(0.75);
        }
      }
    }
  }
}
@include breakpoint($tablet-portrait){
  .button_and_info{
    .button_status{
      padding:6px 12px 6px 30px;
      
      .icon_check{
        transform:scale(0.75);
      }
      
      .icon_check,
      .icon_retry{
        top:5px;
        left:8px;
      }
    }
    
    .button_info{
      padding:6px 12px 6px 34px;
    }
  }
}

@include breakpoint($phone){
  .button{
    font-size:13px;
    padding-top:11px;
    padding-bottom:11px;
    // line-height:19px;
    // padding:10px 18px;
  }
}
