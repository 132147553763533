// Partials / Try Dragonchain

.try-dragonchain{
  position:relative;
  background-size:100% auto;
  background-repeat:no-repeat;
  background-color:#0f2549;

  & > .inner{
    display:flex;
  }

  .try-content{
    padding: 180px 80px 50px 200px;
    width:50%;
    color:$white;

    .title{
      font-size:41px;
      line-height:51px;
      font-weight:600;
      margin-bottom:30px;

      &.large{
        font-size: 74px;
        line-height: 90px;
        margin-top: -55px;
      }
    }
    
    .description{
      color:$blue-light-on-blue-med;
      font-size:19px;
      line-height:31px;
      font-weight:400;
      padding-right:45px;
      margin-bottom:40px;

      p{
        margin-bottom:25px;

        strong{
          &:first-child{
            color:$white;
            font-weight:600;
          }
        }
      }

      a:not(.button){
        @include link_underline($blue-light-on-blue-med, $red-light, 2);
        color:$white;
        text-decoration:none;
        padding-bottom:2px;
      }
    }
    

    .actions{
      ul{
        margin-top:30px;
        flex-wrap: wrap;

        li{
          display:inline-block;
          margin-right:14px;
          margin-bottom:12px;
          
          &:last-child{
            margin-right:0;
          }
        }
      }
    }
  }

  .try-interactive{
    width:50%;
    padding-top:100px;
    padding-bottom:100px;
  }

  .try-code{
    position:relative;
    background-color:$near-white;
    border-radius:3px 0 0 3px;
    box-shadow:3px 10px 35px 0 rgba(0,0,0,0.5);
    font-style:16px;
    line-height:21px;
    font-weight:500;
    color:$blue-black;

    .flag{
      pointer-events:none;
      position:absolute;
      top:-32px;
      left:40px;
    }

    .languages{
      ul{
        display:table;
      }

      li{
        display:table-cell;

        &:first-child{
          a,
          .button{
            border-radius:3px 0 0 0;
          }
        }
      }

      a,
      button{
        cursor:pointer;
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        transition-duration:0.2s;
        transition-property:background;
        background-color:$near-white;
        color:$blue-black;
        text-decoration:none;
        display:block;
        padding:30px 45px;
        font-size:16px;

        &:hover{
          &{
            span{
              @include link-underline($red, $red);
            }
          }

          &.active{
            span{
              @include link-underline($blue, $blue);
            }
          }
        }

        span{
          @include link-underline(transparent, $red);
          padding-bottom:4px;
          padding-left:1px;
          padding-right:2px;
        }

        &.active{
          background-color:$white;

          span{
            @include link-underline($blue-light, $blue);
          }
        }
      }
    }

    .try-transition{
      & > .inner{
        max-width:400px;
        margin-left:auto;
        margin-right:auto;
      }

      .post-illustration{
        max-width:310px;
        margin-left:auto;
        margin-right:auto;
      }

      .alert{
        margin-left:auto;
        margin-right:auto;
        max-width:300px;
        text-align:center;
        padding-left:15px;
        padding-right:15px;
      }
    }

    .actions{
      margin-top:35px;
      margin-bottom: 35px;

      ul{
        display:table;
      }

      li{
        display:table-cell;
        vertical-align:middle;

        &:not(.instructions){
          white-space:nowrap;
        }
      }

      .instructions{
        color:$grey;
        font-size:14px;
        line-height:19px;
        font-weight:400;
        padding-left:20px;
        max-width:400px;
        padding-top:3px;
      }
    }
  }

  .try-step{
    padding:18px 90px 18px 40px;
  }

}

@include breakpoint($laptop){
  .try-dragonchain{
    .try-content{
      padding-top:160px;
      padding-left:140px;

      .title{
        font-size:37px;
        line-height:43px;
        padding-right:0;
      }

      .description{
        padding-right:10px;
      }
    }
  }
}

@include breakpoint($tablet-landscape){
  .try-dragonchain{

    & > .inner{
      flex-wrap:wrap;
    }

    .try-content{
      width:100%;
      padding:70px 80px 0 80px;

      .title{
        font-size:36px;
        line-height:42px;
        font-weight:600;
        margin-bottom:10px;
      }

      .description{
        margin-bottom:0;
        padding-right:80px;
        font-size:17px;
        line-height: 27px;

        p{
          &:last-child{
            margin-bottom:0;
          }
        }
      }
    }

    .try-interactive{
      width:100%;

      & > .inner{
        padding-left:80px;
        padding-right:80px;
      }
    }

    .try-code{
      border-radius:3px;

      .alert{
        font-size:14px;
      }

      .languages{
        a,
        button{
          padding:24px 45px;
          font-size:15px;
        }
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .try-dragonchain{
    .try-content{
      padding-left:50px;
      padding-right:50px;

      .title{
        font-size:31px;
        line-height:36px;
      }

      .description{
        font-size:16px;
        line-height:27px;

        p{
          margin-bottom:20px;
        }
      }
    }

    .try-interactive{
      padding-top:80px;

      & > .inner{
        padding-left:50px;
        padding-right:50px;
      }
    }

    .try-code{
      .flag{
        top:-24px;
        left:30px;
      }
    }
  }
}

@include breakpoint($phone){
  .try-dragonchain{
    .try-content{
      padding:50px 30px;

      .title{
        font-size:29px;
        line-height:33px;
        font-weight:600;
        padding-right:0;
      }
      
      .description{
        padding-right:0;
        font-size:16px;
        line-height:27px;
      }
    }

    .try-interactive{
      padding:30px 0 50px 0;
      
      & > .inner{
        padding-left:7px;
        padding-right:7px;
      }
    }

    .try-code{
      .flag{
        left:15px;
      }

      .languages{
        a,
        button{
          padding:24px 30px;
        }
      }

      .try-step{
        padding-left:15px;
        padding-right:15px;
      }

      code{
        font-size:13px;
        padding-left:5px;
        padding-right:5px;
      }

      .actions{
        margin-left:-15px;
        margin-right:-15px;
        margin-bottom:-18px;
        padding:25px 21px 15px 21px;
        background-color:$try-dragonchain-actions-bg-color;
        border-radius:0 0 3px 3px;

        ul{
          display:block;

          li{
            display:block;
            margin-bottom:20px;

            &:last-child{
              margin-bottom:0;
            }

            &.instructions{
              padding:0 0 10px 0;
              color:$blue-grey-med;
            }
          }
        }
      }
    }

  }

}
