// Partials / Feature Subscribe

.subscribe-large {
  max-width:1180px;
  margin-left:auto;
  margin-right:auto;
  margin-top:80px;
  margin-bottom:80px;
  color:$subscribe-color;

  &:last-child{
    padding-bottom:80px;
  }

  &.has-sdks{
    .content-left{
      .description{
        margin-bottom:30px;
      }
    }

    &.has-actions{
      .sdks{
        margin-bottom:40px;
      }
    }
  }

  & > .inner {
    background: $subscribe-bg-blue-dark;
    background: -moz-linear-gradient(45deg, $subscribe-bg-blue-dark 0%, $subscribe-bg-blue-light 100%);
    background: -webkit-linear-gradient(45deg, $subscribe-bg-blue-dark 0%, $subscribe-bg-blue-light 100%);
    background: linear-gradient(45deg, $subscribe-bg-blue-dark 0%, $subscribe-bg-blue-light 100%);

    border-radius:3px;
    box-shadow:0 7px 50px 0 rgba(0,0,0,0.35);
    
    & > .layout {
      display:flex;
    }
  }

  .content-left{
    position:relative;
    width:50%;
    background: $subscribe-bg-blue-dark;
    background: -moz-linear-gradient(45deg, $subscribe-bg-blue-dark 0%, $subscribe-bg-blue-light 100%);
    background: -webkit-linear-gradient(45deg, $subscribe-bg-blue-dark 0%, $subscribe-bg-blue-light 100%);
    background: linear-gradient(45deg, $subscribe-bg-blue-dark 0%, $subscribe-bg-blue-light 100%);

    border-radius:3px 0 0 3px;
    padding:110px 80px 75px 58px;

    .label{
      font-size:17px;
      line-height:25px;
      font-weight:400;
      margin-bottom:8px;
      text-transform:none;
      padding-left:1px;
    }

    .title{
      font-size:34px;
      line-height:45px;
      font-weight:500;
      margin-bottom:25px;
      color:$white;
    }

    .description{
      font-size:17px;
      line-height:27px;
      margin-bottom:75px;
      font-weight:400;
    }

    .sdks{
      margin-bottom:75px;

      &:last-child{
        margin-bottom:0;
      }
    }

    .actions{
      margin-bottom:50px;

      &:last-child{
        margin-bottom:0;
      }

      ul{
        display:flex;
        align-items:middle;
        flex-wrap: wrap;

        li{
          margin-right:12px;
          margin-bottom:12px;
        }
      }
    }

    .subscribe-form{
      position:absolute;
      left:0;
      right:0;
      bottom:0;
      height:74px;

      form{
        &.has-response{
          overflow:hidden;

          .fields-inline{
            top:80px;
          }

          .action{
            pointer-events:none;

            .button-style{
              opacity:0;
            }


          }

          .message-response{
            opacity:1;
          }
        }
      }

      .fields-inline{
        background-color:$subscribe-form-bg-blue-light;
        border-bottom-left-radius:3px;
        transition-duration:0.2s;
        transition-property:top;
        position:relative;
        top:0;
        display:flex;
      }

      .field{
        position:relative;
        flex-grow:1;

        &:first-child{
          width:215px;
          flex-grow:0;

          input[type=text],
          input[type=email]{
            padding-left:58px;
            border-bottom-left-radius:3px;
          }
        }

        & + .field{
          &:before,
          &:after{
            position:absolute;
            content:'';
            display:block;
            transform-origin:center;
            top:29px;
            left:0;
            width:2px;
            height:15px;
            border-radius:1px;
            background-color:$white;
          }

          &:before{
            transform:rotate(0deg);
          }

          &:after{
            transform:rotate(90deg);
          }
        }

        input[type=text],
        input[type=email]{
          color:$white;
          background-color:transparent;
          box-shadow:none;
          border:none;
          height:74px;
          margin:0;
          padding-left:40px;

          &::placeholder{
            color:$white;
          }

          &:focus{
            &::placeholder{
              color:$blue-light-on-blue;
            }
          }

          &:hover{
            background-color:$subscribe-form-bg-blue-dark;
          }
        }
      }

      .action{
        flex-grow:0;
        width:74px;
        height:74px;

        .button-style{
          width:100%;
          height:100%;
        }

        .smooth-spinner{
          svg{
            padding:15px;
          }
        }
      }

      .button{
        transition-duration:0.2s;
        transition-property:background;
        position:relative;
        display:block;
        background-color:$subscribe-form-bg-blue-dark;
        border:none;
        border-radius:0;
        width:100%;
        height:100%;
        text-indent:-9999;
        font-size:0;
        line-height:0;

        &:hover{
          background-color:#0068AD;
        }

        &:before{
          content:'';
          position:absolute;
          top:50%;
          left:50%;
          transform:translate(-50%, -50%);
          display:block;
          -webkit-mask: url(../../assets/images/icon/arrow-with-stem.svg) no-repeat center;
          mask: url(../../assets/images/icon/arrow-with-stem.svg) no-repeat center;
          background-color:$white;
          width:30px;
          height:30px;

        }
      }

      .message-alert{
        pointer-events:none;
        position: absolute;
        top:-20px;
        right:15px;
        background-color:$white;
        font-size:12px;
        font-weight:600;
        border-radius:2px;
        box-shadow:0 2px 10px 0 rgba(0,0,0,0.2);
        padding:5px 6px 3px 6px;

        & > div{
          transform-origin:center;
          transform:scale(0.8);
          margin-right:3px;
        }
      }

      .message-response{
        transition-duration:0.5s;
        transition-property:opacity;
        transition-delay:2s;
        position:absolute;
        top:0;
        left:0;
        right:0;
        height:74px;
        padding:0 58px;
        opacity:0;
        background-color:#0068AD;
        border-radius:3px 0 0 3px;
        color:$white;

        p{
          display:block;
          position:relative;
          top:50%;
          transform:translateY(-50%);
        }
      }

    }
  }

  .content-right{
    position:relative;
    width:50%;

    .image{
      background-size:cover;
      background-repeat:no-repeat;
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      border-radius:0 3px 3px 0;
    }

  }
}

// Themes
.subscribe-large{
  &.blue-dark{
    color:$subscribe-theme-blue-dark-color;

    & > .inner {
      background:none;
      background-image:none;
      background-color:$subscribe-theme-blue-dark-bg-color;
    }

    .content-left{
      background:none;
      background-image:none;
      background-color:$subscribe-theme-blue-dark-bg-color;

      .subscribe-form{
        .fields-inline{
          background-color:$subscribe-theme-blue-dark-input-color;
        }

        .field{
          input[type=text],
          input[type=email]{
            background-color:$subscribe-theme-blue-dark-input-color;

            &:focus{
              &::placeholder{
                color:$subscribe-theme-blue-dark-input-color-placeholder;
              }
            }
  
            &:hover{
              background-color:$subscribe-theme-blue-dark-input-color-hover;
            }
          }
        }

        .button{
          background-color:$subscribe-theme-blue-dark-button-bg-color;

          &:hover{
            background-color:$subscribe-theme-blue-dark-button-bg-color-hover;
          }
        }
      }
    }
  }

  &.red{
    color:$subscribe-theme-red-color;

    & > .inner {
      background:none;
      background-image:none;
      background-color:$subscribe-theme-red-bg-color;
    }

    .content-left{
      background:none;
      background-image:none;
      background-color:$subscribe-theme-red-bg-color;

      .subscribe-form{
        .fields-inline{
          background-color:$subscribe-theme-red-input-color;
        }

        .field{
          input[type=text],
          input[type=email]{
            background-color:$subscribe-theme-red-input-color;

            &:focus{
              &::placeholder{
                color:$subscribe-theme-red-input-color-placeholder;
              }
            }
  
            &:hover{
              background-color:$subscribe-theme-red-input-color-hover;
            }
          }
        }

        .button{
          background-color:$subscribe-theme-red-button-bg-color;

          &:hover{
            background-color:$subscribe-theme-red-button-bg-color-hover;
          }
        }
      }

      .sdks{
        border-color:$subscribe-theme-red-sdks-color;

        .sdk{
          border-color:$subscribe-theme-red-sdks-color;

          a{
            &:hover{
              background-color:$subscribe-theme-red-sdks-color;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($laptop-tablet){
  .subscribe-large {
    padding-top:0;
    padding-bottom:0;

    &:last-child{
      padding-bottom:0;
    }

    & > .inner {
      border-radius:0;
    }

    .content-left,
    .content-right{
      border-radius:0;
    }

    .content-left{
      .description{
        padding-right:20px;
      }

      .fields-inline{
        border-bottom-left-radius:0;
      }

      .field{
        &:first-child{
          input[type=text],
          input[type=email]{
            border-bottom-left-radius:0;
          }
        }
      }

      .message-response{
        border-radius:0;
      }
    }

    .content-right{
      .image{
        border-radius:0;
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .subscribe-large{
    margin-top:40px;

    .content-left{
      width:60%;
      padding-top:60px;
      padding-left:50px;
      padding-right:60px;

      &:not(.has-subscribe-form){
        padding-bottom:50px;
      }

      .label{
        font-size:15px;
        line-height:21px;
      }

      .title{
        font-size:27px;
        line-height:35px;
      }

      .description{
        font-size:17px;
        line-height:25px;
      }

      .sdks{
        margin-bottom:50px;
      }

      .subscribe-form{
        .field:first-child{
          input[type=text],
          input[type=email]{
            padding-left:50px;
          }
        }
      }
    }

    .content-right{
      width:40%;
    }

  }
}

@include breakpoint($phone){
  .subscribe-large{
    margin-top:0;

    &.has-sdks{
      .content-left{
        .description{
          margin-bottom:25px;
        }
      }

      &.has-actions{
        .sdks{
          margin-bottom:25px;
        }
      }
    }

    &:not(.has-subscribe-form){
      .content-left{
        padding-bottom:50px;
      }
    }

    & > .inner{
      & > .layout{
        flex-direction:column-reverse;
      }
    }

    .content-left{
      width:100%;
      padding-top:30px;
      padding-left:30px;
      padding-right:40px;

      .label{
        margin-bottom:0;
      }

      .title{
        font-size:21px;
        line-height:29px;
        margin-bottom:12px;
      }

      .description{
        margin-bottom:20px;
        font-size:15px;
        line-height:23px;
      }

      .sdks{
        margin-bottom:15px;
      }

      .subscribe-form{
        .field{
          input[type=text],
          input[type=email]{
            padding-left:30px;
          }
        }
        
        .field:first-child{
          width:180px;

          input[type=text],
          input[type=email]{
            padding-left:30px;
          }
        }
      }

      .sdks{
        margin-bottom:30px;
      }

      .sdks + .actions{
        margin-top:0;
      }
    }

    .content-right{
      width:100%;

      .image{
        position:relative;
        top:auto;
        left:auto;
        right:auto;
        bottom:auto;
        width:auto;
        height:225px;
      }
    }
  }
}
