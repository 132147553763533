// Label

.label{
  cursor:default;
  display:block;
  font-size:10px;
  line-height:12px;
  font-weight:600;
  letter-spacing:0.5px;
  margin-bottom:10px;
  text-transform:uppercase;
  
  &.white{
    & > .inner{
      background-color:$white;
      color:$blue;
    }
  }
  
  & > .inner{
    display:inline-block;
    background-color:$blue;
    color:$white;
    border-radius:3px;
    padding:5px 6px 3px 7px;

    &.grey{
      background-color:$grey;
    }
  }
  .children{
    margin-right: 10px;
  }
}

@include breakpoint($tablet-phone){
  .label{
    font-size:9px;
  }  
}
