// Partials / Pricing Block

.pricing-block{
  color:$blue-grey-med;
  font-size:18px;
  line-height:23px;
  max-width:1180px;
  margin-left:auto;
  margin-right:auto;
  margin-top:80px;
  margin-bottom:80px;

  & > .inner{
    background-color:$white;
    border-radius:3px;
    box-shadow:0 7px 50px 0 rgba(0,0,0,0.35);
    padding:50px;
  }

  .label{
    font-size:17px;
    line-height:25px;
    font-weight:400;
    margin-bottom:8px;
    text-transform:none;
    color:$blue;
    padding-left:1px;
  }

  .title{
    font-size:34px;
    line-height:45px;
    font-weight:500;
    margin-bottom:25px;
    color:$blue-black;
  }

  .description{
    font-size:18px;
    line-height:27px;
    margin-bottom:40px;
    font-weight:400;
    max-width:620px;
  }

  .pricing-features{
    margin-bottom:25px;

    & > .inner{
      & > .layout{
        display:flex;
        width:100%;
        border:1px solid $pricing-features-line-color;
        border-radius:3px;
      }
    }

    .feature{
      display:flex;
      flex:1;
      align-items:middle;
      justify-content:center;
      border-right:1px solid $pricing-features-line-color;
      padding:35px 25px 28px 25px;

      &:last-child{
        border-right:none;
      }

      .icon{
        width:40px;
        height:40px;
        flex-shrink:0;

        svg{
          width:100%;
          height:100%;
          fill:$blue-grey-med;
        }
      }

      .content{
        color:$blue-grey-med;
        font-size:13px;
        line-height:19px;
        padding-left:18px;

        strong{
          display:block;
          color:$blue_black;
          font-weight:500;
          font-size:18px;
          line-height:21px;
        }
      }
    }


  }

}

@include breakpoint($desktop){
  .pricing-block{
    .description{
      font-size:17px;
      line-height:27px;
      margin-bottom:35px;
    }

    .pricing-features{
      margin-bottom:20px;
    }

  }
}

@include breakpoint($tablet-landscape){
  .pricing-block{
    padding-left:30px;
    padding-right:30px;

    .pricing-features{
      .feature{
        padding:25px 15px 18px 15px;

        .icon{
          width:35px;
          height:35px;
        }

        .content{
          strong{
            font-size:15px;
          }
        }
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .pricing-block{
    margin-top:40px;
    margin-bottom:40px;

    & > .inner{
      padding:30px;
    }

    .label{
      font-size:15px;
      line-height:21px;
    }

    .title{
      font-size:27px;
      line-height:35px;
    }

    .pricing-features{
      .feature{
        display:block;
        padding:15px 15px 18px 17px;

        .icon{
          margin-bottom:5px;
        }

        .content{
          padding-left:0;
          font-size:13px;

          strong{
            font-size:13px;
          }
        }
      }
    }
  }
}


@include breakpoint($phone){
  .pricing-block{
    margin-top:10px;
    margin-bottom:10px;
    padding:10px;

    & > .inner{
      padding:30px 22px 40px 22px
      // padding-bottom:50px;
    }

    .label{
      margin-bottom:0;
    }

    .title{
      font-size:21px;
      line-height:29px;
      margin-bottom:12px;
    }

    .description{
      font-size:15px;
      line-height:23px;
      margin-bottom:25px;
    }

    .pricing-features{
      & > .inner{
        & > .layout{
          display:block;
        }

        .feature{
          display:flex;
          justify-content:left;
          border-right:none;
          border-bottom:1px solid $pricing-features-line-color;

          &:last-child{
            border-bottom:none;
          }

          .icon{
            display:flex;
            width:35px;
            flex-shrink:0;
          }

          .content{
            padding-left:10px;
          }
        }

        
      }
    }
  }
}

