// Hero

.full-background{
  .hero{
    background-image:none !important;
    background-color:transparent !important;

    &.is-header{
      padding-bottom:40px;
    }
  }
}

.hero{
  @extend %background-image-positions;
  background-size:cover;
  background-position:center;
  background-color:$hero-bg;
  
  & > .inner{
    position:relative;
    padding:280px 160px 240px 200px;
    display:flex;
  }
  
  &.is-header{
    & > .inner{
      max-width:1140px;
      margin-left:200px;
      margin-right:auto;
      padding-top:120px; //180px
      padding-bottom:60px;
      padding-left:0;
      padding-right:180px;
    }
    
    .content{
      & > .inner{
        padding-left:0;
      }

      .title{
        &.medium{
          
        }
      }
    }
  }

  &.half{
    .content{
      & > .inner{
        width:100%;
      }
    }
  }
  
  .content{
    flex-grow:1;
    
    & > .inner{
      padding-top:90px;
      padding-left:40px;
    }
    
    .title{
      @extend %title;
      display:inline-block;
      vertical-align:baseline;
      color:$white;
      font-size:60px;
      line-height:70px;
      max-width:860px;
      position:relative;
      left:-2px;
      margin-right:10px;
      margin-bottom:20px;
      
      &.medium{
        font-size:85px;
        line-height:92px;
        // max-width:620px;
        max-width:100%;
      }
      
      &.large{
        font-size:106px;
        line-height:115px;
      }

    }
    
    .description{
      @extend %description;
      font-size:21px;
      line-height:35px;
      font-weight:400;
      color:$white;
      padding-right:50px;
      margin-bottom:50px;
      max-width:670px;
    }
    
    .actions{
      li{
        list-style:none;
        display:inline-block;
        margin-right:10px;
        margin-bottom:13px;
        
        &:last-child{
          margin-right:0;
        }
      }
    }
  }
}

@include microsoft-edge-or-ie {
  // Edge does a horrible job at rendering -webkit-background-clip, remove it
  // IE doesn't support -webkit-background-clip, remove it
  .hero{
    .content{
      .title{
        @include undo-headline-cloud-effect;
      }
    }
  }
}

@include breakpoint($desktop){
  .hero{
    & > .inner{
      padding-top:220px;
    }

    &.is-header{
      & > .inner{
        margin-left:140px;
      }
    }
    
    .content{
      & > .inner{
        padding-top:80px;
      }
      
      .title{
        font-size:50px;
        line-height:60px;
        
        &.medium{
          font-size:75px;
          line-height:80px;
        }
        
        &.large{
          
        }
      }
      
      .description{
        padding-right:50px;
        margin-bottom:40px;
        font-style:20px;
        line-height:31px;
      }
    }
  }
}

@include breakpoint($laptop){
  .hero{
    & > .inner{
      padding-top:200px;
      padding-left:85px;
      padding-bottom:160px;
    }
    
    &.is-header{
      & > .inner{
        margin-left:85px;
        padding-right:240px;
      }
    }
    
    .content{
      & > .inner{
        padding-top:70px;
      }
      
      .title{
        &.medium{
          font-size:65px;
          line-height:70px;
          margin-bottom:10px;
        }
        
        &.large{
  
        }
      }
      
      .description{
        margin-bottom:30px;
        max-width:540px;
        
        &:last-child{
          margin-bottom:0;
        }
      }
    }
  }
  
}

@include breakpoint($laptop-tablet){
  .hero{
    & > .inner{
      padding-left:70px;
    }
    
    &.is-header{
      & > .inner{
        margin-left:70px;
      }
      
      .content{
        & > .inner{
          padding-left:0;
        }
      }
    }
    
    .content{
      & > .inner{
        padding-top:45px;
      }
      
      .title{
        font-size:45px;
        line-height:50px;
        
        &.medium{
          font-size:60px;
          line-height:65px;
        }
        
        &.large{
          font-size:90px;
          line-height:90px;
        }
      }
      
      .description{
        padding-right:0;
      }
    }
  }
}

@include breakpoint($tablet-landscape){
  .full-background{
    .hero{
      &.is-header{
        & > .inner{
          padding-bottom:25px;
        }
      }
    }
  }
  .hero{
    & > .inner{
      padding-left:50px;
      padding-right:120px;
    }
    
    &.is-header{
      & > .inner{
        margin-left:50px;
      }

      .content{
        .description{
          max-width:100%;
        }
      }
    }
    
    .content{
      & > .inner{
        padding-top:20px;
      }
      
      .title{
        font-size:37px;
        line-height:45px;
        
        &.medium{
          font-size:52px;
          line-height:60px;
        }
        
        &.large{
          font-size:80px;
          line-height:80px;
        }
      }

      .description{
        font-size:19px;
        line-height:29px;
        padding-right:0;
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .hero{
    & > .inner{
      padding-top:180px;
      padding-bottom:130px;
    }
    
    .content{
      & > .inner{
        padding-top:20px;
        padding-left:20px;
      }
      
      .title{
        font-size:33px;
        line-height:39px;
        max-width:400px;
        
        &.medium{
          font-size:49px;
          line-height:55px;
          max-width:400px;
        }
        
        &.large{
          font-size:70px;
          line-height:70px;
          margin-bottom:20px;
        }
      }
    }
  }
  
}

@include breakpoint($tablet-phone){
  .hero{
    & > .inner{
      padding-right:60px;
    }
    
    &.is-header{
      & > .inner{
        padding-right:160px;
      }
    }
    
    .content{
      .title{
        &.medium{
          font-size:42px;
          line-height:49px;
        }
        
        &.large{
          font-size:60px;
          line-height:66px;
        }
      }
    }
  }
}

@include breakpoint($phone){
  .hero{
    background-position:center center;
    
    & > .inner{
      padding-left:30px;
      padding-top:110px;
      padding-right:70px;
      padding-bottom:80px;
      display:block;
    }
    
    &.is-header{
      background-position:70% center;
      
      & > .inner{
        margin-left:30px;
        padding-top:100px;
        padding-right:80px;
        padding-bottom:30px;
      }
    }

    &.half{
      .content{
        & > .inner{
          width:100%;
        }
      }
    }
    
    .content{
      display:block;
      
      & > .inner{
        padding-left:0;
      }
      
      .title{
        font-size:27px;
        line-height:33px;
        
        &.medium{
          font-size:39px;
          line-height:47px;
        }
        
        &.large{
          font-size:60px;
          line-height:66px;
          padding-right:0;
          margin-bottom:15px;
        }
      }
      
      .description{
        font-size:16px;
        line-height:27px;
        padding-right:15px;
        padding-right:5px;
      }
    }
  }
}
