// Nav

.nav{
  position:absolute;
  z-index:1;
  top:54px;
  right:200px;
  font-size:13px;
  line-height:19px;
  font-weight:400;
  
  & > ul > li{
    position:relative;
    display:inline-block;
    margin-left:0;
    margin-right:0;
    padding:15px 20px;

    &.has-button{
      padding:7px 0;
      margin-left:20px;
      margin-right:0;
    }
    
    & > a:not(.button){
      @include link-underline(rgba(0,0,0,0), $blue);
      padding: 4px 2px;
      font-size:14px;
      line-height:19px;
      color:$grey;
      font-weight:500;
      text-decoration:none;
      padding:3px 1px;
      
      &:hover{
        color:$black;
      }
    }

    & > a.button{
      font-size:13px;
      line-height:15px;
      padding:8px 14px;
    }
  }
  
  .nav-icon{
    position:relative;
    top:-2px;
    vertical-align:middle;
    margin-right:3px;
    
    &.icon-ethereum{
      top:-3px;
    }
  }
}

.header{

  &.light {
    .nav{
      
      a:not(.button){
        color:$blue-black;
        
        &:hover{
          color:$blue-black;
          
        }
      }

      a.button{
        &.outline_blue_light{
          color:$blue-dark;
        }

        &:hover{
          &.hover_blue_on_white{
            background-color:$blue;
            border-color:$blue;
            color:$white;
          }

          &.hover_red_on_white{
            background-color:$red;
            border-color:$red;
            color:$white;
          }
        }
      }
    }
  }

  &.dark {
    .nav{
      a:not(.button){
        color:$white;

        &:hover{
          color:$white;
        }
      }
    }
  }

}

@include breakpoint($desktop){
  .nav{
    right:160px;
    top:42px;
  }
}

@include breakpoint($laptop){
  .nav{
    top:37px;
    right:140px;
  }
}


@include breakpoint($laptop-tablet){
  .nav{
    top:31px;
    right:120px;
    
    & > ul > li{
      padding-left:15px;
      padding-right:15px;

      &.has-button{
        margin-left:10px;
      }
      
      & > a:not(.button){
        font-size:14px;
      }

      & > a.button{
        font-size:13px;
        padding-left:12px;
        padding-right:12px;
      }
    }
  }
}

@include breakpoint($tablet-landscape){
  .nav{
    top:25px;
    right:90px;

    & > ul > li{
      padding-left:10px;
      padding-right:10px;

      & > a:not(.button){
        font-size:13px;
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .nav{
    top:25px;

    & > ul > li{
      padding-left:8px;
      padding-right:8px;

      &.has-button{
        margin-left:6px;
      }

      & > a:not(.button){
        font-size:12px;
      }

      & > a.button{
        font-size:11px;
        padding:6px 10px;
      }
    }
  }
}

@include breakpoint($tablet-phone){
  .nav{
    display:none;
  }
}
