// Partials / Video Player

// Sandbox Video Container
.sandbox-video-container{
  max-width:800px;
  margin-left:50px;
}

// Videos within Content 60-40
.contents-60-40{
  &.clouds{
    .content-60{
      .video-player{
        box-shadow:0 5px 15px 0 rgba(0,0,0,0.3);
      }
    }
  }
}

.video-player{
  position:relative;
  padding-bottom:56.25%;
  height:0;
  overflow:hidden;
  max-width:100%;

  &:hover{
    .video-play-button{
      background-color:$blue;
    }
  }

  .video-poster,
  .video-play-button{
    cursor:pointer;
    transition-duration:0.5s;
    transition-property:opacity background-color;
    opacity:1;
  }

  &.js-played{
    .video-poster,
    .video-play-button{
      cursor:default;
      pointer-events:none;
      opacity:0;
    }
  }
  
  & > .inner{
    position:absolute;
    display:block;
    width:100%;
    height:100%;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }

  .video-poster{
    position:absolute;
    z-index:1;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:$blue-dark;
    background-size:cover;
  }

  .video-play-button{
    position:absolute;
    z-index:2;
    left:0;
    bottom:0;
    width:74px;
    height:74px;
    background-color:$red-light;
  }

  .video-play-button-icon{
    position:absolute;
    left:calc(50% - 11px);
    top:calc(50% - 13px);
    transform:rotate(90deg);
    transform-origin:50% 50%;
    width:26px;
    height:26px;
  }
  
  .video-play-button-icon-triangle{
    position:relative;
    left:6px;
    top:7px;
    background-color:$white;
    text-align:left;

    &:before,
    &:after{
      content:'';
      position:absolute;
      background-color:inherit;
    }

    &,
    &:before,
    &:after{
      width:13px;
      height:13px;
      border-top-right-radius:15%;
    }

    &{
      transform:rotate(-60deg) skewX(-30deg) scale(1,.866);
    }

    &:before{
      transform:rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
    }

    &:after{
      transform:rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
    }
  }

  .video-holder{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width:100% !important;
    height:100% !important;

    & > div{
      width:100% !important;
      height:100% !important;
    }
  }
}

@include breakpoint($tablet-portrait){
  .video-player{
    .video-play-button{
      width:50px;
      height:50px;
    }

    .video-play-button-icon{
      left:calc(50% - 13px);
    }

    .video-play-button-icon-triangle{
      left:8px;
      top:8px;

      &,
      &:before,
      &:after{
        width:9px;
        height:9px;
      }
    }
  }
}
