// Partials / Content Block

.content-block{
  background-size:100% auto;
  background-position:center top;
  background-repeat:no-repeat;
  
  &.border-top-blue{
    border-top:9px solid $blue;
  }
  
  &.bg-blue-dark-bokeh{
    background-color:$path-bg-color !important;
    @include bg('../../assets/images/texture/path-bg.jpg',
                '../../assets/images/texture/path-bg@2x.jpg');
    background-size:100% auto;
    background-position:center top;
    background-repeat:no-repeat;
  }
  
  .photo-cover{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
    font-size:0;
    line-height:0;
    
    &.border-bottom-yellow{
      border-bottom:9px solid $yellow;
    }
  }
}

@include breakpoint($phone){
  .content-block{
    .photo-cover{
      position:relative;
      top:auto;
      left:auto;
      right:auto;
      bottom:auto;
      width:100%;
      height:0;
      padding-bottom:75%;
    }
  }
}

