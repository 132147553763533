// Footer

@mixin footer-social-icon($name){
  .footer-#{$name}{
    -webkit-mask: url(../images/icon/social-#{$name}.svg) no-repeat center;
    mask: url(../images/icon/social-#{$name}.svg) no-repeat center;
  }
}

.landing{
  & + .footer{
    & > .inner{
      padding-left:200px;
    }
  }
}

.course-viewer{
  & + .footer{
    display:none;
  }
}

.footer{
  background-color:$white;
  
  & > .inner{
    padding:120px 200px 90px 140px;
  }
  
  .promise{
    color:$black;
    
    & > .inner{
      display:inline-block;
    }
    
    .jojo{
      display:block;
      width:90px;
      height:90px;
      
      // JoJo Ledger
      // background-image:url(../images/brand/jojo-small.svg);
      // END JoJo Ledger

      // JoJo Treasure
      background-image:url(../images/brand/jojo-treasure-small.png);
      // END JoJo Treasure

      background-size:90px 90px;
      margin-bottom:33px;
    }
    
    .tagline{
      font-size:30px;
      line-height:35px;
      font-weight:700;
      margin-bottom:15px;
    }
    
    .copyright{
      font-size:14px;
      line-height:21px;
      margin-bottom:29px;
    }
    
    .colors{
      display:table;
      table-layout:fixed;
      width:400px;
      
      div{
        display:table-cell;
        width:auto;
        height:3px;
        
        &:nth-child(1){
          background-color:$blue-dark;
        }
        
        &:nth-child(2){
          background-color:$blue-med;
        }
        
        &:nth-child(3){
          background-color:$blue;
        }
        
        &:nth-child(4){
          background-color:$blue-light;
        }
        
        &:nth-child(5){
          background-color:$yellow;
        }
        
        &:nth-child(6){
          background-color:$red;
        }
      }
    }
  }
  
  li{
    list-style:none;
  }
  
  .links{
    display:flex;
    padding-top:57px;
    padding-bottom:57px;
    
    .dragonchain-links{
      display:flex;
      width:55%;
      
      ul{
        flex:1;
        vertical-align:top;
      }
      
      li{
        font-size:19px;
        line-height:33px;
        font-weight:600;
        color:$black;
      }
      
      a{
        transition-duration:0.2s;
        transition-property:color;
        color:$black;
        text-decoration:none;
        
        &.disabled{
          pointer-events:none;
          color:$footer-link-disabled-color;
        }
        
        &:hover{
          color:$blue;
        }
      }
      
    }
    
    .social-links{
      position:relative;
      top:-15px;
      width:45%;
      text-align:right;
      
      li{
        display:inline-block;
        margin-right:20px;
        
        &:last-child{
          margin-right:0;
        }
      }
      
      a{
        transition-duration:0.2s;
        transition-property:background-color;
        display:block;
        width:40px;
        height:40px;
        background-color:$footer-social-icon-color;
        font-size:0;
        line-height:0;
        
        &:hover{
          background-color:$footer-social-icon-color-hover;
        }
      }
      
      // Footer Social Icons
      // # -> .footer-facebook{ ... }
      @include footer-social-icon(facebook);
      @include footer-social-icon(twitter);
      @include footer-social-icon(linkedin);
      @include footer-social-icon(github);
      @include footer-social-icon(telegram);
      @include footer-social-icon(youtube);
    }
    
  }
  
  .fineprint{
    display:flex;
    width:100%;
    border-top:1px solid $footer-line-color;
    padding-top:30px;
    
    .disclaimer{
      font-size:12px;
      line-height:21px;
      color:$grey;
      width:66%;

      a:not(.button){
        transition-duration:0.2s;
        transition-property:color;
        color:inherit;

        &:hover{
          color:$blue-black;
        }
      }
    }
    
    .location{
      color:$black;
      text-align:right;
      width:auto;
      width:34%;
      
      .flag-usa{
        background-image:url(../images/icon/usa.svg);
        background-repeat:no-repeat;
        width:42px;
        height:25px;
        display:inline-block;
        vertical-align:middle;
        margin-right:7px;
      
        &:after{
          display:none;
        }
      }
    
      .country{
        display:inline-block;
        vertical-align:middle;
        font-size:12px;
        line-height:15px;
        font-weight:700;
      }
    }
    
  }
  
}

@include breakpoint($desktop){
  .landing{
    & + .footer{
      & > .inner{
        padding-left:140px;
      }
    }
  }
  
  .footer{
    & > .inner{
      padding:100px 160px 80px 100px;
    }
    
    .promise{
      .tagline{
        font-size:27px;
        line-height:32px;
        margin-bottom:12px;
      }
      
      .colors{
        width:360px;
      }
    }
    
    .links{
      padding-top:50px;
      padding-bottom:50px;
      
      .dragonchain-links{
        li{
          font-size:17px;
          line-height:31px;
        }
      }
    }
  }
}

@include breakpoint($laptop){
  .landing{
    & + .footer{
      & > .inner{
        padding-left:100px;
      }
    }
  }
  
  .footer{
    & > .inner{
      padding:90px 100px 70px 85px;
    }
  }
}

@include breakpoint($laptop-tablet){
  .landing{
    & + .footer{
      & > .inner{
        padding-left:80px;
      }
    }
  }
  
  .footer{
    & > .inner{
      padding:80px 70px 60px 80px;
    }
    
    .links{
      .social-links{
        a{
          width:35px;
          height:35px;
        }
      }
    }
  }
}

@include breakpoint($tablet-landscape){
  .landing{
    & + .footer{
      & > .inner{
        padding-left:50px;
      }
    }
  }
  
  .footer{
    & > .inner{
      padding:70px 50px 50px 50px;
    }
  }
}

@include breakpoint($tablet-portrait){
  .footer{
    .links{
      display:block;
      padding-bottom:15px;
      
      .dragonchain-links{
        margin-bottom:45px;
        
        ul{
          margin-right:50px;
        }
      }
      
      .social-links{
        position:relative;
        left:-7px;
        width:auto;
        text-align:left;
        
        li{
          margin-right:15px;
          
          &:last-child{
            margin-right:0;
          }
        }
        
        a{
          width:30px;
          height:30px;
        }
      }
      
    }
  }
}

@include breakpoint($tablet-phone){
  .footer{
    .links{
      .dragonchain-links{
        display:block;
        
        ul{
          display:block;
          
          &.documents{
            margin-top:30px;
          }
        }
      }
    }
  }
}

@include breakpoint($phone){
  .landing{
    & + .footer{
      & > .inner{
        padding-left:30px;
      }
    }
  }
  
  .footer{
    & > .inner{
      padding:40px 30px 80px 30px;
    }
    
    .promise{
      .jojo{
        position:relative;
        left:-5px;
      }
      
      .tagline{
        font-size:20px;
        line-height:25px;
        margin-bottom:5px;
      }
      
      .copyright{
        font-size:12px;
        line-height:19px;
        margin-bottom:22px;
      }
      
      .colors{
        width:100%;
      }
    }
    
    .links{
      .dragonchain-links{
        ul{
          margin-right:30px;
        }
      }

      .social-links{
        margin-left:-10px;
        margin-right:-10px;
        
        ul{
          display:table;
          width:100%;
          table-layout:fixed;
        }
        
        li{
          display:table-cell;
          margin-right:0;
          text-align:center;
          vertical-align:middle;
          
          a{
            margin-left:auto;
            margin-right:auto;
          }
        }
      }
    }
    
    .fineprint{
      display:block;
      
      .disclaimer,
      .location{
        display:block;
        width:auto;
      }
      
      .disclaimer{
        margin-bottom:30px;
      }
      
      .location{
        text-align:left;
      }
    }
  }
}



