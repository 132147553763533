// contents-quarters

.content-block{
  .contents-quarters{
    display:flex;
    padding: 0px 180px;

    &:last-child{
      .content-quarter{
        padding-bottom:100px;
      }
    }
    
    &.reverse{
      flex-direction:row-reverse;
    }
    
    .content-quarter{
      width:25%;
      color:$grey-med;
      
      & > .inner{
        padding:80px 20px 40px;
      }
      
      .photo-content{
        position:relative;
        top:-30px;
      }
      
      .label + .title{
        position:relative;
        margin-top:-4px;
      }
      
      .title{
        font-size:33px;
        line-height:46px;
        font-weight:600;
        margin-bottom:0;
        color:$black;
      }
      
      .description{
        font-size:19px;
        line-height:29px;
        margin-bottom:30px;
        
        &:last-child{
          margin-bottom:0;
        }
        
        & + .label{
          margin-top:55px;
        }
      }
    }
  }
  
}

@include breakpoint($desktop){
  .content-block{
    .contents-quarters{
      margin: 0px -60px;

      .content-quarter{
        .label{
          & + .title{
            margin-top:0
          }
        }
      }
    }
  }
}

@include breakpoint($laptop){
  .content-block{
    .contents-quarters{
      margin: 0px -100px;
      
      &:last-child{
        .content-quarter{
          padding-bottom:60px;
        }
      }
      
      .content-quarter{
        & > .inner{
          padding-top:60px;
          padding-bottom:60px;
        }
        
        .title{
          font-size:29px;
          line-height:35px;
          margin-bottom:10px;
        }
        
        .description{
          font-size:17px;
          line-height:29px;
        }
        
        .photo-content{
          top:-10px;
        }
      }
    }
  }
}

@include breakpoint($laptop-tablet){
  .content-block{
    .contents-quarters{
      margin: 0px -120px;
      flex-wrap: wrap;

      .content-quarter{
        width:50%;

        & > .inner{
          padding-top:40px;
          padding-bottom:40px;
        }
        
        .title{
          font-size:25px;
          line-height:29px;
        }
        
        .description{
          font-size:17px;
          line-height:27px;
        }
      }
    }
  }
}

@include breakpoint($tablet-landscape){
  .content-block{
    .contents-quarters{
      margin: 0px;
      padding: 0px;

      &.reverse{
        .content-quarter{
          &:nth-child(even){
            & > .inner{
              padding-left:80px;
            }
          }
      
          &:nth-child(odd){
            & > .inner{
              padding-right:80px;
            }
          }
        }
      }
      
      &:not(.reverse){
        .content-quarter{
          &:nth-child(even){
            & > .inner{
              padding-right:80px;
            }
          }
          
          &:nth-child(odd){
            & > .inner{
              padding-left:80px;
            }
          }
        }
      }
      
      .content-quarter{
        .description{
          font-size:16px;
          line-height:27px;
          
          & + .label{
            margin-top:35px;
          }
        }
      }
    }
  }
}


@include breakpoint($tablet-portrait){
  .content-block{
    .contents-quarters{
      
      &.reverse{
        .content-quarter{
          &:nth-child(even){
            & > .inner{
              padding-left:50px;
            }
          }
      
          &:nth-child(odd){
            & > .inner{
              padding-right:50px;
            }
          }
        }
      }
      
      &:not(.reverse){
        .content-quarter{
          &:nth-child(even){
            & > .inner{
              padding-right:50px;
            }
          }
          
          &:nth-child(odd){
            & > .inner{
              padding-left:50px;
            }
          }
        }
      }
      
      .content-quarter{
        .description{
          font-size:16px; 
          line-height:27px;
          
          & + .label{
            margin-top:35px;
          }
        }
      }
    }
  }
}

@include breakpoint($phone){
  .content-block{
    .contents-quarters{
      margin: 0px;
      padding: 0px;
      display:block;
      
      &:last-child{
        .content-quarter{
          padding-bottom:0;
        }
      }
      
      &.reverse{
        .content-quarter{
          &:nth-child(even){
            & > .inner{
              padding-left:30px;
              padding-right:30px;
            }
          }
      
          &:nth-child(odd){
            & > .inner{
              padding-left:30px;
              padding-right:30px;
            }
          }
        }
      }
      
      &:not(.reverse){
        .content-quarter{
          &:nth-child(even){
            & > .inner{
              padding-left:30px;
              padding-right:30px;
            }
          }
          
          &:nth-child(odd){
            & > .inner{
              padding-left:30px;
              padding-right:30px;
            }
          }
        }
      }
      
      .content-quarter{
        width:auto;
        
        .photo-content{
          margin-bottom:-30px;
        }
      }
      
    }
  }
}
