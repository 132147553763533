// Extend / Description

%description{
  &:last-child{
    margin-bottom: 0;
  }

  &.black{
    color:$black;
  }
  
  &.grey-med{
    color:$grey-med;
  }

  &.white{
    color:$white;
  }
  
  &.blue-light{
    // color:$blue-light;
    color:#9FDBF9;
  }
}