// Forms / Switch Nav

.switch-nav{
  display:inline-block;
  font-size:0;
  line-height:0;
  margin-top:20px;
  margin-bottom:20px;

  a,
  button{
    cursor:pointer;
    transition-duration:0.2s;
    transition-property:all;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    text-decoration:none;
    display:inline-block;
    vertical-align:middle;
    font-size:16px;
    line-height:19px;
    border:1px solid $line_color;
    border-right:none;
    padding:12px 25px;
    margin:0;
    background-color:transparent;

    &:first-child{
      border-radius:40px 0 0 40px;
      padding-left:30px;
    }

    &:last-child{
      border-radius:0 40px 40px 0;
      border-right:1px solid $line_color;
      padding-right:30px;
    }
  }

  &.blue_on_blue{
    a,
    button{
      border-color:$switch-blue-bg-color;
      background-color:transparent;
      color:$switch-blue-bg-color;

      &.white{
        color:$white;
      }
      
      &.js-active{
        background-color:$white;
        border-color:$white;
        color:$blue;

        &.white{
          color:$blue;
        }
      }

      &:focus{
        outline:0;
      }

      &.js-active + button{
        border-left-color:$white;
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .switch-nav{
    margin-top:15px;
    margin-bottom:15px;

    a,
    button{
      font-size:14px;
      line-height:17px;
      padding:10px 20px;
    }
  }
}
