// Partials / Quote

.multi-quote{
  padding-bottom:20px;

  .flickity-viewport {
    transition: height 0.2s;
  }

  .quote{
    width:100%;
  }

  .flickity-button{
    display:none;
  }

  .flickity-page-dots{
    bottom:25px;

    .dot{
      background-color: $white;
      width:12px;
      height:12px;

      &.is-selected{
        background-color: $blue;
      }
    }
  }
}

.quote{
  position:relative;
  background-color:$blue-black;
  color:$white;

  & > .inner{
    padding:80px 0 80px 0;

    & >  .layout{
      display: flex;
    }
  }

  .logo{
    width:340px;
    display:flex;
    align-items:center;

    width:38%;
    padding-left:70px;

    img{
      width:100%;
      max-width:275px;
      height:auto;
      margin-left:auto;
      margin-right:auto;
    }
  }

  .content{
    flex:1;
    padding-left:0;
    padding-right:80px;
  }

  blockquote{
    position:relative;
    padding:15px 40px 15px 140px;
    font-size:21px;
    line-height:38px;
    font-weight:600;

    &:before{
      position:absolute;
      content:'';
      width:5px;
      left:0;
      top:0;
      bottom:0;
      background-color:red;
      border-radius:10px;

      background: rgb(0,78,161);
      background: -moz-linear-gradient(top, rgba(0,78,161,1) 0%, rgba(23,151,229,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,78,161,1) 0%,rgba(23,151,229,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,78,161,1) 0%,rgba(23,151,229,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    &.has-quotes{
      text-indent:-10px;
    }

    p{
      margin-bottom:15px;
    }

    a{
      @include link_underline($blue-light, $red-light, 4);
      text-decoration:none;
      color:$white;
      padding-bottom:2px;
    }

    footer{
      color:$grey;
      font-size:15px;
      line-height:19px;
      font-weight:400;
    }
  }
  
}

@include breakpoint($laptop){
  .quote{
    blockquote{
      padding-left:80px;
    }
  }
}

@include breakpoint($tablet-landscape){
  .quote{
    & > .inner{
      padding-left:58px;
      padding-right:80px;
    }

    .logo{
      width:200px;
      padding-left:0;
    }

    .content{
      padding-left:35px;
      padding-right:0;
    }

    blockquote{
      padding-left:50px;
      padding-right:0;
      font-size:19px;
      line-height:29px;
    }
  }
}

@include breakpoint($tablet-portrait){
  .quote{
    & > .inner{
      padding-left:60px;
    }

    blockquote{
      font-size:17px;
      line-height:29px;
      padding-right:15px;
    }

  }
}

@include breakpoint($phone){
  .quote{
    & > .inner{
      padding:60px 30px 50px 30px;
  
      & >  .layout{
        display:block;
      }
    }

    .logo,
    .content{
      display:block;
      width:100%;
      padding:0;
    }

    .logo{
      margin-bottom:40px;

      img{
        display:block;
        margin-left:auto;
        margin-right:auto;
        max-width:200px;
      }
    }

    blockquote{
      padding:70px 2px 30px 2px;
      font-size:17px;
      line-height:31px;

      &:before{
        top:0;
        left:0;
        right:0;
        bottom:auto;
        width:auto;
        height:5px;

        background: rgb(0,78,161);
        background: -moz-linear-gradient(left, rgba(0,78,161,1) 0%, rgba(23,151,229,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(0,78,161,1) 0%,rgba(23,151,229,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(0,78,161,1) 0%,rgba(23,151,229,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
    }
  }
}
