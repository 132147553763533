// Mixins / Fonts

@mixin font-sans{
  font-family:Graphik, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;;
  font-weight:400;
}

@mixin font-condensed{
  font-family:acumin-pro-extra-condensed, sans-serif;
  font-weight:400;
}
