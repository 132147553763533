// Quicknav

.quicknav{
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  right:0;
  left:0;
  bottom:0;
  z-index:3;
  
  &.is-stuck{
    .handle{
      height:100px;
      
      .controls{
        div{
          top:50px;
        }
      }
      
      .open-label,
      .close-label{
        opacity:0 !important;
        top:90px;
        white-space:nowrap;
      }
    }
  }
  
  .handle{
    transition-duration:0.4s;
    transition-property:background height;
    
    cursor:pointer;
    position:absolute;
    z-index:1;
    top:0;
    right:0;
    width:100px;
    height:285px;
    color:$white;

    &.red{
      background-color:$red-light;
    }
    
    &.blue{
      background: $blue-med;
      background: -moz-linear-gradient(top, #002e66 0%, #003b82 32%, #006eb3 100%);
      background: -webkit-linear-gradient(top, #002e66 0%,#003b82 32%,#006eb3 100%);
      background: linear-gradient(to bottom, #002e66 0%,#003b82 32%,#006eb3 100%);
    }

    &:after{
      transition-duration:0.2s;
      transition-property:opacity;
      pointer-events:none;
      content:'';
      position:absolute;
      width:100%;
      height:200px;
      left:0;
      right:0;
      bottom:-200px;
      opacity:1;
      
      background: -moz-linear-gradient(top, rgba(0,0,0,0.04) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0.04) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0.04) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    
    }
    
    &:hover{
      background-color:$red;
    }
    
    .controls{
      position:relative;
      
      div{
        transition-duration:0.2s;
        transition-property:all;
        width:24px;
        height:24px;
        top:75px;
        left:38px;
        
        &,
        &:before,
        &:after{
          transition-duration:0.2s;
          transition-property:all;
          position:relative;
          content:'';
          display:block;
          width:24px;
          height:2px;
          background-color:$white;
          border-radius:1px;
        }
        
        &:before{
          top:-8px;
        }
        
        &:after{
          top:6px;
        }
      }
    }
    
    .open-label,
    .close-label{
      transition-duration:0.2s;
      transition-property:opacity top;
      opacity:1;
      position:absolute;
      top:110px;
      left:61px;
      font-size:17px;
      line-height:23px;
      font-weight:500;
      text-transform:none;
      transform-origin: top left;
      transform:rotate(90deg);
      letter-spacing:1px;
      opacity:1;
      width:110px;
    }
    
    .close-label{
      opacity:0;
    }
  }
  
  &.is-open{
    .handle{
      .controls{
        position:relative;
      
        div{
          background-color:rgba(255,255,255,0);
          
          &:before{
            transform:rotate(45deg);
            top:-1px;
          }
          
          &:after{
            transform:rotate(-45deg);
            top:-3px;
          }
        }
      }
      
      .close-label{
        opacity:1;
      }
      
      .open-label{
        opacity:0;
      }
    }
    
    .menu{
      opacity:1;
      pointer-events:auto;
    }
  }
  
  .menu{
    opacity:0;
    pointer-events:none;
    
    position:absolute;
    top:0;
    left:0;
    right:0;
    overflow:auto;
    
    & > .inner{
      box-shadow:0 3px 20px 0 rgba(0,0,0,0.2);
      background-color:$white;
      margin-bottom:30px;
    }
    
    .jojo{
      position:absolute;
      z-index:2;
      left:60px;
      top:80px;
      width:180px;
    
      & > .inner{
        position:relative;
        display:block;

        // JoJo Ledger
        // background-image:url(../../assets/images/brand/jojo-small.svg);
        // END JoJo Ledger

        // JoJo Treasure
        background-image:url(../../assets/images/brand/jojo-treasure-small.png);
        background-size:100% auto;
        // END JoJo Treasure

        width:180px;
        height:180px;
      }
    }
    
    .links{
      display:flex;
      flex-direction:row-reverse;
      min-height:600px;
    }
    
    .link-list{
      width:50%;
      padding:100px 105px 150px 400px;
      
      a:not(.button){
        @include link-underline(rgba(0,0,0,0), $blue);
        padding-bottom:2px;
        
        font-size:15px;
        line-height:19px;
        color:$grey;
        font-weight:500;
        text-decoration:none;
        
        &:hover{
          color:$black;
        }
      }

      &:first-child{
        background-color:$grey-faint;
        width:50%;
        padding-left:105px;
        
        li{
          color:$blue;
          
          a:not(.button){
            @include link-underline(transparent, $blue, 3px);
            color:$black;
          }
        }
      }
      
      li{
        display:block;
        font-size:23px;
        line-height:29px;
        color:$red;
        padding-top:10px;
        padding-bottom:10px;
        
        a:not(.button){
          display:inline-block;
          // font-size:31px;
          // line-height:35px;
          font-size:27px;
          line-height:33px;

          font-weight:600;
          text-decoration:none;
          
          @include link-underline(transparent, $red, 3px);
          color:$black;
        }
      }
    }
  }
  
}

@include breakpoint($desktop){
  .quicknav{
    
    &.is-stuck{
      .handle{
        height:80px;
        
        .controls{
          div{
            top:37px;
          }
        }
        
        .open-label,
        .close-label{
          top:60px;
        }
      }
    }
    
    .handle{
      width:80px;
      height:228px;
      
      .controls{
        div{
          top:56px;
          left:28px;
        }
      }
      
      .open-label,
      .close-label{
        top:88px;
        left:51px;
        font-size:15px;
      }
    }
    
    .menu{
      .jojo{
        width:140px;
        
        & > .inner{
          width:140px;
          height:140px;
        }
      }
      
      .link-list{
        padding-left:300px;
      }
    }
  }
}

@include breakpoint($laptop){
  .quicknav{
    .menu{
      .jojo{
        width:120px;
        left:40px;
        top:70px;
        
        & > .inner{
          width:120px;
          height:120px;
        }
      }
      
      .link-list{
        padding-left:230px;
        padding-top:80px;
        padding-bottom:100px;
        
        // li{
        //   padding-top:14px;
        //   padding-bottom:14px;
          
        //   a:not(.button){
        //     font-size:27px;
        //     line-height:33px;
        //   }
        // }
      }
    }
  }
}

@include breakpoint($tablet-landscape){
  .quicknav{
    
    &.is-stuck{
      .handle{
        top:10px;
        right:7px;
        border-radius:60px;
        
        width:60px;
        height:60px;
        
        &:after{
          opacity:0;
        }
        
        .controls{
          div{
            top:29px;
            left:18px;
          }
        }
      }
    }
    
    .handle{
      width:69px;
      height:210px;
      
      .controls{
        div{
          top:49px;
          left:23px;
        }
      }
      
      .open-label,
      .close-label{
        top:81px;
        left:46px;
        font-size:13px;
      }
    }
    
    .menu{
      .jojo{
        width:100px;
        left:60px;
        
        & > .inner{
          width:100px;
          height:100px;
        }
      }
      
      .link-list{
        padding:80px 60px 80px 230px;
        
        &:first-child{
          padding-left:80px;
        }
        
        li{
          font-size:17px;
          line-height:21px;
          padding-top:10px;
          padding-bottom:10px;
          
          a:not(.button){
            font-size:21px;
            line-height:25px;
          }
        }
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .quicknav{
    .handle{
      width:62px;
      height:190px;
      
      .controls{
        div{
          top:48px;
          left:21px;
          width:18px;
          
          &,
          &:before,
          &:after{
            width:22px;
          }
          
          &:before{
            top:-7px;
          }
        
          &:after{
            top:5px;
          }
        }
      }
      
      .open-label,
      .close-label{
        top:76px;
        left:42px;
        font-size:12px;
      }
    }

    .menu{
      .jojo{
        width:80px;
        left:30px;
        
        & > .inner{
          width:80px;
          height:80px;
        }
      }
      
      .link-list{
        padding:80px 60px 100px 150px;
        
        &:first-child{
          padding-left:80px;
        }
        
        li{
          padding-top:10px;
          padding-bottom:10px;
          font-size:15px;
          line-height:21px;
          
          a:not(.button){
            font-size:17px;
            line-height:23px;
          }
          
          a.button{
            transform:scale(0.9);
            transform-origin:0 0;
          }
        }
      }
      
    }
  }
}

@include breakpoint($tablet-phone){
  .quicknav{
    
    &.is-stuck{
      .handle{
        top:10px;
        right:5px;
        border-radius:48px;
        width:47px;
        height:47px;
        
        &:after{
          opacity:0;
        }
        
        .controls{
          div{
            top:23px;
            left:14px;
          }
        }
      }
    }
    
    .handle{
      width:54px;
      height:174px;
      
      &:after{
        display:none;
      }
      
      .controls{
        div{
          top:38px;
          left:18px;
          width:18px;
          
          &,
          &:before,
          &:after{
            width:18px;
          }
          
          &:before{
            top:-6px;
          }
        
          &:after{
            top:4px;
          }
        }
      }
      
      .open-label,
      .close-label{
        top:64px;
        left:38px;
        font-size:13px;
      }
    }
    
    .menu{
      & > .inner{
        position:fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        overflow-y:scroll;
        -webkit-overflow-scrolling: touch;
        margin-bottom:0;
      }
      
      .jojo{
        position:relative;
        z-index:1;
        width:70px;
        top:30px;
        
        & > .inner{
          width:70px;
          height:70px;
        }
      }
      
      .links{
        position:relative;
        display:block;
        background-color:$white;
      }
      
      .link-list{
        display:block;
        padding:20px 30px 70px 30px;
        width:100%;
        
        &:first-child{
          width:100%;
          padding-top:50px;
          padding-left:30px;
          background-color:$white;
          padding-bottom:0px;
        }
        
        li{
          padding-top:5px;
          padding-bottom:5px;
          font-size:14px;
          
          a:not(.button){
            font-size:16px;
            line-height:21px;
          }
          
          a.button{
            margin-top:5px;
            margin-bottom:-8px;
            transform:scale(0.75);
            transform-origin:0 0;
          }
        }
      }
    }
  }
}
