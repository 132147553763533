// Stylesheets / Partials  Table

.table{
  position:relative;
  display:table;
  width:100%;
  
  // Shadow on the table requires JavaScript to enable the box-shadow
  .shadow{
    pointer-events:none;
    color:$black;
    display:block;
    position:absolute;
    height:0;
    left:0;
    right:0;
    box-shadow:4px 4px 15px 0 rgba(0,0,0,0.1);
    z-index:0;
  }
  
  .thead{
    display:table-row-group;
    @include font-sans;
    color:$table_thead_color;
    font-size:16px;
    line-height:19px;
    
    .th{
      position:relative;
      padding-bottom:20px;
      
      &.is-sortable{
        cursor:pointer;
        
        &:empty{
          &:after{
            display:none;
          }
        }
        
        &:after{
          position:relative;
          display:inline-block;
          content:'';
          width:7px;
          height:7px;
          transform:rotate(-135deg);
          top:0px;
          border-right:2px solid transparent;
          border-bottom:2px solid transparent;
          margin-left:8px;
        }
        
        &.js-active{
          &:after{
            border-right:2px solid $table_thead_color_active;
            border-bottom:2px solid $table_thead_color_active;
          }
        }
        
        &.js-sort-desc{
          &:after{
            transform:rotate(45deg);
            top:-3px;
          }
        }
        
        &:hover{
          color:$table_thead_color_active;
        }
      }
      
      &.js-active{
        color:$table_thead_color_active;
      }
    }
    
  }
  
  .tbody{
    display:table-row-group;
    position:relative;
    
    border-radius:3px;
    
    .tr{
      &.selected{
        .td{
          color: $blue-dark;
          font-weight: 500;
          border-top: 2px solid $blue;
          border-bottom: 2px solid $blue; 
        }

        .td{
          &:first-child{
            border-left: 2px solid $blue;
          }
          
          &:last-child{
            border-right: 2px solid $blue;
          }
        }
  
        .td{
          &:first-child{
            border-bottom-left-radius:3px;
          }
          
          &:last-child{
            border-bottom-right-radius:3px;
          }
        }
      }

      &:first-child{
        .td{
          &:first-child{
            border-top-left-radius:3px;
          }
          
          &:last-child{
            border-top-right-radius:3px;
          }
        }
      }
      
      &:last-child{
        .td{
          &:first-child{
            border-bottom-left-radius:3px;
          }
          
          &:last-child{
            border-bottom-right-radius:3px;
          }
        }
      }
      
      &:hover{
        .td{
          background-color:#f9f9f9;
          
          &:before{
            background-color:$line_color;
          }
          
          &:after{
            left:0;
            right:0;
          }
        }
      }
    }
  }
  
  .tbody{
    @include font-sans;
    box-shadow:1px 3px 15px 0 rgba(0, 0, 0, 0.2);
    color:$table_tbody_color;
    font-size:14px;
    line-height:17px;
    
    &:first-child{
      border-radius:3px 3px 0 0;
    }
    
    &:last-child{
      border-radius:0 0 3px 3px;
    }
    
    .td{
      background-color:$white;
    }
    
  }
  
  .tr{
    display:table-row;
  }
  
  .th{
    display:table-cell;
    padding:10px 23px;
  }
  
  .th,
  .td{
    &.min{
      width:1%;
      white-space:nowrap;
    }
    
    &.center{
      text-align:center;
    }
  }
  
  .th{
    &.center{
      &.is-sortable{
        &:before{
          position:relative;
          display:inline-block;
          content:'';
          width:7px;
          height:7px;
          transform:rotate(45deg);
          border-right:2px solid transparent;
          border-bottom:2px solid transparent;
          margin-right:8px;
          top:-3px;
        }
      }
    }
  }
  
  .td{
    position:relative;
    display:table-cell;
    padding:20px 23px;
    vertical-align:middle;
    
    &:before,
    &:after{
      position:absolute;
      content:'';
      display:block;
      height:1px;
      background-color:$line_color;
      left:0;
      right:0;
    }
    
    &:before{
      background-color:transparent;
      top:-1px;
    }
    
    &:after{
      bottom:0;
    }
    
    &:first-child{
      &:after{
        left:23px;
      }
    }
    
    &:last-child{
      &:after{
        right:23px;
      }
    }
    
    .icon{
      margin-right:5px;
      
      &:last-child{
        margin-right:0;
      }
    }
    
    .icon-gold-jojo-small{
      top:-1px;
      margin-right:8px;
    }
  }
  
  .tr{
    &:first-child{
      .td{
        &:before{
          display:none;
        }
      }
    }
    
    &:last-child{
      .td{
        &:after{
          display:none;
        }
      }
    }
  }
  
  strong{
    color:$table_tbody_strong_color;
  }
  
  .title{
    color:$blue_med;
    font-size:17px;
    line-height:21px;
    margin-bottom:8px;
    
    a{
      transition-duration:0.2s;
      transition-property:border;
      
      color:inherit;
      text-decoration:none;
      border-bottom:1px solid $line_color;
      
      &:hover{
        border-bottom:3px solid $blue_light;
      }
    }
    
    &:last-child{
      margin-bottom:0;
    }
    
    strong{
      @include font-sans;
      color:inherit;
    }
  }
  
  .small{
    color:$table_tbody_small_color;
    @include font-sans;
    font-size:12px;
    line-height:14px;
    
    strong{
      @include font-sans;
    }
  }
  
  .detail{
    color:$table_tbody_detail_color;
    font-size:12px;
    line-height:15px;
    margin-top:5px;
    margin-bottom:10px;
    
    &:first-child{
      margin-top:0;
    }
    
    &:last-child{
      margin-bottom:0;
    }
  }
  
}

@include breakpoint($laptop){
  .table{
    .th,
    .td{
      
      padding-left:10px;
      padding-right:10px;
      
      &:first-child{
        padding-left:23px;
      }
      
      &:last-child{
        padding-right:23px;
      }
      
    }
  }
}

@include breakpoint($tablet-portrait){
  .table{
    .td{
      vertical-align:top;
    }
  }
}

@include breakpoint($phone){
  .table{
    display:block;
    
    .shadow{
      display:none;
    }
    
    .thead,
    .tbody{
      display:block;
    }
    
    .tr{
      display:block;
    }
    
    .th,
    .td{
      display:block;
    }
    
    .thead{
      .th{
        padding-bottom:10px;

      }
    }
    
    .tbody{
      .tr{
        background-color:$white;
        border-bottom:1px solid $line_color;

        &.selected{
          .td{
            color: $blue-dark;
            font-weight: 500;
            border-top: none;
            border-bottom: none; 
          }
  
          .td{
            &:first-child{
              border-left: none;
            }
            
            &:last-child{
              border-right: none;
            }
          }
    
          .td{
            &:first-child{
              border-bottom-left-radius:3px;
            }
            
            &:last-child{
              border-bottom-right-radius:3px;
            }
          }
        }
      }
    }
    
    .tr{
      padding:20px;
      
    }
    
    .th{
      padding:0;
      
      &:first-child{
        padding-left:0;
      }
      
      &:last-child{
        padding-right:0;
      }
    }
    
    .td{
      position:relative;
      padding:0;
      margin-bottom:10px;
      
      &:after{
        display:none;
      }
      
      &:first-child{
        padding-left:0;
      }
      
      &:last-child{
        padding-right:0;
      }
      
      &.phone-half{
//        float:left;
//        clear:none;
        display:inline-block !important;
        
        & + .phone-half{
          padding-left:20px;
        }
      }
      
      .accepts{
        position:absolute;
        top:0;
        right:0;
      }
    }
  }
}
