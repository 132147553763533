// contents-60-40
.contents-60-40{
  display:flex;
  
  &.reverse{
    flex-direction:row-reverse;
    
    .content-40{
      &:last-child{
        padding-left:200px;
        padding-right:100px;
      }
    }
  }
  
  &:not(.reverse){
    .content-40{
      &:last-child{
        padding-left:130px;
        padding-right:200px;
      }
    }
  }
  
  &.border-top-blue{
    border-top:10px solid $blue;
  }

  &.has-small-image{
    .content-60{
      width:38%;
    }
    
    .content-40{
      width:62%;
    }
  }

  &.has-video{
    .content-60{
      &:only-child{
        margin-left:auto;
        margin-right:auto;
        padding-left:0 !important;
        padding-right:0 !important;
      }
    }

    &:not(.reverse){
      .content-60{
        padding-left:200px;
      }
    }

    &.reverse{
      .content-60{
        padding-right:200px;
      }
    }
  }

  &.clouds{
    @include bg('../../assets/images/content/try-dragonchain.jpg', 
                '../../assets/images/content/try-dragonchain@2x.jpg');
    background-size:cover;
    background-repeat:no-repeat;
    background-position:right middle;
  }
  
  .content-60{
    position:relative;
    width:62%;
  }
  
  .content-40{
    position:relative;
    width:38%;
    
    &.clouds{
      @include bg('../../assets/images/texture/clouds-blue-bg-reverse.jpg', 
                  '../../assets/images/texture/clouds-blue-bg-reverse@2x.jpg');
      background-size:cover;
      background-repeat:no-repeat;
      background-position:right middle;
    }

    &.white,
    &.default{
      background-color: $white; 
    }

    &.blue-dark-bokeh{
      background-color:$path-bg-color !important;
      @include bg('../../assets/images/texture/path-bg.jpg',
                  '../../assets/images/texture/path-bg@2x.jpg');
    }
  
  }

  &.bg-blue-dark-bokeh{
    background-color:$path-bg-color !important;
    @include bg('../../assets/images/texture/path-bg.jpg',
                '../../assets/images/texture/path-bg@2x.jpg');
    background-size:100% auto;
    background-position:center top;
    background-repeat:no-repeat;
  }
  
  .content-60,
  .content-40{
    padding-top:100px;
    padding-bottom:100px;
    color:$grey-med;
    
    .title{
      @extend %title;
      font-size:41px;
      line-height:46px;
      padding-right:70px;
      margin-bottom:50px;
      font-weight:600;

      &.medium{
        font-size:65px;
        line-height:69px;
      }
    }
    
    .description{
      @extend %description;
      font-size:19px;
      line-height:34px;
      padding-right:25px;
      margin-bottom:25px;
      
      p:first-child{
        margin-bottom:15px;
      }

      &:last-child{
        margin-bottom:0;
      }

      ul{
        margin-bottom:25px;
        
        li{
          position:relative;
          margin-left:35px;
  
          &:before{
            content:'';
            position:absolute;
            display:block;
            top:11px;
            left:-19px;
            width:7px;
            height:7px;
            background-color:$blue-grey-med;
            background-color:$blue;
            border-radius:100%;
          }
        }
      }
    }
    
    .actions{
      ul{
        flex-wrap: wrap;

        li{
          display:inline-block;
          margin-right:15px;
          margin-bottom:12px;
          
          &:last-child{
            margin-right:0;
          }
        }
      }
    }
  }
  
  .content-60,
  .content-40{
    &.grey-faint{
      background-color:$grey-faint;
    }
  }

  .photo-cover{    
    &.yellow{
      border-bottom:9px solid $yellow;
    }
  }

}

@include breakpoint($desktop){
  .contents-60-40{
    &.reverse{
      .content-40{
        &:last-child{
          padding-left:140px;
        }
        
        .title{
          padding-right:0;
        }
      }
    }
    
    &:not(.reverse){
      .content-40{
        &:last-child{
          padding-left:140px;
          // padding-left:100px;
          padding-right:140px;
        }
        
        .title{
          padding-right:0;
        }
      }
    }

    &.has-video{
      &:not(.reverse){
        .content-60{
          padding-left:140px;
        }

        .content-40{
          padding-left:100px;
          padding-top:160px;
        }
      }
  
      &.reverse{
        .content-60{
          padding-right:140px;
        }

        .content-40{
          padding-top:160px;
        }
      }
    }
    
    .content-60,
    .content-40{
      .title{
        font-size:37px;
        line-height:46px;
        margin-bottom:30px;
      }
    }
    
  }
}

@include breakpoint($laptop){
  .contents-60-40{
      
    &.reverse{
      .content-40{
        &:last-child{
          padding-left:100px;
          padding-right:80px;
        }
      }
    }
    
    &:not(.reverse){
      .content-40{
        &:last-child{
          padding-left:80px;
          padding-right:100px;
        }
      }
    }

    &.has-video{
      &:not(.reverse){
        .content-60{
          padding-left:100px;
        }

        .content-40{
          padding-top:120px;
        }
      }
  
      &.reverse{
        .content-60{
          padding-right:80px;
        }

        .content-40{
          padding-top:120px;
        }
      }
    }
    
    .content-60,
    .content-40{
      padding-top:90px;
      padding-bottom:90px;
      
      .title{
        font-size:33px;
        line-height:39px;
      }
      
      .description{
        padding-right:0;
        font-size:18px;
        line-height:31px;
      }
    }
  }
}

@include breakpoint($laptop-tablet){
  .contents-60-40{
      
    &.reverse{
      .content-40{
        &:last-child{
          padding-left:80px;
          padding-right:80px;
        }
      }
    }
    
    &:not(.reverse){
      .content-40{
        &:last-child{
          padding-left:80px;
          padding-right:80px;
        }
      }
    }

    &.has-video{
      &:not(.reverse){
        .content-60{
          padding-left:80px;
        }

        .content-40{
          padding-top:100px;
        }
      }
  
      &.reverse{
        .content-60{
          padding-right:80px;
        }

        .content-40{
          padding-top:100px;
        }
      }
    }
    
    .content-60,
    .content-40{
      padding-top:80px;
      padding-bottom:80px;
      
      .description{
        font-size:17px;
        line-height:29px;
      }
    }
  }
}


@include breakpoint($tablet-landscape){
  .contents-60-40{
      
    &.reverse{
      .content-40{
        &:last-child{
          padding-left:60px;
          padding-right:60px;
        }
      }
    }
    
    &:not(.reverse){
      .content-40{
        &:last-child{
          padding-left:60px;
          padding-right:60px;
        }
      }
    }

    &.has-video{
      &:not(.reverse){
        .content-60{
          padding-left:60px;
        }
      }
  
      &.reverse{
        .content-60{
          padding-right:60px;
        }
      }
    }
    
    .content-60,
    .content-40{
      padding-top:70px;
      padding-bottom:70px;
      
      .title{
        font-size:29px;
        line-height:35px;
      }
      
      .description{
        font-size:17px;
        line-height:29px;
      }
    }
  }
}


@include breakpoint($tablet-portrait){
  .contents-60-40{
      
    &.reverse{
      .content-40{
        &:last-child{
          padding-left:50px;
          padding-right:50px;
        }
        
      }
    }
    
    &:not(.reverse){
      .content-40{
        &:last-child{
          padding-left:50px;
          padding-right:50px;
        }
      }
    }

    &.has-video{
      .content-60{
        &:only-child{
          max-width:100%;
          width:100%;
          margin-left:50px !important;
          margin-right:50px !important;
        }
      }

      &:not(.reverse){
        .content-60{
          padding-left:50px;
        }

        .content-40{
          padding-top:70px;
        }
      }
  
      &.reverse{
        .content-60{
          padding-right:50px;
        }

        .content-40{
          padding-top:70px;
        }
      }
    }
    
    .content-60{
      width:50%;
    }
    
    .content-40{
      width:50%;
    }
    
    .content-60,
    .content-40{
      padding-top:50px;
      padding-bottom:50px;
      
      .title{
        font-size:28px;
        line-height:35px;
        margin-bottom:20px;
      }
      
      .description{
        font-size:16px;
        line-height:27px;
        padding-right:10px;
      }
    }
    
  }
}

@include breakpoint($phone){
  .contents-60-40{
    display:block;
    
    &.reverse{
      .content-40{
        &:last-child{
          padding-left:30px;
          padding-right:30px;
        }
        
      }
    }
    
    &:not(.reverse){
      .content-40{
        &:last-child{
          padding-left:30px;
          padding-right:30px;
        }
      }
    }

    &.has-small-image{
      .content-60{
        width:100%;
      }

      .content-40{
        width:100%;
      }
    }

    &.has-video{
      .content-60{
        &:only-child{
          max-width:100%;
          width:100%;
          margin-left:0 !important;
          margin-right:0 !important;
        }
      }

      &:not(.reverse){
        .content-60{
          padding-left:0;
        }
      }
  
      &.reverse{
        .content-60{
          padding-right:0;
        }
      }
    }
    
    .content-60,
    .content-40{
      padding-top:50px;
      padding-bottom:50px;
      
      .description{
        margin-bottom:35px;
      }
    }
    
    .content-60{
      width:100%;
      padding:0;
    }
    
    .content-40{
      width:100%;
    }
  
    .photo-cover {
      margin-bottom:-60px;
      padding-bottom: 75%;
    }
  }

}

