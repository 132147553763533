// Partials / Image Card
.photo {
  -webkit-box-shadow: 20px 20px 0 0 rgba(0, 0, 0, 0.06);
  box-shadow: 20px 20px 0 0 rgba(0, 0, 0, 0.06);
  font-size: 0;
  line-height: 0;

  img {
    width: 100%;
    height: auto;
  }

  .dark {
    -webkit-box-shadow: 20px 20px 0 0 rgba(0, 0, 0, 0.2);
    box-shadow: 20px 20px 0 0 rgba(0, 0, 0, 0.2);
  }
  .light {
    -webkit-box-shadow: 20px 20px 0 0 rgba(0, 0, 0, 0.06);
    box-shadow: 20px 20px 0 0 rgba(0, 0, 0, 0.06);
  }
}

@include breakpoint($tablet-landscape){
  .photo{
    margin-right:0;
  }
}
