/* Customized for TryDragonchain theme */

/* PrismJS 1.15.0
https://prismjs.com/download.html#themes=prism-coy&languages=css+clike+javascript+json+python */
/**
 * prism.js Coy theme for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/tshedor/workshop-wp-theme (Example: http://workshop.kansan.com/category/sessions/basics or http://workshop.timshedor.com/category/sessions/basics);
 * @author Tim  Shedor
 */

code[class*="language-"],
pre[class*="language-"] {
	color:#08152c;
	background: none;
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	font-size: 1em;
	text-align: left;
	white-space: pre-wrap;
	word-spacing: normal;
	word-break: break-word;
	word-wrap: normal;
	line-height: 1.5;

	-moz-tab-size: 2;
	tab-size: 2;

	-webkit-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
	position: relative;
	margin: .5em 0;
	overflow: visible;
	padding: 0;
}
pre[class*="language-"]>code {
	position: relative;
	border:none;
}

code[class*="language"] {
	max-height: inherit;
	height: inherit;
	padding: 0 13px;
	display: block;
	overflow: auto;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	position: relative;
	padding: .2em;
	border-radius: 0.3em;
	color: #c92c2c;
	border: 1px solid rgba(0, 0, 0, 0.1);
	display: inline;
	white-space: normal;
}

pre[class*="language-"]:before,
pre[class*="language-"]:after {
	content: '';
	z-index: -2;
	display: block;
	position: absolute;
	bottom: 0.75em;
	left: 0.18em;
	width: 40%;
	height: 20%;
	max-height: 13em;
	box-shadow: 0px 13px 8px #979797;
	-webkit-transform: rotate(-2deg);
	transform: rotate(-2deg);
}

:not(pre) > code[class*="language-"]:after,
pre[class*="language-"]:after {
	right: 0.75em;
	left: auto;
	-webkit-transform: rotate(2deg);
	transform: rotate(2deg);
}

.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: #7D8B99;
}

.token.punctuation,
.token.operator {
	color: #8494a5;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.function-name,
.token.constant,
.token.symbol,
.token.deleted {
	color: #f34321;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
	color: #1797e5;
}

.token.function{
	color: #f34321;
}

.token.entity,
.token.url,
.token.variable {
	color: #0164a9;
	background: rgba(255, 255, 255, 0.05);
}

.token.atrule,
.token.attr-value,
.token.keyword{
	color: #0164a9;
}

.token.class-name {
	color: #f34321;
}

.token.regex,
.token.important {
	color: #f34321;
}

.language-css .token.string,
.style .token.string {
	color: #f34321;
	background: rgba(255, 255, 255, 0.05);
}

.token.important {
	font-weight: normal;
}

.token.bold {
	font-weight: bold;
}
.token.italic {
	font-style: italic;
}

.token.entity {
	cursor: help;
}

.namespace {
	opacity: .7;
}

@media screen and (max-width: 767px) {
	pre[class*="language-"]:before,
	pre[class*="language-"]:after {
		bottom: 14px;
		box-shadow: none;
	}

}

/* Plugin styles */
.token.tab:not(:empty):before,
.token.cr:before,
.token.lf:before {
	color: #e0d7d1;
}

/* Plugin styles: Line Numbers */
pre[class*="language-"].line-numbers.line-numbers {
	padding-left: 0;
}

pre[class*="language-"].line-numbers.line-numbers code {
	padding-left: 3.8em;
}

pre[class*="language-"].line-numbers.line-numbers .line-numbers-rows {
	left: 0;
}

/* Plugin styles: Line Highlight */
pre[class*="language-"][data-line] {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
}
pre[data-line] code {
	position: relative;
	padding-left: 4em;
}
pre .line-highlight {
	margin-top: 0;
}

/* JSON */
.language-json .token.property{
	color: #0164a9;
}

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

@font-face {
  src: url(/static/media/Graphik-Light.042a5b44.otf), url(/static/media/graphik-light-webfont.e1f5af5d.eot) format("embedded-opentype"), url(/static/media/Graphik-Light.042a5b44.otf) format("opentype");
  font-display: swap;
  font-family: Graphik;
  font-weight: 300;
  font-style: normal; }

@font-face {
  src: url(/static/media/Graphik-Regular.e2a1876e.otf), url(/static/media/graphik-regular-webfont.f8701da6.eot) format("embedded-opentype"), url(/static/media/Graphik-Regular.e2a1876e.otf) format("opentype");
  font-display: swap;
  font-family: Graphik;
  font-weight: 400;
  font-style: normal; }

@font-face {
  src: url(/static/media/Graphik-RegularItalic.6bd6ed0f.otf), url(/static/media/graphik-regularitalic-webfont.0695b287.eot) format("embedded-opentype"), url(/static/media/Graphik-RegularItalic.6bd6ed0f.otf) format("opentype");
  font-display: swap;
  font-family: Graphik;
  font-weight: 400;
  font-style: italic; }

@font-face {
  src: url(/static/media/Graphik-Medium.4a127c95.otf), url(/static/media/graphik-medium-webfont.10a74c2d.eot) format("embedded-opentype"), url(/static/media/Graphik-Medium.4a127c95.otf) format("opentype");
  font-display: swap;
  font-family: Graphik;
  font-weight: 500;
  font-style: normal; }

@font-face {
  src: url(/static/media/Graphik-Semibold.99c54fc5.otf), url(/static/media/graphik-semibold-webfont.36244893.eot) format("embedded-opentype"), url(/static/media/Graphik-Semibold.99c54fc5.otf) format("opentype");
  font-display: swap;
  font-family: Graphik;
  font-weight: 600;
  font-style: normal; }

@font-face {
  src: url(/static/media/Graphik-Bold.8a604ed5.otf), url(/static/media/graphik-bold-webfont.20b642f2.eot) format("embedded-opentype"), url(/static/media/Graphik-Bold.8a604ed5.otf) format("opentype");
  font-display: swap;
  font-family: Graphik;
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "acumin-pro-extra-condensed";
  src: url(/static/media/acumin.9186cacb.woff2) format("woff2"), url(/static/media/acumin.f6613228.woff) format("woff"), url(/static/media/acumin.e1a8c037.otf) format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400; }

.tk-acumin-pro-extra-condensed {
  font-family: "acumin-pro-extra-condensed", sans-serif; }

.left-top.hero {
  background-position: left top; }

.left-center.hero {
  background-position: left center; }

.left-bottom.hero {
  background-position: left bottom; }

.right-top.hero {
  background-position: right top; }

.right-center.hero {
  background-position: right center; }

.right-bottom.hero {
  background-position: right bottom; }

.center-top.hero {
  background-position: center top; }

.center-center.hero {
  background-position: center center; }

.center-bottom.hero {
  background-position: center bottom; }

@media (max-width: 570px) {
  .phone-left-top.hero {
    background-position: left top; }
  .phone-left-center.hero {
    background-position: left center; }
  .phone-left-bottom.hero {
    background-position: left bottom; }
  .phone-right-top.hero {
    background-position: right top; }
  .phone-right-center.hero {
    background-position: right center; }
  .phone-right-bottom.hero {
    background-position: right bottom; }
  .phone-center-top.hero {
    background-position: center top; }
  .phone-center-center.hero {
    background-position: center center; }
  .phone-center-bottom.hero {
    background-position: center bottom; } }

.contents-full .description:first-child, .page section:first-child {
  margin-top: 50px; }

.contents-full .description h1, .page section h1,
.contents-full .description h2,
.page section h2 {
  color: #131313;
  font-size: 34px;
  line-height: 49px;
  font-weight: 700;
  max-width: 950px;
  margin-bottom: 30px; }
  .contents-full .description h1:last-child, .page section h1:last-child,
  .contents-full .description h2:last-child,
  .page section h2:last-child {
    margin-bottom: 0; }

.contents-full .description h3, .page section h3 {
  color: #131313;
  font-size: 29px;
  line-height: 35px;
  font-weight: 600;
  margin-bottom: 25px; }
  .contents-full .description h3:last-child, .page section h3:last-child {
    margin-bottom: 0; }

.contents-full .description h4, .page section h4 {
  color: #131313;
  font-size: 14px;
  line-height: 35px;
  font-weight: 500; }
  .contents-full .description h4:last-child, .page section h4:last-child {
    margin-bottom: 0; }

.contents-full .description p, .page section p,
.contents-full .description li,
.page section li {
  list-style-type: none;
  font-size: 19px;
  line-height: 34px; }
  .contents-full .description p a:not(.button), .page section p a:not(.button),
  .contents-full .description li a:not(.button),
  .page section li a:not(.button) {
    padding-bottom: 2px;
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-property: all;
    transition-property: all;
    box-shadow: #2eb6ef 0px -1.5px 0px 0px inset;
    color: inherit;
    text-decoration: none;
    color: #131313; }
    .contents-full .description p a:not(.button):hover, .page section p a:not(.button):hover,
    .contents-full .description li a:not(.button):hover,
    .page section li a:not(.button):hover {
      box-shadow: #dc2800 0px -1.5px 0px 0px inset; }

.contents-full .description p, .page section p {
  margin-bottom: 50px; }
  .contents-full .description p:last-child, .page section p:last-child {
    padding-bottom: 0; }

.contents-full .description > ul, .page section > ul,
.contents-full .description > ol,
.page section > ol {
  margin-bottom: 50px; }
  .contents-full .description > ul:last-child, .page section > ul:last-child,
  .contents-full .description > ol:last-child,
  .page section > ol:last-child {
    padding-bottom: 0; }
  .contents-full .description > ul li, .page section > ul li,
  .contents-full .description > ol li,
  .page section > ol li {
    position: relative;
    padding-left: 40px; }

.contents-full .description > ul.has-title li:first-child, .page section > ul.has-title li:first-child {
  padding-left: 0;
  margin-bottom: 4px; }
  .contents-full .description > ul.has-title li:first-child:before, .page section > ul.has-title li:first-child:before {
    display: none; }

.contents-full .description > ul li:before, .page section > ul li:before {
  content: '';
  position: absolute;
  top: 10px;
  left: 20px;
  display: block;
  width: 7px;
  height: 7px;
  background-color: #BCBEC0;
  border-radius: 7px; }

.contents-full .description ul li, .page section ul li {
  position: relative;
  margin-left: 35px; }
  .contents-full .description ul li:before, .page section ul li:before {
    content: '';
    position: absolute;
    display: block;
    top: 11px;
    left: -19px;
    width: 7px;
    height: 7px;
    background-color: #8494a5;
    background-color: #1693e1;
    border-radius: 100%; }

.contents-full .description ol, .page section ol {
  counter-reset: ol-counter; }
  .contents-full .description ol li, .page section ol li {
    counter-increment: ol-counter;
    position: relative;
    margin-left: 35px; }
    .contents-full .description ol li:before, .page section ol li:before {
      content: counter(ol-counter) ". ";
      position: absolute;
      display: block;
      color: #1693e1;
      left: -22px;
      text-align: right; }

.contents-full .description strong, .page section strong,
.contents-full .description b,
.page section b {
  font-weight: 600;
  color: #131313; }

.contents-full .description em, .page section em,
.contents-full .description i,
.page section i {
  font-style: italic; }

.contents-full .description .actions li, .page section .actions li {
  display: inline-block;
  margin-right: 15px; }
  .contents-full .description .actions li:last-child, .page section .actions li:last-child {
    margin-right: 0; }

@media (max-width: 1440px) {
  .contents-full .description h1, .page section h1,
  .contents-full .description h2,
  .page section h2 {
    font-size: 29px;
    line-height: 33px;
    margin-bottom: 20px; }
  .contents-full .description p, .page section p,
  .contents-full .description li,
  .page section li {
    font-size: 16px;
    line-height: 27px; } }

.contents-60-40 .content-60 .title,
.contents-60-40 .content-40 .title, .call-to-action .title, .engagement-inline .engagement-content .title, .feature-chart .title, .contents-full .title, .hero .content .title, .hero-jojo .content .title {
  font-weight: 700; }
  .contents-60-40 .content-60 .black.title,
  .contents-60-40 .content-40 .black.title, .call-to-action .black.title, .engagement-inline .engagement-content .black.title, .feature-chart .black.title, .contents-full .black.title, .hero .content .black.title, .hero-jojo .content .black.title {
    color: #131313; }
  .contents-60-40 .content-60 .white.title,
  .contents-60-40 .content-40 .white.title, .call-to-action .white.title, .engagement-inline .engagement-content .white.title, .feature-chart .white.title, .contents-full .white.title, .hero .content .white.title, .hero-jojo .content .white.title {
    color: #fff; }
  .contents-60-40 .content-60 .blue-light.title,
  .contents-60-40 .content-40 .blue-light.title, .call-to-action .blue-light.title, .engagement-inline .engagement-content .blue-light.title, .feature-chart .blue-light.title, .contents-full .blue-light.title, .hero .content .blue-light.title, .hero-jojo .content .blue-light.title {
    color: #2eb6ef; }
  .contents-60-40 .content-60 .clouds-light.title,
  .contents-60-40 .content-40 .clouds-light.title, .call-to-action .clouds-light.title, .engagement-inline .engagement-content .clouds-light.title, .feature-chart .clouds-light.title, .contents-full .clouds-light.title, .hero .content .clouds-light.title, .hero-jojo .content .clouds-light.title {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: url(/static/media/text-mask-light@2x.ec026980.jpg);
    background-size: cover;
    background-position: left bottom;
    color: #2eb6ef; }
  .contents-60-40 .content-60 .clouds-dark.title,
  .contents-60-40 .content-40 .clouds-dark.title, .call-to-action .clouds-dark.title, .engagement-inline .engagement-content .clouds-dark.title, .feature-chart .clouds-dark.title, .contents-full .clouds-dark.title, .hero .content .clouds-dark.title, .hero-jojo .content .clouds-dark.title {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: url(/static/media/text-mask.48cfc2d6.jpg);
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: cover;
    background-position: left bottom;
    background-image: url(/static/media/text-mask-reverse.7657b7af.jpg);
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover; }
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      .contents-60-40 .content-60 .clouds-dark.title,
      .contents-60-40 .content-40 .clouds-dark.title, .call-to-action .clouds-dark.title, .engagement-inline .engagement-content .clouds-dark.title, .feature-chart .clouds-dark.title, .contents-full .clouds-dark.title, .hero .content .clouds-dark.title, .hero-jojo .content .clouds-dark.title {
        background-image: url(/static/media/text-mask@2x.b37a3df1.jpg); } }
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      .contents-60-40 .content-60 .clouds-dark.title,
      .contents-60-40 .content-40 .clouds-dark.title, .call-to-action .clouds-dark.title, .engagement-inline .engagement-content .clouds-dark.title, .feature-chart .clouds-dark.title, .contents-full .clouds-dark.title, .hero .content .clouds-dark.title, .hero-jojo .content .clouds-dark.title {
        background-image: url(/static/media/text-mask-reverse@2x.7511fb69.jpg); } }
  @supports (-ms-ime-align: auto) {
    .contents-60-40 .content-60 .clouds.title,
    .contents-60-40 .content-40 .clouds.title, .call-to-action .clouds.title, .engagement-inline .engagement-content .clouds.title, .feature-chart .clouds.title, .contents-full .clouds.title, .hero .content .clouds.title, .hero-jojo .content .clouds.title {
      -webkit-text-fill-color: currentcolor !important;
      -webkit-background-clip: border-box !important;
      background-image: none !important;
      color: #61e2fb; } }
  .contents-60-40 .content-60 .title body.ie.clouds, .contents-60-40 .content-40 .title body.ie.clouds, .call-to-action .title body.ie.clouds, .engagement-inline .engagement-content .title body.ie.clouds, .feature-chart .title body.ie.clouds, .contents-full .title body.ie.clouds, .hero .content .title body.ie.clouds, .hero-jojo .content .title body.ie.clouds {
    -webkit-text-fill-color: currentcolor !important;
    -webkit-background-clip: border-box !important;
    background-image: none !important;
    color: #61e2fb; }

.contents-60-40 .content-60 .description:last-child,
.contents-60-40 .content-40 .description:last-child, .call-to-action .description:last-child, .contents-full .description:last-child, .hero .content .description:last-child, .hero-jojo .content .description:last-child {
  margin-bottom: 0; }

.contents-60-40 .content-60 .black.description,
.contents-60-40 .content-40 .black.description, .call-to-action .black.description, .contents-full .black.description, .hero .content .black.description, .hero-jojo .content .black.description {
  color: #131313; }

.contents-60-40 .content-60 .grey-med.description,
.contents-60-40 .content-40 .grey-med.description, .call-to-action .grey-med.description, .contents-full .grey-med.description, .hero .content .grey-med.description, .hero-jojo .content .grey-med.description {
  color: #808285; }

.contents-60-40 .content-60 .white.description,
.contents-60-40 .content-40 .white.description, .call-to-action .white.description, .contents-full .white.description, .hero .content .white.description, .hero-jojo .content .white.description {
  color: #fff; }

.contents-60-40 .content-60 .blue-light.description,
.contents-60-40 .content-40 .blue-light.description, .call-to-action .blue-light.description, .contents-full .blue-light.description, .hero .content .blue-light.description, .hero-jojo .content .blue-light.description {
  color: #9FDBF9; }

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

html {
  box-sizing: border-box;
  scroll-behavior: smooth; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  font-family: Graphik, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #000;
  background-image: url(/static/media/body-white-bg.5dd4e0e6.jpg);
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f1f1f1;
  background-size: 100% auto;
  background-repeat: no-repeat; }
  @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
    body {
      background-image: url(/static/media/body-white-bg@2x.3267569e.jpg); } }
  body.white {
    background-image: url(/static/media/body-white-bg.5dd4e0e6.jpg);
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f1f1f1; }
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      body.white {
        background-image: url(/static/media/body-white-bg@2x.3267569e.jpg); } }
  body.blue {
    background-image: url(/static/media/body-blue-bg@2x.668ae88b.jpg);
    background-color: #00aed5; }
  body.blue-dark {
    background-image: url(/static/media/body-blue-dark-bg@2x.7e6a9f68.jpg);
    background-color: #203455; }

.all {
  max-width: 1780px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px; }
  .all > .inner {
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15); }
  .all.is-minimalist {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    bottom: 0;
    padding-top: 0px;
    padding-bottom: 0px; }
    .all.is-minimalist .header .logo {
      left: 0;
      top: 15px;
      -webkit-transform: scale(0.8);
              transform: scale(0.8); }
    .all.is-minimalist .header .nav {
      display: none; }
    .all.is-minimalist > .inner {
      position: relative;
      width: 100%;
      height: 100%; }
  .all.is-authentication {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto; }
    .all.is-authentication > .inner {
      position: relative;
      background-color: transparent; }

li {
  list-style: none; }

@media (max-width: 1440px) {
  .all {
    padding: 35px; } }

@media (max-width: 1250px) {
  .all {
    padding: 30px; } }

@media (max-width: 1100px) {
  .all {
    padding: 0; }
    .all.is-minimalist {
      top: 0; } }

.contents-60-40 {
  display: flex; }
  .contents-60-40.reverse {
    flex-direction: row-reverse; }
    .contents-60-40.reverse .content-40:last-child {
      padding-left: 200px;
      padding-right: 100px; }
  .contents-60-40:not(.reverse) .content-40:last-child {
    padding-left: 130px;
    padding-right: 200px; }
  .contents-60-40.border-top-blue {
    border-top: 10px solid #1693e1; }
  .contents-60-40.has-small-image .content-60 {
    width: 38%; }
  .contents-60-40.has-small-image .content-40 {
    width: 62%; }
  .contents-60-40.has-video .content-60:only-child {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .contents-60-40.has-video:not(.reverse) .content-60 {
    padding-left: 200px; }
  .contents-60-40.has-video.reverse .content-60 {
    padding-right: 200px; }
  .contents-60-40.clouds {
    background-image: url(/static/media/try-dragonchain.5e318bcd.jpg);
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right middle; }
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      .contents-60-40.clouds {
        background-image: url(/static/media/try-dragonchain@2x.ed9b2b8c.jpg); } }
  .contents-60-40 .content-60 {
    position: relative;
    width: 62%; }
  .contents-60-40 .content-40 {
    position: relative;
    width: 38%; }
    .contents-60-40 .content-40.clouds {
      background-image: url(/static/media/clouds-blue-bg-reverse.60440ba7.jpg);
      background-position: center 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right middle; }
      @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
        .contents-60-40 .content-40.clouds {
          background-image: url(/static/media/clouds-blue-bg-reverse@2x.b37a3df1.jpg); } }
    .contents-60-40 .content-40.white, .contents-60-40 .content-40.default {
      background-color: #fff; }
    .contents-60-40 .content-40.blue-dark-bokeh {
      background-color: #132031 !important;
      background-image: url(/static/media/path-bg.4b907741.jpg);
      background-position: center 0;
      background-repeat: no-repeat;
      background-size: cover; }
      @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
        .contents-60-40 .content-40.blue-dark-bokeh {
          background-image: url(/static/media/path-bg@2x.84fee8bb.jpg); } }
  .contents-60-40.bg-blue-dark-bokeh {
    background-color: #132031 !important;
    background-image: url(/static/media/path-bg.4b907741.jpg);
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% auto;
    background-position: center top;
    background-repeat: no-repeat; }
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      .contents-60-40.bg-blue-dark-bokeh {
        background-image: url(/static/media/path-bg@2x.84fee8bb.jpg); } }
  .contents-60-40 .content-60,
  .contents-60-40 .content-40 {
    padding-top: 100px;
    padding-bottom: 100px;
    color: #808285; }
    .contents-60-40 .content-60 .title,
    .contents-60-40 .content-40 .title {
      font-size: 41px;
      line-height: 46px;
      padding-right: 70px;
      margin-bottom: 50px;
      font-weight: 600; }
      .contents-60-40 .content-60 .title.medium,
      .contents-60-40 .content-40 .title.medium {
        font-size: 65px;
        line-height: 69px; }
    .contents-60-40 .content-60 .description,
    .contents-60-40 .content-40 .description {
      font-size: 19px;
      line-height: 34px;
      padding-right: 25px;
      margin-bottom: 25px; }
      .contents-60-40 .content-60 .description p:first-child,
      .contents-60-40 .content-40 .description p:first-child {
        margin-bottom: 15px; }
      .contents-60-40 .content-60 .description:last-child,
      .contents-60-40 .content-40 .description:last-child {
        margin-bottom: 0; }
      .contents-60-40 .content-60 .description ul,
      .contents-60-40 .content-40 .description ul {
        margin-bottom: 25px; }
        .contents-60-40 .content-60 .description ul li,
        .contents-60-40 .content-40 .description ul li {
          position: relative;
          margin-left: 35px; }
          .contents-60-40 .content-60 .description ul li:before,
          .contents-60-40 .content-40 .description ul li:before {
            content: '';
            position: absolute;
            display: block;
            top: 11px;
            left: -19px;
            width: 7px;
            height: 7px;
            background-color: #8494a5;
            background-color: #1693e1;
            border-radius: 100%; }
    .contents-60-40 .content-60 .actions ul,
    .contents-60-40 .content-40 .actions ul {
      flex-wrap: wrap; }
      .contents-60-40 .content-60 .actions ul li,
      .contents-60-40 .content-40 .actions ul li {
        display: inline-block;
        margin-right: 15px;
        margin-bottom: 12px; }
        .contents-60-40 .content-60 .actions ul li:last-child,
        .contents-60-40 .content-40 .actions ul li:last-child {
          margin-right: 0; }
  .contents-60-40 .content-60.grey-faint,
  .contents-60-40 .content-40.grey-faint {
    background-color: #F9F9F9; }
  .contents-60-40 .photo-cover.yellow {
    border-bottom: 9px solid #ffc623; }

@media (max-width: 1680px) {
  .contents-60-40.reverse .content-40:last-child {
    padding-left: 140px; }
  .contents-60-40.reverse .content-40 .title {
    padding-right: 0; }
  .contents-60-40:not(.reverse) .content-40:last-child {
    padding-left: 140px;
    padding-right: 140px; }
  .contents-60-40:not(.reverse) .content-40 .title {
    padding-right: 0; }
  .contents-60-40.has-video:not(.reverse) .content-60 {
    padding-left: 140px; }
  .contents-60-40.has-video:not(.reverse) .content-40 {
    padding-left: 100px;
    padding-top: 160px; }
  .contents-60-40.has-video.reverse .content-60 {
    padding-right: 140px; }
  .contents-60-40.has-video.reverse .content-40 {
    padding-top: 160px; }
  .contents-60-40 .content-60 .title,
  .contents-60-40 .content-40 .title {
    font-size: 37px;
    line-height: 46px;
    margin-bottom: 30px; } }

@media (max-width: 1440px) {
  .contents-60-40.reverse .content-40:last-child {
    padding-left: 100px;
    padding-right: 80px; }
  .contents-60-40:not(.reverse) .content-40:last-child {
    padding-left: 80px;
    padding-right: 100px; }
  .contents-60-40.has-video:not(.reverse) .content-60 {
    padding-left: 100px; }
  .contents-60-40.has-video:not(.reverse) .content-40 {
    padding-top: 120px; }
  .contents-60-40.has-video.reverse .content-60 {
    padding-right: 80px; }
  .contents-60-40.has-video.reverse .content-40 {
    padding-top: 120px; }
  .contents-60-40 .content-60,
  .contents-60-40 .content-40 {
    padding-top: 90px;
    padding-bottom: 90px; }
    .contents-60-40 .content-60 .title,
    .contents-60-40 .content-40 .title {
      font-size: 33px;
      line-height: 39px; }
    .contents-60-40 .content-60 .description,
    .contents-60-40 .content-40 .description {
      padding-right: 0;
      font-size: 18px;
      line-height: 31px; } }

@media (max-width: 1250px) {
  .contents-60-40.reverse .content-40:last-child {
    padding-left: 80px;
    padding-right: 80px; }
  .contents-60-40:not(.reverse) .content-40:last-child {
    padding-left: 80px;
    padding-right: 80px; }
  .contents-60-40.has-video:not(.reverse) .content-60 {
    padding-left: 80px; }
  .contents-60-40.has-video:not(.reverse) .content-40 {
    padding-top: 100px; }
  .contents-60-40.has-video.reverse .content-60 {
    padding-right: 80px; }
  .contents-60-40.has-video.reverse .content-40 {
    padding-top: 100px; }
  .contents-60-40 .content-60,
  .contents-60-40 .content-40 {
    padding-top: 80px;
    padding-bottom: 80px; }
    .contents-60-40 .content-60 .description,
    .contents-60-40 .content-40 .description {
      font-size: 17px;
      line-height: 29px; } }

@media (max-width: 1100px) {
  .contents-60-40.reverse .content-40:last-child {
    padding-left: 60px;
    padding-right: 60px; }
  .contents-60-40:not(.reverse) .content-40:last-child {
    padding-left: 60px;
    padding-right: 60px; }
  .contents-60-40.has-video:not(.reverse) .content-60 {
    padding-left: 60px; }
  .contents-60-40.has-video.reverse .content-60 {
    padding-right: 60px; }
  .contents-60-40 .content-60,
  .contents-60-40 .content-40 {
    padding-top: 70px;
    padding-bottom: 70px; }
    .contents-60-40 .content-60 .title,
    .contents-60-40 .content-40 .title {
      font-size: 29px;
      line-height: 35px; }
    .contents-60-40 .content-60 .description,
    .contents-60-40 .content-40 .description {
      font-size: 17px;
      line-height: 29px; } }

@media (max-width: 860px) {
  .contents-60-40.reverse .content-40:last-child {
    padding-left: 50px;
    padding-right: 50px; }
  .contents-60-40:not(.reverse) .content-40:last-child {
    padding-left: 50px;
    padding-right: 50px; }
  .contents-60-40.has-video .content-60:only-child {
    max-width: 100%;
    width: 100%;
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .contents-60-40.has-video:not(.reverse) .content-60 {
    padding-left: 50px; }
  .contents-60-40.has-video:not(.reverse) .content-40 {
    padding-top: 70px; }
  .contents-60-40.has-video.reverse .content-60 {
    padding-right: 50px; }
  .contents-60-40.has-video.reverse .content-40 {
    padding-top: 70px; }
  .contents-60-40 .content-60 {
    width: 50%; }
  .contents-60-40 .content-40 {
    width: 50%; }
  .contents-60-40 .content-60,
  .contents-60-40 .content-40 {
    padding-top: 50px;
    padding-bottom: 50px; }
    .contents-60-40 .content-60 .title,
    .contents-60-40 .content-40 .title {
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 20px; }
    .contents-60-40 .content-60 .description,
    .contents-60-40 .content-40 .description {
      font-size: 16px;
      line-height: 27px;
      padding-right: 10px; } }

@media (max-width: 570px) {
  .contents-60-40 {
    display: block; }
    .contents-60-40.reverse .content-40:last-child {
      padding-left: 30px;
      padding-right: 30px; }
    .contents-60-40:not(.reverse) .content-40:last-child {
      padding-left: 30px;
      padding-right: 30px; }
    .contents-60-40.has-small-image .content-60 {
      width: 100%; }
    .contents-60-40.has-small-image .content-40 {
      width: 100%; }
    .contents-60-40.has-video .content-60:only-child {
      max-width: 100%;
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .contents-60-40.has-video:not(.reverse) .content-60 {
      padding-left: 0; }
    .contents-60-40.has-video.reverse .content-60 {
      padding-right: 0; }
    .contents-60-40 .content-60,
    .contents-60-40 .content-40 {
      padding-top: 50px;
      padding-bottom: 50px; }
      .contents-60-40 .content-60 .description,
      .contents-60-40 .content-40 .description {
        margin-bottom: 35px; }
    .contents-60-40 .content-60 {
      width: 100%;
      padding: 0; }
    .contents-60-40 .content-40 {
      width: 100%; }
    .contents-60-40 .photo-cover {
      margin-bottom: -60px;
      padding-bottom: 75%; } }

.alert {
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 25px;
  color: #fff; }
  .alert:first-child {
    margin-top: 0; }
  .alert .inner {
    display: inline-block;
    padding: 11px 13px 10px 13px;
    background-color: #1693e1;
    border-radius: 3px;
    box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.06); }
  .alert.blue > .inner {
    background-color: #1693e1; }
  .alert.blue-light > .inner {
    background-color: #26b6ff; }
  .alert.blue-med > .inner {
    background-color: #003b82; }
  .alert.grey > .inner {
    background-color: #3c4a5b; }
  .alert.grey-med > .inner {
    background-color: #8494a5; }
  .alert.white > .inner {
    background-color: #fff;
    color: #08152c; }

.full-background.clouds {
  background-image: url(/static/media/clouds-blue-fade-to-blue@2x.bfb78975.jpg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #184d8e;
  background-color: #014b94; }

@media (max-width: 860px) {
  .full-background.clouds {
    background-size: 150% auto;
    background-position: right top; } }

@media (max-width: 570px) {
  .full-background.clouds {
    background-size: 230% auto;
    background-position: right top; } }

.block-label {
  position: absolute;
  top: 80px;
  left: 55px;
  color: #fff;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }
  .block-label > .inner {
    position: relative;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
    .block-label > .inner:after {
      content: '';
      position: relative;
      top: -5px;
      display: inline-block;
      width: 30px;
      height: 1px;
      border-radius: 1px;
      background-color: #fff;
      margin-left: 15px; }
  .block-label.blue {
    color: #1693e1; }
    .block-label.blue > .inner:after {
      background-color: #1693e1; }
  .block-label.blue-light {
    color: #3fccfe; }
    .block-label.blue-light > .inner:after {
      background-color: #3fccfe; }
  .block-label.grey {
    color: #8494a5; }
    .block-label.grey > .inner:after {
      background-color: #8494a5; }

@media (max-width: 1100px) {
  .block-label {
    display: none; } }

.blog > .inner {
  display: flex;
  padding-top: 210px; }

.blog .blog-article {
  width: 73%; }
  .blog .blog-article > .inner {
    position: relative;
    padding-left: 180px;
    padding-right: 110px; }
  .blog .blog-article .blog-article-sidebar {
    position: absolute;
    left: 60px;
    top: 0;
    margin-top: -50px; }
    .blog .blog-article .blog-article-sidebar .back {
      display: block;
      position: relative;
      margin-top: -4px;
      margin-bottom: 80px;
      padding-left: 5px; }
      .blog .blog-article .blog-article-sidebar .back a {
        -webkit-transition-duration: 0.35s;
                transition-duration: 0.35s;
        -webkit-transition-property: opacity;
        transition-property: opacity;
        display: block;
        -webkit-mask: url(/static/media/back.fa9031f1.svg) no-repeat center;
        mask: url(/static/media/back.fa9031f1.svg) no-repeat center;
        background-color: #131313;
        font-size: 0;
        line-height: 0;
        width: 26px;
        height: 26px;
        opacity: 0.5; }
        .blog .blog-article .blog-article-sidebar .back a:hover {
          -webkit-transition-duration: 0s;
                  transition-duration: 0s;
          opacity: 1; }
    .blog .blog-article .blog-article-sidebar .share ul {
      border-right: 1px solid #e6e7e8;
      padding-right: 25px; }
    .blog .blog-article .blog-article-sidebar .share li {
      display: block;
      padding-top: 11px;
      padding-bottom: 11px; }
    .blog .blog-article .blog-article-sidebar .share .blog-facebook {
      cursor: pointer;
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: background-color;
      transition-property: background-color;
      display: block;
      -webkit-mask: url(/static/media/social-facebook.1fd2c3c3.svg) no-repeat center;
      mask: url(/static/media/social-facebook.1fd2c3c3.svg) no-repeat center;
      background-color: #08152c;
      font-size: 0;
      line-height: 0;
      width: 40px;
      height: 40px; }
      .blog .blog-article .blog-article-sidebar .share .blog-facebook:hover {
        background-color: #f34322; }
    .blog .blog-article .blog-article-sidebar .share .blog-twitter {
      cursor: pointer;
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: background-color;
      transition-property: background-color;
      display: block;
      -webkit-mask: url(/static/media/social-twitter.4a776a47.svg) no-repeat center;
      mask: url(/static/media/social-twitter.4a776a47.svg) no-repeat center;
      background-color: #08152c;
      font-size: 0;
      line-height: 0;
      width: 40px;
      height: 40px; }
      .blog .blog-article .blog-article-sidebar .share .blog-twitter:hover {
        background-color: #f34322; }
    .blog .blog-article .blog-article-sidebar .share .blog-linkedin {
      cursor: pointer;
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: background-color;
      transition-property: background-color;
      display: block;
      -webkit-mask: url(/static/media/social-linkedin.24818759.svg) no-repeat center;
      mask: url(/static/media/social-linkedin.24818759.svg) no-repeat center;
      background-color: #08152c;
      font-size: 0;
      line-height: 0;
      width: 40px;
      height: 40px; }
      .blog .blog-article .blog-article-sidebar .share .blog-linkedin:hover {
        background-color: #f34322; }
    .blog .blog-article .blog-article-sidebar .share .blog-github {
      cursor: pointer;
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: background-color;
      transition-property: background-color;
      display: block;
      -webkit-mask: url(/static/media/social-github.ff12d275.svg) no-repeat center;
      mask: url(/static/media/social-github.ff12d275.svg) no-repeat center;
      background-color: #08152c;
      font-size: 0;
      line-height: 0;
      width: 40px;
      height: 40px; }
      .blog .blog-article .blog-article-sidebar .share .blog-github:hover {
        background-color: #f34322; }
    .blog .blog-article .blog-article-sidebar .share .blog-telegram {
      cursor: pointer;
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: background-color;
      transition-property: background-color;
      display: block;
      -webkit-mask: url(/static/media/social-telegram.13428295.svg) no-repeat center;
      mask: url(/static/media/social-telegram.13428295.svg) no-repeat center;
      background-color: #08152c;
      font-size: 0;
      line-height: 0;
      width: 40px;
      height: 40px; }
      .blog .blog-article .blog-article-sidebar .share .blog-telegram:hover {
        background-color: #f34322; }
    .blog .blog-article .blog-article-sidebar .share .blog-youtube {
      cursor: pointer;
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: background-color;
      transition-property: background-color;
      display: block;
      -webkit-mask: url(/static/media/social-youtube.d2046f67.svg) no-repeat center;
      mask: url(/static/media/social-youtube.d2046f67.svg) no-repeat center;
      background-color: #08152c;
      font-size: 0;
      line-height: 0;
      width: 40px;
      height: 40px; }
      .blog .blog-article .blog-article-sidebar .share .blog-youtube:hover {
        background-color: #f34322; }
  .blog .blog-article .blog-article-date {
    color: #808285;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 35px; }
    .blog .blog-article .blog-article-date .category,
    .blog .blog-article .blog-article-date time {
      display: inline-block;
      margin-right: 8px; }
    .blog .blog-article .blog-article-date .category {
      font-size: 15px;
      font-weight: 500;
      color: #1693e1; }
  .blog .blog-article .blog-article-image {
    margin-bottom: 45px; }
    .blog .blog-article .blog-article-image img {
      max-width: 100%;
      height: auto; }
  .blog .blog-article .blog-article-image + .blog-article-text h1:first-child,
  .blog .blog-article .blog-article-image + .blog-article-text h2:first-child {
    margin-top: 0; }
  .blog .blog-article .blog-article-headline {
    font-size: 33px;
    line-height: 42px;
    color: #131313;
    padding-right: 50px;
    margin-bottom: 35px; }
    .blog .blog-article .blog-article-headline h1 {
      font-weight: 600; }
  .blog .blog-article .blog-article-summary {
    font-size: 21px;
    line-height: 31px;
    font-weight: 400;
    color: #1693e1;
    padding-right: 50px;
    padding-bottom: 40px;
    border-bottom: 1px solid #e6e7e8;
    margin-bottom: 40px; }
    .blog .blog-article .blog-article-summary p {
      margin-bottom: 25px; }
      .blog .blog-article .blog-article-summary p:last-child {
        margin-bottom: 0; }
  .blog .blog-article .blog-article-headline + .blog-article-summary {
    margin-top: -15px; }
  .blog .blog-article .blog-article-text {
    color: #3c4a5b;
    font-size: 17px;
    line-height: 31px;
    margin-bottom: 50px; }
    .blog .blog-article .blog-article-text:after {
      content: '';
      display: table;
      clear: both; }
    .blog .blog-article .blog-article-text [data-oembed-type="video"] {
      padding-bottom: 30px;
      max-width: 100%; }
      .blog .blog-article .blog-article-text [data-oembed-type="video"] iframe {
        max-width: 100%;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        max-height: 500px; }
    .blog .blog-article .blog-article-text img {
      position: relative;
      top: 5px;
      float: left;
      margin-right: 30px;
      margin-bottom: 30px;
      max-width: 45%;
      height: auto; }
    .blog .blog-article .blog-article-text h1,
    .blog .blog-article .blog-article-text h2 {
      color: #08152c;
      margin-top: 60px;
      margin-bottom: 25px;
      font-size: 29px;
      line-height: 37px;
      font-weight: 500; }
      .blog .blog-article .blog-article-text h1 strong,
      .blog .blog-article .blog-article-text h1 b,
      .blog .blog-article .blog-article-text h2 strong,
      .blog .blog-article .blog-article-text h2 b {
        font-weight: inherit; }
    .blog .blog-article .blog-article-text h3,
    .blog .blog-article .blog-article-text h4,
    .blog .blog-article .blog-article-text h5,
    .blog .blog-article .blog-article-text h6 {
      font-size: 22px;
      line-height: 33px;
      font-weight: 500;
      color: #1693e1;
      margin-bottom: 5px; }
      .blog .blog-article .blog-article-text h3 strong,
      .blog .blog-article .blog-article-text h3 b,
      .blog .blog-article .blog-article-text h4 strong,
      .blog .blog-article .blog-article-text h4 b,
      .blog .blog-article .blog-article-text h5 strong,
      .blog .blog-article .blog-article-text h5 b,
      .blog .blog-article .blog-article-text h6 strong,
      .blog .blog-article .blog-article-text h6 b {
        font-weight: inherit; }
    .blog .blog-article .blog-article-text p,
    .blog .blog-article .blog-article-text li {
      overflow-wrap: break-word; }
      .blog .blog-article .blog-article-text p:empty,
      .blog .blog-article .blog-article-text li:empty {
        display: none; }
    .blog .blog-article .blog-article-text p,
    .blog .blog-article .blog-article-text ul,
    .blog .blog-article .blog-article-text ol {
      margin-bottom: 25px; }
      .blog .blog-article .blog-article-text p:last-child,
      .blog .blog-article .blog-article-text ul:last-child,
      .blog .blog-article .blog-article-text ol:last-child {
        margin-bottom: 0; }
    .blog .blog-article .blog-article-text ul,
    .blog .blog-article .blog-article-text ol {
      margin-top: 10px;
      margin-bottom: 40px; }
    .blog .blog-article .blog-article-text li {
      padding-bottom: 15px;
      border-bottom: 1px solid #F1F1F1;
      margin-bottom: 15px; }
      .blog .blog-article .blog-article-text li:last-child {
        border-bottom: none;
        padding-bottom: none; }
    .blog .blog-article .blog-article-text ul li {
      position: relative;
      margin-left: 35px; }
      .blog .blog-article .blog-article-text ul li:before {
        content: '';
        position: absolute;
        display: block;
        top: 11px;
        left: -19px;
        width: 7px;
        height: 7px;
        background-color: #8494a5;
        background-color: #1693e1;
        border-radius: 100%; }
    .blog .blog-article .blog-article-text ol {
      counter-reset: ol-counter; }
      .blog .blog-article .blog-article-text ol li {
        counter-increment: ol-counter;
        position: relative;
        margin-left: 35px; }
        .blog .blog-article .blog-article-text ol li:before {
          content: counter(ol-counter) ". ";
          position: absolute;
          display: block;
          color: #1693e1;
          left: -22px;
          text-align: right; }
    .blog .blog-article .blog-article-text h1 + ul,
    .blog .blog-article .blog-article-text h1 + ol, .blog .blog-article .blog-article-text h2 + ul,
    .blog .blog-article .blog-article-text h2 + ol, .blog .blog-article .blog-article-text h3 + ul,
    .blog .blog-article .blog-article-text h3 + ol, .blog .blog-article .blog-article-text h4 + ul,
    .blog .blog-article .blog-article-text h4 + ol, .blog .blog-article .blog-article-text h5 + ul,
    .blog .blog-article .blog-article-text h5 + ol, .blog .blog-article .blog-article-text h6 + ul,
    .blog .blog-article .blog-article-text h6 + ol {
      margin-top: 10px; }
    .blog .blog-article .blog-article-text a {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: all;
      transition-property: all;
      box-shadow: #2eb6ef 0px -2px 0px 0px inset;
      color: inherit;
      text-decoration: none;
      text-decoration: none;
      color: #08152c;
      padding-bottom: 2px; }
      .blog .blog-article .blog-article-text a:hover {
        box-shadow: #f34322 0px -2px 0px 0px inset; }
    .blog .blog-article .blog-article-text pre {
      font-size: 0.85em;
      line-height: 1.6em;
      color: #08152c;
      background-color: #f2f3f3;
      border-radius: 3px;
      padding: 15px;
      margin-bottom: 25px;
      overflow-x: auto;
      -moz-tab-size: 4;
           tab-size: 4; }
      .blog .blog-article .blog-article-text pre br:first-child, .blog .blog-article .blog-article-text pre br:last-child {
        display: none; }
  .blog .blog-article .blog-article-quote {
    position: relative;
    padding: 15px 40px;
    font-size: 21px;
    line-height: 38px;
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 50px; }
    .blog .blog-article .blog-article-quote:before {
      position: absolute;
      content: '';
      width: 5px;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: red;
      background: #004ea1;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #004ea1 0%, #1797e5 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: -webkit-gradient(linear, left top, left bottom, from(#004ea1), to(#1797e5));
      background: linear-gradient(to bottom, #004ea1 0%, #1797e5 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
    .blog .blog-article .blog-article-quote.has-quotes {
      text-indent: -10px; }
    .blog .blog-article .blog-article-quote p {
      margin-bottom: 15px; }
    .blog .blog-article .blog-article-quote a {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: all;
      transition-property: all;
      box-shadow: #2eb6ef 0px -4px 0px 0px inset;
      color: inherit;
      text-decoration: none;
      text-decoration: none;
      color: #08152c;
      padding-bottom: 2px; }
      .blog .blog-article .blog-article-quote a:hover {
        box-shadow: #f34322 0px -4px 0px 0px inset; }
    .blog .blog-article .blog-article-quote footer {
      color: #939598;
      font-size: 15px;
      line-height: 19px;
      font-weight: 400; }
  .blog .blog-article .blog-article-share {
    color: #fff;
    margin-top: 50px;
    margin-bottom: 50px; }
    .blog .blog-article .blog-article-share > .inner {
      display: table;
      widtH: auto;
      border-radius: 3px;
      background-color: #08152c; }
    .blog .blog-article .blog-article-share .title {
      display: table-cell;
      vertical-align: middle;
      font-size: 13px;
      font-weight: 600;
      padding-left: 17px;
      padding-right: 5px; }
    .blog .blog-article .blog-article-share .share {
      display: table-cell;
      vertical-align: middle; }
      .blog .blog-article .blog-article-share .share li {
        display: inline-block;
        vertical-align: top;
        padding: 5px 8px;
        border-right: 1px solid rgba(255, 255, 255, 0.15); }
      .blog .blog-article .blog-article-share .share a,
      .blog .blog-article .blog-article-share .share button {
        -webkit-transform: scale(0.8);
                transform: scale(0.8); }
      .blog .blog-article .blog-article-share .share .blog-facebook {
        cursor: pointer;
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: background-color;
        transition-property: background-color;
        display: block;
        -webkit-mask: url(/static/media/social-facebook.1fd2c3c3.svg) no-repeat center;
        mask: url(/static/media/social-facebook.1fd2c3c3.svg) no-repeat center;
        background-color: #2eb6ef;
        font-size: 0;
        line-height: 0;
        width: 40px;
        height: 40px; }
        .blog .blog-article .blog-article-share .share .blog-facebook:hover {
          background-color: #fff; }
      .blog .blog-article .blog-article-share .share .blog-twitter {
        cursor: pointer;
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: background-color;
        transition-property: background-color;
        display: block;
        -webkit-mask: url(/static/media/social-twitter.4a776a47.svg) no-repeat center;
        mask: url(/static/media/social-twitter.4a776a47.svg) no-repeat center;
        background-color: #2eb6ef;
        font-size: 0;
        line-height: 0;
        width: 40px;
        height: 40px; }
        .blog .blog-article .blog-article-share .share .blog-twitter:hover {
          background-color: #fff; }
      .blog .blog-article .blog-article-share .share .blog-linkedin {
        cursor: pointer;
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: background-color;
        transition-property: background-color;
        display: block;
        -webkit-mask: url(/static/media/social-linkedin.24818759.svg) no-repeat center;
        mask: url(/static/media/social-linkedin.24818759.svg) no-repeat center;
        background-color: #2eb6ef;
        font-size: 0;
        line-height: 0;
        width: 40px;
        height: 40px; }
        .blog .blog-article .blog-article-share .share .blog-linkedin:hover {
          background-color: #fff; }
      .blog .blog-article .blog-article-share .share .blog-github {
        cursor: pointer;
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: background-color;
        transition-property: background-color;
        display: block;
        -webkit-mask: url(/static/media/social-github.ff12d275.svg) no-repeat center;
        mask: url(/static/media/social-github.ff12d275.svg) no-repeat center;
        background-color: #2eb6ef;
        font-size: 0;
        line-height: 0;
        width: 40px;
        height: 40px; }
        .blog .blog-article .blog-article-share .share .blog-github:hover {
          background-color: #fff; }
      .blog .blog-article .blog-article-share .share .blog-telegram {
        cursor: pointer;
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: background-color;
        transition-property: background-color;
        display: block;
        -webkit-mask: url(/static/media/social-telegram.13428295.svg) no-repeat center;
        mask: url(/static/media/social-telegram.13428295.svg) no-repeat center;
        background-color: #2eb6ef;
        font-size: 0;
        line-height: 0;
        width: 40px;
        height: 40px; }
        .blog .blog-article .blog-article-share .share .blog-telegram:hover {
          background-color: #fff; }
      .blog .blog-article .blog-article-share .share .blog-youtube {
        cursor: pointer;
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: background-color;
        transition-property: background-color;
        display: block;
        -webkit-mask: url(/static/media/social-youtube.d2046f67.svg) no-repeat center;
        mask: url(/static/media/social-youtube.d2046f67.svg) no-repeat center;
        background-color: #2eb6ef;
        font-size: 0;
        line-height: 0;
        width: 40px;
        height: 40px; }
        .blog .blog-article .blog-article-share .share .blog-youtube:hover {
          background-color: #fff; }
  .blog .blog-article .blog-article-about {
    border: 1px solid #e6e7e8;
    background-color: #fff;
    box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #e6e7e8;
    border-radius: 4px;
    font-size: 15px;
    line-height: 25px;
    color: #3c4a5b;
    font-weight: 400;
    margin-bottom: 35px; }
    .blog .blog-article .blog-article-about li {
      display: block;
      border-bottom: 1px solid #e6e7e8;
      padding: 30px 38px; }
    .blog .blog-article .blog-article-about h6 {
      color: #1693e1;
      font-size: 19px;
      line-height: 29px;
      font-weight: 600;
      margin-bottom: 10px; }
    .blog .blog-article .blog-article-about strong {
      font-weight: 700;
      color: #08152c; }
    .blog .blog-article .blog-article-about a {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: all;
      transition-property: all;
      box-shadow: #2eb6ef 0px -2px 0px 0px inset;
      color: inherit;
      text-decoration: none;
      text-decoration: none;
      color: #08152c;
      padding-bottom: 2px; }
      .blog .blog-article .blog-article-about a:hover {
        box-shadow: #f34322 0px -2px 0px 0px inset; }
  .blog .blog-article .blog-article-categories {
    font-size: 13px;
    line-height: 19px;
    text-transform: capitalize; }
    .blog .blog-article .blog-article-categories > * {
      display: inline-block; }
    .blog .blog-article .blog-article-categories .title {
      color: #08152c;
      font-weight: 600;
      margin-right: 8px; }
    .blog .blog-article .blog-article-categories li {
      display: inline-block;
      margin-right: 8px; }
      .blog .blog-article .blog-article-categories li a {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: background-color;
        transition-property: background-color;
        display: inline-block;
        background-color: #1693e1;
        border-radius: 3px;
        color: #fff;
        text-decoration: none;
        padding: 2px 6px;
        margin-bottom: 10px; }
        .blog .blog-article .blog-article-categories li a:hover {
          background-color: #08152c; }

.blog .blog-sidebar {
  width: 27%;
  margin-top: -50px; }
  .blog .blog-sidebar > .inner {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding-top: 50px;
    padding-right: 140px; }
  .blog .blog-sidebar .blog-sidebar-group {
    margin-bottom: 40px; }
    .blog .blog-sidebar .blog-sidebar-group .title {
      color: #8494a5;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 10px; }
  .blog .blog-sidebar .blog-search {
    margin-bottom: 60px; }
    .blog .blog-sidebar .blog-search > .inner {
      position: relative; }
    .blog .blog-sidebar .blog-search input[type] {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: border background;
      transition-property: border background;
      background-color: #08152c;
      border: 2px solid #08152c;
      box-shadow: 0 0 0 rgba(255, 255, 255, 0);
      border-radius: 50px;
      padding-left: 20px;
      color: #fff; }
      .blog .blog-sidebar .blog-search input[type] ::-webkit-input-placeholder {
        color: #bcbec0; }
      .blog .blog-sidebar .blog-search input[type] :-moz-placeholder {
        color: #bcbec0; }
      .blog .blog-sidebar .blog-search input[type] ::-moz-placeholder {
        color: #bcbec0; }
      .blog .blog-sidebar .blog-search input[type] :-ms-input-placeholder {
        color: #bcbec0; }
      .blog .blog-sidebar .blog-search input[type]:focus {
        border-color: #1693e1; }
    .blog .blog-sidebar .blog-search button {
      cursor: pointer;
      position: absolute;
      top: 9px;
      right: 15px;
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: background-color;
      transition-property: background-color;
      display: block;
      -webkit-mask: url(/static/media/search.640efbde.svg) no-repeat center;
      mask: url(/static/media/search.640efbde.svg) no-repeat center;
      background-color: #bcbec0;
      font-size: 0;
      line-height: 0;
      width: 24px;
      height: 24px; }
      .blog .blog-sidebar .blog-search button:hover {
        background-color: #fff; }
  .blog .blog-sidebar .blog-categories {
    margin-top: 22px;
    font-size: 19px;
    line-height: 23px;
    color: #08152c;
    text-transform: capitalize; }
    .blog .blog-sidebar .blog-categories li {
      display: block;
      padding-top: 5px;
      padding-bottom: 5px; }
    .blog .blog-sidebar .blog-categories a {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: all;
      transition-property: all;
      box-shadow: #e6e7e8 0px -2px 0px 0px inset;
      color: inherit;
      text-decoration: none;
      text-decoration: none;
      font-weight: 600;
      padding-bottom: 1px; }
      .blog .blog-sidebar .blog-categories a:hover {
        box-shadow: #1693e1 0px -2px 0px 0px inset; }
  .blog .blog-sidebar .blog-subscribe form.has-response .fields-inline {
    opacity: 0; }
  .blog .blog-sidebar .blog-subscribe p {
    margin-bottom: 14px;
    font-size: 13px;
    line-height: 19px;
    color: #8494a5; }
  .blog .blog-sidebar .blog-subscribe .smooth-spinner {
    height: 42px;
    width: 42px; }
  .blog .blog-sidebar .blog-subscribe input[type=text],
  .blog .blog-sidebar .blog-subscribe input[type=email] {
    margin-bottom: 18px; }

@media (max-width: 1100px) {
  .blog > .inner {
    padding-top: 150px; }
  .blog .blog-article {
    width: 70%; }
    .blog .blog-article > .inner {
      padding-left: 50px;
      padding-right: 70px; }
    .blog .blog-article .blog-article-sidebar {
      display: none; }
    .blog .blog-article .blog-article-headline {
      font-size: 31px;
      line-height: 37px;
      padding-right: 25px; }
    .blog .blog-article .blog-article-summary {
      font-size: 19px;
      line-height: 29px;
      padding-right: 20px; }
    .blog .blog-article .blog-article-quote {
      margin-top: 30px;
      margin-bottom: 30px;
      line-height: 31px;
      padding-left: 30px;
      padding-right: 10px; }
    .blog .blog-article .blog-article-text ul,
    .blog .blog-article .blog-article-text ol {
      margin-top: 30px;
      margin-bottom: 30px; }
  .blog .blog-sidebar {
    width: 30%; }
    .blog .blog-sidebar > .inner {
      padding-right: 100px; }
    .blog .blog-sidebar .blog-categories {
      font-size: 16px;
      line-height: 23px; } }

@media (max-width: 860px) {
  .blog > .inner {
    display: block; }
  .blog .blog-article {
    display: block;
    width: auto;
    margin-bottom: 50px; }
    .blog .blog-article > .inner {
      padding-right: 50px; }
    .blog .blog-article .blog-article-headline {
      font-size: 25px;
      line-height: 35px;
      padding-right: 25px; }
    .blog .blog-article .blog-article-summary {
      font-size: 18px; }
    .blog .blog-article .blog-article-quote {
      font-size: 20px;
      line-height: 31px; }
    .blog .blog-article .blog-article-text {
      margin-bottom: 30px; }
      .blog .blog-article .blog-article-text [data-oembed-type="video"] iframe {
        max-width: -webkit-fill-available;
        height: 270px; }
      .blog .blog-article .blog-article-text h1 + p, .blog .blog-article .blog-article-text h2 + p, .blog .blog-article .blog-article-text h3 + p, .blog .blog-article .blog-article-text h4 + p, .blog .blog-article .blog-article-text h5 + p, .blog .blog-article .blog-article-text h6 + p {
        margin-top: -10px; }
      .blog .blog-article .blog-article-text p + h1,
      .blog .blog-article .blog-article-text p + h2,
      .blog .blog-article .blog-article-text p + h3,
      .blog .blog-article .blog-article-text p + h4,
      .blog .blog-article .blog-article-text p + h5,
      .blog .blog-article .blog-article-text p + h6 {
        margin-top: 40px; }
  .blog .blog-sidebar {
    border-top: 5px solid #F1F1F1;
    margin-top: 50px;
    padding-top: 50px;
    display: block;
    width: auto; }
    .blog .blog-sidebar > .inner {
      padding-left: 50px;
      padding-right: 100px;
      display: flex;
      flex-wrap: wrap; }
    .blog .blog-sidebar .blog-sidebar-group {
      width: 33.3333%; }
      .blog .blog-sidebar .blog-sidebar-group .title {
        color: #1693e1; }
      .blog .blog-sidebar .blog-sidebar-group:nth-child(1) {
        order: 2;
        width: 50%; }
      .blog .blog-sidebar .blog-sidebar-group:nth-child(2) {
        order: 1;
        width: 100%; }
      .blog .blog-sidebar .blog-sidebar-group:nth-child(3) {
        order: 3;
        width: 50%; }
      .blog .blog-sidebar .blog-sidebar-group .fields-inline {
        display: flex;
        flex-wrap: wrap; }
        .blog .blog-sidebar .blog-sidebar-group .fields-inline .field {
          width: 50%;
          padding-right: 20px; }
        .blog .blog-sidebar .blog-sidebar-group .fields-inline .action {
          width: 100%; } }

@media (max-width: 570px) {
  .blog > .inner {
    padding-top: 145px; }
  .blog .blog-article > .inner {
    padding-left: 30px;
    padding-right: 30px; }
  .blog .blog-article .blog-article-date {
    margin-bottom: 15px; }
  .blog .blog-article .blog-article-headline {
    font-size: 23px;
    line-height: 29px; }
  .blog .blog-article .blog-article-summary {
    font-size: 17px;
    line-height: 23px;
    padding-right: 0; }
  .blog .blog-article .blog-article-text {
    font-size: 15px;
    line-height: 25px; }
    .blog .blog-article .blog-article-text [data-oembed-type="video"] iframe {
      max-width: -webkit-fill-available;
      height: 270px; }
    .blog .blog-article .blog-article-text h1,
    .blog .blog-article .blog-article-text h2 {
      font-size: 22px;
      line-height: 29px;
      margin-top: 40px; }
    .blog .blog-article .blog-article-text h3,
    .blog .blog-article .blog-article-text h4,
    .blog .blog-article .blog-article-text h5,
    .blog .blog-article .blog-article-text h6 {
      font-size: 19px;
      line-height: 25px;
      margin-bottom: 10px; }
    .blog .blog-article .blog-article-text p.block-img {
      margin-bottom: 0; }
    .blog .blog-article .blog-article-text ul li {
      margin-left: 20px; }
      .blog .blog-article .blog-article-text ul li:before {
        top: 7px;
        left: -17px;
        background-color: #bcbec0;
        width: 6px;
        height: 6px; }
    .blog .blog-article .blog-article-text ol li {
      margin-left: 20px; }
      .blog .blog-article .blog-article-text ol li:before {
        top: 1px;
        left: -19px;
        color: #bcbec0;
        font-size: 14px; }
    .blog .blog-article .blog-article-text img {
      float: none;
      margin-right: 0;
      max-width: 100%; }
  .blog .blog-article .blog-article-quote {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 17px;
    line-height: 27px;
    padding: 10px 20px; }
  .blog .blog-article .blog-article-categories .title {
    display: block;
    margin-bottom: 10px; }
  .blog .blog-article .blog-article-about {
    font-size: 13px;
    line-height: 21px; }
    .blog .blog-article .blog-article-about li {
      padding: 20px; }
    .blog .blog-article .blog-article-about h6 {
      font-size: 17px;
      line-height: 23px;
      font-weight: 500; }
  .blog .blog-sidebar > .inner {
    padding-left: 30px;
    padding-right: 30px; }
    .blog .blog-sidebar > .inner .blog-sidebar-group .title {
      font-size: 16px;
      line-height: 21px; }
    .blog .blog-sidebar > .inner .blog-sidebar-group:nth-child(1) {
      padding-right: 10px; }
    .blog .blog-sidebar > .inner .blog-sidebar-group:nth-child(3) {
      padding-left: 10px; }
    .blog .blog-sidebar > .inner .blog-categories {
      font-size: 13px;
      line-height: 21px;
      font-weight: 500; }
    .blog .blog-sidebar > .inner .blog-subscribe .fields-inline {
      display: block; }
      .blog .blog-sidebar > .inner .blog-subscribe .fields-inline .field {
        width: auto;
        padding-right: 0; }
        .blog .blog-sidebar > .inner .blog-subscribe .fields-inline .field input {
          margin-bottom: 10px; }
      .blog .blog-sidebar > .inner .blog-subscribe .fields-inline .action {
        margin-top: 20px; } }

.blog .blog-articles {
  width: 73%; }
  .blog .blog-articles > .inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-left: 180px;
    padding-right: 110px;
    margin-left: -17px;
    margin-right: -17px; }

.blog .blog-article-card {
  position: relative;
  width: 50%;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 80px; }
  .blog .blog-article-card > .inner {
    -webkit-transition-duration: 0.25s;
            transition-duration: 0.25s;
    -webkit-transition-property: all;
    transition-property: all;
    position: relative;
    top: 0;
    height: 100%;
    overflow-x: hidden;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 2px 7px 0 rgba(0, 0, 0, 0.12); }
    .blog .blog-article-card > .inner:hover {
      box-shadow: 0px 20px 30px 0 rgba(0, 0, 0, 0.45);
      top: -5px; }
      .blog .blog-article-card > .inner:hover .title span {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: all;
        transition-property: all;
        box-shadow: #1693e1 0px -2px 0px 0px inset;
        color: inherit;
        text-decoration: none; }
        .blog .blog-article-card > .inner:hover .title span:hover {
          box-shadow: #1693e1 0px -2px 0px 0px inset; }
  .blog .blog-article-card a {
    color: inherit;
    text-decoration: none;
    display: table;
    table-layout: fixed;
    width: 101%;
    height: 100%; }
  .blog .blog-article-card .content,
  .blog .blog-article-card .image {
    display: table-row; }
  .blog .blog-article-card .content > .inner {
    position: relative;
    padding: 60px 40px 24px 30px; }
  .blog .blog-article-card .content time {
    position: absolute;
    top: 26px;
    right: 26px;
    color: #bcbec0;
    font-size: 12px;
    line-height: 15px; }
  .blog .blog-article-card .content .category {
    color: #1693e1;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 20px; }
  .blog .blog-article-card .content .title {
    padding-bottom: 2px;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500; }
    .blog .blog-article-card .content .title span {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: all;
      transition-property: all;
      box-shadow: transparent 0px -2px 0px 0px inset;
      color: inherit;
      text-decoration: none; }
      .blog .blog-article-card .content .title span:hover {
        box-shadow: #1693e1 0px -2px 0px 0px inset; }
  .blog .blog-article-card .image {
    width: 105%;
    font-size: 0;
    line-height: 0;
    height: 1px;
    overflow-x: hidden; }
    .blog .blog-article-card .image > .inner {
      position: relative;
      width: 100%;
      height: 100%; }
    .blog .blog-article-card .image .img {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center; }
    .blog .blog-article-card .image img {
      width: 100%;
      height: auto;
      opacity: 0; }

@media (max-width: 1440px) {
  .blog .blog-articles > .inner {
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
    padding-right: 40px; } }

@media (max-width: 1100px) {
  .blog-hero + .blog > .inner {
    padding-top: 50px; }
  .blog .blog-articles {
    width: auto; }
    .blog .blog-articles > .inner {
      padding-left: 35px;
      padding-right: 35px; }
  .blog .blog-article-card {
    padding-bottom: 40px; }
    .blog .blog-article-card .content .title {
      font-size: 17px;
      line-height: 24px; } }

@media (max-width: 570px) {
  .blog-hero + .blog > .inner {
    padding-top: 40px; }
  .blog .blog-articles > .inner {
    padding-left: 15px;
    padding-right: 15px; }
  .blog .blog-articles .blog-article-card {
    width: 100%; } }

.blog-hero {
  padding-top: 200px; }
  .blog-hero .carousel {
    height: 544px; }
  .blog-hero .flickity-viewport {
    height: 544px !important; }
  .blog-hero .flickity-prev-next-button {
    width: 74px;
    height: 74px;
    border-radius: 0;
    outline: 0;
    top: 507px; }
    .blog-hero .flickity-prev-next-button:before {
      content: '';
      position: absolute;
      top: 22px;
      left: 22px;
      display: block;
      -webkit-mask: url(/static/media/arrow-with-stem.c018e2a8.svg) no-repeat center;
      mask: url(/static/media/arrow-with-stem.c018e2a8.svg) no-repeat center;
      background-color: #fff;
      width: 30px;
      height: 30px; }
    .blog-hero .flickity-prev-next-button.previous:before {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .blog-hero .flickity-prev-next-button svg {
      display: none; }
  .blog-hero .flickity-button.previous {
    background-color: #2eb6ef;
    left: auto;
    right: calc(27% + 110px - 74px - 2px); }
  .blog-hero .flickity-button.next {
    background-color: #1693e1;
    left: auto;
    right: calc(27% + 110px - 74px - 74px - 2px); }
  .blog-hero .flickity-page-dots {
    display: none; }
  .blog-hero .slide {
    -webkit-transition-duration: 0.35s;
            transition-duration: 0.35s;
    -webkit-transition-property: height;
    transition-property: height;
    width: calc(73% - 290px);
    height: 470px !important; }
    .blog-hero .slide.is-selected {
      height: 544px !important;
      z-index: 2; }
    .blog-hero .slide > .inner {
      -webkit-transform: translateX(180px);
              transform: translateX(180px);
      position: relative;
      height: 100%; }
      .blog-hero .slide > .inner:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        right: -1px;
        z-index: 2;
        pointer-events: none;
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.6)));
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
    .blog-hero .slide a {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      text-decoration: none;
      color: #fff; }
      .blog-hero .slide a:hover .title span {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: all;
        transition-property: all;
        box-shadow: #1693e1 0px -2px 0px 0px inset;
        color: inherit;
        text-decoration: none; }
        .blog-hero .slide a:hover .title span:hover {
          box-shadow: #1693e1 0px -2px 0px 0px inset; }
    .blog-hero .slide .img {
      position: absolute;
      top: 0;
      left: -1px;
      right: -1px;
      bottom: 0;
      background-size: cover;
      background-position: center; }
    .blog-hero .slide .content {
      position: absolute;
      z-index: 3;
      left: 65px;
      bottom: 45px; }
      .blog-hero .slide .content .title {
        display: block;
        font-size: 31px;
        line-height: 39px;
        font-weight: 600;
        margin-bottom: 12px;
        color: #fff;
        padding-right: 100px; }
        .blog-hero .slide .content .title span {
          -webkit-transition-duration: 0.2s;
                  transition-duration: 0.2s;
          -webkit-transition-property: all;
          transition-property: all;
          box-shadow: transparent 0px -2px 0px 0px inset;
          color: inherit;
          text-decoration: none; }
          .blog-hero .slide .content .title span:hover {
            box-shadow: #1693e1 0px -2px 0px 0px inset; }
      .blog-hero .slide .content .category,
      .blog-hero .slide .content time {
        display: inline-block; }
      .blog-hero .slide .content .category {
        color: #1693e1;
        font-size: 15px;
        line-height: 19px;
        vertical-align: baseline;
        margin-right: 10px; }
      .blog-hero .slide .content time {
        color: #fff;
        font-size: 13px;
        line-height: 19px;
        vertical-align: baseline; }

@media (max-width: 1440px) {
  .blog-hero {
    padding-top: 150px; }
    .blog-hero .slide {
      width: calc(73% - 110px); }
      .blog-hero .slide > .inner {
        -webkit-transform: translateX(0);
                transform: translateX(0); }
      .blog-hero .slide .content {
        left: 85px; } }

@media (max-width: 1100px) {
  .blog-hero {
    padding-top: 100px; } }

@media (max-width: 860px) {
  .blog-hero .slide {
    width: 100%;
    height: 544px !important; }
    .blog-hero .slide .content {
      left: 50px; }
  .blog-hero .flickity-button {
    width: 60px;
    height: 60px;
    top: 514px; }
    .blog-hero .flickity-button:before {
      top: 15px;
      left: 16px; }
    .blog-hero .flickity-button.previous {
      background-color: #2eb6ef;
      left: auto;
      right: 60px; }
    .blog-hero .flickity-button.next {
      right: 0; } }

@media (max-width: 570px) {
  .blog-hero {
    padding-top: 80px; }
    .blog-hero .carousel {
      height: 380px; }
    .blog-hero .flickity-viewport {
      height: 380px !important; }
    .blog-hero .slide, .blog-hero .slide.is-selected {
      height: 380px !important; }
    .blog-hero .slide .content {
      left: 30px;
      right: 30px; }
      .blog-hero .slide .content .title {
        font-size: 23px;
        line-height: 29px;
        padding-right: 0; }
      .blog-hero .slide .content .category,
      .blog-hero .slide .content time {
        display: block; }
    .blog-hero .flickity-button {
      top: 350px; } }

.blog-title {
  color: #1693e1;
  display: inline-block;
  text-transform: capitalize;
  font-size: 29px;
  line-height: 37px;
  font-weight: 600;
  padding-top: 200px;
  padding-left: 184px;
  padding-bottom: 44px; }
  .blog-title a {
    color: #8494a5;
    text-decoration: none;
    font-weight: 400;
    display: inline-block;
    margin-right: 10px; }
  .blog-title h1,
  .blog-title span {
    display: inline-block;
    text-transform: capitalize;
    font-weight: 400; }
  .blog-title + .blog > .inner {
    padding-top: 0; }

.blog-hero + .blog-title {
  padding-top: 44px; }

@media (max-width: 1440px) {
  .blog-title {
    padding-left: 85px; } }

@media (max-width: 1100px) {
  .blog-title {
    padding-top: 160px;
    font-size: 21px;
    line-height: 29px; }
    .blog-title a {
      display: block; } }

@media (max-width: 860px) {
  .blog-title {
    padding-left: 50px; } }

@media (max-width: 570px) {
  .blog-title {
    padding-top: 140px;
    padding-bottom: 35px;
    padding-left: 30px;
    font-size: 19px;
    line-height: 25px; }
    .blog-title a {
      display: block; } }

.button, .submission button {
  cursor: pointer;
  position: relative;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-property: border color background;
  transition-property: border color background;
  display: inline-block;
  border: 1px solid #1693e1;
  color: #fff;
  background-color: #1693e1;
  text-decoration: none;
  border-radius: 30px;
  font-family: Graphik, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  padding: 14px 22px 12px 22px; }
  .button.disabled, .submission button.disabled {
    pointer-events: none; }
  .button:focus, .submission button:focus {
    outline: 0; }
  .button.small, .submission button.small {
    padding: 12px 18px 11px 18px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-right: 12px; }
    .button.small:last-child, .submission button.small:last-child {
      margin-right: 0; }
    .button.small .icon_check, .submission button.small .icon_check, .button.small .button.has_icon_check:before, .submission button.small .button.has_icon_check:before, .button.small .submission button.has_icon_check:before, .submission .button.small button.has_icon_check:before, .submission button.small button.has_icon_check:before {
      width: 18px;
      height: 18px; }
    .button.small .icon_retry, .submission button.small .icon_retry {
      width: 18px;
      height: 18px; }
  .button.red, .submission button.red {
    border-color: #f34322;
    background-color: #f34322;
    color: #fff; }
  .button.blue, .submission button.blue {
    border-color: #1693e1;
    background-color: #1693e1;
    color: #fff; }
  .button.blue_dark, .submission button.blue_dark {
    border-color: #02173e;
    background-color: #02173e;
    color: #fff; }
  .button.blue_med, .submission button.blue_med {
    border-color: #003b82;
    background-color: #003b82;
    color: #fff; }
  .button.blue_light, .submission button.blue_light {
    border-color: #2eb6ef;
    background-color: #2eb6ef;
    color: #fff; }
  .button.black, .submission button.black {
    border-color: #08152c;
    background-color: #08152c;
    color: #fff; }
  .button.yellow, .submission button.yellow {
    border-color: #ffc623;
    background-color: #ffc623;
    color: #02173e; }
  .button.white, .submission button.white {
    background-color: #fff;
    border-color: #fff;
    color: #1693e1; }
  .button.hover_red:hover, .submission button.hover_red:hover {
    border-color: #dc2800;
    background-color: #dc2800;
    color: #fff; }
  .button.hover_blue:hover, .submission button.hover_blue:hover {
    border-color: #1693e1;
    background-color: #1693e1;
    color: #fff; }
  .button.hover_blue_dark:hover, .submission button.hover_blue_dark:hover {
    border-color: #02173e;
    background-color: #02173e;
    color: #fff; }
  .button.hover_black:hover, .submission button.hover_black:hover {
    border-color: #08152c;
    background-color: #08152c;
    color: #fff; }
  .button.hover_blue_med:hover, .submission button.hover_blue_med:hover {
    border-color: #003b82;
    background-color: #003b82;
    color: #fff; }
  .button.hover_blue_light:hover, .submission button.hover_blue_light:hover {
    border-color: #2eb6ef;
    background-color: #2eb6ef;
    color: #fff; }
  .button.hover_yellow:hover, .submission button.hover_yellow:hover {
    border-color: #ffc623;
    background-color: #ffc623;
    color: #02173e; }
  .button.hover_white:hover, .submission button.hover_white:hover, .button.hover_blue_on_white:hover, .submission button.hover_blue_on_white:hover {
    background-color: #fff;
    border-color: #fff;
    color: #1693e1; }
  .button.hover_red_on_white:hover, .submission button.hover_red_on_white:hover {
    background-color: #fff;
    border-color: #fff;
    color: #dc2800; }
  .button.outline, .submission button.outline {
    border-width: 1px;
    background-color: transparent;
    color: #1693e1;
    border-color: #c1e7fc; }
  .button.outline_blue_light, .submission button.outline_blue_light {
    border-width: 1px;
    background-color: transparent;
    color: #fff;
    border-color: #2eb6ef; }
  .button.has_icon_check, .submission button.has_icon_check {
    padding-left: 35px; }
    .button.has_icon_check:before, .submission button.has_icon_check:before {
      content: '';
      background-color: #fff;
      position: absolute;
      top: 6px;
      left: 12px; }

.button_and_info {
  cursor: pointer;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-property: border color background;
  transition-property: border color background;
  display: inline-block;
  text-decoration: none;
  font-size: 13px;
  line-height: 17px;
  margin-right: 12px; }
  .button_and_info.yellow .button_status {
    border-color: #f0b611;
    background-color: #f0b611; }
  .button_and_info.yellow .button_info {
    border-color: #f0b611;
    color: #f0b611; }
  .button_and_info.yellow:hover .button_status {
    border-color: #ffc623;
    background-color: #ffc623; }
  .button_and_info.yellow:hover .button_info {
    border-color: #ffc623;
    color: #ffc623; }
  .button_and_info.blue:hover .button_status {
    border-color: #2eb6ef;
    background-color: #2eb6ef; }
  .button_and_info.blue:hover .button_info {
    border-color: #2eb6ef;
    color: #2eb6ef; }
  .button_and_info .button_status {
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    position: relative;
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
    border: 1.5px solid #1693e1;
    color: #fff;
    background-color: #1693e1;
    border-radius: 30px;
    padding: 7px 14px 7px 34px; }
  .button_and_info .button_info {
    position: relative;
    margin-left: -30px;
    display: inline-block;
    vertical-align: middle;
    border: 1.5px solid #1693e1;
    background-color: #fff;
    color: #1693e1;
    border-radius: 0 30px 30px 0;
    padding: 7px 14px 7px 34px;
    font-weight: 600; }

@media (max-width: 1250px) {
  .button, .submission button {
    font-size: 15px;
    line-height: 19px;
    padding: 10px 18px; } }

@media (max-width: 860px) {
  .button.small, .submission button.small {
    padding: 6px 12px;
    font-size: 13px;
    line-height: 15px; }
    .button.small.has_icon_check, .submission button.small.has_icon_check {
      padding-left: 32px; }
      .button.small.has_icon_check:before, .submission button.small.has_icon_check:before {
        top: 4px;
        left: 10px;
        -webkit-transform: scale(0.75);
                transform: scale(0.75); } }

@media (max-width: 860px) {
  .button_and_info .button_status {
    padding: 6px 12px 6px 30px; }
    .button_and_info .button_status .icon_check, .button_and_info .button_status .button.has_icon_check:before, .button_and_info .button_status .submission button.has_icon_check:before, .submission .button_and_info .button_status button.has_icon_check:before {
      -webkit-transform: scale(0.75);
              transform: scale(0.75); }
    .button_and_info .button_status .icon_check, .button_and_info .button_status .button.has_icon_check:before, .button_and_info .button_status .submission button.has_icon_check:before, .submission .button_and_info .button_status button.has_icon_check:before,
    .button_and_info .button_status .icon_retry {
      top: 5px;
      left: 8px; }
  .button_and_info .button_info {
    padding: 6px 12px 6px 34px; } }

@media (max-width: 570px) {
  .button, .submission button {
    font-size: 13px;
    padding-top: 11px;
    padding-bottom: 11px; } }

.call-to-action {
  position: relative;
  background-color: #02173e;
  color: #fff;
  background-size: cover;
  background-position: right bottom; }
  .call-to-action > .inner {
    padding: 170px 140px 150px 140px; }
  .call-to-action .title {
    max-width: 750px;
    font-size: 64px;
    line-height: 70px;
    margin-bottom: 30px;
    color: #fff; }
    .call-to-action .title.medium {
      font-size: 55px;
      line-height: 63px; }
  .call-to-action .description {
    max-width: 750px;
    font-size: 21px;
    line-height: 35px;
    color: #fff;
    margin-bottom: 50px; }
  .call-to-action.medium .description, .call-to-action.medium .title {
    padding-right: 0;
    max-width: 900px; }
  .call-to-action.wide .description, .call-to-action.wide .title {
    padding-right: 0;
    max-width: 1125px; }
  .call-to-action .actions li {
    display: inline-block;
    margin-right: 15px; }
    .call-to-action .actions li:last-child {
      margin-right: 0; }

@media (max-width: 1440px) {
  .call-to-action > .inner {
    padding: 140px 100px 120px 100px; } }

@media (max-width: 1250px) {
  .call-to-action > .inner {
    padding: 100px 80px 80px 80px; }
  .call-to-action .title {
    font-size: 59px;
    line-height: 63px;
    max-width: 675px; }
  .call-to-action .description {
    max-width: 675px; } }

@media (max-width: 860px) {
  .call-to-action .title {
    font-size: 44px;
    line-height: 53px;
    letter-spacing: 0;
    max-width: 580px; }
  .call-to-action .description {
    font-size: 18px;
    line-height: 27px;
    max-width: 580px; } }

@media (max-width: 750px) {
  .call-to-action > .inner {
    padding-left: 50px;
    padding-right: 40px; } }

@media (max-width: 570px) {
  .call-to-action > .inner {
    padding: 120px 30px 100px 40px; }
  .call-to-action .title {
    font-size: 39px;
    line-height: 45px; }
  .call-to-action .description {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 30px;
    padding-right: 20px; } }

.content-block {
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat; }
  .content-block.border-top-blue {
    border-top: 9px solid #1693e1; }
  .content-block.bg-blue-dark-bokeh {
    background-color: #132031 !important;
    background-image: url(/static/media/path-bg.4b907741.jpg);
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% auto;
    background-position: center top;
    background-repeat: no-repeat; }
    @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      .content-block.bg-blue-dark-bokeh {
        background-image: url(/static/media/path-bg@2x.84fee8bb.jpg); } }
  .content-block .photo-cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 0;
    line-height: 0; }
    .content-block .photo-cover.border-bottom-yellow {
      border-bottom: 9px solid #ffc623; }

@media (max-width: 570px) {
  .content-block .photo-cover {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 0;
    padding-bottom: 75%; } }

.engagement-inline {
  padding-top: 50px; }
  .engagement-inline > .layout {
    display: flex; }
  .engagement-inline .engagement-content {
    width: 40%;
    padding-right: 50px; }
    .engagement-inline .engagement-content .title {
      display: inline-block;
      font-size: 39px;
      line-height: 45px;
      margin-bottom: 25px; }
    .engagement-inline .engagement-content .description {
      font-size: 19px;
      line-height: 34px; }
  .engagement-inline .engagement-form {
    width: 60%; }
  .engagement-inline iframe {
    max-width: 100% !important;
    margin: auto auto auto 0 !important; }

@media (max-width: 860px) {
  .engagement-inline {
    padding-top: 25px; }
    .engagement-inline > .layout {
      flex-wrap: wrap; }
    .engagement-inline .engagement-content,
    .engagement-inline .engagement-form {
      width: 100%; }
    .engagement-inline .engagement-content {
      margin-bottom: 45px; }
      .engagement-inline .engagement-content .title {
        font-size: 31px;
        line-height: 36px; }
      .engagement-inline .engagement-content .description {
        font-size: 16px;
        line-height: 27px; } }

@media (max-width: 570px) {
  .engagement-inline {
    padding-top: 15px; }
    .engagement-inline .engagement-content {
      margin-bottom: 35px; }
      .engagement-inline .engagement-content .title {
        font-size: 25px;
        line-height: 33px;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 29px;
        line-height: 33px;
        font-weight: 600; }
      .engagement-inline .engagement-content .description {
        font-size: 16px;
        line-height: 27px; } }

.faqs .faq-item {
  border-top: 2px solid #F1F1F1;
  padding-top: 20px;
  margin-top: 17px; }
  .faqs .faq-item.is-highlight {
    border-top: none; }
    .faqs .faq-item.is-highlight .answer {
      display: block; }
  .faqs .faq-item:not(.is-open) .question-content .question,
  .faqs .faq-item:not(.is-open) .question-content .question-link {
    margin-bottom: 0; }
  .faqs .faq-item.is-open > .inner > div:first-child:before {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    border-left-color: #1693e1; }
  .faqs .faq-item.is-open .answer {
    display: block; }
  .faqs .faq-item:last-child {
    margin-bottom: 0; }
  .faqs .faq-item.is-highlight {
    padding-bottom: 50px; }
    .faqs .faq-item.is-highlight .inner a {
      margin-top: 20px;
      word-break: break-all; }
    .faqs .faq-item.is-highlight .flag {
      margin-bottom: 30px; }
    .faqs .faq-item.is-highlight .question {
      font-size: 41px;
      line-height: 49px;
      font-weight: 600;
      margin-bottom: 25px; }
  .faqs .faq-item .question-header {
    position: relative; }
    .faqs .faq-item .question-header:before {
      -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
      -webkit-transition-property: all;
      transition-property: all;
      content: '';
      position: absolute;
      left: -24px;
      top: 6px;
      display: block;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid #939598; }
  .faqs .faq-item .question-content {
    display: flex;
    align-items: safe;
    justify-content: space-between;
    align-items: top; }
    .faqs .faq-item .question-content .question {
      cursor: pointer;
      font-size: 19px;
      line-height: 25px;
      font-weight: 500;
      color: #131313;
      margin-bottom: 15px; }
    .faqs .faq-item .question-content .question-link {
      cursor: pointer;
      flex-shrink: 0;
      height: 23px; }
    .faqs .faq-item .question-content .copy-message {
      -webkit-transition-duration: 0.5s;
              transition-duration: 0.5s;
      -webkit-transition-property: opacity;
      transition-property: opacity;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -24px;
      margin-right: 13px;
      font-size: 12px;
      line-height: 15px;
      padding: 3px 5px;
      border-radius: 2px;
      background-color: #1693e1;
      color: #fff;
      font-weight: 600; }
      .faqs .faq-item .question-content .copy-message.copied {
        -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
        -webkit-transition-property: opacity;
        transition-property: opacity;
        opacity: 1; }
  .faqs .faq-item p,
  .faqs .faq-item li {
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 50px; }
    .faqs .faq-item p:last-child,
    .faqs .faq-item li:last-child {
      padding-bottom: 0; }
  .faqs .faq-item .answer {
    display: none;
    padding-top: 10px;
    padding-bottom: 10px; }
  .faqs .faq-item .answer:last-child p:last-child,
  .faqs .faq-item .answer div:last-child p:last-child {
    margin-bottom: 0; }
  .faqs .faq-item .answer a,
  .faqs .faq-item .answer div a {
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-property: all;
    transition-property: all;
    box-shadow: #1693e1 0px -2px 0px 0px inset;
    color: inherit;
    text-decoration: none;
    padding-bottom: 2px;
    color: #131313;
    font-weight: 500;
    text-decoration: none; }
    .faqs .faq-item .answer a:hover,
    .faqs .faq-item .answer div a:hover {
      box-shadow: #f34322 0px -2px 0px 0px inset; }
    .faqs .faq-item .answer a:hover,
    .faqs .faq-item .answer div a:hover {
      color: #131313; }
  .faqs .faq-item .answer h1,
  .faqs .faq-item .answer h2,
  .faqs .faq-item .answer div h1,
  .faqs .faq-item .answer div h2 {
    color: #131313;
    font-size: 34px;
    line-height: 49px;
    font-weight: 700;
    max-width: 950px;
    margin-bottom: 30px; }
    .faqs .faq-item .answer h1:last-child,
    .faqs .faq-item .answer h2:last-child,
    .faqs .faq-item .answer div h1:last-child,
    .faqs .faq-item .answer div h2:last-child {
      margin-bottom: 0; }
  .faqs .faq-item .answer h3,
  .faqs .faq-item .answer div h3 {
    color: #131313;
    font-size: 29px;
    line-height: 35px;
    font-weight: 600;
    margin-bottom: 25px; }
    .faqs .faq-item .answer h3:last-child,
    .faqs .faq-item .answer div h3:last-child {
      margin-bottom: 0; }
  .faqs .faq-item .answer h4,
  .faqs .faq-item .answer div h4 {
    color: #131313;
    font-size: 14px;
    line-height: 35px;
    font-weight: 500; }
    .faqs .faq-item .answer h4:last-child,
    .faqs .faq-item .answer div h4:last-child {
      margin-bottom: 0; }
  .faqs .faq-item .answer p,
  .faqs .faq-item .answer li,
  .faqs .faq-item .answer div p,
  .faqs .faq-item .answer div li {
    list-style-type: none;
    font-size: 17px;
    line-height: 29px; }
    .faqs .faq-item .answer p a:not(.button),
    .faqs .faq-item .answer li a:not(.button),
    .faqs .faq-item .answer div p a:not(.button),
    .faqs .faq-item .answer div li a:not(.button) {
      padding-bottom: 2px;
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: all;
      transition-property: all;
      box-shadow: #2eb6ef 0px -1.5px 0px 0px inset;
      color: inherit;
      text-decoration: none;
      color: #131313; }
      .faqs .faq-item .answer p a:not(.button):hover,
      .faqs .faq-item .answer li a:not(.button):hover,
      .faqs .faq-item .answer div p a:not(.button):hover,
      .faqs .faq-item .answer div li a:not(.button):hover {
        box-shadow: #dc2800 0px -1.5px 0px 0px inset; }
  .faqs .faq-item .answer p,
  .faqs .faq-item .answer div p {
    margin-bottom: 20px;
    word-break: break-word; }
    .faqs .faq-item .answer p:last-child,
    .faqs .faq-item .answer div p:last-child {
      margin-bottom: 0; }
  .faqs .faq-item .answer li,
  .faqs .faq-item .answer div li {
    margin-bottom: 20px; }
  .faqs .faq-item .answer > ul,
  .faqs .faq-item .answer > ol,
  .faqs .faq-item .answer div > ul,
  .faqs .faq-item .answer div > ol {
    margin-bottom: 50px; }
    .faqs .faq-item .answer > ul:last-child,
    .faqs .faq-item .answer > ol:last-child,
    .faqs .faq-item .answer div > ul:last-child,
    .faqs .faq-item .answer div > ol:last-child {
      margin-bottom: 0; }
    .faqs .faq-item .answer > ul li,
    .faqs .faq-item .answer > ol li,
    .faqs .faq-item .answer div > ul li,
    .faqs .faq-item .answer div > ol li {
      position: relative;
      padding-left: 40px; }
  .faqs .faq-item .answer > ul.has-title li:first-child,
  .faqs .faq-item .answer div > ul.has-title li:first-child {
    padding-left: 0;
    margin-bottom: 4px; }
    .faqs .faq-item .answer > ul.has-title li:first-child:before,
    .faqs .faq-item .answer div > ul.has-title li:first-child:before {
      display: none; }
  .faqs .faq-item .answer > ul li:before,
  .faqs .faq-item .answer div > ul li:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 20px;
    display: block;
    width: 7px;
    height: 7px;
    background-color: #BCBEC0;
    border-radius: 7px; }
  .faqs .faq-item .answer ol,
  .faqs .faq-item .answer div ol {
    counter-reset: ol-counter; }
    .faqs .faq-item .answer ol li,
    .faqs .faq-item .answer div ol li {
      counter-increment: ol-counter;
      position: relative;
      margin-left: 35px;
      padding-left: 10px; }
      .faqs .faq-item .answer ol li:before,
      .faqs .faq-item .answer div ol li:before {
        content: counter(ol-counter) ". ";
        position: absolute;
        display: block;
        color: #1693e1;
        left: -22px;
        text-align: right; }
  .faqs .faq-item .answer strong,
  .faqs .faq-item .answer b,
  .faqs .faq-item .answer div strong,
  .faqs .faq-item .answer div b {
    font-weight: 600;
    color: #131313; }
  .faqs .faq-item .answer em,
  .faqs .faq-item .answer i,
  .faqs .faq-item .answer div em,
  .faqs .faq-item .answer div i {
    font-style: italic; }
  .faqs .faq-item .answer .actions li,
  .faqs .faq-item .answer div .actions li {
    display: inline-block;
    margin-right: 15px; }
    .faqs .faq-item .answer .actions li:last-child,
    .faqs .faq-item .answer div .actions li:last-child {
      margin-right: 0; }

.faqs .faq-nav {
  position: relative;
  margin-bottom: 50px; }
  .faqs .faq-nav ul {
    width: 100%;
    border-bottom: 5px solid #F1F1F1; }
  .faqs .faq-nav li {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    text-align: left;
    color: #131313;
    font-size: 15px;
    line-height: 21px;
    font-weight: 600;
    padding-left: 30px;
    padding-right: 30px; }
    .faqs .faq-nav li:first-child {
      padding-left: 0; }
      .faqs .faq-nav li:first-child a {
        padding-left: 0; }
    .faqs .faq-nav li a {
      color: #131313;
      text-decoration: none;
      position: relative;
      bottom: -5px;
      display: inline-block;
      cursor: pointer;
      border-bottom: 5px solid #F1F1F1;
      padding: 30px 5px 30px 5px; }
      .faqs .faq-nav li a:hover {
        text-decoration: none;
        color: #131313;
        border-color: #ffc623; }
      .faqs .faq-nav li a.selected {
        text-decoration: none;
        color: #131313;
        border-color: #ffc623; }

@media (max-width: 1100px) {
  .faqs .faq-item .question-content .question {
    font-size: 19px;
    line-height: 25px; } }

@media (max-width: 860px) {
  .faqs .faq-item.is-highlight {
    padding-bottom: 30px; }
    .faqs .faq-item.is-highlight .question {
      font-size: 27px;
      line-height: 39px;
      margin-bottom: 20px; }
    .faqs .faq-item.is-highlight .answer,
    .faqs .faq-item.is-highlight .answer div {
      padding-left: 0; }
  .faqs .faq-item > .inner > a:first-child {
    padding-left: 17px; }
    .faqs .faq-item > .inner > a:first-child:before {
      top: 5px;
      left: 0;
      border-top-width: 6px;
      border-bottom-width: 6px;
      border-left-width: 6px; }
  .faqs .faq-item .question-content .question {
    font-size: 19px;
    line-height: 25px; }
  .faqs .faq-item .answer p,
  .faqs .faq-item .answer li,
  .faqs .faq-item .answer div p,
  .faqs .faq-item .answer div li {
    font-size: 17px;
    line-height: 27px; }
  .faqs .faq-nav {
    position: relative;
    margin-bottom: 35px; }
    .faqs .faq-nav:before {
      content: 'FAQ Categories';
      display: block;
      font-size: 16px;
      font-weight: 500;
      color: #1693e1;
      margin-bottom: 5px; }
    .faqs .faq-nav ul {
      border-bottom: none; }
    .faqs .faq-nav li {
      display: block;
      padding-left: 0;
      padding-right: 0;
      font-weight: 500; }
      .faqs .faq-nav li a {
        border-bottom: none;
        border-left: 3px solid #F1F1F1; }
        .faqs .faq-nav li a, .faqs .faq-nav li a.selected {
          padding: 5px 0 5px 10px; }
        .faqs .faq-nav li a.selected {
          font-weight: 600; } }

@media (max-width: 570px) {
  .faqs .faq-item {
    margin-top: 14px;
    padding-top: 14px; }
    .faqs .faq-item.is-highlight .question {
      font-size: 23px;
      line-height: 35px; }
    .faqs .faq-item .question-header:before {
      top: 5px;
      left: 2px;
      border-top-width: 5px;
      border-bottom-width: 5px;
      border-left-width: 5px; }
    .faqs .faq-item .question-content {
      display: block;
      padding-right: 25px; }
      .faqs .faq-item .question-content .question {
        font-size: 15px;
        line-height: 23px;
        padding-left: 20px; }
      .faqs .faq-item .question-content .has-question-link {
        position: absolute;
        top: -1px;
        right: 0; }
      .faqs .faq-item .question-content .question-link {
        -webkit-transform-origin: center;
                transform-origin: center;
        -webkit-transform: scale(0.8);
                transform: scale(0.8); }
    .faqs .faq-item .answer {
      padding-left: 0; }
    .faqs .faq-item .answer p,
    .faqs .faq-item .answer li,
    .faqs .faq-item .answer div p,
    .faqs .faq-item .answer div li {
      font-size: 15px;
      line-height: 25px; }
    .faqs .faq-item .answer > ul li,
    .faqs .faq-item .answer div > ul li {
      padding-left: 23px; }
      .faqs .faq-item .answer > ul li:before,
      .faqs .faq-item .answer div > ul li:before {
        left: 2px;
        top: 8px; }
    .faqs .faq-item .answer > ol li,
    .faqs .faq-item .answer div > ol li {
      margin-left: 0;
      padding-left: 23px; }
      .faqs .faq-item .answer > ol li:before,
      .faqs .faq-item .answer div > ol li:before {
        left: 2px; } }

.feature-chart {
  position: relative; }
  .feature-chart > .inner {
    position: relative;
    padding: 80px 0 0 200px; }
  .feature-chart .block-label {
    color: #939598; }
    .feature-chart .block-label .inner:after {
      background-color: #939598; }
  .feature-chart .title {
    color: #08152c;
    font-size: 41px;
    line-height: 51px; }
  .feature-chart .feature-chart-table {
    position: relative;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    margin-top: 80px;
    margin-bottom: 80px;
    color: #3c4a5b; }
    .feature-chart .feature-chart-table.phone-only {
      display: none; }
    .feature-chart .feature-chart-table > .inner {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .feature-chart .feature-chart-table > .inner:before {
        left: 20%;
        right: 0; }
      .feature-chart .feature-chart-table > .inner:after {
        left: 20%;
        right: 60%; }
      .feature-chart .feature-chart-table > .inner:hover .row.highlight .column.feature {
        color: inherit; }
        .feature-chart .feature-chart-table > .inner:hover .row.highlight .column.feature:before {
          background-color: transparent; }
      .feature-chart .feature-chart-table > .inner:hover .row.highlight .column.dragonchain {
        background-color: transparent;
        color: #1693e1; }
      .feature-chart .feature-chart-table > .inner:before {
        position: absolute;
        z-index: 0;
        content: '';
        display: block;
        background-color: #F3F3F3;
        top: 0;
        bottom: 0;
        border-radius: 4px 0 0 4px;
        box-shadow: 0 5px 55px 0 rgba(0, 0, 0, 0.25); }
      .feature-chart .feature-chart-table > .inner:after {
        position: absolute;
        z-index: 0;
        content: '';
        display: block;
        background-color: #fff;
        top: 0;
        bottom: 0;
        border-radius: 4px 0 0 4px; }
    .feature-chart .feature-chart-table .head,
    .feature-chart .feature-chart-table .row {
      display: table-row; }
    .feature-chart .feature-chart-table .row:last-child .column {
      border-bottom: none; }
    .feature-chart .feature-chart-table .row:hover .column:before {
      opacity: 1; }
    .feature-chart .feature-chart-table .row:hover .column.feature {
      color: #fff !important; }
      .feature-chart .feature-chart-table .row:hover .column.feature:before {
        background-color: #1693e1 !important; }
    .feature-chart .feature-chart-table .row:hover .column.dragonchain {
      background-color: #1693e1 !important;
      color: #fff !important; }
    .feature-chart .feature-chart-table .row.highlight .column.dragonchain {
      background-color: #1693e1;
      color: #fff; }
    .feature-chart .feature-chart-table .row.highlight .column.feature {
      color: #fff; }
      .feature-chart .feature-chart-table .row.highlight .column.feature:before {
        background-color: #1693e1;
        opacity: 1; }
    .feature-chart .feature-chart-table .column {
      position: relative;
      z-index: 1;
      display: table-cell;
      width: 25%;
      padding: 30px 15px 27px 15px;
      text-align: center;
      vertical-align: middle;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
      .feature-chart .feature-chart-table .column:not(.feature):before {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: all;
        transition-property: all;
        opacity: 0; }
      .feature-chart .feature-chart-table .column:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: -1px;
        background-color: rgba(0, 0, 0, 0.05); }
      .feature-chart .feature-chart-table .column.feature {
        -webkit-transition-duration: 0;
                transition-duration: 0;
        text-align: right;
        padding-right: 50px;
        color: #3c4a5b;
        border-color: transparent;
        opacity: 1; }
        .feature-chart .feature-chart-table .column.feature:before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: -1px;
          background-color: transparent;
          z-index: -1;
          border-radius: 3px 0 0 3px; }
      .feature-chart .feature-chart-table .column.blue {
        color: #1693e1; }
      .feature-chart .feature-chart-table .column.platform {
        font-size: 19px;
        line-height: 25px; }
      .feature-chart .feature-chart-table .column.dragonchain {
        background-color: transparent; }
      .feature-chart .feature-chart-table .column.no {
        color: #8494a5; }
      .feature-chart .feature-chart-table .column em {
        margin-top: 4px;
        font-style: normal;
        display: block;
        font-weight: 400;
        font-size: 0.9em; }

@media (max-width: 1440px) {
  .feature-chart > .inner {
    padding-left: 140px; }
  .feature-chart .feature-chart-table .column {
    font-size: 15px; }
    .feature-chart .feature-chart-table .column.platform {
      font-size: 17px; } }

@media (max-width: 1100px) {
  .feature-chart > .inner {
    padding-left: 80px;
    padding-right: 80px; }
  .feature-chart .title {
    font-size: 36px;
    line-height: 42px;
    font-weight: 600; }
  .feature-chart .feature-chart-table {
    margin-left: -40px; }
    .feature-chart .feature-chart-table .column {
      font-size: 14px;
      line-height: 19px; }
      .feature-chart .feature-chart-table .column.platform {
        font-size: 15px; } }

@media (max-width: 860px) {
  .feature-chart > .inner {
    padding-left: 50px;
    padding-right: 50px; }
  .feature-chart .title {
    font-size: 31px;
    line-height: 36px; }
  .feature-chart .feature-chart-table {
    margin-top: 50px;
    margin-left: -20px;
    margin-right: -20px; }
    .feature-chart .feature-chart-table .column {
      padding-top: 15px;
      padding-bottom: 13px;
      font-size: 13px;
      line-height: 17px; }
      .feature-chart .feature-chart-table .column.platform {
        font-size: 14px;
        line-height: 19px; }
      .feature-chart .feature-chart-table .column.feature {
        padding-right: 25px;
        font-size: 12px; } }

@media (max-width: 570px) {
  .feature-chart > .inner {
    padding: 50px 30px; }
  .feature-chart .title {
    font-size: 29px;
    line-height: 33px;
    padding-right: 20px; }
  .feature-chart .feature-chart-table {
    display: none;
    margin-left: -10px;
    margin-right: -10px; }
    .feature-chart .feature-chart-table.phone-only {
      display: table;
      margin-bottom: 50px; }
      .feature-chart .feature-chart-table.phone-only:last-child {
        margin-bottom: 0; }
      .feature-chart .feature-chart-table.phone-only > .inner:before {
        left: 50%;
        right: 0;
        border-radius: 3px; }
      .feature-chart .feature-chart-table.phone-only > .inner:after {
        left: 50%;
        right: 0;
        border-radius: 3px; } }

.flag {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 400;
  position: relative;
  left: 2px;
  font-size: 22px;
  line-height: 25px;
  text-transform: uppercase;
  margin-bottom: 15px; }
  .flag.large {
    font-size: 27px;
    line-height: 33px;
    text-rendering: geometricPrecision;
    margin-bottom: 27px; }
    .flag.large > .inner {
      padding: 2px 10px 7px 10px; }
    .flag.large:after {
      bottom: -18px;
      border-top: 18px solid #1693e1; }
  .flag > .inner {
    display: inline-block;
    padding: 0px 8px 5px 8px;
    background-color: #2eb6ef;
    color: #fff; }
  .flag:after {
    position: absolute;
    pointer-events: none;
    content: '';
    width: 0;
    height: 0;
    left: 0;
    bottom: -13px;
    border-top: 13px solid #1693e1;
    border-right: 8px solid transparent; }

@media (max-width: 1680px) {
  .flag.large {
    font-size: 26px;
    line-height: 31px;
    margin-bottom: 24px; }
    .flag.large > .inner {
      padding: 1px 10px 6px 10px; } }

@media (max-width: 860px) {
  .flag.large {
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 25px; }
    .flag.large > .inner {
      padding: 2px 8px 5px 8px;
      border-radius: 2px 2px 2px 0; } }

.landing + .footer > .inner {
  padding-left: 200px; }

.course-viewer + .footer {
  display: none; }

.footer {
  background-color: #fff; }
  .footer > .inner {
    padding: 120px 200px 90px 140px; }
  .footer .promise {
    color: #131313; }
    .footer .promise > .inner {
      display: inline-block; }
    .footer .promise .jojo {
      display: block;
      width: 90px;
      height: 90px;
      background-image: url(/static/media/jojo-treasure-small.f19c04fe.png);
      background-size: 90px 90px;
      margin-bottom: 33px; }
    .footer .promise .tagline {
      font-size: 30px;
      line-height: 35px;
      font-weight: 700;
      margin-bottom: 15px; }
    .footer .promise .copyright {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 29px; }
    .footer .promise .colors {
      display: table;
      table-layout: fixed;
      width: 400px; }
      .footer .promise .colors div {
        display: table-cell;
        width: auto;
        height: 3px; }
        .footer .promise .colors div:nth-child(1) {
          background-color: #02173e; }
        .footer .promise .colors div:nth-child(2) {
          background-color: #003b82; }
        .footer .promise .colors div:nth-child(3) {
          background-color: #1693e1; }
        .footer .promise .colors div:nth-child(4) {
          background-color: #2eb6ef; }
        .footer .promise .colors div:nth-child(5) {
          background-color: #ffc623; }
        .footer .promise .colors div:nth-child(6) {
          background-color: #dc2800; }
  .footer li {
    list-style: none; }
  .footer .links {
    display: flex;
    padding-top: 57px;
    padding-bottom: 57px; }
    .footer .links .dragonchain-links {
      display: flex;
      width: 55%; }
      .footer .links .dragonchain-links ul {
        flex: 1 1;
        vertical-align: top; }
      .footer .links .dragonchain-links li {
        font-size: 19px;
        line-height: 33px;
        font-weight: 600;
        color: #131313; }
      .footer .links .dragonchain-links a {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: color;
        transition-property: color;
        color: #131313;
        text-decoration: none; }
        .footer .links .dragonchain-links a.disabled {
          pointer-events: none;
          color: #A7A9AC; }
        .footer .links .dragonchain-links a:hover {
          color: #1693e1; }
    .footer .links .social-links {
      position: relative;
      top: -15px;
      width: 45%;
      text-align: right; }
      .footer .links .social-links li {
        display: inline-block;
        margin-right: 20px; }
        .footer .links .social-links li:last-child {
          margin-right: 0; }
      .footer .links .social-links a {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: background-color;
        transition-property: background-color;
        display: block;
        width: 40px;
        height: 40px;
        background-color: #02173e;
        font-size: 0;
        line-height: 0; }
        .footer .links .social-links a:hover {
          background-color: #1693e1; }
      .footer .links .social-links .footer-facebook {
        -webkit-mask: url(/static/media/social-facebook.1fd2c3c3.svg) no-repeat center;
        mask: url(/static/media/social-facebook.1fd2c3c3.svg) no-repeat center; }
      .footer .links .social-links .footer-twitter {
        -webkit-mask: url(/static/media/social-twitter.4a776a47.svg) no-repeat center;
        mask: url(/static/media/social-twitter.4a776a47.svg) no-repeat center; }
      .footer .links .social-links .footer-linkedin {
        -webkit-mask: url(/static/media/social-linkedin.24818759.svg) no-repeat center;
        mask: url(/static/media/social-linkedin.24818759.svg) no-repeat center; }
      .footer .links .social-links .footer-github {
        -webkit-mask: url(/static/media/social-github.ff12d275.svg) no-repeat center;
        mask: url(/static/media/social-github.ff12d275.svg) no-repeat center; }
      .footer .links .social-links .footer-telegram {
        -webkit-mask: url(/static/media/social-telegram.13428295.svg) no-repeat center;
        mask: url(/static/media/social-telegram.13428295.svg) no-repeat center; }
      .footer .links .social-links .footer-youtube {
        -webkit-mask: url(/static/media/social-youtube.d2046f67.svg) no-repeat center;
        mask: url(/static/media/social-youtube.d2046f67.svg) no-repeat center; }
  .footer .fineprint {
    display: flex;
    width: 100%;
    border-top: 1px solid #E6E7E8;
    padding-top: 30px; }
    .footer .fineprint .disclaimer {
      font-size: 12px;
      line-height: 21px;
      color: #939598;
      width: 66%; }
      .footer .fineprint .disclaimer a:not(.button) {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: color;
        transition-property: color;
        color: inherit; }
        .footer .fineprint .disclaimer a:not(.button):hover {
          color: #08152c; }
    .footer .fineprint .location {
      color: #131313;
      text-align: right;
      width: auto;
      width: 34%; }
      .footer .fineprint .location .flag-usa {
        background-image: url(/static/media/usa.3f21ce66.svg);
        background-repeat: no-repeat;
        width: 42px;
        height: 25px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px; }
        .footer .fineprint .location .flag-usa:after {
          display: none; }
      .footer .fineprint .location .country {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        line-height: 15px;
        font-weight: 700; }

@media (max-width: 1680px) {
  .landing + .footer > .inner {
    padding-left: 140px; }
  .footer > .inner {
    padding: 100px 160px 80px 100px; }
  .footer .promise .tagline {
    font-size: 27px;
    line-height: 32px;
    margin-bottom: 12px; }
  .footer .promise .colors {
    width: 360px; }
  .footer .links {
    padding-top: 50px;
    padding-bottom: 50px; }
    .footer .links .dragonchain-links li {
      font-size: 17px;
      line-height: 31px; } }

@media (max-width: 1440px) {
  .landing + .footer > .inner {
    padding-left: 100px; }
  .footer > .inner {
    padding: 90px 100px 70px 85px; } }

@media (max-width: 1250px) {
  .landing + .footer > .inner {
    padding-left: 80px; }
  .footer > .inner {
    padding: 80px 70px 60px 80px; }
  .footer .links .social-links a {
    width: 35px;
    height: 35px; } }

@media (max-width: 1100px) {
  .landing + .footer > .inner {
    padding-left: 50px; }
  .footer > .inner {
    padding: 70px 50px 50px 50px; } }

@media (max-width: 860px) {
  .footer .links {
    display: block;
    padding-bottom: 15px; }
    .footer .links .dragonchain-links {
      margin-bottom: 45px; }
      .footer .links .dragonchain-links ul {
        margin-right: 50px; }
    .footer .links .social-links {
      position: relative;
      left: -7px;
      width: auto;
      text-align: left; }
      .footer .links .social-links li {
        margin-right: 15px; }
        .footer .links .social-links li:last-child {
          margin-right: 0; }
      .footer .links .social-links a {
        width: 30px;
        height: 30px; } }

@media (max-width: 750px) {
  .footer .links .dragonchain-links {
    display: block; }
    .footer .links .dragonchain-links ul {
      display: block; }
      .footer .links .dragonchain-links ul.documents {
        margin-top: 30px; } }

@media (max-width: 570px) {
  .landing + .footer > .inner {
    padding-left: 30px; }
  .footer > .inner {
    padding: 40px 30px 80px 30px; }
  .footer .promise .jojo {
    position: relative;
    left: -5px; }
  .footer .promise .tagline {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 5px; }
  .footer .promise .copyright {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 22px; }
  .footer .promise .colors {
    width: 100%; }
  .footer .links .dragonchain-links ul {
    margin-right: 30px; }
  .footer .links .social-links {
    margin-left: -10px;
    margin-right: -10px; }
    .footer .links .social-links ul {
      display: table;
      width: 100%;
      table-layout: fixed; }
    .footer .links .social-links li {
      display: table-cell;
      margin-right: 0;
      text-align: center;
      vertical-align: middle; }
      .footer .links .social-links li a {
        margin-left: auto;
        margin-right: auto; }
  .footer .fineprint {
    display: block; }
    .footer .fineprint .disclaimer,
    .footer .fineprint .location {
      display: block;
      width: auto; }
    .footer .fineprint .disclaimer {
      margin-bottom: 30px; }
    .footer .fineprint .location {
      text-align: left; } }

.contents-full {
  position: relative; }
  .contents-full:first-child > .inner {
    padding-top: 120px; }
  .contents-full > .inner {
    padding: 80px 200px 80px 200px; }
  .contents-full.wide .description {
    padding-right: 0;
    max-width: 1125px; }
  .contents-full .title {
    font-size: 51px;
    font-weight: 700;
    line-height: 59px;
    margin-bottom: 15px; }
    .contents-full .title.large {
      font-size: 60px;
      line-height: 72px;
      margin-bottom: 35px; }
  .contents-full .black {
    color: #131313; }
  .contents-full .white {
    color: #fff; }
  .contents-full .description {
    font-size: 19px;
    line-height: 34px;
    color: #939598;
    padding-right: 100px;
    margin-bottom: 50px;
    max-width: 1000px; }
    .contents-full .description p {
      margin-bottom: 30px; }
      .contents-full .description p:last-child {
        margin-bottom: 0; }
    .contents-full .description:last-child {
      margin-bottom: 0; }
  .contents-full .actions li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    margin-bottom: 12px; }

@media (max-width: 1680px) {
  .contents-full > .inner {
    padding-left: 140px; }
  .contents-full .title {
    font-size: 47px;
    line-height: 53px; } }

@media (max-width: 1440px) {
  .contents-full > .inner {
    padding-left: 100px;
    padding-top: 80px;
    padding-bottom: 20px; }
  .contents-full:first-child > .inner {
    padding-top: 100px; }
  .contents-full .title {
    font-size: 43px;
    line-height: 49px; }
    .contents-full .title.large {
      font-size: 43px;
      line-height: 49px;
      margin-bottom: 20px; }
  .contents-full .description {
    font-size: 19px;
    line-height: 29px; } }

@media (max-width: 1250px) {
  .contents-full > .inner {
    padding-left: 80px;
    padding-top: 40px;
    padding-bottom: 10px; }
  .contents-full:first-child > .inner {
    padding-top: 70px; }
  .contents-full:last-child {
    padding-bottom: 55px; }
  .contents-full .title {
    font-size: 36px;
    line-height: 42px; }
    .contents-full .title.large {
      font-size: 41px;
      line-height: 44px; }
  .contents-full .description {
    font-size: 17px;
    line-height: 27px;
    padding-right: 0px;
    margin-bottom: 30px; } }

@media (max-width: 1100px) {
  .contents-full > .inner {
    padding-left: 50px;
    padding-right: 100px; }
    .contents-full > .inner .title {
      font-size: 31px;
      line-height: 36px; }
      .contents-full > .inner .title.large {
        font-size: 31px;
        line-height: 36px; } }

@media (max-width: 860px) {
  .contents-full > .inner {
    padding-left: 50px;
    padding-right: 50px; }
  .contents-full .title {
    font-size: 31px;
    line-height: 36px; }
    .contents-full .title.large {
      font-size: 31px;
      line-height: 36px; }
  .contents-full .description {
    font-size: 16px;
    line-height: 27px; } }

@media (max-width: 570px) {
  .contents-full {
    padding: 0; }
    .contents-full:first-child {
      padding-bottom: 0; }
      .contents-full:first-child > .inner {
        padding-top: 50px;
        padding-bottom: 50px; }
    .contents-full > .inner {
      padding: 20px 50px 20px 30px; }
    .contents-full .title {
      font-size: 29px;
      line-height: 33px;
      font-weight: 600; }
      .contents-full .title.large {
        font-size: 29px;
        line-height: 33px; }
    .contents-full .description {
      font-size: 15px;
      line-height: 13px;
      padding-right: 10px; } }

.content-block .contents-halves {
  display: flex; }
  .content-block .contents-halves:last-child .content-half {
    padding-bottom: 25px; }
  .content-block .contents-halves.reverse {
    flex-direction: row-reverse; }
    .content-block .contents-halves.reverse .content-half:nth-child(even) > .inner {
      padding-left: 200px;
      padding-right: 40px; }
    .content-block .contents-halves.reverse .content-half:nth-child(odd) > .inner {
      padding-left: 40px;
      padding-right: 200px; }
  .content-block .contents-halves .content-half {
    position: relative;
    width: 50%;
    color: #808285; }
    .content-block .contents-halves .content-half > .inner {
      padding: 80px 40px 80px 40px; }
    .content-block .contents-halves .content-half:nth-child(even) > .inner {
      padding-left: 40px;
      padding-right: 200px; }
    .content-block .contents-halves .content-half:nth-child(odd) > .inner {
      padding-left: 200px;
      padding-right: 40px; }
    .content-block .contents-halves .content-half .photo-content {
      position: relative;
      top: -30px; }
    .content-block .contents-halves .content-half .label + .title {
      position: relative;
      margin-top: -4px; }
    .content-block .contents-halves .content-half .title {
      font-size: 33px;
      line-height: 46px;
      font-weight: 600;
      margin-bottom: 0;
      color: #131313; }
    .content-block .contents-halves .content-half .description {
      font-size: 19px;
      line-height: 29px;
      margin-bottom: 30px; }
      .content-block .contents-halves .content-half .description:last-child {
        margin-bottom: 0; }
      .content-block .contents-halves .content-half .description + .label {
        margin-top: 55px; }

@media (max-width: 1680px) {
  .content-block .contents-halves.reverse .content-half:nth-child(even) > .inner {
    padding-left: 140px; }
  .content-block .contents-halves.reverse .content-half:nth-child(odd) > .inner {
    padding-right: 140px; }
  .content-block .contents-halves:not(.reverse) .content-half:nth-child(even) > .inner {
    padding-right: 140px; }
  .content-block .contents-halves:not(.reverse) .content-half:nth-child(odd) > .inner {
    padding-left: 140px; }
  .content-block .contents-halves .content-half .label + .title {
    margin-top: 0; } }

@media (max-width: 1440px) {
  .content-block .contents-halves:last-child .content-half {
    padding-bottom: 60px; }
  .content-block .contents-halves.reverse .content-half:nth-child(even) > .inner {
    padding-left: 100px; }
  .content-block .contents-halves.reverse .content-half:nth-child(odd) > .inner {
    padding-right: 100px; }
  .content-block .contents-halves:not(.reverse) .content-half:nth-child(even) > .inner {
    padding-right: 100px; }
  .content-block .contents-halves:not(.reverse) .content-half:nth-child(odd) > .inner {
    padding-left: 100px; }
  .content-block .contents-halves .content-half > .inner {
    padding-top: 60px;
    padding-bottom: 60px; }
  .content-block .contents-halves .content-half .title {
    font-size: 29px;
    line-height: 35px;
    margin-bottom: 10px; }
  .content-block .contents-halves .content-half .description {
    font-size: 17px;
    line-height: 29px; }
  .content-block .contents-halves .content-half .photo-content {
    top: -10px; } }

@media (max-width: 1250px) {
  .content-block .contents-halves.reverse .content-half:nth-child(even) > .inner {
    padding-left: 80px; }
  .content-block .contents-halves.reverse .content-half:nth-child(odd) > .inner {
    padding-right: 80px; }
  .content-block .contents-halves:not(.reverse) .content-half:nth-child(even) > .inner {
    padding-right: 80px; }
  .content-block .contents-halves:not(.reverse) .content-half:nth-child(odd) > .inner {
    padding-left: 80px; }
  .content-block .contents-halves .content-half > .inner {
    padding-top: 40px;
    padding-bottom: 40px; }
  .content-block .contents-halves .content-half .title {
    font-size: 25px;
    line-height: 29px; }
  .content-block .contents-halves .content-half .description {
    font-size: 17px;
    line-height: 27px; } }

@media (max-width: 860px) {
  .content-block .contents-halves.reverse .content-half:nth-child(even) > .inner {
    padding-left: 50px; }
  .content-block .contents-halves.reverse .content-half:nth-child(odd) > .inner {
    padding-right: 50px; }
  .content-block .contents-halves:not(.reverse) .content-half:nth-child(even) > .inner {
    padding-right: 50px; }
  .content-block .contents-halves:not(.reverse) .content-half:nth-child(odd) > .inner {
    padding-left: 50px; }
  .content-block .contents-halves .content-half .description {
    font-size: 16px;
    line-height: 27px; }
    .content-block .contents-halves .content-half .description + .label {
      margin-top: 35px; } }

@media (max-width: 570px) {
  .content-block .contents-halves {
    display: block; }
    .content-block .contents-halves:last-child .content-half {
      padding-bottom: 0; }
    .content-block .contents-halves.reverse .content-half:nth-child(even) > .inner {
      padding-left: 30px;
      padding-right: 30px; }
    .content-block .contents-halves.reverse .content-half:nth-child(odd) > .inner {
      padding-left: 30px;
      padding-right: 30px; }
    .content-block .contents-halves:not(.reverse) .content-half:nth-child(even) > .inner {
      padding-left: 30px;
      padding-right: 30px; }
    .content-block .contents-halves:not(.reverse) .content-half:nth-child(odd) > .inner {
      padding-left: 30px;
      padding-right: 30px; }
    .content-block .contents-halves .content-half {
      width: auto; }
      .content-block .contents-halves .content-half .photo-content {
        margin-bottom: -30px; } }

header.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #131313; }
  header.header .logo {
    position: absolute;
    z-index: 1;
    font-weight: 700;
    width: 220px;
    height: 40px;
    left: 139px;
    top: 57px; }
  header.header.dark .logo-text-color {
    fill: #fff !important; }
  header.header.dark a {
    color: #fff; }
  header.header.light .logo-text-color {
    fill: #231F20 !important; }
  header.header.light a:not(.button) {
    color: #231F20; }

@media (max-width: 1680px) {
  header.header .logo {
    left: 100px;
    top: 46px; } }

@media (max-width: 1440px) {
  header.header .logo {
    left: 85px;
    top: 38px; } }

@media (max-width: 1250px) {
  header.header .logo {
    left: 70px;
    top: 38px;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0; } }

@media (max-width: 1100px) {
  header.header .logo {
    left: 50px;
    top: 33px; } }

@media (max-width: 860px) {
  header.header .logo {
    top: 35px;
    -webkit-transform: scale(0.7);
            transform: scale(0.7); } }

@media (max-width: 570px) {
  header.header .logo {
    margin-top: 23px;
    position: relative;
    top: auto;
    left: auto;
    margin-left: 30px;
    margin-bottom: 20px; } }

.full-background .hero {
  background-image: none !important;
  background-color: transparent !important; }
  .full-background .hero.is-header {
    padding-bottom: 40px; }

.hero {
  background-size: cover;
  background-position: center;
  background-color: #051738; }
  .hero > .inner {
    position: relative;
    padding: 280px 160px 240px 200px;
    display: flex; }
  .hero.is-header > .inner {
    max-width: 1140px;
    margin-left: 200px;
    margin-right: auto;
    padding-top: 120px;
    padding-bottom: 60px;
    padding-left: 0;
    padding-right: 180px; }
  .hero.is-header .content > .inner {
    padding-left: 0; }
  .hero.half .content > .inner {
    width: 100%; }
  .hero .content {
    flex-grow: 1; }
    .hero .content > .inner {
      padding-top: 90px;
      padding-left: 40px; }
    .hero .content .title {
      display: inline-block;
      vertical-align: baseline;
      color: #fff;
      font-size: 60px;
      line-height: 70px;
      max-width: 860px;
      position: relative;
      left: -2px;
      margin-right: 10px;
      margin-bottom: 20px; }
      .hero .content .title.medium {
        font-size: 85px;
        line-height: 92px;
        max-width: 100%; }
      .hero .content .title.large {
        font-size: 106px;
        line-height: 115px; }
    .hero .content .description {
      font-size: 21px;
      line-height: 35px;
      font-weight: 400;
      color: #fff;
      padding-right: 50px;
      margin-bottom: 50px;
      max-width: 670px; }
    .hero .content .actions li {
      list-style: none;
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 13px; }
      .hero .content .actions li:last-child {
        margin-right: 0; }

@supports (-ms-ime-align: auto) {
  .hero .content .title {
    -webkit-text-fill-color: currentcolor !important;
    -webkit-background-clip: border-box !important;
    background-image: none !important; } }

body.ie .hero .content .title {
  -webkit-text-fill-color: currentcolor !important;
  -webkit-background-clip: border-box !important;
  background-image: none !important; }

@media (max-width: 1680px) {
  .hero > .inner {
    padding-top: 220px; }
  .hero.is-header > .inner {
    margin-left: 140px; }
  .hero .content > .inner {
    padding-top: 80px; }
  .hero .content .title {
    font-size: 50px;
    line-height: 60px; }
    .hero .content .title.medium {
      font-size: 75px;
      line-height: 80px; }
  .hero .content .description {
    padding-right: 50px;
    margin-bottom: 40px;
    font-style: 20px;
    line-height: 31px; } }

@media (max-width: 1440px) {
  .hero > .inner {
    padding-top: 200px;
    padding-left: 85px;
    padding-bottom: 160px; }
  .hero.is-header > .inner {
    margin-left: 85px;
    padding-right: 240px; }
  .hero .content > .inner {
    padding-top: 70px; }
  .hero .content .title.medium {
    font-size: 65px;
    line-height: 70px;
    margin-bottom: 10px; }
  .hero .content .description {
    margin-bottom: 30px;
    max-width: 540px; }
    .hero .content .description:last-child {
      margin-bottom: 0; } }

@media (max-width: 1250px) {
  .hero > .inner {
    padding-left: 70px; }
  .hero.is-header > .inner {
    margin-left: 70px; }
  .hero.is-header .content > .inner {
    padding-left: 0; }
  .hero .content > .inner {
    padding-top: 45px; }
  .hero .content .title {
    font-size: 45px;
    line-height: 50px; }
    .hero .content .title.medium {
      font-size: 60px;
      line-height: 65px; }
    .hero .content .title.large {
      font-size: 90px;
      line-height: 90px; }
  .hero .content .description {
    padding-right: 0; } }

@media (max-width: 1100px) {
  .full-background .hero.is-header > .inner {
    padding-bottom: 25px; }
  .hero > .inner {
    padding-left: 50px;
    padding-right: 120px; }
  .hero.is-header > .inner {
    margin-left: 50px; }
  .hero.is-header .content .description {
    max-width: 100%; }
  .hero .content > .inner {
    padding-top: 20px; }
  .hero .content .title {
    font-size: 37px;
    line-height: 45px; }
    .hero .content .title.medium {
      font-size: 52px;
      line-height: 60px; }
    .hero .content .title.large {
      font-size: 80px;
      line-height: 80px; }
  .hero .content .description {
    font-size: 19px;
    line-height: 29px;
    padding-right: 0; } }

@media (max-width: 860px) {
  .hero > .inner {
    padding-top: 180px;
    padding-bottom: 130px; }
  .hero .content > .inner {
    padding-top: 20px;
    padding-left: 20px; }
  .hero .content .title {
    font-size: 33px;
    line-height: 39px;
    max-width: 400px; }
    .hero .content .title.medium {
      font-size: 49px;
      line-height: 55px;
      max-width: 400px; }
    .hero .content .title.large {
      font-size: 70px;
      line-height: 70px;
      margin-bottom: 20px; } }

@media (max-width: 750px) {
  .hero > .inner {
    padding-right: 60px; }
  .hero.is-header > .inner {
    padding-right: 160px; }
  .hero .content .title.medium {
    font-size: 42px;
    line-height: 49px; }
  .hero .content .title.large {
    font-size: 60px;
    line-height: 66px; } }

@media (max-width: 570px) {
  .hero {
    background-position: center center; }
    .hero > .inner {
      padding-left: 30px;
      padding-top: 110px;
      padding-right: 70px;
      padding-bottom: 80px;
      display: block; }
    .hero.is-header {
      background-position: 70% center; }
      .hero.is-header > .inner {
        margin-left: 30px;
        padding-top: 100px;
        padding-right: 80px;
        padding-bottom: 30px; }
    .hero.half .content > .inner {
      width: 100%; }
    .hero .content {
      display: block; }
      .hero .content > .inner {
        padding-left: 0; }
      .hero .content .title {
        font-size: 27px;
        line-height: 33px; }
        .hero .content .title.medium {
          font-size: 39px;
          line-height: 47px; }
        .hero .content .title.large {
          font-size: 60px;
          line-height: 66px;
          padding-right: 0;
          margin-bottom: 15px; }
      .hero .content .description {
        font-size: 16px;
        line-height: 27px;
        padding-right: 15px;
        padding-right: 5px; } }

.hero-jojo {
  background-size: cover; }
  .hero-jojo > .inner {
    position: relative;
    padding: 240px 160px 200px 140px;
    display: flex; }
  .hero-jojo .jojo {
    position: absolute;
    left: 150px;
    width: 400px;
    padding-top: 30px; }
    .hero-jojo .jojo > .inner {
      position: relative;
      left: -30px;
      display: block;
      background-image: url(/static/media/jojo-treasure.774ae8fa.png);
      background-size: 100% auto;
      width: 400px;
      height: 400px; }
  .hero-jojo .content {
    flex-grow: 1;
    max-width: 780px;
    margin-left: 440px; }
    .hero-jojo .content > .inner {
      padding-top: 90px;
      padding-left: 40px; }
    .hero-jojo .content .title {
      display: inline-block;
      vertical-align: baseline;
      color: #02173e;
      font-size: 70px;
      line-height: 74px;
      position: relative;
      left: -2px;
      margin-right: 10px;
      margin-bottom: 20px; }
      .hero-jojo .content .title.medium {
        font-size: 75px;
        line-height: 79px;
        max-width: 620px; }
      .hero-jojo .content .title.large {
        font-size: 106px;
        line-height: 115px; }
      .hero-jojo .content .title.small {
        font-size: 65px;
        line-height: 69px; }
    .hero-jojo .content .description {
      font-size: 20px;
      line-height: 31px;
      color: #939598;
      padding-right: 100px;
      margin-bottom: 50px; }
    .hero-jojo .content .actions li {
      list-style: none;
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 13px; }
      .hero-jojo .content .actions li:last-child {
        margin-right: 0; }

@supports (-ms-ime-align: auto) {
  .hero-jojo .content .title {
    -webkit-text-fill-color: currentcolor !important;
    -webkit-background-clip: border-box !important;
    background-image: none !important; } }

body.ie .hero-jojo .content .title {
  -webkit-text-fill-color: currentcolor !important;
  -webkit-background-clip: border-box !important;
  background-image: none !important; }

@media (max-width: 1680px) {
  .hero-jojo > .inner {
    padding-left: 100px; }
  .hero-jojo .jojo {
    width: 380px; }
    .hero-jojo .jojo > .inner {
      width: 380px;
      height: 380px; }
  .hero-jojo .content > .inner {
    padding-top: 80px; }
  .hero-jojo .content .title {
    font-size: 59px;
    line-height: 65px; }
    .hero-jojo .content .title.medium {
      font-size: 59px;
      line-height: 65px; }
    .hero-jojo .content .title.small {
      font-size: 54px;
      line-height: 59px;
      margin-right: 25px; }
  .hero-jojo .content .description {
    padding-right: 50px;
    margin-bottom: 40px; } }

@media (max-width: 1440px) {
  .hero-jojo > .inner {
    padding-top: 200px;
    padding-left: 85px;
    padding-bottom: 160px; }
  .hero-jojo .jojo {
    width: 350px;
    padding-top: 0;
    left: 100px; }
    .hero-jojo .jojo > .inner {
      width: 350px;
      height: 350px; }
  .hero-jojo .content {
    margin-left: 350px; }
    .hero-jojo .content > .inner {
      padding-top: 70px; }
    .hero-jojo .content .title {
      font-size: 49px;
      line-height: 53px;
      margin-bottom: 10px; }
      .hero-jojo .content .title.medium {
        font-size: 65px;
        line-height: 70px;
        margin-bottom: 10px; }
      .hero-jojo .content .title.small {
        font-size: 49px;
        line-height: 53px; }
    .hero-jojo .content .description {
      padding-right: 125px;
      font-size: 19px;
      line-height: 29px;
      margin-bottom: 30px; }
      .hero-jojo .content .description:last-child {
        margin-bottom: 0; } }

@media (max-width: 1250px) {
  .hero-jojo > .inner {
    padding-left: 70px; }
  .hero-jojo .jojo {
    position: relative;
    left: auto;
    width: 330px; }
    .hero-jojo .jojo > .inner {
      width: 330px;
      height: 330px; }
  .hero-jojo .content {
    margin-left: 0; }
    .hero-jojo .content > .inner {
      padding-top: 45px; }
    .hero-jojo .content .title {
      font-size: 45px;
      line-height: 51px; }
      .hero-jojo .content .title.medium {
        font-size: 60px;
        line-height: 65px; }
      .hero-jojo .content .title.large {
        font-size: 90px;
        line-height: 90px; }
      .hero-jojo .content .title.small {
        font-size: 39px;
        line-height: 45px; }
    .hero-jojo .content .description {
      padding-right: 50px;
      font-size: 17px;
      line-height: 27px; } }

@media (max-width: 1100px) {
  .hero-jojo > .inner {
    padding-left: 50px;
    padding-right: 120px; }
  .hero-jojo .jojo {
    width: 250px;
    padding-top: 0; }
    .hero-jojo .jojo > .inner {
      left: -20px;
      width: 250px;
      height: 250px; }
  .hero-jojo .content > .inner {
    padding-top: 20px; }
  .hero-jojo .content .title {
    font-size: 39px;
    line-height: 45px; }
    .hero-jojo .content .title.medium {
      font-size: 52px;
      line-height: 60px; }
    .hero-jojo .content .title.large {
      font-size: 80px;
      line-height: 80px; }
    .hero-jojo .content .title.small {
      margin-right: 0; } }

@media (max-width: 860px) {
  .hero-jojo > .inner {
    padding-top: 180px;
    padding-bottom: 130px; }
  .hero-jojo .jojo {
    width: 200px;
    padding-top: 0; }
    .hero-jojo .jojo > .inner {
      left: -15px;
      width: 200px;
      height: 200px; }
  .hero-jojo .content > .inner {
    padding-top: 20px;
    padding-left: 20px; }
  .hero-jojo .content .title {
    font-size: 31px;
    line-height: 36px; }
    .hero-jojo .content .title.medium {
      font-size: 49px;
      line-height: 55px;
      max-width: 400px; }
    .hero-jojo .content .title.large {
      font-size: 70px;
      line-height: 70px;
      margin-bottom: 20px; }
    .hero-jojo .content .title.small {
      font-size: 31px;
      line-height: 36px; }
  .hero-jojo .content .description {
    font-size: 18px;
    line-height: 27px;
    padding-right: 25px; } }

@media (max-width: 570px) {
  .hero-jojo > .inner {
    padding-left: 30px;
    padding-top: 110px;
    padding-right: 40px;
    padding-bottom: 80px;
    display: block; }
  .hero-jojo .jojo {
    display: block;
    width: 240px;
    margin-left: -15px; }
    .hero-jojo .jojo > .inner {
      left: -10px;
      width: 240px;
      height: 240px; }
  .hero-jojo .content {
    display: block; }
    .hero-jojo .content > .inner {
      padding-left: 0; }
    .hero-jojo .content .title {
      font-size: 41px;
      line-height: 41px;
      padding-right: 0;
      margin-bottom: 15px;
      margin-right: 0; }
      .hero-jojo .content .title.medium {
        font-size: 42px;
        line-height: 49px; }
      .hero-jojo .content .title.large {
        font-size: 60px;
        line-height: 66px; }
      .hero-jojo .content .title.small {
        font-size: 30px;
        line-height: 39px;
        font-weight: 600; }
    .hero-jojo .content .description {
      font-size: 16px;
      line-height: 27px;
      padding-right: 0;
      margin-bottom: 25px; } }

.ext_icon_base, .icon_check, .button.has_icon_check:before, .submission button.has_icon_check:before, .icon_check_small, .icon_arrow, .icon_caution, .icon_ethereum, .icon_time, .icon_retry {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center; }

.nav_icon {
  position: relative;
  top: -2px;
  vertical-align: middle;
  margin-right: 3px; }
  .nav_icon.icon_ethereum {
    top: -3px; }

.button_icon {
  position: absolute;
  left: 10px;
  top: 6px;
  display: inline-block;
  width: 18px;
  height: 18px; }

.icon_circle {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #1693e1;
  margin-left: 7px; }
  .icon_circle.blue {
    background-color: #1693e1; }
  .icon_circle.white {
    background-color: #fff; }
  .icon_circle.outline {
    background-color: transparent;
    border: 2px solid #ddd; }
  .icon_circle div {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.icon_check, .button.has_icon_check:before, .submission button.has_icon_check:before {
  width: 18px;
  height: 18px;
  background-image: url(/static/media/check.dcb79404.svg); }
  .icon_check.white, .white.button.has_icon_check:before, .submission button.white.has_icon_check:before {
    background-image: url(/static/media/check-white.3e436986.svg); }
  .icon_check.blue, .blue.button.has_icon_check:before, .submission button.blue.has_icon_check:before {
    background-image: url(/static/media/check-blue.e7f421bc.svg); }

.icon_check_small {
  width: 10px;
  height: 10px;
  background-image: url(/static/media/check.dcb79404.svg); }
  .icon_check_small.white {
    background-image: url(/static/media/check-white.3e436986.svg); }
  .icon_check_small.blue {
    background-image: url(/static/media/check-blue.e7f421bc.svg); }

.icon_arrow {
  width: 20px;
  height: 20px;
  background-image: url(/static/media/arrow.0854f63f.svg); }
  .icon_arrow.white {
    background-image: url(/static/media/arrow-white.7b267585.svg); }

.icon_caution {
  width: 20px;
  height: 20px;
  background-image: url(/static/media/caution.b0cce4f7.svg); }
  .icon_caution.white {
    background-image: url(/static/media/caution-white.5b6d5806.svg); }
  .icon_caution.yellow {
    background-image: url(/static/media/caution-yellow.b02d244b.svg); }
  .icon_caution.blue {
    background-image: url(/static/media/caution-blue.b9a310b6.svg); }

.icon_ethereum {
  width: 20px;
  height: 20px;
  background-image: url(/static/media/ethereum.06703f5f.svg); }
  .icon_ethereum.white {
    background-image: url(/static/media/ethereum-white.eb342761.svg); }

.icon_time {
  width: 20px;
  height: 20px;
  background-image: url(/static/media/time.c0de62c7.svg); }
  .icon_time.white {
    background-image: url(/static/media/time-white.27d2508b.svg); }
  .icon_time.blue_dark {
    background-image: url(/static/media/time-blue-dark.5003855f.svg); }

.icon_retry {
  width: 20px;
  height: 20px;
  background-image: url(/static/media/retry.5150feca.svg); }
  .icon_retry.white {
    background-image: url(/static/media/retry-white.fd6b27ac.svg); }
  .icon_retry.yellow {
    background-image: url(/static/media/retry-yellow.86d4cff9.svg); }

.icon_practitioner {
  background-image: url(/static/media/course-practitioner.03ad9520.svg);
  width: 60px;
  height: 60px;
  margin-bottom: 15px; }

.photo {
  box-shadow: 20px 20px 0 0 rgba(0, 0, 0, 0.06);
  font-size: 0;
  line-height: 0; }
  .photo img {
    width: 100%;
    height: auto; }
  .photo .dark {
    box-shadow: 20px 20px 0 0 rgba(0, 0, 0, 0.2); }
  .photo .light {
    box-shadow: 20px 20px 0 0 rgba(0, 0, 0, 0.06); }

@media (max-width: 1100px) {
  .photo {
    margin-right: 0; } }

.label {
  cursor: default;
  display: block;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  text-transform: uppercase; }
  .label.white > .inner {
    background-color: #fff;
    color: #1693e1; }
  .label > .inner {
    display: inline-block;
    background-color: #1693e1;
    color: #fff;
    border-radius: 3px;
    padding: 5px 6px 3px 7px; }
    .label > .inner.grey {
      background-color: #939598; }
  .label .children {
    margin-right: 10px; }

@media (max-width: 750px) {
  .label {
    font-size: 9px; } }

.leaderboard .tbody .tr .td.badges {
  padding-top: 15px;
  padding-bottom: 15px; }
  .leaderboard .tbody .tr .td.badges img {
    height: 32px;
    padding-right: 11px; }
  .leaderboard .tbody .tr .td.badges :last-child {
    padding-right: 0px; }

.leaderboard .legend {
  padding: 45px 0px 20px 25px;
  display: flex;
  flex-wrap: wrap; }
  .leaderboard .legend .content {
    display: flex;
    padding-right: 40px;
    align-items: center; }
    .leaderboard .legend .content :last-child {
      padding-right: 0px; }
    .leaderboard .legend .content .title {
      flex-shrink: 0; }
    .leaderboard .legend .content img {
      height: 32px;
      padding-right: 11px; }

.leaderboard a {
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  display: inline-block;
  background-color: #1693e1;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  padding: 0px 10px;
  line-height: 20px;
  font-weight: 500; }
  .leaderboard a:hover {
    background-color: #08152c; }

@media (max-width: 860px) {
  .legend {
    padding-left: 5px;
    padding-top: 20px; }
    .legend .content {
      width: 100%;
      padding-bottom: 18px; }
    .legend :last-child {
      padding-bottom: 0px; } }

.listItem .title {
  position: relative;
  margin-top: -4px; }

.listItem .title {
  font-size: 33px;
  line-height: 46px;
  font-weight: 600;
  margin-bottom: 0;
  color: #131313; }

.listItem .description {
  font-size: 19px;
  line-height: 29px;
  margin-bottom: 30px; }

.listItem:first-child {
  margin-top: 30px; }

.listItem:last-child {
  margin-bottom: 0; }

.listItem + .listItem {
  margin-top: 55px; }

@media (max-width: 1440px) {
  .listItem .title {
    font-size: 29px;
    line-height: 35px;
    margin-bottom: 10px; }
  .listItem .description {
    font-size: 17px;
    line-height: 29px; }
  .listItem:first-child {
    margin-top: 0px; }
  .listItem:last-child {
    margin-bottom: 0; }
  .listItem + .listItem {
    margin-top: 55px; } }

@media (max-width: 1250px) {
  .listItem .title {
    font-size: 25px;
    line-height: 29px; }
  .listItem .description {
    font-size: 17px;
    line-height: 27px; }
  :first-child {
    margin-top: 0px; }
  :last-child {
    margin-bottom: 0; }
  + .listItem {
    margin-top: 55px; } }

@media (max-width: 860px) {
  .listItem {
    display: block; }
    .listItem:first-child {
      margin-top: 10px; }
    .listItem:last-child {
      margin-bottom: 0; }
    .listItem + .listItem {
      margin-top: 40px; } }

@media (max-width: 570px) {
  .listItem {
    display: block; }
    .listItem:first-child {
      margin-top: -20px; }
    .listItem:last-child {
      margin-bottom: 0; }
    .listItem + .listItem {
      margin-top: 40px; } }

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 990; }
  .modal .modal_overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 995;
    background: rgba(1, 18, 45, 0.5); }
  .modal .modal_box {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-height: 90%;
    overflow-y: auto;
    background: #fff;
    box-sizing: border-box;
    padding: 30px;
    box-shadow: 0 1px 25px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    max-width: 520px; }
  .modal .modal_header {
    margin-top: -10px;
    margin-bottom: 20px; }
    .modal .modal_header .modal_name {
      font-size: 21px;
      line-height: 25px;
      color: #1693e1;
      font-weight: 600; }
  .modal .modal_content {
    font-size: 15px;
    line-height: 25px;
    color: #6D6E71; }
    .modal .modal_content .modal_title {
      font-size: 21px;
      color: #131313;
      font-weight: 600;
      margin-bottom: 15px; }
    .modal .modal_content > p {
      margin-bottom: 23px; }
      .modal .modal_content > p:last-child {
        margin-bottom: 0; }
  .modal .modal_close {
    position: absolute;
    right: 3px;
    top: 21px;
    cursor: pointer;
    font-size: 0 !important;
    line-height: 17px !important;
    background: none;
    border: none;
    padding: 0 !important;
    width: 40px;
    height: 40px;
    border-radius: 0; }
    .modal .modal_close:hover:before, .modal .modal_close:hover:after {
      background-color: #1693e1; }
    .modal .modal_close:before {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: all;
      transition-property: all;
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      position: relative;
      top: 9.5px;
      content: '';
      display: block;
      width: 21px;
      height: 2px;
      background-color: #939598;
      border-radius: 2px; }
    .modal .modal_close:after {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: all;
      transition-property: all;
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      position: relative;
      top: -9.5px;
      content: '';
      display: block;
      width: 21px;
      height: 2px;
      background-color: #939598;
      border-radius: 2px; }
    .modal .modal_close:hover {
      opacity: 0.9; }

@media (max-width: 860px) {
  .modal .modal_box {
    top: 50px;
    left: 50px;
    right: 50px;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@media (max-width: 570px) {
  .modal .modal_box {
    top: 20px;
    left: 20px;
    right: 20px;
    -webkit-transform: translate(0);
            transform: translate(0); } }

.nav {
  position: absolute;
  z-index: 1;
  top: 54px;
  right: 200px;
  font-size: 13px;
  line-height: 19px;
  font-weight: 400; }
  .nav > ul > li {
    position: relative;
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
    padding: 15px 20px; }
    .nav > ul > li.has-button {
      padding: 7px 0;
      margin-left: 20px;
      margin-right: 0; }
    .nav > ul > li > a:not(.button) {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: all;
      transition-property: all;
      box-shadow: rgba(0, 0, 0, 0) 0px -2px 0px 0px inset;
      color: inherit;
      text-decoration: none;
      padding: 4px 2px;
      font-size: 14px;
      line-height: 19px;
      color: #939598;
      font-weight: 500;
      text-decoration: none;
      padding: 3px 1px; }
      .nav > ul > li > a:not(.button):hover {
        box-shadow: #1693e1 0px -2px 0px 0px inset; }
      .nav > ul > li > a:not(.button):hover {
        color: #131313; }
    .nav > ul > li > a.button {
      font-size: 13px;
      line-height: 15px;
      padding: 8px 14px; }
  .nav .nav-icon {
    position: relative;
    top: -2px;
    vertical-align: middle;
    margin-right: 3px; }
    .nav .nav-icon.icon-ethereum {
      top: -3px; }

.header.light .nav a:not(.button) {
  color: #08152c; }
  .header.light .nav a:not(.button):hover {
    color: #08152c; }

.header.light .nav a.button.outline_blue_light {
  color: #02173e; }

.header.light .nav a.button:hover.hover_blue_on_white {
  background-color: #1693e1;
  border-color: #1693e1;
  color: #fff; }

.header.light .nav a.button:hover.hover_red_on_white {
  background-color: #dc2800;
  border-color: #dc2800;
  color: #fff; }

.header.dark .nav a:not(.button) {
  color: #fff; }
  .header.dark .nav a:not(.button):hover {
    color: #fff; }

@media (max-width: 1680px) {
  .nav {
    right: 160px;
    top: 42px; } }

@media (max-width: 1440px) {
  .nav {
    top: 37px;
    right: 140px; } }

@media (max-width: 1250px) {
  .nav {
    top: 31px;
    right: 120px; }
    .nav > ul > li {
      padding-left: 15px;
      padding-right: 15px; }
      .nav > ul > li.has-button {
        margin-left: 10px; }
      .nav > ul > li > a:not(.button) {
        font-size: 14px; }
      .nav > ul > li > a.button {
        font-size: 13px;
        padding-left: 12px;
        padding-right: 12px; } }

@media (max-width: 1100px) {
  .nav {
    top: 25px;
    right: 90px; }
    .nav > ul > li {
      padding-left: 10px;
      padding-right: 10px; }
      .nav > ul > li > a:not(.button) {
        font-size: 13px; } }

@media (max-width: 860px) {
  .nav {
    top: 25px; }
    .nav > ul > li {
      padding-left: 8px;
      padding-right: 8px; }
      .nav > ul > li.has-button {
        margin-left: 6px; }
      .nav > ul > li > a:not(.button) {
        font-size: 12px; }
      .nav > ul > li > a.button {
        font-size: 11px;
        padding: 6px 10px; } }

@media (max-width: 750px) {
  .nav {
    display: none; } }

.page {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  color: #6D6E71; }
  .page > .inner {
    padding: 35px 20px 100px 20px; }
  .page hr {
    margin-left: auto;
    margin-right: auto;
    height: 5px;
    border: none;
    background-color: #F1F1F1;
    margin-top: 60px;
    margin-bottom: 70px; }

@media (max-width: 1440px) {
  .page {
    margin-left: 90px;
    margin-right: 140px; }
    .page > .inner {
      padding-left: 0;
      padding-right: 0; } }

@media (max-width: 1250px) {
  .page {
    margin-left: 70px; } }

@media (max-width: 1100px) {
  .page {
    margin-left: 50px;
    margin-right: 50px; } }

@media (max-width: 570px) {
  .page {
    margin-left: 30px;
    margin-right: 30px; } }

.person .person-name {
  font-size: 27px;
  line-height: 33px;
  font-weight: 600;
  margin-top: 54px;
  margin-bottom: 3px;
  color: #131313; }

.person .person-job-title {
  font-size: 19px;
  line-height: 25px;
  font-weight: 500;
  margin-bottom: 25px; }

.person .person-description {
  font-size: 17px;
  line-height: 27px;
  padding-right: 10px; }

.person.dark {
  color: #A7A9AC; }
  .person.dark .person-name {
    color: #fff; }
  .person.dark .person-job-title {
    color: #1693e1; }

@media (max-width: 1100px) {
  .person .person-name {
    font-size: 25px;
    line-height: 29px; }
  .person .person-job-title {
    font-size: 18px; }
  .person .person-description {
    padding-right: 0;
    font-size: 16px;
    line-height: 27px; } }

@media (max-width: 860px) {
  .person .person-name {
    font-size: 21px;
    line-height: 25px; }
  .person .person-job-title {
    font-size: 17px; } }

.pricing {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px; }
  .pricing .pricing-header {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #3fccfe;
    font-size: 15px;
    margin-bottom: 40px; }
    .pricing .pricing-header .title {
      font-size: 26px;
      line-height: 33px;
      font-weight: 600;
      color: #fff; }
    .pricing .pricing-header p {
      color: #fff;
      font-size: 15px; }
      .pricing .pricing-header p a {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: all;
        transition-property: all;
        box-shadow: #2eb6ef 0px -2px 0px 0px inset;
        color: inherit;
        text-decoration: none;
        cursor: pointer; }
        .pricing .pricing-header p a:hover {
          box-shadow: #9fdbf9 0px -2px 0px 0px inset; }
  .pricing .pricing-layout {
    font-size: 14px;
    line-height: 19px;
    color: #3c4a5b; }
    .pricing .pricing-layout > .inner {
      background-color: #fff;
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.35);
      border-radius: 3px; }
    .pricing .pricing-layout .pricing-table {
      display: table;
      table-layout: fixed;
      width: 100%; }
      .pricing .pricing-layout .pricing-table.phone-only {
        display: none; }
      .pricing .pricing-layout .pricing-table .row.is-monthly {
        display: table-row; }
      .pricing .pricing-layout .pricing-table .row.is-annual {
        display: none; }
      .pricing .pricing-layout .pricing-table.js-annual .row.is-monthly {
        display: none; }
      .pricing .pricing-layout .pricing-table.js-annual .row.is-annual {
        display: table-row; }
    .pricing .pricing-layout .row {
      display: table-row; }
      .pricing .pricing-layout .row:first-child .column {
        padding-top: 40px; }
      .pricing .pricing-layout .row:last-child .column {
        padding-bottom: 50px; }
      .pricing .pricing-layout .row.has-highlight + .has-feature .column {
        padding-top: 40px; }
    .pricing .pricing-layout .column {
      display: table-cell;
      color: #3c4a5b;
      border-right: 1px solid #F1F2F2; }
      .pricing .pricing-layout .column:last-child {
        border-right: none; }
      .pricing .pricing-layout .column.center {
        text-align: center; }
      .pricing .pricing-layout .column > .inner,
      .pricing .pricing-layout .column .plan,
      .pricing .pricing-layout .column .price,
      .pricing .pricing-layout .column .highlight,
      .pricing .pricing-layout .column .feature,
      .pricing .pricing-layout .column .action {
        max-width: 280px;
        margin-left: auto;
        margin-right: auto; }
      .pricing .pricing-layout .column .icon {
        position: relative;
        display: block;
        width: 50px;
        height: 50px;
        margin-left: auto;
        margin-right: auto;
        font-size: 0;
        line-height: 0;
        margin-bottom: 20px; }
        .pricing .pricing-layout .column .icon svg {
          fill: #8494a5; }
      .pricing .pricing-layout .column .plan,
      .pricing .pricing-layout .column .price,
      .pricing .pricing-layout .column .highlight,
      .pricing .pricing-layout .column .action {
        text-align: center; }
      .pricing .pricing-layout .column .title {
        font-style: 16px;
        line-height: 21px;
        font-weight: 500;
        margin-bottom: 20px;
        text-transform: capitalize; }
        .pricing .pricing-layout .column .title a {
          -webkit-transition-duration: 0.2s;
                  transition-duration: 0.2s;
          -webkit-transition-property: background border color;
          transition-property: background border color;
          display: block;
          max-width: 140px;
          margin-left: auto;
          margin-right: auto;
          color: #08152c;
          text-decoration: none;
          padding: 7px 8px 6px 8px;
          border: 1px solid #D1D3D4;
          border-radius: 20px; }
          .pricing .pricing-layout .column .title a:hover {
            background-color: #08152c;
            border-color: #08152c;
            color: #fff; }
      .pricing .pricing-layout .column .description {
        font-size: 13px;
        line-height: 21px;
        color: #8494a5;
        margin-bottom: 40px; }
      .pricing .pricing-layout .column .price {
        margin-bottom: 25px; }
        .pricing .pricing-layout .column .price .amount {
          font-size: 29px;
          line-height: 35px;
          font-weight: 600;
          color: #0082D1; }
        .pricing .pricing-layout .column .price .period {
          color: #8494a5;
          font-size: 13px;
          line-height: 19px; }
      .pricing .pricing-layout .column .highlight {
        margin-bottom: 25px; }
        .pricing .pricing-layout .column .highlight .name {
          font-size: 19px;
          line-height: 25px;
          color: #08152c;
          font-weight: 600;
          margin-bottom: 4px; }
      .pricing .pricing-layout .column .feature {
        cursor: default;
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: background;
        transition-property: background;
        display: flex;
        padding: 10px;
        background-color: transparent;
        border-radius: 3px; }
        .pricing .pricing-layout .column .feature:hover {
          background-color: #f9f9f9; }
        .pricing .pricing-layout .column .feature .name {
          position: relative;
          top: 2px;
          margin-right: 20px; }
          .pricing .pricing-layout .column .feature .name.no {
            color: #bcbec0; }
        .pricing .pricing-layout .column .feature .icon {
          flex-shrink: 0;
          width: 20px;
          height: 20px;
          margin-right: 0;
          margin-bottom: 0; }
      .pricing .pricing-layout .column .action {
        padding-top: 54px; }
        .pricing .pricing-layout .column .action p {
          margin-bottom: 10px;
          color: #8494a5; }
        .pricing .pricing-layout .column .action .button, .pricing .pricing-layout .column .action .submission button, .submission .pricing .pricing-layout .column .action button {
          display: block;
          max-width: 260px;
          margin-left: auto;
          margin-right: auto;
          text-align: center; }

@media (max-width: 1250px) {
  .pricing {
    max-width: 100%; }
    .pricing .pricing-header {
      margin-bottom: 80px; }
      .pricing .pricing-header .title {
        font-size: 23px;
        line-height: 27px; }
    .pricing .pricing-layout > .inner {
      border-radius: 0;
      padding: 30px; }
    .pricing .pricing-layout .pricing-table {
      margin-top: -70px;
      border: 1px solid #F1F2F2;
      border-radius: 3px;
      background-color: #fff;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2); }
      .pricing .pricing-layout .pricing-table .action .button, .pricing .pricing-layout .pricing-table .action .submission button, .submission .pricing .pricing-layout .pricing-table .action button {
        padding-top: 12px;
        padding-bottom: 12px; } }

@media (max-width: 860px) {
  .pricing .pricing-header .title {
    font-size: 21px;
    line-height: 27px; }
  .pricing .pricing-layout > .inner {
    padding-left: 20px;
    padding-right: 20px; }
  .pricing .pricing-layout .pricing-table .row.has-highlight + .has-feature .column {
    padding-top: 0; }
  .pricing .pricing-layout .pricing-table .column {
    padding-left: 20px;
    padding-right: 20px; }
  .pricing .pricing-layout .pricing-table .plan .description {
    margin-bottom: 20px; }
  .pricing .pricing-layout .pricing-table .price .amount {
    font-size: 24px;
    line-height: 31px; }
  .pricing .pricing-layout .pricing-table .price .period {
    font-size: 11px;
    line-height: 17px; }
  .pricing .pricing-layout .pricing-table .feature .name {
    font-size: 13px; }
  .pricing .pricing-layout .pricing-table .action {
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px; }
    .pricing .pricing-layout .pricing-table .action .button, .pricing .pricing-layout .pricing-table .action .submission button, .submission .pricing .pricing-layout .pricing-table .action button {
      max-width: 100%; } }

@media (max-width: 570px) {
  .pricing .pricing-layout .pricing-table {
    display: none; }
    .pricing .pricing-layout .pricing-table.phone-only {
      display: table;
      border: none; }
      .pricing .pricing-layout .pricing-table.phone-only + .pricing-table.phone-only {
        margin-top: 20px; } }

.pricing-block {
  color: #8494a5;
  font-size: 18px;
  line-height: 23px;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 80px; }
  .pricing-block > .inner {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 7px 50px 0 rgba(0, 0, 0, 0.35);
    padding: 50px; }
  .pricing-block .label {
    font-size: 17px;
    line-height: 25px;
    font-weight: 400;
    margin-bottom: 8px;
    text-transform: none;
    color: #1693e1;
    padding-left: 1px; }
  .pricing-block .title {
    font-size: 34px;
    line-height: 45px;
    font-weight: 500;
    margin-bottom: 25px;
    color: #08152c; }
  .pricing-block .description {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 40px;
    font-weight: 400;
    max-width: 620px; }
  .pricing-block .pricing-features {
    margin-bottom: 25px; }
    .pricing-block .pricing-features > .inner > .layout {
      display: flex;
      width: 100%;
      border: 1px solid #E6E7E8;
      border-radius: 3px; }
    .pricing-block .pricing-features .feature {
      display: flex;
      flex: 1 1;
      align-items: middle;
      justify-content: center;
      border-right: 1px solid #E6E7E8;
      padding: 35px 25px 28px 25px; }
      .pricing-block .pricing-features .feature:last-child {
        border-right: none; }
      .pricing-block .pricing-features .feature .icon {
        width: 40px;
        height: 40px;
        flex-shrink: 0; }
        .pricing-block .pricing-features .feature .icon svg {
          width: 100%;
          height: 100%;
          fill: #8494a5; }
      .pricing-block .pricing-features .feature .content {
        color: #8494a5;
        font-size: 13px;
        line-height: 19px;
        padding-left: 18px; }
        .pricing-block .pricing-features .feature .content strong {
          display: block;
          color: #08152c;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px; }

@media (max-width: 1680px) {
  .pricing-block .description {
    font-size: 17px;
    line-height: 27px;
    margin-bottom: 35px; }
  .pricing-block .pricing-features {
    margin-bottom: 20px; } }

@media (max-width: 1100px) {
  .pricing-block {
    padding-left: 30px;
    padding-right: 30px; }
    .pricing-block .pricing-features .feature {
      padding: 25px 15px 18px 15px; }
      .pricing-block .pricing-features .feature .icon {
        width: 35px;
        height: 35px; }
      .pricing-block .pricing-features .feature .content strong {
        font-size: 15px; } }

@media (max-width: 860px) {
  .pricing-block {
    margin-top: 40px;
    margin-bottom: 40px; }
    .pricing-block > .inner {
      padding: 30px; }
    .pricing-block .label {
      font-size: 15px;
      line-height: 21px; }
    .pricing-block .title {
      font-size: 27px;
      line-height: 35px; }
    .pricing-block .pricing-features .feature {
      display: block;
      padding: 15px 15px 18px 17px; }
      .pricing-block .pricing-features .feature .icon {
        margin-bottom: 5px; }
      .pricing-block .pricing-features .feature .content {
        padding-left: 0;
        font-size: 13px; }
        .pricing-block .pricing-features .feature .content strong {
          font-size: 13px; } }

@media (max-width: 570px) {
  .pricing-block {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px; }
    .pricing-block > .inner {
      padding: 30px 22px 40px 22px; }
    .pricing-block .label {
      margin-bottom: 0; }
    .pricing-block .title {
      font-size: 21px;
      line-height: 29px;
      margin-bottom: 12px; }
    .pricing-block .description {
      font-size: 15px;
      line-height: 23px;
      margin-bottom: 25px; }
    .pricing-block .pricing-features > .inner > .layout {
      display: block; }
    .pricing-block .pricing-features > .inner .feature {
      display: flex;
      justify-content: left;
      border-right: none;
      border-bottom: 1px solid #E6E7E8; }
      .pricing-block .pricing-features > .inner .feature:last-child {
        border-bottom: none; }
      .pricing-block .pricing-features > .inner .feature .icon {
        display: flex;
        width: 35px;
        flex-shrink: 0; }
      .pricing-block .pricing-features > .inner .feature .content {
        padding-left: 10px; } }

.content-block .contents-quarters {
  display: flex;
  padding: 0px 180px; }
  .content-block .contents-quarters:last-child .content-quarter {
    padding-bottom: 100px; }
  .content-block .contents-quarters.reverse {
    flex-direction: row-reverse; }
  .content-block .contents-quarters .content-quarter {
    width: 25%;
    color: #808285; }
    .content-block .contents-quarters .content-quarter > .inner {
      padding: 80px 20px 40px; }
    .content-block .contents-quarters .content-quarter .photo-content {
      position: relative;
      top: -30px; }
    .content-block .contents-quarters .content-quarter .label + .title {
      position: relative;
      margin-top: -4px; }
    .content-block .contents-quarters .content-quarter .title {
      font-size: 33px;
      line-height: 46px;
      font-weight: 600;
      margin-bottom: 0;
      color: #131313; }
    .content-block .contents-quarters .content-quarter .description {
      font-size: 19px;
      line-height: 29px;
      margin-bottom: 30px; }
      .content-block .contents-quarters .content-quarter .description:last-child {
        margin-bottom: 0; }
      .content-block .contents-quarters .content-quarter .description + .label {
        margin-top: 55px; }

@media (max-width: 1680px) {
  .content-block .contents-quarters {
    margin: 0px -60px; }
    .content-block .contents-quarters .content-quarter .label + .title {
      margin-top: 0; } }

@media (max-width: 1440px) {
  .content-block .contents-quarters {
    margin: 0px -100px; }
    .content-block .contents-quarters:last-child .content-quarter {
      padding-bottom: 60px; }
    .content-block .contents-quarters .content-quarter > .inner {
      padding-top: 60px;
      padding-bottom: 60px; }
    .content-block .contents-quarters .content-quarter .title {
      font-size: 29px;
      line-height: 35px;
      margin-bottom: 10px; }
    .content-block .contents-quarters .content-quarter .description {
      font-size: 17px;
      line-height: 29px; }
    .content-block .contents-quarters .content-quarter .photo-content {
      top: -10px; } }

@media (max-width: 1250px) {
  .content-block .contents-quarters {
    margin: 0px -120px;
    flex-wrap: wrap; }
    .content-block .contents-quarters .content-quarter {
      width: 50%; }
      .content-block .contents-quarters .content-quarter > .inner {
        padding-top: 40px;
        padding-bottom: 40px; }
      .content-block .contents-quarters .content-quarter .title {
        font-size: 25px;
        line-height: 29px; }
      .content-block .contents-quarters .content-quarter .description {
        font-size: 17px;
        line-height: 27px; } }

@media (max-width: 1100px) {
  .content-block .contents-quarters {
    margin: 0px;
    padding: 0px; }
    .content-block .contents-quarters.reverse .content-quarter:nth-child(even) > .inner {
      padding-left: 80px; }
    .content-block .contents-quarters.reverse .content-quarter:nth-child(odd) > .inner {
      padding-right: 80px; }
    .content-block .contents-quarters:not(.reverse) .content-quarter:nth-child(even) > .inner {
      padding-right: 80px; }
    .content-block .contents-quarters:not(.reverse) .content-quarter:nth-child(odd) > .inner {
      padding-left: 80px; }
    .content-block .contents-quarters .content-quarter .description {
      font-size: 16px;
      line-height: 27px; }
      .content-block .contents-quarters .content-quarter .description + .label {
        margin-top: 35px; } }

@media (max-width: 860px) {
  .content-block .contents-quarters.reverse .content-quarter:nth-child(even) > .inner {
    padding-left: 50px; }
  .content-block .contents-quarters.reverse .content-quarter:nth-child(odd) > .inner {
    padding-right: 50px; }
  .content-block .contents-quarters:not(.reverse) .content-quarter:nth-child(even) > .inner {
    padding-right: 50px; }
  .content-block .contents-quarters:not(.reverse) .content-quarter:nth-child(odd) > .inner {
    padding-left: 50px; }
  .content-block .contents-quarters .content-quarter .description {
    font-size: 16px;
    line-height: 27px; }
    .content-block .contents-quarters .content-quarter .description + .label {
      margin-top: 35px; } }

@media (max-width: 570px) {
  .content-block .contents-quarters {
    margin: 0px;
    padding: 0px;
    display: block; }
    .content-block .contents-quarters:last-child .content-quarter {
      padding-bottom: 0; }
    .content-block .contents-quarters.reverse .content-quarter:nth-child(even) > .inner {
      padding-left: 30px;
      padding-right: 30px; }
    .content-block .contents-quarters.reverse .content-quarter:nth-child(odd) > .inner {
      padding-left: 30px;
      padding-right: 30px; }
    .content-block .contents-quarters:not(.reverse) .content-quarter:nth-child(even) > .inner {
      padding-left: 30px;
      padding-right: 30px; }
    .content-block .contents-quarters:not(.reverse) .content-quarter:nth-child(odd) > .inner {
      padding-left: 30px;
      padding-right: 30px; }
    .content-block .contents-quarters .content-quarter {
      width: auto; }
      .content-block .contents-quarters .content-quarter .photo-content {
        margin-bottom: -30px; } }

.quicknav {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3; }
  .quicknav.is-stuck .handle {
    height: 100px; }
    .quicknav.is-stuck .handle .controls div {
      top: 50px; }
    .quicknav.is-stuck .handle .open-label,
    .quicknav.is-stuck .handle .close-label {
      opacity: 0 !important;
      top: 90px;
      white-space: nowrap; }
  .quicknav .handle {
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    -webkit-transition-property: background height;
    transition-property: background height;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 100px;
    height: 285px;
    color: #fff; }
    .quicknav .handle.red {
      background-color: #f34322; }
    .quicknav .handle.blue {
      background: #003b82;
      background: -webkit-linear-gradient(top, #002e66 0%, #003b82 32%, #006eb3 100%);
      background: -webkit-gradient(linear, left top, left bottom, from(#002e66), color-stop(32%, #003b82), to(#006eb3));
      background: linear-gradient(to bottom, #002e66 0%, #003b82 32%, #006eb3 100%); }
    .quicknav .handle:after {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: opacity;
      transition-property: opacity;
      pointer-events: none;
      content: '';
      position: absolute;
      width: 100%;
      height: 200px;
      left: 0;
      right: 0;
      bottom: -200px;
      opacity: 1;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.04)), to(rgba(255, 255, 255, 0)));
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
    .quicknav .handle:hover {
      background-color: #dc2800; }
    .quicknav .handle .controls {
      position: relative; }
      .quicknav .handle .controls div {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: all;
        transition-property: all;
        width: 24px;
        height: 24px;
        top: 75px;
        left: 38px; }
        .quicknav .handle .controls div, .quicknav .handle .controls div:before, .quicknav .handle .controls div:after {
          -webkit-transition-duration: 0.2s;
                  transition-duration: 0.2s;
          -webkit-transition-property: all;
          transition-property: all;
          position: relative;
          content: '';
          display: block;
          width: 24px;
          height: 2px;
          background-color: #fff;
          border-radius: 1px; }
        .quicknav .handle .controls div:before {
          top: -8px; }
        .quicknav .handle .controls div:after {
          top: 6px; }
    .quicknav .handle .open-label,
    .quicknav .handle .close-label {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: opacity top;
      transition-property: opacity top;
      opacity: 1;
      position: absolute;
      top: 110px;
      left: 61px;
      font-size: 17px;
      line-height: 23px;
      font-weight: 500;
      text-transform: none;
      -webkit-transform-origin: top left;
              transform-origin: top left;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      letter-spacing: 1px;
      opacity: 1;
      width: 110px; }
    .quicknav .handle .close-label {
      opacity: 0; }
  .quicknav.is-open .handle .controls {
    position: relative; }
    .quicknav.is-open .handle .controls div {
      background-color: rgba(255, 255, 255, 0); }
      .quicknav.is-open .handle .controls div:before {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        top: -1px; }
      .quicknav.is-open .handle .controls div:after {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        top: -3px; }
  .quicknav.is-open .handle .close-label {
    opacity: 1; }
  .quicknav.is-open .handle .open-label {
    opacity: 0; }
  .quicknav.is-open .menu {
    opacity: 1;
    pointer-events: auto; }
  .quicknav .menu {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: auto; }
    .quicknav .menu > .inner {
      box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.2);
      background-color: #fff;
      margin-bottom: 30px; }
    .quicknav .menu .jojo {
      position: absolute;
      z-index: 2;
      left: 60px;
      top: 80px;
      width: 180px; }
      .quicknav .menu .jojo > .inner {
        position: relative;
        display: block;
        background-image: url(/static/media/jojo-treasure-small.f19c04fe.png);
        background-size: 100% auto;
        width: 180px;
        height: 180px; }
    .quicknav .menu .links {
      display: flex;
      flex-direction: row-reverse;
      min-height: 600px; }
    .quicknav .menu .link-list {
      width: 50%;
      padding: 100px 105px 150px 400px; }
      .quicknav .menu .link-list a:not(.button) {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: all;
        transition-property: all;
        box-shadow: rgba(0, 0, 0, 0) 0px -2px 0px 0px inset;
        color: inherit;
        text-decoration: none;
        padding-bottom: 2px;
        font-size: 15px;
        line-height: 19px;
        color: #939598;
        font-weight: 500;
        text-decoration: none; }
        .quicknav .menu .link-list a:not(.button):hover {
          box-shadow: #1693e1 0px -2px 0px 0px inset; }
        .quicknav .menu .link-list a:not(.button):hover {
          color: #131313; }
      .quicknav .menu .link-list:first-child {
        background-color: #F9F9F9;
        width: 50%;
        padding-left: 105px; }
        .quicknav .menu .link-list:first-child li {
          color: #1693e1; }
          .quicknav .menu .link-list:first-child li a:not(.button) {
            -webkit-transition-duration: 0.2s;
                    transition-duration: 0.2s;
            -webkit-transition-property: all;
            transition-property: all;
            box-shadow: transparent 0px -3pxpx 0px 0px inset;
            color: inherit;
            text-decoration: none;
            color: #131313; }
            .quicknav .menu .link-list:first-child li a:not(.button):hover {
              box-shadow: #1693e1 0px -3pxpx 0px 0px inset; }
      .quicknav .menu .link-list li {
        display: block;
        font-size: 23px;
        line-height: 29px;
        color: #dc2800;
        padding-top: 10px;
        padding-bottom: 10px; }
        .quicknav .menu .link-list li a:not(.button) {
          display: inline-block;
          font-size: 27px;
          line-height: 33px;
          font-weight: 600;
          text-decoration: none;
          -webkit-transition-duration: 0.2s;
                  transition-duration: 0.2s;
          -webkit-transition-property: all;
          transition-property: all;
          box-shadow: transparent 0px -3pxpx 0px 0px inset;
          color: inherit;
          text-decoration: none;
          color: #131313; }
          .quicknav .menu .link-list li a:not(.button):hover {
            box-shadow: #dc2800 0px -3pxpx 0px 0px inset; }

@media (max-width: 1680px) {
  .quicknav.is-stuck .handle {
    height: 80px; }
    .quicknav.is-stuck .handle .controls div {
      top: 37px; }
    .quicknav.is-stuck .handle .open-label,
    .quicknav.is-stuck .handle .close-label {
      top: 60px; }
  .quicknav .handle {
    width: 80px;
    height: 228px; }
    .quicknav .handle .controls div {
      top: 56px;
      left: 28px; }
    .quicknav .handle .open-label,
    .quicknav .handle .close-label {
      top: 88px;
      left: 51px;
      font-size: 15px; }
  .quicknav .menu .jojo {
    width: 140px; }
    .quicknav .menu .jojo > .inner {
      width: 140px;
      height: 140px; }
  .quicknav .menu .link-list {
    padding-left: 300px; } }

@media (max-width: 1440px) {
  .quicknav .menu .jojo {
    width: 120px;
    left: 40px;
    top: 70px; }
    .quicknav .menu .jojo > .inner {
      width: 120px;
      height: 120px; }
  .quicknav .menu .link-list {
    padding-left: 230px;
    padding-top: 80px;
    padding-bottom: 100px; } }

@media (max-width: 1100px) {
  .quicknav.is-stuck .handle {
    top: 10px;
    right: 7px;
    border-radius: 60px;
    width: 60px;
    height: 60px; }
    .quicknav.is-stuck .handle:after {
      opacity: 0; }
    .quicknav.is-stuck .handle .controls div {
      top: 29px;
      left: 18px; }
  .quicknav .handle {
    width: 69px;
    height: 210px; }
    .quicknav .handle .controls div {
      top: 49px;
      left: 23px; }
    .quicknav .handle .open-label,
    .quicknav .handle .close-label {
      top: 81px;
      left: 46px;
      font-size: 13px; }
  .quicknav .menu .jojo {
    width: 100px;
    left: 60px; }
    .quicknav .menu .jojo > .inner {
      width: 100px;
      height: 100px; }
  .quicknav .menu .link-list {
    padding: 80px 60px 80px 230px; }
    .quicknav .menu .link-list:first-child {
      padding-left: 80px; }
    .quicknav .menu .link-list li {
      font-size: 17px;
      line-height: 21px;
      padding-top: 10px;
      padding-bottom: 10px; }
      .quicknav .menu .link-list li a:not(.button) {
        font-size: 21px;
        line-height: 25px; } }

@media (max-width: 860px) {
  .quicknav .handle {
    width: 62px;
    height: 190px; }
    .quicknav .handle .controls div {
      top: 48px;
      left: 21px;
      width: 18px; }
      .quicknav .handle .controls div, .quicknav .handle .controls div:before, .quicknav .handle .controls div:after {
        width: 22px; }
      .quicknav .handle .controls div:before {
        top: -7px; }
      .quicknav .handle .controls div:after {
        top: 5px; }
    .quicknav .handle .open-label,
    .quicknav .handle .close-label {
      top: 76px;
      left: 42px;
      font-size: 12px; }
  .quicknav .menu .jojo {
    width: 80px;
    left: 30px; }
    .quicknav .menu .jojo > .inner {
      width: 80px;
      height: 80px; }
  .quicknav .menu .link-list {
    padding: 80px 60px 100px 150px; }
    .quicknav .menu .link-list:first-child {
      padding-left: 80px; }
    .quicknav .menu .link-list li {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 15px;
      line-height: 21px; }
      .quicknav .menu .link-list li a:not(.button) {
        font-size: 17px;
        line-height: 23px; }
      .quicknav .menu .link-list li a.button {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
        -webkit-transform-origin: 0 0;
                transform-origin: 0 0; } }

@media (max-width: 750px) {
  .quicknav.is-stuck .handle {
    top: 10px;
    right: 5px;
    border-radius: 48px;
    width: 47px;
    height: 47px; }
    .quicknav.is-stuck .handle:after {
      opacity: 0; }
    .quicknav.is-stuck .handle .controls div {
      top: 23px;
      left: 14px; }
  .quicknav .handle {
    width: 54px;
    height: 174px; }
    .quicknav .handle:after {
      display: none; }
    .quicknav .handle .controls div {
      top: 38px;
      left: 18px;
      width: 18px; }
      .quicknav .handle .controls div, .quicknav .handle .controls div:before, .quicknav .handle .controls div:after {
        width: 18px; }
      .quicknav .handle .controls div:before {
        top: -6px; }
      .quicknav .handle .controls div:after {
        top: 4px; }
    .quicknav .handle .open-label,
    .quicknav .handle .close-label {
      top: 64px;
      left: 38px;
      font-size: 13px; }
  .quicknav .menu > .inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 0; }
  .quicknav .menu .jojo {
    position: relative;
    z-index: 1;
    width: 70px;
    top: 30px; }
    .quicknav .menu .jojo > .inner {
      width: 70px;
      height: 70px; }
  .quicknav .menu .links {
    position: relative;
    display: block;
    background-color: #fff; }
  .quicknav .menu .link-list {
    display: block;
    padding: 20px 30px 70px 30px;
    width: 100%; }
    .quicknav .menu .link-list:first-child {
      width: 100%;
      padding-top: 50px;
      padding-left: 30px;
      background-color: #fff;
      padding-bottom: 0px; }
    .quicknav .menu .link-list li {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 14px; }
      .quicknav .menu .link-list li a:not(.button) {
        font-size: 16px;
        line-height: 21px; }
      .quicknav .menu .link-list li a.button {
        margin-top: 5px;
        margin-bottom: -8px;
        -webkit-transform: scale(0.75);
                transform: scale(0.75);
        -webkit-transform-origin: 0 0;
                transform-origin: 0 0; } }

.multi-quote {
  padding-bottom: 20px; }
  .multi-quote .flickity-viewport {
    -webkit-transition: height 0.2s;
    transition: height 0.2s; }
  .multi-quote .quote {
    width: 100%; }
  .multi-quote .flickity-button {
    display: none; }
  .multi-quote .flickity-page-dots {
    bottom: 25px; }
    .multi-quote .flickity-page-dots .dot {
      background-color: #fff;
      width: 12px;
      height: 12px; }
      .multi-quote .flickity-page-dots .dot.is-selected {
        background-color: #1693e1; }

.quote {
  position: relative;
  background-color: #08152c;
  color: #fff; }
  .quote > .inner {
    padding: 80px 0 80px 0; }
    .quote > .inner > .layout {
      display: flex; }
  .quote .logo {
    width: 340px;
    display: flex;
    align-items: center;
    width: 38%;
    padding-left: 70px; }
    .quote .logo img {
      width: 100%;
      max-width: 275px;
      height: auto;
      margin-left: auto;
      margin-right: auto; }
  .quote .content {
    flex: 1 1;
    padding-left: 0;
    padding-right: 80px; }
  .quote blockquote {
    position: relative;
    padding: 15px 40px 15px 140px;
    font-size: 21px;
    line-height: 38px;
    font-weight: 600; }
    .quote blockquote:before {
      position: absolute;
      content: '';
      width: 5px;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: red;
      border-radius: 10px;
      background: #004ea1;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #004ea1 0%, #1797e5 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: -webkit-gradient(linear, left top, left bottom, from(#004ea1), to(#1797e5));
      background: linear-gradient(to bottom, #004ea1 0%, #1797e5 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
    .quote blockquote.has-quotes {
      text-indent: -10px; }
    .quote blockquote p {
      margin-bottom: 15px; }
    .quote blockquote a {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: all;
      transition-property: all;
      box-shadow: #2eb6ef 0px -4px 0px 0px inset;
      color: inherit;
      text-decoration: none;
      text-decoration: none;
      color: #fff;
      padding-bottom: 2px; }
      .quote blockquote a:hover {
        box-shadow: #f34322 0px -4px 0px 0px inset; }
    .quote blockquote footer {
      color: #939598;
      font-size: 15px;
      line-height: 19px;
      font-weight: 400; }

@media (max-width: 1440px) {
  .quote blockquote {
    padding-left: 80px; } }

@media (max-width: 1100px) {
  .quote > .inner {
    padding-left: 58px;
    padding-right: 80px; }
  .quote .logo {
    width: 200px;
    padding-left: 0; }
  .quote .content {
    padding-left: 35px;
    padding-right: 0; }
  .quote blockquote {
    padding-left: 50px;
    padding-right: 0;
    font-size: 19px;
    line-height: 29px; } }

@media (max-width: 860px) {
  .quote > .inner {
    padding-left: 60px; }
  .quote blockquote {
    font-size: 17px;
    line-height: 29px;
    padding-right: 15px; } }

@media (max-width: 570px) {
  .quote > .inner {
    padding: 60px 30px 50px 30px; }
    .quote > .inner > .layout {
      display: block; }
  .quote .logo,
  .quote .content {
    display: block;
    width: 100%;
    padding: 0; }
  .quote .logo {
    margin-bottom: 40px; }
    .quote .logo img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 200px; }
  .quote blockquote {
    padding: 70px 2px 30px 2px;
    font-size: 17px;
    line-height: 31px; }
    .quote blockquote:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: auto;
      width: auto;
      height: 5px;
      background: #004ea1;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #004ea1 0%, #1797e5 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: -webkit-gradient(linear, left top, right top, from(#004ea1), to(#1797e5));
      background: linear-gradient(to right, #004ea1 0%, #1797e5 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ } }

.request-demo > .modal .modal_box {
  width: 800px;
  overflow-y: auto;
  background: transparent;
  padding: 0px;
  box-shadow: none;
  max-width: none; }

.request-demo .modal_close {
  position: absolute;
  right: 8px;
  top: 68px; }

@media (max-width: 860px) {
  .request-demo > .modal .modal_box {
    width: auto;
    top: 50px;
    left: 50px;
    right: 50px;
    -webkit-transform: translate(0);
            transform: translate(0); }
  .request-demo .modal_close {
    position: absolute;
    right: 0px;
    top: 57px; } }

@media (max-width: 570px) {
  .request-demo > .modal .modal_box {
    width: auto;
    top: 20px;
    left: 20px;
    right: 20px;
    -webkit-transform: translate(0);
            transform: translate(0); }
  .request-demo .modal_close {
    position: absolute;
    right: 48px;
    top: 10px; } }

.sdks {
  display: inline-block;
  border: 1px solid #5DC5F9;
  border-radius: 3px;
  display: table;
  margin-bottom: 15px; }
  .sdks:last-child {
    margin-bottom: 0; }
  .sdks .sdk {
    display: table-cell;
    vertical-align: middle;
    border-right: 1px solid #5DC5F9; }
    .sdks .sdk:last-child {
      border-right: none; }
    .sdks .sdk a,
    .sdks .sdk button {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: background;
      transition-property: background;
      display: block;
      padding: 1px 13px;
      background-color: transparent; }
      .sdks .sdk a:hover,
      .sdks .sdk button:hover {
        background-color: #5DC5F9; }
    .sdks .sdk svg {
      position: relative;
      top: 2px;
      height: 40px;
      fill: #fff; }

@media (max-width: 1100px) {
  .sdks .sdk svg {
    height: 30px; } }

.submission {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 1px 3px 15px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 70px; }
  .submission > .inner {
    padding: 20px 20px 30px 20px; }
  .submission fieldset {
    position: relative;
    border: 1px solid #F1F1F1;
    padding: 80px 20px 30px 30px;
    margin-bottom: 25px;
    border-radius: 3px; }
    .submission fieldset > legend {
      position: absolute;
      top: 25px;
      left: 30px;
      color: #1693e1;
      font-size: 23px;
      line-height: 29px;
      font-weight: 600;
      margin-bottom: 5px;
      background-color: transparent; }
    .submission fieldset#root {
      border: none;
      padding: 0; }
      .submission fieldset#root:before {
        display: none; }
      .submission fieldset#root > legend {
        display: none; }
    .submission fieldset .form-group {
      margin-bottom: 20px; }
      .submission fieldset .form-group:last-child {
        margin-bottom: 0; }
      .submission fieldset .form-group label {
        display: block;
        font-size: 15px;
        line-height: 21px;
        font-weight: 400;
        margin-bottom: 7px; }
    .submission fieldset fieldset {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: border;
      transition-property: border; }
      .submission fieldset fieldset:hover {
        border-color: #d6dbdf; }
    .submission fieldset .field-radio-group {
      margin-top: 5px; }
      .submission fieldset .field-radio-group .radio {
        display: inline-block;
        margin-right: 13px; }
        .submission fieldset .field-radio-group .radio label {
          -webkit-transition-duration: 0.2s;
                  transition-duration: 0.2s;
          -webkit-transition-property: border;
          transition-property: border;
          display: block;
          border: 1px solid #e6e7e8;
          padding: 3px 14px 8px 12px;
          border-radius: 20px; }
          .submission fieldset .field-radio-group .radio label:hover {
            border-color: #939598; }
          .submission fieldset .field-radio-group .radio label:focus {
            border-color: #1693e1; }
        .submission fieldset .field-radio-group .radio input {
          margin-left: 0;
          margin-right: 8px; }
        .submission fieldset .field-radio-group .radio span span {
          font-size: 13px;
          line-height: 19px;
          font-weight: 500;
          text-transform: capitalize;
          position: relative;
          vertical-align: middle;
          top: 2px; }
  .submission button {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: scale(0.85);
            transform: scale(0.85); }
    .submission button:hover {
      border-color: #02173e;
      background-color: #02173e; }
  .submission fieldset.field-array-of-object {
    border: none;
    padding: 0; }
    .submission fieldset.field-array-of-object:last-of-type {
      margin-bottom: 0; }
    .submission fieldset.field-array-of-object fieldset {
      padding: 0;
      border: none; }
    .submission fieldset.field-array-of-object .array-item-list {
      border: 1px solid #F1F1F1;
      border-radius: 3px;
      padding: 30px 30px 20px 30px;
      margin-bottom: 30px; }
      .submission fieldset.field-array-of-object .array-item-list:empty {
        display: none; }
      .submission fieldset.field-array-of-object .array-item-list .array-item {
        border-bottom: 5px solid #F1F1F1;
        padding-bottom: 20px;
        margin-bottom: 30px; }
        .submission fieldset.field-array-of-object .array-item-list .array-item:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0; }
    .submission fieldset.field-array-of-object .array-item-toolbox {
      position: relative;
      margin-top: -10px; }
  .submission .array-item-add button,
  .submission button.array-item-remove {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    display: inline-block; }
    .submission .array-item-add button:before,
    .submission button.array-item-remove:before {
      position: relative;
      font-size: 15px;
      line-height: 24px;
      font-weight: 500; }
  .submission .array-item .btn-group {
    display: block !important; }
  .submission .array-item-add button:before {
    content: '+\A0   Add'; }
  .submission button.array-item-remove {
    padding-left: 22px !important;
    padding-right: 22px !important; }
    .submission button.array-item-remove:before {
      content: '\2212\A0   Remove'; }

@media (max-width: 860px) {
  .submission {
    margin-top: -10px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); }
    .submission fieldset {
      padding: 70px 20px 20px 20px; }
      .submission fieldset > legend {
        left: 20px;
        font-size: 20px; } }

@media (max-width: 570px) {
  .submission {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    margin-top: -20px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 0; }
    .submission > .inner {
      padding: 0; }
    .submission .formbase > p {
      padding-left: 17px; }
    .submission fieldset {
      padding: 60px 17px 17px 17px; }
      .submission fieldset > legend {
        top: 15px;
        left: 17px;
        font-size: 19px; }
      .submission fieldset#root {
        margin-bottom: 0; }
      .submission fieldset .form-group label {
        font-size: 14px; }
      .submission fieldset .field-radio-group .radio label {
        padding-bottom: 5px; }
    .submission fieldset.field-array-of-object .array-item-list {
      padding: 15px 15px 10px 15px; }
    .submission .message-alert {
      padding-left: 17px; } }

.nav {
  z-index: 2; }
  .nav > ul > li:hover .subnav {
    pointer-events: auto; }
    .nav > ul > li:hover .subnav > .inner {
      -webkit-transition-duration: 0.4s;
              transition-duration: 0.4s;
      top: 20px;
      opacity: 1; }
  .nav > ul > li.js-subnav-closed .subnav {
    pointer-events: none; }
    .nav > ul > li.js-subnav-closed .subnav > .inner {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      top: 30px;
      opacity: 0; }

.subnav {
  position: absolute;
  top: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  pointer-events: none; }
  .subnav.is-mega-menu > .inner > .layout {
    display: flex;
    width: 700px; }
  .subnav > .inner {
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-property: all;
    transition-property: all;
    position: relative;
    top: 30px;
    opacity: 0;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.45); }
    .subnav > .inner:before {
      content: '';
      position: absolute;
      left: 50%;
      top: -7px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      display: block;
      border-color: #fff transparent;
      border-style: solid;
      border-width: 0px 7px 7px 7px;
      height: 0px;
      width: 0px; }
    .subnav > .inner > .layout {
      display: flex;
      width: auto; }
  .subnav .subnav-links {
    width: 55%;
    padding-top: 30px;
    padding-bottom: 25px; }
    .subnav .subnav-links:only-child {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 10px; }
      .subnav .subnav-links:only-child .inner {
        padding-top: 5px;
        padding-bottom: 5px; }
      .subnav .subnav-links:only-child .content {
        padding-left: 28px;
        padding-right: 28px; }
        .subnav .subnav-links:only-child .content .title {
          white-space: nowrap; }
    .subnav .subnav-links .inner {
      display: flex;
      padding-top: 15px;
      padding-bottom: 15px; }
    .subnav .subnav-links a {
      text-decoration: none; }
      .subnav .subnav-links a:hover .icon svg {
        fill: #1693e1; }
      .subnav .subnav-links a:hover .title {
        color: #1693e1; }
    .subnav .subnav-links .icon {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      width: 75px; }
      .subnav .subnav-links .icon > div {
        position: relative;
        top: -3px;
        display: block;
        max-width: 40px;
        margin-left: auto;
        margin-right: auto; }
      .subnav .subnav-links .icon .isvg {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 40px;
        height: 40px;
        fill: #8494a5; }
    .subnav .subnav-links .content {
      color: #8494a5;
      font-size: 13px;
      line-height: 19px;
      font-weight: 400;
      padding-right: 35px; }
      .subnav .subnav-links .content .title {
        display: inline-block;
        color: #08152c;
        font-size: 15px;
        font-weight: 500; }
  .subnav .subnav-content {
    display: flex;
    align-items: center;
    width: 45%;
    text-align: center;
    color: #fff;
    background: #1797e5;
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #1797e5 0%, #0776c4 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-linear-gradient(315deg, #1797e5 0%, #0776c4 100%);
    background: linear-gradient(135deg, #1797e5 0%, #0776c4 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    padding: 40px;
    border-radius: 0 3px 3px 0; }
    .subnav .subnav-content .title-icon {
      display: block;
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto; }
      .subnav .subnav-content .title-icon svg {
        width: 40px;
        height: 40px;
        fill: #fff; }
    .subnav .subnav-content .title {
      color: #9fdbf9;
      font-size: 19px;
      line-height: 25px;
      margin-bottom: 10px; }
    .subnav .subnav-content .content {
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 30px;
      padding-left: 3px;
      padding-right: 3px; }
    .subnav .subnav-content .sdks {
      margin-left: auto;
      margin-right: auto; }

@media (max-width: 1100px) {
  .subnav.is-mega-menu .subnav-links {
    padding-top: 10px;
    padding-bottom: 10px; }
  .subnav .subnav-links {
    width: 55%;
    padding-top: 30px;
    padding-bottom: 25px; }
    .subnav .subnav-links:only-child .content {
      padding-left: 20px;
      padding-right: 20px; }
    .subnav .subnav-links .content .title {
      font-size: 13px; } }

@media (max-width: 860px) {
  .subnav.is-mega-menu > .inner {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none; }
    .subnav.is-mega-menu > .inner > .layout {
      width: 600px;
      background-color: #fff;
      border-radius: 3px;
      box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.45);
      -webkit-transform: translateX(60px);
              transform: translateX(60px); } }

@media (max-width: 570px) {
  .subnav {
    display: none; } }

.form {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 1px 3px 15px 0 rgba(0, 0, 0, 0.2);
  margin-top: 30px;
  margin-bottom: 70px; }
  .form:last-child {
    margin-bottom: 0; }
  .form > .inner {
    padding: 20px 20px 30px 20px; }
  .form .title {
    color: #1693e1;
    font-size: 20px;
    line-height: 29px;
    font-weight: 600;
    margin-bottom: 5px; }
  .form .description {
    color: #939598;
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
    max-width: 80%;
    margin-bottom: 20px; }
    .form .description:empty {
      display: none; }
  .form .title + .description:empty + .fields-inline {
    margin-top: 10px; }
  .form .fields-inline {
    display: table;
    width: 100%; }
    .form .fields-inline .field,
    .form .fields-inline .action {
      display: table-cell;
      vertical-align: middle; }
    .form .fields-inline .field > div {
      margin: 0;
      padding-right: 18px; }
    .form .fields-inline .field input {
      margin-bottom: 0; }
    .form .fields-inline .action {
      width: 1%;
      white-space: nowrap; }

@media (max-width: 1100px) {
  .subscribe > .inner {
    padding-bottom: 25px;
    padding-right: 0; }
  .subscribe .fields-inline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .subscribe .fields-inline .field {
      width: 33%;
      margin-bottom: 17px; }
    .subscribe .fields-inline .action {
      width: 33%;
      align-items: center;
      justify-content: center; } }

@media (max-width: 570px) {
  .subscribe {
    box-shadow: 1px 3px 15px 0 rgba(0, 0, 0, 0.15); }
    .subscribe > .inner {
      padding-bottom: 25px;
      padding-right: 20px; }
    .subscribe .title {
      margin-bottom: 15px; }
    .subscribe .fields-inline .field {
      width: 100%; }
      .subscribe .fields-inline .field > div {
        padding-right: 0; }
    .subscribe .fields-inline .action {
      width: 100%;
      padding-top: 5px;
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      -webkit-transform: scale(1.1);
              transform: scale(1.1); } }

.subscribe-large {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 80px;
  color: #8ED9FF; }
  .subscribe-large:last-child {
    padding-bottom: 80px; }
  .subscribe-large.has-sdks .content-left .description {
    margin-bottom: 30px; }
  .subscribe-large.has-sdks.has-actions .sdks {
    margin-bottom: 40px; }
  .subscribe-large > .inner {
    background: #0776C4;
    background: -webkit-linear-gradient(45deg, #0776C4 0%, #1797E5 100%);
    background: linear-gradient(45deg, #0776C4 0%, #1797E5 100%);
    border-radius: 3px;
    box-shadow: 0 7px 50px 0 rgba(0, 0, 0, 0.35); }
    .subscribe-large > .inner > .layout {
      display: flex; }
  .subscribe-large .content-left {
    position: relative;
    width: 50%;
    background: #0776C4;
    background: -webkit-linear-gradient(45deg, #0776C4 0%, #1797E5 100%);
    background: linear-gradient(45deg, #0776C4 0%, #1797E5 100%);
    border-radius: 3px 0 0 3px;
    padding: 110px 80px 75px 58px; }
    .subscribe-large .content-left .label {
      font-size: 17px;
      line-height: 25px;
      font-weight: 400;
      margin-bottom: 8px;
      text-transform: none;
      padding-left: 1px; }
    .subscribe-large .content-left .title {
      font-size: 34px;
      line-height: 45px;
      font-weight: 500;
      margin-bottom: 25px;
      color: #fff; }
    .subscribe-large .content-left .description {
      font-size: 17px;
      line-height: 27px;
      margin-bottom: 75px;
      font-weight: 400; }
    .subscribe-large .content-left .sdks {
      margin-bottom: 75px; }
      .subscribe-large .content-left .sdks:last-child {
        margin-bottom: 0; }
    .subscribe-large .content-left .actions {
      margin-bottom: 50px; }
      .subscribe-large .content-left .actions:last-child {
        margin-bottom: 0; }
      .subscribe-large .content-left .actions ul {
        display: flex;
        align-items: middle;
        flex-wrap: wrap; }
        .subscribe-large .content-left .actions ul li {
          margin-right: 12px;
          margin-bottom: 12px; }
    .subscribe-large .content-left .subscribe-form {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 74px; }
      .subscribe-large .content-left .subscribe-form form.has-response {
        overflow: hidden; }
        .subscribe-large .content-left .subscribe-form form.has-response .fields-inline {
          top: 80px; }
        .subscribe-large .content-left .subscribe-form form.has-response .action {
          pointer-events: none; }
          .subscribe-large .content-left .subscribe-form form.has-response .action .button-style {
            opacity: 0; }
        .subscribe-large .content-left .subscribe-form form.has-response .message-response {
          opacity: 1; }
      .subscribe-large .content-left .subscribe-form .fields-inline {
        background-color: #2CA2E6;
        border-bottom-left-radius: 3px;
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: top;
        transition-property: top;
        position: relative;
        top: 0;
        display: flex; }
      .subscribe-large .content-left .subscribe-form .field {
        position: relative;
        flex-grow: 1; }
        .subscribe-large .content-left .subscribe-form .field:first-child {
          width: 215px;
          flex-grow: 0; }
          .subscribe-large .content-left .subscribe-form .field:first-child input[type=text],
          .subscribe-large .content-left .subscribe-form .field:first-child input[type=email] {
            padding-left: 58px;
            border-bottom-left-radius: 3px; }
        .subscribe-large .content-left .subscribe-form .field + .field:before, .subscribe-large .content-left .subscribe-form .field + .field:after {
          position: absolute;
          content: '';
          display: block;
          -webkit-transform-origin: center;
                  transform-origin: center;
          top: 29px;
          left: 0;
          width: 2px;
          height: 15px;
          border-radius: 1px;
          background-color: #fff; }
        .subscribe-large .content-left .subscribe-form .field + .field:before {
          -webkit-transform: rotate(0deg);
                  transform: rotate(0deg); }
        .subscribe-large .content-left .subscribe-form .field + .field:after {
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg); }
        .subscribe-large .content-left .subscribe-form .field input[type=text],
        .subscribe-large .content-left .subscribe-form .field input[type=email] {
          color: #fff;
          background-color: transparent;
          box-shadow: none;
          border: none;
          height: 74px;
          margin: 0;
          padding-left: 40px; }
          .subscribe-large .content-left .subscribe-form .field input[type=text]::-webkit-input-placeholder,
          .subscribe-large .content-left .subscribe-form .field input[type=email]::-webkit-input-placeholder {
            color: #fff; }
          .subscribe-large .content-left .subscribe-form .field input[type=text]::-moz-placeholder,
          .subscribe-large .content-left .subscribe-form .field input[type=email]::-moz-placeholder {
            color: #fff; }
          .subscribe-large .content-left .subscribe-form .field input[type=text]::-ms-input-placeholder,
          .subscribe-large .content-left .subscribe-form .field input[type=email]::-ms-input-placeholder {
            color: #fff; }
          .subscribe-large .content-left .subscribe-form .field input[type=text]::placeholder,
          .subscribe-large .content-left .subscribe-form .field input[type=email]::placeholder {
            color: #fff; }
          .subscribe-large .content-left .subscribe-form .field input[type=text]:focus::-webkit-input-placeholder,
          .subscribe-large .content-left .subscribe-form .field input[type=email]:focus::-webkit-input-placeholder {
            color: #9fdbf9; }
          .subscribe-large .content-left .subscribe-form .field input[type=text]:focus::-moz-placeholder,
          .subscribe-large .content-left .subscribe-form .field input[type=email]:focus::-moz-placeholder {
            color: #9fdbf9; }
          .subscribe-large .content-left .subscribe-form .field input[type=text]:focus::-ms-input-placeholder,
          .subscribe-large .content-left .subscribe-form .field input[type=email]:focus::-ms-input-placeholder {
            color: #9fdbf9; }
          .subscribe-large .content-left .subscribe-form .field input[type=text]:focus::placeholder,
          .subscribe-large .content-left .subscribe-form .field input[type=email]:focus::placeholder {
            color: #9fdbf9; }
          .subscribe-large .content-left .subscribe-form .field input[type=text]:hover,
          .subscribe-large .content-left .subscribe-form .field input[type=email]:hover {
            background-color: #1797E5; }
      .subscribe-large .content-left .subscribe-form .action {
        flex-grow: 0;
        width: 74px;
        height: 74px; }
        .subscribe-large .content-left .subscribe-form .action .button-style {
          width: 100%;
          height: 100%; }
        .subscribe-large .content-left .subscribe-form .action .smooth-spinner svg {
          padding: 15px; }
      .subscribe-large .content-left .subscribe-form .button, .subscribe-large .content-left .subscribe-form .submission button, .submission .subscribe-large .content-left .subscribe-form button {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: background;
        transition-property: background;
        position: relative;
        display: block;
        background-color: #1797E5;
        border: none;
        border-radius: 0;
        width: 100%;
        height: 100%;
        text-indent: -9999;
        font-size: 0;
        line-height: 0; }
        .subscribe-large .content-left .subscribe-form .button:hover, .subscribe-large .content-left .subscribe-form .submission button:hover, .submission .subscribe-large .content-left .subscribe-form button:hover {
          background-color: #0068AD; }
        .subscribe-large .content-left .subscribe-form .button:before, .subscribe-large .content-left .subscribe-form .submission button:before, .submission .subscribe-large .content-left .subscribe-form button:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          display: block;
          -webkit-mask: url(/static/media/arrow-with-stem.c018e2a8.svg) no-repeat center;
          mask: url(/static/media/arrow-with-stem.c018e2a8.svg) no-repeat center;
          background-color: #fff;
          width: 30px;
          height: 30px; }
      .subscribe-large .content-left .subscribe-form .message-alert {
        pointer-events: none;
        position: absolute;
        top: -20px;
        right: 15px;
        background-color: #fff;
        font-size: 12px;
        font-weight: 600;
        border-radius: 2px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
        padding: 5px 6px 3px 6px; }
        .subscribe-large .content-left .subscribe-form .message-alert > div {
          -webkit-transform-origin: center;
                  transform-origin: center;
          -webkit-transform: scale(0.8);
                  transform: scale(0.8);
          margin-right: 3px; }
      .subscribe-large .content-left .subscribe-form .message-response {
        -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
        -webkit-transition-property: opacity;
        transition-property: opacity;
        -webkit-transition-delay: 2s;
                transition-delay: 2s;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 74px;
        padding: 0 58px;
        opacity: 0;
        background-color: #0068AD;
        border-radius: 3px 0 0 3px;
        color: #fff; }
        .subscribe-large .content-left .subscribe-form .message-response p {
          display: block;
          position: relative;
          top: 50%;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%); }
  .subscribe-large .content-right {
    position: relative;
    width: 50%; }
    .subscribe-large .content-right .image {
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0 3px 3px 0; }

.subscribe-large.blue-dark {
  color: #3fccfe; }
  .subscribe-large.blue-dark > .inner {
    background: none;
    background-image: none;
    background-color: #00346d; }
  .subscribe-large.blue-dark .content-left {
    background: none;
    background-image: none;
    background-color: #00346d; }
    .subscribe-large.blue-dark .content-left .subscribe-form .fields-inline {
      background-color: #005cad; }
    .subscribe-large.blue-dark .content-left .subscribe-form .field input[type=text],
    .subscribe-large.blue-dark .content-left .subscribe-form .field input[type=email] {
      background-color: #005cad; }
      .subscribe-large.blue-dark .content-left .subscribe-form .field input[type=text]:focus::-webkit-input-placeholder,
      .subscribe-large.blue-dark .content-left .subscribe-form .field input[type=email]:focus::-webkit-input-placeholder {
        color: #6cbbe6; }
      .subscribe-large.blue-dark .content-left .subscribe-form .field input[type=text]:focus::-moz-placeholder,
      .subscribe-large.blue-dark .content-left .subscribe-form .field input[type=email]:focus::-moz-placeholder {
        color: #6cbbe6; }
      .subscribe-large.blue-dark .content-left .subscribe-form .field input[type=text]:focus::-ms-input-placeholder,
      .subscribe-large.blue-dark .content-left .subscribe-form .field input[type=email]:focus::-ms-input-placeholder {
        color: #6cbbe6; }
      .subscribe-large.blue-dark .content-left .subscribe-form .field input[type=text]:focus::placeholder,
      .subscribe-large.blue-dark .content-left .subscribe-form .field input[type=email]:focus::placeholder {
        color: #6cbbe6; }
      .subscribe-large.blue-dark .content-left .subscribe-form .field input[type=text]:hover,
      .subscribe-large.blue-dark .content-left .subscribe-form .field input[type=email]:hover {
        background-color: #004f94; }
    .subscribe-large.blue-dark .content-left .subscribe-form .button, .subscribe-large.blue-dark .content-left .subscribe-form .submission button, .submission .subscribe-large.blue-dark .content-left .subscribe-form button {
      background-color: #006ecf; }
      .subscribe-large.blue-dark .content-left .subscribe-form .button:hover, .subscribe-large.blue-dark .content-left .subscribe-form .submission button:hover, .submission .subscribe-large.blue-dark .content-left .subscribe-form button:hover {
        background-color: #00428c; }

.subscribe-large.red {
  color: #fff; }
  .subscribe-large.red > .inner {
    background: none;
    background-image: none;
    background-color: #df2600; }
  .subscribe-large.red .content-left {
    background: none;
    background-image: none;
    background-color: #df2600; }
    .subscribe-large.red .content-left .subscribe-form .fields-inline {
      background-color: #c22100; }
    .subscribe-large.red .content-left .subscribe-form .field input[type=text],
    .subscribe-large.red .content-left .subscribe-form .field input[type=email] {
      background-color: #c22100; }
      .subscribe-large.red .content-left .subscribe-form .field input[type=text]:focus::-webkit-input-placeholder,
      .subscribe-large.red .content-left .subscribe-form .field input[type=email]:focus::-webkit-input-placeholder {
        color: #eb9f99; }
      .subscribe-large.red .content-left .subscribe-form .field input[type=text]:focus::-moz-placeholder,
      .subscribe-large.red .content-left .subscribe-form .field input[type=email]:focus::-moz-placeholder {
        color: #eb9f99; }
      .subscribe-large.red .content-left .subscribe-form .field input[type=text]:focus::-ms-input-placeholder,
      .subscribe-large.red .content-left .subscribe-form .field input[type=email]:focus::-ms-input-placeholder {
        color: #eb9f99; }
      .subscribe-large.red .content-left .subscribe-form .field input[type=text]:focus::placeholder,
      .subscribe-large.red .content-left .subscribe-form .field input[type=email]:focus::placeholder {
        color: #eb9f99; }
      .subscribe-large.red .content-left .subscribe-form .field input[type=text]:hover,
      .subscribe-large.red .content-left .subscribe-form .field input[type=email]:hover {
        background-color: #ad1e00; }
    .subscribe-large.red .content-left .subscribe-form .button, .subscribe-large.red .content-left .subscribe-form .submission button, .submission .subscribe-large.red .content-left .subscribe-form button {
      background-color: #d12400; }
      .subscribe-large.red .content-left .subscribe-form .button:hover, .subscribe-large.red .content-left .subscribe-form .submission button:hover, .submission .subscribe-large.red .content-left .subscribe-form button:hover {
        background-color: #8f1900; }
    .subscribe-large.red .content-left .sdks {
      border-color: #ff928a; }
      .subscribe-large.red .content-left .sdks .sdk {
        border-color: #ff928a; }
        .subscribe-large.red .content-left .sdks .sdk a:hover {
          background-color: #ff928a; }

@media (max-width: 1250px) {
  .subscribe-large {
    padding-top: 0;
    padding-bottom: 0; }
    .subscribe-large:last-child {
      padding-bottom: 0; }
    .subscribe-large > .inner {
      border-radius: 0; }
    .subscribe-large .content-left,
    .subscribe-large .content-right {
      border-radius: 0; }
    .subscribe-large .content-left .description {
      padding-right: 20px; }
    .subscribe-large .content-left .fields-inline {
      border-bottom-left-radius: 0; }
    .subscribe-large .content-left .field:first-child input[type=text],
    .subscribe-large .content-left .field:first-child input[type=email] {
      border-bottom-left-radius: 0; }
    .subscribe-large .content-left .message-response {
      border-radius: 0; }
    .subscribe-large .content-right .image {
      border-radius: 0; } }

@media (max-width: 860px) {
  .subscribe-large {
    margin-top: 40px; }
    .subscribe-large .content-left {
      width: 60%;
      padding-top: 60px;
      padding-left: 50px;
      padding-right: 60px; }
      .subscribe-large .content-left:not(.has-subscribe-form) {
        padding-bottom: 50px; }
      .subscribe-large .content-left .label {
        font-size: 15px;
        line-height: 21px; }
      .subscribe-large .content-left .title {
        font-size: 27px;
        line-height: 35px; }
      .subscribe-large .content-left .description {
        font-size: 17px;
        line-height: 25px; }
      .subscribe-large .content-left .sdks {
        margin-bottom: 50px; }
      .subscribe-large .content-left .subscribe-form .field:first-child input[type=text],
      .subscribe-large .content-left .subscribe-form .field:first-child input[type=email] {
        padding-left: 50px; }
    .subscribe-large .content-right {
      width: 40%; } }

@media (max-width: 570px) {
  .subscribe-large {
    margin-top: 0; }
    .subscribe-large.has-sdks .content-left .description {
      margin-bottom: 25px; }
    .subscribe-large.has-sdks.has-actions .sdks {
      margin-bottom: 25px; }
    .subscribe-large:not(.has-subscribe-form) .content-left {
      padding-bottom: 50px; }
    .subscribe-large > .inner > .layout {
      flex-direction: column-reverse; }
    .subscribe-large .content-left {
      width: 100%;
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 40px; }
      .subscribe-large .content-left .label {
        margin-bottom: 0; }
      .subscribe-large .content-left .title {
        font-size: 21px;
        line-height: 29px;
        margin-bottom: 12px; }
      .subscribe-large .content-left .description {
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 23px; }
      .subscribe-large .content-left .sdks {
        margin-bottom: 15px; }
      .subscribe-large .content-left .subscribe-form .field input[type=text],
      .subscribe-large .content-left .subscribe-form .field input[type=email] {
        padding-left: 30px; }
      .subscribe-large .content-left .subscribe-form .field:first-child {
        width: 180px; }
        .subscribe-large .content-left .subscribe-form .field:first-child input[type=text],
        .subscribe-large .content-left .subscribe-form .field:first-child input[type=email] {
          padding-left: 30px; }
      .subscribe-large .content-left .sdks {
        margin-bottom: 30px; }
      .subscribe-large .content-left .sdks + .actions {
        margin-top: 0; }
    .subscribe-large .content-right {
      width: 100%; }
      .subscribe-large .content-right .image {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        width: auto;
        height: 225px; } }

.subscribe-bar {
  position: relative;
  background-color: #f2f3f3;
  color: #999; }
  .subscribe-bar > .inner {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 160px;
    padding-bottom: 160px; }
  .subscribe-bar .subscribe-form-bar {
    position: relative; }
    .subscribe-bar .subscribe-form-bar .title {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      color: #999;
      font-size: 29px;
      line-height: 35px;
      font-weight: 300;
      margin-right: 93px; }
    .subscribe-bar .subscribe-form-bar form.has-response {
      overflow: hidden; }
      .subscribe-bar .subscribe-form-bar form.has-response .fields-inline {
        top: 200px; }
      .subscribe-bar .subscribe-form-bar form.has-response .action {
        pointer-events: none; }
        .subscribe-bar .subscribe-form-bar form.has-response .action .button-style {
          opacity: 0; }
      .subscribe-bar .subscribe-form-bar form.has-response .message-response {
        opacity: 1; }
    .subscribe-bar .subscribe-form-bar .fields-inline {
      background-color: transparent;
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: top;
      transition-property: top;
      position: relative;
      top: 0;
      display: flex; }
    .subscribe-bar .subscribe-form-bar .field {
      position: relative;
      flex-grow: 1; }
      .subscribe-bar .subscribe-form-bar .field:first-child {
        width: 215px;
        flex-grow: 0; }
        .subscribe-bar .subscribe-form-bar .field:first-child input[type=text],
        .subscribe-bar .subscribe-form-bar .field:first-child input[type=email] {
          padding-left: 58px; }
      .subscribe-bar .subscribe-form-bar .field + .field:before, .subscribe-bar .subscribe-form-bar .field + .field:after {
        position: absolute;
        content: '';
        display: block;
        -webkit-transform-origin: center;
                transform-origin: center;
        top: 19px;
        left: 0;
        width: 2px;
        height: 15px;
        background-color: #fff; }
      .subscribe-bar .subscribe-form-bar .field + .field:before {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg); }
      .subscribe-bar .subscribe-form-bar .field + .field:after {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
      .subscribe-bar .subscribe-form-bar .field input[type=text],
      .subscribe-bar .subscribe-form-bar .field input[type=email] {
        color: #fff;
        background-color: #1797E5;
        box-shadow: none;
        border: none;
        height: 54px;
        margin: 0;
        padding-left: 40px;
        border-radius: 0px; }
        .subscribe-bar .subscribe-form-bar .field input[type=text]::-webkit-input-placeholder,
        .subscribe-bar .subscribe-form-bar .field input[type=email]::-webkit-input-placeholder {
          color: #fff; }
        .subscribe-bar .subscribe-form-bar .field input[type=text]::-moz-placeholder,
        .subscribe-bar .subscribe-form-bar .field input[type=email]::-moz-placeholder {
          color: #fff; }
        .subscribe-bar .subscribe-form-bar .field input[type=text]::-ms-input-placeholder,
        .subscribe-bar .subscribe-form-bar .field input[type=email]::-ms-input-placeholder {
          color: #fff; }
        .subscribe-bar .subscribe-form-bar .field input[type=text]::placeholder,
        .subscribe-bar .subscribe-form-bar .field input[type=email]::placeholder {
          color: #fff; }
        .subscribe-bar .subscribe-form-bar .field input[type=text]:hover,
        .subscribe-bar .subscribe-form-bar .field input[type=email]:hover {
          background-color: #0068AD; }
    .subscribe-bar .subscribe-form-bar .content + .field input {
      border-radius: 3px 0 0 3px; }
    .subscribe-bar .subscribe-form-bar .action {
      flex-grow: 0;
      width: 54px;
      height: 54px; }
      .subscribe-bar .subscribe-form-bar .action .button-style {
        width: 100%;
        height: 100%; }
      .subscribe-bar .subscribe-form-bar .action .smooth-spinner svg {
        padding: 15px; }
    .subscribe-bar .subscribe-form-bar .button, .subscribe-bar .subscribe-form-bar .submission button, .submission .subscribe-bar .subscribe-form-bar button {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: background;
      transition-property: background;
      position: relative;
      display: block;
      background-color: #0068AD;
      border: none;
      border-radius: 0 3px 3px 0;
      width: 100%;
      height: 100%;
      text-indent: -9999;
      font-size: 0;
      line-height: 0; }
      .subscribe-bar .subscribe-form-bar .button:hover, .subscribe-bar .subscribe-form-bar .submission button:hover, .submission .subscribe-bar .subscribe-form-bar button:hover {
        background-color: #003b82; }
      .subscribe-bar .subscribe-form-bar .button:before, .subscribe-bar .subscribe-form-bar .submission button:before, .submission .subscribe-bar .subscribe-form-bar button:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        display: block;
        -webkit-mask: url(/static/media/arrow-with-stem.c018e2a8.svg) no-repeat center;
        mask: url(/static/media/arrow-with-stem.c018e2a8.svg) no-repeat center;
        background-color: #fff;
        width: 30px;
        height: 30px; }
    .subscribe-bar .subscribe-form-bar .message-alert {
      pointer-events: none;
      position: absolute;
      top: -20px;
      right: 15px;
      background-color: #fff;
      font-size: 12px;
      font-weight: 600;
      border-radius: 2px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
      padding: 5px 6px 3px 6px; }
      .subscribe-bar .subscribe-form-bar .message-alert > div {
        -webkit-transform-origin: center;
                transform-origin: center;
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
        margin-right: 3px; }
    .subscribe-bar .subscribe-form-bar .message-response {
      -webkit-transition-duration: 0.5s;
              transition-duration: 0.5s;
      -webkit-transition-property: opacity;
      transition-property: opacity;
      -webkit-transition-delay: 2s;
              transition-delay: 2s;
      position: absolute;
      left: 0;
      right: 0;
      height: 54px;
      opacity: 0;
      color: #fff;
      color: #999;
      text-align: center; }
      .subscribe-bar .subscribe-form-bar .message-response p {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        font-size: 21px;
        font-weight: 600;
        color: #666; }
        .subscribe-bar .subscribe-form-bar .message-response p small {
          display: block;
          margin-top: 2px;
          font-size: 15px;
          line-height: 21px;
          font-weight: 500;
          color: #999; }

@media (max-width: 1680px) {
  .subscribe-bar > .inner {
    padding-top: 100px;
    padding-bottom: 100px; } }

@media (max-width: 1440px) {
  .subscribe-bar > .inner {
    padding-left: 80px;
    padding-right: 80px; } }

@media (max-width: 1100px) {
  .subscribe-bar .subscribe-form-bar .title {
    font-size: 23px;
    line-height: 27px; } }

@media (max-width: 860px) {
  .subscribe-bar > .inner {
    padding-top: 50px;
    padding-bottom: 70px; }
  .subscribe-bar .subscribe-form-bar .title {
    color: #3c4a5b;
    font-weight: 400;
    font-size: 19px;
    line-height: 23px; }
  .subscribe-bar .subscribe-form-bar .fields-inline {
    flex-wrap: wrap; }
  .subscribe-bar .subscribe-form-bar .content {
    width: 100%;
    margin-bottom: 20px; } }

@media (max-width: 750px) {
  .subscribe-bar > .inner {
    padding: 50px 20px 60px 20px; }
  .subscribe-bar .subscribe-form-bar .title {
    margin: 0;
    font-size: 21px;
    line-height: 23px; }
  .subscribe-bar .subscribe-form-bar .field {
    width: 35%; }
    .subscribe-bar .subscribe-form-bar .field input[type=text],
    .subscribe-bar .subscribe-form-bar .field input[type=email] {
      padding-left: 20px; }
  .subscribe-bar .subscribe-form-bar .field + .field {
    width: 45%; }
  .subscribe-bar .subscribe-form-bar .action button:before {
    top: 13px;
    left: 13px;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); } }

.table {
  position: relative;
  display: table;
  width: 100%; }
  .table .shadow {
    pointer-events: none;
    color: #131313;
    display: block;
    position: absolute;
    height: 0;
    left: 0;
    right: 0;
    box-shadow: 4px 4px 15px 0 rgba(0, 0, 0, 0.1);
    z-index: 0; }
  .table .thead {
    display: table-row-group;
    font-family: Graphik, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 400;
    color: #a7a9ac;
    font-size: 16px;
    line-height: 19px; }
    .table .thead .th {
      position: relative;
      padding-bottom: 20px; }
      .table .thead .th.is-sortable {
        cursor: pointer; }
        .table .thead .th.is-sortable:empty:after {
          display: none; }
        .table .thead .th.is-sortable:after {
          position: relative;
          display: inline-block;
          content: '';
          width: 7px;
          height: 7px;
          -webkit-transform: rotate(-135deg);
                  transform: rotate(-135deg);
          top: 0px;
          border-right: 2px solid transparent;
          border-bottom: 2px solid transparent;
          margin-left: 8px; }
        .table .thead .th.is-sortable.js-active:after {
          border-right: 2px solid #6d6e71;
          border-bottom: 2px solid #6d6e71; }
        .table .thead .th.is-sortable.js-sort-desc:after {
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
          top: -3px; }
        .table .thead .th.is-sortable:hover {
          color: #6d6e71; }
      .table .thead .th.js-active {
        color: #6d6e71; }
  .table .tbody {
    display: table-row-group;
    position: relative;
    border-radius: 3px; }
    .table .tbody .tr.selected .td {
      color: #02173e;
      font-weight: 500;
      border-top: 2px solid #1693e1;
      border-bottom: 2px solid #1693e1; }
    .table .tbody .tr.selected .td:first-child {
      border-left: 2px solid #1693e1; }
    .table .tbody .tr.selected .td:last-child {
      border-right: 2px solid #1693e1; }
    .table .tbody .tr.selected .td:first-child {
      border-bottom-left-radius: 3px; }
    .table .tbody .tr.selected .td:last-child {
      border-bottom-right-radius: 3px; }
    .table .tbody .tr:first-child .td:first-child {
      border-top-left-radius: 3px; }
    .table .tbody .tr:first-child .td:last-child {
      border-top-right-radius: 3px; }
    .table .tbody .tr:last-child .td:first-child {
      border-bottom-left-radius: 3px; }
    .table .tbody .tr:last-child .td:last-child {
      border-bottom-right-radius: 3px; }
    .table .tbody .tr:hover .td {
      background-color: #f9f9f9; }
      .table .tbody .tr:hover .td:before {
        background-color: #F1F1F1; }
      .table .tbody .tr:hover .td:after {
        left: 0;
        right: 0; }
  .table .tbody {
    font-family: Graphik, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 400;
    box-shadow: 1px 3px 15px 0 rgba(0, 0, 0, 0.2);
    color: #808285;
    font-size: 14px;
    line-height: 17px; }
    .table .tbody:first-child {
      border-radius: 3px 3px 0 0; }
    .table .tbody:last-child {
      border-radius: 0 0 3px 3px; }
    .table .tbody .td {
      background-color: #fff; }
  .table .tr {
    display: table-row; }
  .table .th {
    display: table-cell;
    padding: 10px 23px; }
  .table .th.min,
  .table .td.min {
    width: 1%;
    white-space: nowrap; }
  .table .th.center,
  .table .td.center {
    text-align: center; }
  .table .th.center.is-sortable:before {
    position: relative;
    display: inline-block;
    content: '';
    width: 7px;
    height: 7px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    margin-right: 8px;
    top: -3px; }
  .table .td {
    position: relative;
    display: table-cell;
    padding: 20px 23px;
    vertical-align: middle; }
    .table .td:before, .table .td:after {
      position: absolute;
      content: '';
      display: block;
      height: 1px;
      background-color: #F1F1F1;
      left: 0;
      right: 0; }
    .table .td:before {
      background-color: transparent;
      top: -1px; }
    .table .td:after {
      bottom: 0; }
    .table .td:first-child:after {
      left: 23px; }
    .table .td:last-child:after {
      right: 23px; }
    .table .td .icon {
      margin-right: 5px; }
      .table .td .icon:last-child {
        margin-right: 0; }
    .table .td .icon-gold-jojo-small {
      top: -1px;
      margin-right: 8px; }
  .table .tr:first-child .td:before {
    display: none; }
  .table .tr:last-child .td:after {
    display: none; }
  .table strong {
    color: #808285; }
  .table .title {
    color: #003b82;
    font-size: 17px;
    line-height: 21px;
    margin-bottom: 8px; }
    .table .title a {
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: border;
      transition-property: border;
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid #F1F1F1; }
      .table .title a:hover {
        border-bottom: 3px solid #2eb6ef; }
    .table .title:last-child {
      margin-bottom: 0; }
    .table .title strong {
      font-family: Graphik, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
      font-weight: 400;
      color: inherit; }
  .table .small {
    color: #939598;
    font-family: Graphik, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px; }
    .table .small strong {
      font-family: Graphik, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
      font-weight: 400; }
  .table .detail {
    color: #939598;
    font-size: 12px;
    line-height: 15px;
    margin-top: 5px;
    margin-bottom: 10px; }
    .table .detail:first-child {
      margin-top: 0; }
    .table .detail:last-child {
      margin-bottom: 0; }

@media (max-width: 1440px) {
  .table .th,
  .table .td {
    padding-left: 10px;
    padding-right: 10px; }
    .table .th:first-child,
    .table .td:first-child {
      padding-left: 23px; }
    .table .th:last-child,
    .table .td:last-child {
      padding-right: 23px; } }

@media (max-width: 860px) {
  .table .td {
    vertical-align: top; } }

@media (max-width: 570px) {
  .table {
    display: block; }
    .table .shadow {
      display: none; }
    .table .thead,
    .table .tbody {
      display: block; }
    .table .tr {
      display: block; }
    .table .th,
    .table .td {
      display: block; }
    .table .thead .th {
      padding-bottom: 10px; }
    .table .tbody .tr {
      background-color: #fff;
      border-bottom: 1px solid #F1F1F1; }
      .table .tbody .tr.selected .td {
        color: #02173e;
        font-weight: 500;
        border-top: none;
        border-bottom: none; }
      .table .tbody .tr.selected .td:first-child {
        border-left: none; }
      .table .tbody .tr.selected .td:last-child {
        border-right: none; }
      .table .tbody .tr.selected .td:first-child {
        border-bottom-left-radius: 3px; }
      .table .tbody .tr.selected .td:last-child {
        border-bottom-right-radius: 3px; }
    .table .tr {
      padding: 20px; }
    .table .th {
      padding: 0; }
      .table .th:first-child {
        padding-left: 0; }
      .table .th:last-child {
        padding-right: 0; }
    .table .td {
      position: relative;
      padding: 0;
      margin-bottom: 10px; }
      .table .td:after {
        display: none; }
      .table .td:first-child {
        padding-left: 0; }
      .table .td:last-child {
        padding-right: 0; }
      .table .td.phone-half {
        display: inline-block !important; }
        .table .td.phone-half + .phone-half {
          padding-left: 20px; }
      .table .td .accepts {
        position: absolute;
        top: 0;
        right: 0; } }

.try-dragonchain {
  position: relative;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #0f2549; }
  .try-dragonchain > .inner {
    display: flex; }
  .try-dragonchain .try-content {
    padding: 180px 80px 50px 200px;
    width: 50%;
    color: #fff; }
    .try-dragonchain .try-content .title {
      font-size: 41px;
      line-height: 51px;
      font-weight: 600;
      margin-bottom: 30px; }
      .try-dragonchain .try-content .title.large {
        font-size: 74px;
        line-height: 90px;
        margin-top: -55px; }
    .try-dragonchain .try-content .description {
      color: #3fccfe;
      font-size: 19px;
      line-height: 31px;
      font-weight: 400;
      padding-right: 45px;
      margin-bottom: 40px; }
      .try-dragonchain .try-content .description p {
        margin-bottom: 25px; }
        .try-dragonchain .try-content .description p strong:first-child {
          color: #fff;
          font-weight: 600; }
      .try-dragonchain .try-content .description a:not(.button) {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: all;
        transition-property: all;
        box-shadow: #3fccfe 0px -2px 0px 0px inset;
        color: inherit;
        text-decoration: none;
        color: #fff;
        text-decoration: none;
        padding-bottom: 2px; }
        .try-dragonchain .try-content .description a:not(.button):hover {
          box-shadow: #f34322 0px -2px 0px 0px inset; }
    .try-dragonchain .try-content .actions ul {
      margin-top: 30px;
      flex-wrap: wrap; }
      .try-dragonchain .try-content .actions ul li {
        display: inline-block;
        margin-right: 14px;
        margin-bottom: 12px; }
        .try-dragonchain .try-content .actions ul li:last-child {
          margin-right: 0; }
  .try-dragonchain .try-interactive {
    width: 50%;
    padding-top: 100px;
    padding-bottom: 100px; }
  .try-dragonchain .try-code {
    position: relative;
    background-color: #f9fbff;
    border-radius: 3px 0 0 3px;
    box-shadow: 3px 10px 35px 0 rgba(0, 0, 0, 0.5);
    font-style: 16px;
    line-height: 21px;
    font-weight: 500;
    color: #08152c; }
    .try-dragonchain .try-code .flag {
      pointer-events: none;
      position: absolute;
      top: -32px;
      left: 40px; }
    .try-dragonchain .try-code .languages ul {
      display: table; }
    .try-dragonchain .try-code .languages li {
      display: table-cell; }
      .try-dragonchain .try-code .languages li:first-child a,
      .try-dragonchain .try-code .languages li:first-child .button,
      .try-dragonchain .try-code .languages li:first-child .submission button,
      .submission .try-dragonchain .try-code .languages li:first-child button {
        border-radius: 3px 0 0 0; }
    .try-dragonchain .try-code .languages a,
    .try-dragonchain .try-code .languages button {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s;
      -webkit-transition-property: background;
      transition-property: background;
      background-color: #f9fbff;
      color: #08152c;
      text-decoration: none;
      display: block;
      padding: 30px 45px;
      font-size: 16px; }
      .try-dragonchain .try-code .languages a:hover span,
      .try-dragonchain .try-code .languages button:hover span {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: all;
        transition-property: all;
        box-shadow: #dc2800 0px -2px 0px 0px inset;
        color: inherit;
        text-decoration: none; }
        .try-dragonchain .try-code .languages a:hover span:hover,
        .try-dragonchain .try-code .languages button:hover span:hover {
          box-shadow: #dc2800 0px -2px 0px 0px inset; }
      .try-dragonchain .try-code .languages a:hover.active span,
      .try-dragonchain .try-code .languages button:hover.active span {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: all;
        transition-property: all;
        box-shadow: #1693e1 0px -2px 0px 0px inset;
        color: inherit;
        text-decoration: none; }
        .try-dragonchain .try-code .languages a:hover.active span:hover,
        .try-dragonchain .try-code .languages button:hover.active span:hover {
          box-shadow: #1693e1 0px -2px 0px 0px inset; }
      .try-dragonchain .try-code .languages a span,
      .try-dragonchain .try-code .languages button span {
        -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
        -webkit-transition-property: all;
        transition-property: all;
        box-shadow: transparent 0px -2px 0px 0px inset;
        color: inherit;
        text-decoration: none;
        padding-bottom: 4px;
        padding-left: 1px;
        padding-right: 2px; }
        .try-dragonchain .try-code .languages a span:hover,
        .try-dragonchain .try-code .languages button span:hover {
          box-shadow: #dc2800 0px -2px 0px 0px inset; }
      .try-dragonchain .try-code .languages a.active,
      .try-dragonchain .try-code .languages button.active {
        background-color: #fff; }
        .try-dragonchain .try-code .languages a.active span,
        .try-dragonchain .try-code .languages button.active span {
          -webkit-transition-duration: 0.2s;
                  transition-duration: 0.2s;
          -webkit-transition-property: all;
          transition-property: all;
          box-shadow: #2eb6ef 0px -2px 0px 0px inset;
          color: inherit;
          text-decoration: none; }
          .try-dragonchain .try-code .languages a.active span:hover,
          .try-dragonchain .try-code .languages button.active span:hover {
            box-shadow: #1693e1 0px -2px 0px 0px inset; }
    .try-dragonchain .try-code .try-transition > .inner {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto; }
    .try-dragonchain .try-code .try-transition .post-illustration {
      max-width: 310px;
      margin-left: auto;
      margin-right: auto; }
    .try-dragonchain .try-code .try-transition .alert {
      margin-left: auto;
      margin-right: auto;
      max-width: 300px;
      text-align: center;
      padding-left: 15px;
      padding-right: 15px; }
    .try-dragonchain .try-code .actions {
      margin-top: 35px;
      margin-bottom: 35px; }
      .try-dragonchain .try-code .actions ul {
        display: table; }
      .try-dragonchain .try-code .actions li {
        display: table-cell;
        vertical-align: middle; }
        .try-dragonchain .try-code .actions li:not(.instructions) {
          white-space: nowrap; }
      .try-dragonchain .try-code .actions .instructions {
        color: #939598;
        font-size: 14px;
        line-height: 19px;
        font-weight: 400;
        padding-left: 20px;
        max-width: 400px;
        padding-top: 3px; }
  .try-dragonchain .try-step {
    padding: 18px 90px 18px 40px; }

@media (max-width: 1440px) {
  .try-dragonchain .try-content {
    padding-top: 160px;
    padding-left: 140px; }
    .try-dragonchain .try-content .title {
      font-size: 37px;
      line-height: 43px;
      padding-right: 0; }
    .try-dragonchain .try-content .description {
      padding-right: 10px; } }

@media (max-width: 1100px) {
  .try-dragonchain > .inner {
    flex-wrap: wrap; }
  .try-dragonchain .try-content {
    width: 100%;
    padding: 70px 80px 0 80px; }
    .try-dragonchain .try-content .title {
      font-size: 36px;
      line-height: 42px;
      font-weight: 600;
      margin-bottom: 10px; }
    .try-dragonchain .try-content .description {
      margin-bottom: 0;
      padding-right: 80px;
      font-size: 17px;
      line-height: 27px; }
      .try-dragonchain .try-content .description p:last-child {
        margin-bottom: 0; }
  .try-dragonchain .try-interactive {
    width: 100%; }
    .try-dragonchain .try-interactive > .inner {
      padding-left: 80px;
      padding-right: 80px; }
  .try-dragonchain .try-code {
    border-radius: 3px; }
    .try-dragonchain .try-code .alert {
      font-size: 14px; }
    .try-dragonchain .try-code .languages a,
    .try-dragonchain .try-code .languages button {
      padding: 24px 45px;
      font-size: 15px; } }

@media (max-width: 860px) {
  .try-dragonchain .try-content {
    padding-left: 50px;
    padding-right: 50px; }
    .try-dragonchain .try-content .title {
      font-size: 31px;
      line-height: 36px; }
    .try-dragonchain .try-content .description {
      font-size: 16px;
      line-height: 27px; }
      .try-dragonchain .try-content .description p {
        margin-bottom: 20px; }
  .try-dragonchain .try-interactive {
    padding-top: 80px; }
    .try-dragonchain .try-interactive > .inner {
      padding-left: 50px;
      padding-right: 50px; }
  .try-dragonchain .try-code .flag {
    top: -24px;
    left: 30px; } }

@media (max-width: 570px) {
  .try-dragonchain .try-content {
    padding: 50px 30px; }
    .try-dragonchain .try-content .title {
      font-size: 29px;
      line-height: 33px;
      font-weight: 600;
      padding-right: 0; }
    .try-dragonchain .try-content .description {
      padding-right: 0;
      font-size: 16px;
      line-height: 27px; }
  .try-dragonchain .try-interactive {
    padding: 30px 0 50px 0; }
    .try-dragonchain .try-interactive > .inner {
      padding-left: 7px;
      padding-right: 7px; }
  .try-dragonchain .try-code .flag {
    left: 15px; }
  .try-dragonchain .try-code .languages a,
  .try-dragonchain .try-code .languages button {
    padding: 24px 30px; }
  .try-dragonchain .try-code .try-step {
    padding-left: 15px;
    padding-right: 15px; }
  .try-dragonchain .try-code code {
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px; }
  .try-dragonchain .try-code .actions {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -18px;
    padding: 25px 21px 15px 21px;
    background-color: #f1f3f8;
    border-radius: 0 0 3px 3px; }
    .try-dragonchain .try-code .actions ul {
      display: block; }
      .try-dragonchain .try-code .actions ul li {
        display: block;
        margin-bottom: 20px; }
        .try-dragonchain .try-code .actions ul li:last-child {
          margin-bottom: 0; }
        .try-dragonchain .try-code .actions ul li.instructions {
          padding: 0 0 10px 0;
          color: #8494a5; } }

.sandbox-video-container {
  max-width: 800px;
  margin-left: 50px; }

.contents-60-40.clouds .content-60 .video-player {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3); }

.video-player {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }
  .video-player:hover .video-play-button {
    background-color: #1693e1; }
  .video-player .video-poster,
  .video-player .video-play-button {
    cursor: pointer;
    -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
    -webkit-transition-property: opacity background-color;
    transition-property: opacity background-color;
    opacity: 1; }
  .video-player.js-played .video-poster,
  .video-player.js-played .video-play-button {
    cursor: default;
    pointer-events: none;
    opacity: 0; }
  .video-player > .inner {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .video-player .video-poster {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #02173e;
    background-size: cover; }
  .video-player .video-play-button {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 74px;
    height: 74px;
    background-color: #f34322; }
  .video-player .video-play-button-icon {
    position: absolute;
    left: calc(50% - 11px);
    top: calc(50% - 13px);
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    width: 26px;
    height: 26px; }
  .video-player .video-play-button-icon-triangle {
    position: relative;
    left: 6px;
    top: 7px;
    background-color: #fff;
    text-align: left; }
    .video-player .video-play-button-icon-triangle:before, .video-player .video-play-button-icon-triangle:after {
      content: '';
      position: absolute;
      background-color: inherit; }
    .video-player .video-play-button-icon-triangle, .video-player .video-play-button-icon-triangle:before, .video-player .video-play-button-icon-triangle:after {
      width: 13px;
      height: 13px;
      border-top-right-radius: 15%; }
    .video-player .video-play-button-icon-triangle {
      -webkit-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
              transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866); }
    .video-player .video-play-button-icon-triangle:before {
      -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
              transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%); }
    .video-player .video-play-button-icon-triangle:after {
      -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
              transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%); }
  .video-player .video-holder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important; }
    .video-player .video-holder > div {
      width: 100% !important;
      height: 100% !important; }

@media (max-width: 860px) {
  .video-player .video-play-button {
    width: 50px;
    height: 50px; }
  .video-player .video-play-button-icon {
    left: calc(50% - 13px); }
  .video-player .video-play-button-icon-triangle {
    left: 8px;
    top: 8px; }
    .video-player .video-play-button-icon-triangle, .video-player .video-play-button-icon-triangle:before, .video-player .video-play-button-icon-triangle:after {
      width: 9px;
      height: 9px; } }

.formBase.hasName #root_submittor {
  display: none; }

.formBase.notQualified #root_project {
  display: none; }

.formBase.notQualified #root_team {
  display: none; }

label {
  font-weight: 300; }

.locked {
  position: fixed;
  top: -1000px;
  left: -1000px; }

input[type=text],
input[type=email],
input[type=password],
input[type=url],
textarea {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: all;
  transition-property: all;
  font-family: Graphik, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  display: block;
  width: 100%;
  border: 1px solid #d6dbdf;
  background-color: #fff;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.05);
  font-size: 15px;
  line-height: 19px;
  padding: 11px 15px 10px 15px;
  margin: 0 0 12px 0;
  color: #131313;
  border-radius: 3px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }
  input[type=text]::-webkit-input-placeholder,
  input[type=email]::-webkit-input-placeholder,
  input[type=password]::-webkit-input-placeholder,
  input[type=url]::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #939598; }
  input[type=text]::-moz-placeholder,
  input[type=email]::-moz-placeholder,
  input[type=password]::-moz-placeholder,
  input[type=url]::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #939598; }
  input[type=text]::-ms-input-placeholder,
  input[type=email]::-ms-input-placeholder,
  input[type=password]::-ms-input-placeholder,
  input[type=url]::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    color: #939598; }
  input[type=text]::placeholder,
  input[type=email]::placeholder,
  input[type=password]::placeholder,
  input[type=url]::placeholder,
  textarea::placeholder {
    color: #939598; }
  input[type=text]:hover,
  input[type=email]:hover,
  input[type=password]:hover,
  input[type=url]:hover,
  textarea:hover {
    border-color: #939598; }
  input[type=text]:focus,
  input[type=email]:focus,
  input[type=password]:focus,
  input[type=url]:focus,
  textarea:focus {
    -webkit-transition-duration: 0.1s;
            transition-duration: 0.1s;
    border-color: #1693e1; }

textarea {
  height: 180px;
  line-height: 27px;
  margin: 0;
  max-width: 100%; }

select {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: all;
  transition-property: all;
  font-family: Graphik, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  margin-top: 5px;
  display: block;
  width: 100%;
  max-width: 300px;
  height: 45px;
  padding: 0.5rem 0.75rem;
  text-transform: capitalize;
  outline: 0;
  border-radius: 3px;
  border: 1px solid #d6dbdf;
  background-color: #fff;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.05);
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: -webkit-linear-gradient(45deg, transparent 50%, #808285 50%), -webkit-linear-gradient(315deg, #808285 50%, transparent 50%);
  background-image: linear-gradient(45deg, transparent 50%, #808285 50%), linear-gradient(135deg, #808285 50%, transparent 50%);
  background-position: calc(100% - 22px) 20px, calc(100% - 17px) 20px;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat; }
  select:hover {
    border-color: #939598; }
  select:focus {
    border-color: #1693e1; }
  select::-webkit-input-placeholder {
    content: 'Please choose...'; }
  select::-moz-placeholder {
    content: 'Please choose...'; }
  select::-ms-input-placeholder {
    content: 'Please choose...'; }
  select::placeholder {
    content: 'Please choose...'; }

.message-alert {
  display: flex;
  padding-top: 23px;
  padding-left: 5px;
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
  color: #6D6E71;
  vertical-align: middle; }
  .message-alert div {
    position: relative;
    top: -2px;
    margin-right: 11px; }

@media (max-width: 860px) {
  input[type=text],
  input[type=email],
  input[type=password],
  input[type=url],
  textarea {
    padding: 11px 11px 10px 11px; }
  select {
    height: 41px;
    background-position: calc(100% - 22px) 17px, calc(100% - 17px) 17px; } }

@media (max-width: 570px) {
  select {
    max-width: 100%; } }

.smooth-spinner svg {
  -webkit-animation: smooth-spinner-rotate 2s linear infinite;
          animation: smooth-spinner-rotate 2s linear infinite;
  z-index: 2;
  position: relative;
  width: 100%;
  height: 100%; }
  .smooth-spinner svg .path {
    stroke: #93bfec;
    stroke-linecap: round;
    -webkit-animation: smooth-spinner-dash 1.5s ease-in-out infinite;
            animation: smooth-spinner-dash 1.5s ease-in-out infinite; }

.smooth-spinner.blue .path {
  stroke: #1693e1; }

.smooth-spinner.blue_light .path {
  stroke: #2eb6ef; }

.smooth-spinner.blue_med .path {
  stroke: #003b82; }

.smooth-spinner.blue_dark .path {
  stroke: #02173e; }

.smooth-spinner.blue_black .path {
  stroke: #08152c; }

.smooth-spinner.red .path {
  stroke: #dc2800; }

.smooth-spinner.grey .path {
  stroke: #939598; }

.smooth-spinner.grey_med .path {
  stroke: #808285; }

.smooth-spinner.grey_light .path {
  stroke: #e6e7e8; }

@-webkit-keyframes smooth-spinner-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes smooth-spinner-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes smooth-spinner-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124; } }

@keyframes smooth-spinner-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35; }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124; } }

.switch-nav {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  margin-top: 20px;
  margin-bottom: 20px; }
  .switch-nav a,
  .switch-nav button {
    cursor: pointer;
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    line-height: 19px;
    border: 1px solid #F1F1F1;
    border-right: none;
    padding: 12px 25px;
    margin: 0;
    background-color: transparent; }
    .switch-nav a:first-child,
    .switch-nav button:first-child {
      border-radius: 40px 0 0 40px;
      padding-left: 30px; }
    .switch-nav a:last-child,
    .switch-nav button:last-child {
      border-radius: 0 40px 40px 0;
      border-right: 1px solid #F1F1F1;
      padding-right: 30px; }
  .switch-nav.blue_on_blue a,
  .switch-nav.blue_on_blue button {
    border-color: #5DC5F9;
    background-color: transparent;
    color: #5DC5F9; }
    .switch-nav.blue_on_blue a.white,
    .switch-nav.blue_on_blue button.white {
      color: #fff; }
    .switch-nav.blue_on_blue a.js-active,
    .switch-nav.blue_on_blue button.js-active {
      background-color: #fff;
      border-color: #fff;
      color: #1693e1; }
      .switch-nav.blue_on_blue a.js-active.white,
      .switch-nav.blue_on_blue button.js-active.white {
        color: #1693e1; }
    .switch-nav.blue_on_blue a:focus,
    .switch-nav.blue_on_blue button:focus {
      outline: 0; }
    .switch-nav.blue_on_blue a.js-active + button,
    .switch-nav.blue_on_blue button.js-active + button {
      border-left-color: #fff; }

@media (max-width: 860px) {
  .switch-nav {
    margin-top: 15px;
    margin-bottom: 15px; }
    .switch-nav a,
    .switch-nav button {
      font-size: 14px;
      line-height: 17px;
      padding: 10px 20px; } }

/*! Flickity v2.2.0
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

