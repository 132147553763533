// Submission

.submission{
  border-radius:3px;
  background-color:$white;
  box-shadow:1px 3px 15px 0 rgba(0,0,0,0.2);
  margin-bottom:70px;
  
  & > .inner{
    padding:20px 20px 30px 20px;
  }
  
  fieldset{
    position:relative;
    border:1px solid $line-color;
    padding:80px 20px 30px 30px;
    margin-bottom:25px;
    border-radius:3px;
    
    & > legend{
      position:absolute;
      top:25px;
      left:30px;
      color:$blue;
      font-size:23px;
      line-height:29px;
      font-weight:600;
      margin-bottom:5px;
      background-color:transparent;
    }
    
    &#root{
      border:none;
      padding:0;
      
      &:before{
        display:none;
      }
      
      & > legend{
        display:none;
      }
    }
    
    .form-group{
      margin-bottom:20px;
      
      &:last-child{
        margin-bottom:0;
      }
      
      label{
        display:block;
        font-size:15px;
        line-height:21px;
        font-weight:400;
        margin-bottom:7px;
      }
    }
    
    fieldset{
      transition-duration:0.2s;
      transition-property:border;
      
      &:hover{
        border-color:$input-border-color;
      }
    }

    .field-radio-group{
      margin-top:5px;
      
      .radio{
        display:inline-block;
        margin-right:13px;
        
        label{
          transition-duration:0.2s;
          transition-property:border;
          
          display:block;
          border:1px solid $grey-light;
          padding:3px 14px 8px 12px;
          border-radius:20px;
          
          &:hover{
            border-color:$input-border-hover-color;
          }
          
          &:focus{
            border-color:$input-border-focus-color;
          }
        }
        
        input{
          margin-left:0;
          margin-right:8px;
        }
        
        span{
          span{
            font-size:13px;
            line-height:19px;
            font-weight:500;
            text-transform:capitalize;
            position:relative;
            vertical-align:middle;
            top:2px;
          }
        }
      }
    }
  }
  
  button{
    @extend .button;
    
    transform-origin:0 0;
    transform:scale(0.85);
    
    &:hover{
      border-color:$blue-dark;
      background-color:$blue-dark;
    }
  }
  
  // Nested Add/Remove Fieldset
  fieldset.field-array-of-object{
    border:none;
    padding:0;
    
    &:last-of-type{
      margin-bottom:0;
      
    }
    
    fieldset{
      padding:0;
      border:none;
    }
    
    .array-item-list{
      border:1px solid $line-color;
      border-radius:3px;
      padding:30px 30px 20px 30px;
      margin-bottom:30px;
      
      &:empty{
        display:none;
      }
      
      .array-item{
        border-bottom:5px solid $line-color;
        padding-bottom:20px;
        margin-bottom:30px;
        
        &:last-child{
          border-bottom:none;
          padding-bottom:0;
          margin-bottom:0;
        }
      }
    }
    
    .array-item-toolbox{
      position:relative;
      margin-top:-10px;
    }
  }
  
  .array-item-add button,
  button.array-item-remove{
    transform-origin:0 0;
    transform:scale(0.8);
    display:inline-block;
    
    &:before{
      position:relative;
      font-size:15px;
      line-height:24px;
      font-weight:500;
    }
  }
  
  .array-item{
    .btn-group{
      display:block !important;
    }
  }
  
  .array-item-add{
    button{
      &:before{
        content:'\002B\00a0 Add';
      }
    }
  }
  
  button.array-item-remove{
    padding-left:22px !important;
    padding-right:22px !important;
    
    &:before{
      content:'\2212\00a0 Remove';
    }
  }
}

@include breakpoint($tablet-portrait){
  .submission{
    margin-top:-10px;
    margin-left:-20px;
    margin-right:-20px;
    margin-bottom:0;
    box-shadow:0 0 0 0 rgba(0,0,0,0);
    
    fieldset{
      padding:70px 20px 20px 20px;
      
      & > legend{
        left:20px;
        font-size:20px;
      }
    }
  }
}

@include breakpoint($phone){
  .submission{
    box-shadow:0 0 0 0 rgba(0,0,0,0);
    margin-top:-20px;
    margin-left:-20px;
    margin-right:-20px;
    margin-bottom:0;
    
    & > .inner{
      padding:0;
    }
    
    .formbase{
      & > p{
        padding-left:17px;
      }
    }
    
    fieldset{
      padding:60px 17px 17px 17px;
      
      & > legend{
        top:15px;
        left:17px;
        font-size:19px;
      }
      
      &#root{
        margin-bottom:0;
      }
      
      .form-group{
        label{
          font-size:14px;
        }
      }
      
      .field-radio-group{
        .radio{
          label{
            padding-bottom:5px;
          }
        }
      }
    }
    
    fieldset.field-array-of-object{
      .array-item-list{
        padding:15px 15px 10px 15px;
      }
    }
    
    .message-alert{
      padding-left:17px;
    }
  }
}
