// Header

header.header{
  position:absolute;
  top:0;
  left:0;
  right:0;
  color:$black;
  
  .logo{
    position:absolute;
    z-index:1;
    font-weight:700;
    width:220px;
    height:40px;
    left:139px;
    top:57px;    
  }
  
  &.dark {
    .logo-text-color {
      fill:$white !important;
    }
    
    a{
      color:$white;
    }
  }
  
  &.light {
    .logo-text-color {
      fill:#231F20 !important;
    }
    
    a:not(.button){
      color:#231F20;
    }
  }
}

@include breakpoint($desktop){
  header.header{
    .logo{
      left:100px;
      top:46px;
    }
  }
}

@include breakpoint($laptop){
  header.header{
    .logo{
      left:85px;
      top:38px;
    }
  }
}

@include breakpoint($laptop-tablet){
  header.header{
    .logo{
      left:70px;
      top:38px;
      
      transform:scale(0.8);
      transform-origin:0 0;
    }
  }
}

@include breakpoint($tablet-landscape){
  header.header{
    .logo{
      left:50px;
      top:33px;
    }
  }
}

@include breakpoint($tablet-portrait){
  header.header{
    .logo{
      top:35px;
      transform:scale(0.7);
    }
  }
}

@include breakpoint($phone){
  header.header{
    .logo{
      margin-top:23px;
      position:relative;
      top:auto;
      left:auto;
      margin-left:30px;
      margin-bottom:20px;
    }
  }
}
