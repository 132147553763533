// Reset
html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent;}body{line-height:1;}article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary{display:block;}nav ul{list-style:none;}blockquote,q{quotes:none;}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none;}a{margin:0;padding:0;border:0;font-size:100%;vertical-align:baseline;background:transparent;}ins{background-color:#ff9;color:#000;text-decoration:none;}mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold;}del{text-decoration:line-through;}abbr[title],dfn[title]{border-bottom:1px dotted #000;cursor:help;}table{border-collapse:collapse;border-spacing:0;}hr{display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0;}input,select{vertical-align:middle;}


html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body{
  @include font-sans;
  font-size:15px;
  line-height:22px;
  color:#000;
  @include bg('../images/texture/body-white-bg.jpg',
              '../images/texture/body-white-bg@2x.jpg');
  background-color:#f1f1f1;
  background-size:100% auto;
  background-repeat:no-repeat;
  
  &.white {
    @include bg('../images/texture/body-white-bg.jpg',
              '../images/texture/body-white-bg@2x.jpg');
    background-color:#f1f1f1;

  }

  &.blue {
    background-image:url(../images/texture/body-blue-bg@2x.jpg);
    background-color:#00aed5;

  }

  &.blue-dark {
    background-image:url(../images/texture/body-blue-dark-bg@2x.jpg);
    background-color:#203455;

  }

}

.all{
  max-width:1780px;
  margin-left:auto;
  margin-right:auto;
  padding:50px;
  
  & > .inner{
    position:relative;
    
    background-color:$white;
    box-shadow:0 0 15px 0 rgba(0,0,0,0.15);
    
  }

  &.is-minimalist{
    position:absolute;
    left:0;
    right:0;
    top:50px;
    bottom:0;
    
    .header{
      .logo{
        left:0;
        top:15px;
        transform:scale(0.8);
      }
      
      .nav{
        display:none;
      }
    }
    
    padding-top:0px;
    padding-bottom:0px;
    
    & > .inner{
      position:relative;
      width:100%;
      height:100%;
    }
  }

  &.is-authentication{
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;

    & > .inner{
      position:relative;
      background-color:transparent;
      
    }
  }
}

li{
  list-style:none;
}

@include breakpoint($laptop){
  .all{
    padding:35px;
  }
}

@include breakpoint($laptop-tablet){
  .all{
    padding:30px;
  }
}

@include breakpoint($tablet-landscape){
  .all{
    padding:0;

    &.is-minimalist{
      top:0;
    }
  }
}
