@mixin bg($bg_image, $bg_image_retina){
  background-image:url($bg_image);
  background-position:center 0;
  background-repeat:no-repeat;
  background-size:cover;
  
  @media (min--moz-device-pixel-ratio: 1.5),
  (-o-min-device-pixel-ratio: 3/2),
  (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 1.5dppx){
    &{
      background-image:url($bg_image_retina);
    }
  }
}