// Partials / Subnav

.nav{
  // position:relative;
  z-index:2;

  & > ul > li{
    &:hover{
      .subnav{
        pointer-events:auto;

        & > .inner{
          transition-duration:0.4s;
          top:20px;
          opacity:1;
        }
      }
    }

    &.js-subnav-closed{
      .subnav{
        pointer-events:none;

        & > .inner{
          transition-duration:0.2s;
          top:30px;
          opacity:0;
        }
      }
    }
  }
}

.subnav{
  position:absolute;
  top:30px;
  left:50%;
  transform:translateX(-50%);
  pointer-events:none;

  &.is-mega-menu{
    & > .inner{
      & > .layout{
        display:flex;
        width:700px;
      }
    }
  }

  & > .inner{
    transition-duration:0.2s;
    transition-property:all;
    position:relative;
    top:30px;
    opacity:0;
    background-color:$white;
    border-radius:3px;
    box-shadow:0 5px 30px 0 rgba(0,0,0,0.45);

    &:before{
      content:'';
      position:absolute;
      left:50%;
      top:-7px;
      transform:translateX(-50%);
      display:block;

      border-color: $white transparent;
      border-style: solid;
      border-width: 0px 7px 7px 7px;
      height: 0px;
      width: 0px;
      
    }

    & > .layout{
      display:flex;
      width:auto;
    }
  }

  .subnav-links{
    width:55%;
    padding-top:30px;
    padding-bottom:25px;

    &:only-child{
      width:100%;
      padding-top:15px;
      padding-bottom:10px;

      .inner{
        padding-top:5px;
        padding-bottom:5px;
      }

      .content{
        padding-left:28px;
        padding-right:28px;

        .title{
          white-space:nowrap;
        }
      }
    }

    .inner{
      display:flex;
      padding-top:15px;
      padding-bottom:15px;
    }

    a{
      text-decoration:none;

      &:hover{
        .icon{
          svg{
            fill:$blue;
          }
        }
        .title{
          color:$blue;
        }
      }
    }

    .icon{
      display:flex;
      flex-shrink:0;
      align-items:center;
      width:75px;

      & > div{
        position:relative;
        top:-3px;
        display:block;
        max-width:40px;
        margin-left:auto;
        margin-right:auto;
      }

      .isvg{
        display: block;
        margin-left:auto;
        margin-right:auto;
        width:40px;
        height:40px;
        fill:$blue-grey-med;
      }
    }

    .content{
      color:$blue-grey-med;
      font-size:13px;
      line-height:19px;
      font-weight:400;
      padding-right:35px;

      .title{
        display:inline-block;
        color:$blue-black;
        font-size:15px;

        font-weight:500;
      }
    }
  }

  .subnav-content{
    display:flex;
    align-items:center;
    width:45%;
    text-align:center;
    color:$white;

    background: rgb(23,151,229);
    background: -moz-linear-gradient(-45deg, rgba(23,151,229,1) 0%, rgba(7,118,196,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(23,151,229,1) 0%,rgba(7,118,196,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(23,151,229,1) 0%,rgba(7,118,196,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    padding:40px;
    border-radius:0 3px 3px 0;

    .title-icon{
      display:block;
      width:40px;
      height:40px;
      margin-bottom:10px;
      margin-left:auto;
      margin-right:auto;

      svg{
        width:40px;
        height:40px;
        fill:$white;
      }
    }

    .title{
      color:$blue-light-on-blue;
      font-size:19px;
      line-height:25px;
      margin-bottom:10px;
    }

    .content{
      font-size:13px;
      line-height:21px;
      margin-bottom:30px;
      padding-left:3px;
      padding-right:3px;
    }

    .sdks{
      margin-left:auto;
      margin-right:auto;
    }
  }
}

@include breakpoint($tablet-landscape){
  .subnav{
    &.is-mega-menu{
      .subnav-links{
        padding-top:10px;
        padding-bottom:10px;
      }
    }

    .subnav-links{
      width:55%;
      padding-top:30px;
      padding-bottom:25px;
  
      &:only-child{
        
        .content{
          padding-left:20px;
          padding-right:20px;
        }
      }

      .content{
        .title{
          font-size:13px;
        }
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .subnav{
    &.is-mega-menu{
      & > .inner{
        background-color:transparent;
        border-radius:0;
        box-shadow:none;

        & > .layout{
          width:600px;
          background-color:$white;
          border-radius:3px;
          box-shadow:0 5px 30px 0 rgba(0,0,0,0.45);
          transform:translateX(60px);
        }
      }
    }
  }
}

@include breakpoint($phone){
  .subnav{
    display:none;
  }
}
