// Flag
.flag{
  @include font-condensed;
  
  position:relative;
  left:2px;
  
  font-size:22px;
  line-height:25px;
  text-transform:uppercase;
  margin-bottom:15px;
  
  &.large{
    font-size:27px;
    line-height:33px;
    text-rendering:geometricPrecision;
    margin-bottom:27px;
    
    & > .inner{
      padding:2px 10px 7px 10px;
    }
    
    &:after{
      bottom:-18px;
      border-top: 18px solid $blue;
    }
  }
  
  & > .inner{
    display:inline-block;
    padding:0px 8px 5px 8px;
    background-color:$blue-light;
    color:$white;
  }
  
  &:after{
    position:absolute;
    pointer-events:none;
    content:'';
    width: 0;
    height: 0;
    left:0;
    bottom:-13px;
    border-top: 13px solid $blue;
    border-right: 8px solid transparent;
  }
}


@include breakpoint($desktop){
  .flag{
    &.large{
      font-size:26px;
      line-height:31px;
      margin-bottom:24px;
    
      & > .inner{
        padding:1px 10px 6px 10px;
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .flag{
    &.large{
      font-size:22px;
      line-height:25px;
      margin-bottom:25px;
    
      & > .inner{
        // padding:0px 8px 5px 8px;
        padding:2px 8px 5px 8px;
        border-radius:2px 2px 2px 0
      }
    }
  }
}
