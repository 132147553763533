// Forms / Loaders

.smooth-spinner{
  svg{
    animation: smooth-spinner-rotate 2s linear infinite;
    z-index:2;
    position:relative;
    width:100%;
    height:100%;
    
    & .path {
      stroke: hsl(210, 70, 75);
      stroke-linecap: round;
      animation: smooth-spinner-dash 1.5s ease-in-out infinite;
    }
  }
  
  &.blue{
    & .path {
      stroke: $blue;
    }
  }

  &.blue_light{
    & .path {
      stroke: $blue-light;
    }
  }

  &.blue_med{
    & .path {
      stroke: $blue-med;
    }
  }

  &.blue_dark{
    & .path {
      stroke: $blue-dark;
    }
  }

  &.blue_black{
    & .path {
      stroke: $blue-black;
    }
  }

  &.red{
    & .path {
      stroke: $red;
    }
  }

  &.grey{
    & .path {
      stroke: $grey;
    }
  }

  &.grey_med{
    & .path {
      stroke: $grey-med;
    }
  }

  &.grey_light{
    & .path {
      stroke: $grey-light;
    }
  }

}

@keyframes smooth-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes smooth-spinner-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
