// Partials / Hero Jojo

.hero-jojo{
  background-size:cover;
  
  & > .inner{
    position:relative;
    padding:240px 160px 200px 140px;
    display:flex;
  }
  
  .jojo{
    position:absolute;
    left:150px;
    width:400px;
    padding-top:30px;
    
    & > .inner{
      position:relative;
      left:-30px;
      display:block;
      
      // JoJo Ledger
      // background-image:url(../../assets/images/brand/jojo-small.svg);
      // END JoJo Ledger

      // JoJo Treasure
      background-image:url(../../assets/images/brand/jojo-treasure.png);
      background-size:100% auto;
      // END JoJo Treasure

      width:400px;
      height:400px;
    }
  }
  
  .content{
    flex-grow:1;
    max-width:780px;
    margin-left:440px;
    
    & > .inner{
      padding-top:90px;
      padding-left:40px;
    }
    
    .title{
      @extend %title;
      display:inline-block;
      vertical-align:baseline;
      color:$blue-dark;
      font-size:70px;
      line-height:74px;
      position:relative;
      left:-2px;
      margin-right:10px;
      margin-bottom:20px;

      &.medium{
        font-size:75px;
        line-height:79px;
        max-width:620px;
      }
      
      &.large{
        font-size:106px;
        line-height:115px;
      }

      &.small{
        font-size:65px;
        line-height:69px;
      }

    }
    
    .description{
      @extend %description;
      font-size:20px;
      line-height:31px;
      color:$grey;
      padding-right:100px;
      margin-bottom:50px;
    }
    
    .actions{
      li{
        list-style:none;
        display:inline-block;
        margin-right:10px;
        margin-bottom:13px;
        
        &:last-child{
          margin-right:0;
        }
      }
    }
  }
}

@include microsoft-edge-or-ie {
  // Edge does a horrible job at rendering -webkit-background-clip, remove it
  // IE doesn't support -webkit-background-clip, remove it
  .hero-jojo{
    .content{
      .title{
        @include undo-headline-cloud-effect;
      }
    }
  }
}

@include breakpoint($desktop){
  .hero-jojo{
    & > .inner{
      padding-left:100px;
    }
    
    .jojo{
      width:380px;
    
      & > .inner{
        width:380px;
        height:380px;
      }
    }
    
    .content{
      & > .inner{
        padding-top:80px;
      }
      
      .title{
        font-size:59px;
        line-height:65px;
        
        &.medium{
          font-size:59px;
          line-height:65px;
        }
        
        &.large{
          
        }

        &.small{
          font-size:54px;
          line-height:59px;
          margin-right:25px;
        }
      }
      
      .description{
        padding-right:50px;
        margin-bottom:40px;
      }
    }
  }
}

@include breakpoint($laptop){
  .hero-jojo{
    & > .inner{
      padding-top:200px;
      padding-left:85px;
      padding-bottom:160px;
    }
    
    .jojo{
      width:350px;
      padding-top:0;
      left:100px;
    
      & > .inner{
        width:350px;
        height:350px;
      }
    }
    
    .content{
      margin-left:350px;

      & > .inner{
        padding-top:70px;
      }
      
      .title{
        font-size:49px;
        line-height:53px;
        margin-bottom:10px;

        &.medium{
          font-size:65px;
          line-height:70px;
          margin-bottom:10px;
        }
        
        &.large{
  
        }

        &.small{
          font-size:49px;
          line-height:53px;
        }
      }
      
      .description{
        padding-right:125px;
        font-size:19px;
        line-height:29px;
        margin-bottom:30px;
        
        &:last-child{
          margin-bottom:0;
        }
      }
    }
  }
  
}

@include breakpoint($laptop-tablet){
  .hero-jojo{
    & > .inner{
      padding-left:70px;
    }
    
    .jojo{
      position:relative;
      left:auto;
      width:330px;
    
      & > .inner{
        width:330px;
        height:330px;
      
      }
    }
    
    .content{
      margin-left:0;

      & > .inner{
        padding-top:45px;
      }
      
      .title{
        font-size:45px;
        line-height:51px;

        &.medium{
          font-size:60px;
          line-height:65px;
        }
        
        &.large{
          font-size:90px;
          line-height:90px;
        }

        &.small{
          font-size:39px;
          line-height:45px;
        }
      }
      
      .description{
        padding-right:50px;
        font-size:17px;
        line-height:27px;
        
      }
    }
  }
}

@include breakpoint($tablet-landscape){
  .hero-jojo{
    & > .inner{
      padding-left:50px;
      padding-right:120px;
    }
    
    .jojo{
      width:250px;
      padding-top:0;
    
      & > .inner{
        left:-20px;
        width:250px;
        height:250px;
      }
    }
    
    .content{
      & > .inner{
        padding-top:20px;
      }
      
      .title{
        font-size:39px;
        line-height:45px;

        &.medium{
          font-size:52px;
          line-height:60px;
        }
        
        &.large{
          font-size:80px;
          line-height:80px;
        }

        &.small{
          margin-right:0;
        }
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .hero-jojo{
    & > .inner{
      padding-top:180px;
      padding-bottom:130px;
    }
    
    .jojo{
      width:200px;
      padding-top:0;
      
      & > .inner{
        left:-15px;
        width:200px;
        height:200px;
      }
    }
    
    .content{
      & > .inner{
        padding-top:20px;
        padding-left:20px;
      }
      
      .title{
        font-size:31px;
        line-height:36px;

        &.medium{
          font-size:49px;
          line-height:55px;
          max-width:400px;
        }
        
        &.large{
          font-size:70px;
          line-height:70px;
          margin-bottom:20px;
        }

        &.small{
          font-size:31px;
          line-height:36px;
        }
      }
      
      .description{
        font-size:18px;
        line-height:27px;
        padding-right:25px;
      }
    }
  }
  
}

@include breakpoint($phone){
  .hero-jojo{
    & > .inner{
      padding-left:30px;
      padding-top:110px;
      padding-right:40px;
      padding-bottom:80px;
      display:block;
    }
    
    .jojo{
      display:block;
      width:240px;
      margin-left:-15px;
      
      & > .inner{
        left:-10px;
        width:240px;
        height:240px;
      }
    }
    
    .content{
      display:block;
      
      & > .inner{
        padding-left:0;
      }
      
      .title{
        font-size:41px;
        line-height:41px;
        padding-right:0;
        margin-bottom:15px;
        margin-right: 0;

        &.medium{
          font-size:42px;
          line-height:49px;
        }
        
        &.large{
          font-size:60px;
          line-height:66px;
        }

        &.small{
          font-size:30px;
          line-height:39px;
          font-weight:600;
        }
      }
      
      .description{
        font-size:16px;
        line-height:27px;
        padding-right:0;
        margin-bottom:25px;
      }
    }
  }
}


