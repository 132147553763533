// Mixins / Browsers

@mixin microsoft-edge{
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@mixin microsoft-ie{
  body.ie{
    @content;
  }
}

@mixin microsoft-edge-or-ie{
  @include microsoft-edge { @content; }
  @include microsoft-ie { @content; }
}