// Partials / Feature Chart

.feature-chart{
  position:relative;

  & > .inner{
    position: relative;
    padding:80px 0 0 200px;
    
  }

  // temp
  .block-label{
    color:$grey;

    .inner{
      &:after{
        background-color: $grey;
      }
    }
  }
  // END temp

  .title{
    @extend %title;
    color: $blue-black;
    font-size:41px;
    line-height:51px;
  }

  .feature-chart-table{
    position: relative;
    font-size:16px;
    line-height:21px;
    font-weight:500;
    margin-top:80px;
    margin-bottom:80px;
    color:$blue-grey;

    &.phone-only{
      display:none;
    }

    & > .inner{
      display:table;
      width:100%;
      table-layout:fixed;

      &:before{
        // Other column backgrounds
        left:20%;
        right:0;
      }

      &:after{
        // Dragonchain column background
        left:20%;
        right:60%;
      }

      &:hover{
        .row{
          &.highlight{
            .column{
              &.feature{
                color:inherit;

                &:before{
                  background-color:transparent;
                }
              }

              &.dragonchain{
                background-color:transparent;
                color:$blue;
              }
            }
          }
        }
      }

      &:before{
        position:absolute;
        z-index:0;
        content:'';
        display:block;
        background-color:#F3F3F3;
        top:0;
        bottom:0;
        border-radius:4px 0 0 4px;
        box-shadow:0 5px 55px 0 rgba(0,0,0,0.25);
      }

      &:after{
        position:absolute;
        z-index:0;
        content:'';
        display:block;
        background-color:$white;
        top:0;
        bottom:0;
        border-radius:4px 0 0 4px;
      }
    }

    .head,
    .row{
      display:table-row;
    }

    .row{
      &:last-child{
        .column{
          border-bottom:none;
        }
      }

      &:hover{
        .column{

          &:before{
            opacity:1;
          }

          &.feature{
            color:$white !important;

            &:before{
              background-color:$blue !important;
            }
          }

          &.dragonchain{
            background-color:$blue !important;
            color:$white !important;
          }
        }
      }

      &.highlight{
        .column{
          &.dragonchain{
            background-color:$blue;
            color:$white;
          }

          &.feature{
            color:$white;

            &:before{
              background-color:$blue;
              opacity:1;
            }
          }
        }
      }
    }

    .column{
      position:relative;
      z-index:1;
      display:table-cell;
      width: 25%;
      padding:30px 15px 27px 15px;
      text-align:center;
      vertical-align:middle;
      border-bottom:1px solid rgba(0,0,0,0.05);

      &:not(.feature){
        &:before{
          transition-duration:0.2s;
          transition-property:all;
          opacity:0;
        }
      }

      &:before{
        display: block;
        content:'';
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:-1px;
        background-color:rgba(0,0,0,0.05);
      }

      &.feature{
        transition-duration:0;
        text-align:right;
        padding-right:50px;
        color:$blue-grey;
        border-color:transparent;
        opacity:1;

        &:before{
          display:block;
          content:'';
          position:absolute;
          top:0;
          left:0;
          right:0;
          bottom:-1px;
          background-color:transparent;
          z-index:-1;
          border-radius:3px 0 0 3px;
        }
      }

      &.blue{
        color:$blue;
      }

      &.platform{
        font-size:19px;
        line-height:25px;
      }

      &.dragonchain{
        background-color:transparent;
      }

      &.no{
        color:$blue-grey-med;
      }

      em{
        margin-top:4px;
        font-style:normal;
        display:block;
        font-weight:400;
        font-size:0.9em;
      }
    }

  }

}

@include breakpoint($laptop){
  .feature-chart{
    & > .inner{
      padding-left:140px;
    }

    .feature-chart-table{
      .column{
        font-size:15px;

        &.platform{
          font-size:17px;
        }
      }
    }
  }
}

@include breakpoint($tablet-landscape){
  .feature-chart{
    & > .inner{
      padding-left:80px;
      padding-right:80px;
    }

    .title{
      font-size:36px;
      line-height:42px;
      font-weight:600;
    }

    .feature-chart-table{
      margin-left:-40px;

      .column{
        font-size:14px;
        line-height:19px;

        &.platform{
          font-size:15px;
        }
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .feature-chart{
    & > .inner{
      padding-left:50px;
      padding-right:50px;
    }

    .title{
      font-size:31px;
      line-height:36px;
    }

    .feature-chart-table{
      margin-top:50px;
      margin-left:-20px;
      margin-right:-20px;

      .column{
        padding-top:15px;
        padding-bottom:13px;
        font-size:13px;
        line-height:17px;

        &.platform{
          font-size:14px;
          line-height:19px;
        }

        &.feature{
          padding-right:25px;
          font-size:12px;
        }
      }
    }
  }
}

@include breakpoint($phone){
  .feature-chart{
    & > .inner{
      padding:50px 30px;
    }

    .title{
      font-size:29px;
      line-height:33px;
      padding-right:20px;
    }
    
    .feature-chart-table{
      display:none;
      margin-left:-10px;
      margin-right:-10px;

      &.phone-only{
        display:table;
        margin-bottom:50px;
        
        &:last-child{
          margin-bottom:0;
        }

        & > .inner{
          &:before{
            left:50%;
            right:0;
            border-radius:3px;
          }

          &:after{
            left:50%;
            right:0;
            border-radius:3px;
          }
        }
      }
    }
  }
}
