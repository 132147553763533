// Colors

// Dragonchain Colors (+ variations)

$white:#fff;
$near-white:#f9fbff;
$black:#131313;

$grey:#939598;
$grey-med:#808285;
$grey-med-light:#bcbec0;
$grey-light:#e6e7e8;
$grey-faint:#F9F9F9;
$grey-text:#6D6E71;
$grey-on-dark:#bcbec0;

$red:#dc2800;
$red-light:#f34322;

$blue:#1693e1;
$blue-light:#2eb6ef;
$blue-med:#003b82;
$blue-dark:#02173e;
$blue-black:#08152c;
$blue-grey:#3c4a5b;
$blue-grey-med:#8494a5;

$blue-light-on-blue:#9fdbf9;
$blue-light-on-blue-med:#3fccfe;

$yellow:#ffc623;
$yellow-dark:#f0b611;

// UI-specific Colors

$line-color:#F1F1F1;
$li-dot-color:#BCBEC0;

$input-border-color:#d6dbdf;
$input-placeholder-color:#939598;
$input-border-hover-color:$grey;
$input-border-focus-color:$blue;

$button-outline-grey:#ddd;
$button-outline-blue-light:rgba(38,182,255,0.3);
$path-bg-color:#132031;
$path-grey-color:#A7A9AC;

$alert-blue-light:#26b6ff;

$subscribe-color:#8ED9FF;
$subscribe-bg-blue-light:#1797E5;
$subscribe-bg-blue-dark:#0776C4;
$subscribe-form-bg-blue-light:#2CA2E6;
$subscribe-form-bg-blue-dark:#1797E5;
$sdks-border-color:#5DC5F9;

$subscribe-theme-blue-dark-color:#3fccfe;
$subscribe-theme-blue-dark-bg-color:#00346d;
$subscribe-theme-blue-dark-input-color:#005cad;
$subscribe-theme-blue-dark-input-color-hover:#004f94;
$subscribe-theme-blue-dark-input-color-placeholder:#6cbbe6;
$subscribe-theme-blue-dark-button-bg-color:#006ecf;
$subscribe-theme-blue-dark-button-bg-color-hover:#00428c;

$subscribe-theme-red-color:$white;
$subscribe-theme-red-bg-color:#df2600;
$subscribe-theme-red-input-color:#c22100;
$subscribe-theme-red-input-color-hover:#ad1e00;
$subscribe-theme-red-input-color-placeholder:#eb9f99;
$subscribe-theme-red-button-bg-color:#d12400;
$subscribe-theme-red-button-bg-color-hover:#8f1900;
$subscribe-theme-red-sdks-color:#ff928a;

$pricing-border-color:#F1F2F2;
$pricing-title-link-border-color:#D1D3D4;
$pricing-amount-color:#0082D1;

$switch-blue-bg-color:#5DC5F9;
$switch-blue-color:$blue-med;

$try-dragonchain-actions-bg-color:#f1f3f8;

$pricing-features-line-color:#E6E7E8;

// Footer
$footer-line-color:#E6E7E8;
$footer-link-disabled-color:#A7A9AC;
$footer-social-icon-color:$blue-dark;
$footer-social-icon-color-hover:$blue;

// Hero
$body-bg:#00aed5;
$hero-bg:#051738;

// Table
$table_thead_color_active:#6d6e71;
$table_thead_color:#a7a9ac;

$table_tbody_small_color:$grey;
$table_tbody_detail_color:$grey;
$table_tbody_color:$grey_med;
$table_tbody_strong_color:$grey_med;