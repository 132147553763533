// Mixins / Effects

@mixin headline-cloud-effect{
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  @include bg('../../assets/images/texture/text-mask.jpg',
              '../../assets/images/texture/text-mask@2x.jpg');
  background-size:cover;
  background-position:left bottom;
}

@mixin headline-cloud-effect-reverse{
  @include headline-cloud-effect;
  @include bg('../../assets/images/texture/text-mask-reverse.jpg',
              '../../assets/images/texture/text-mask-reverse@2x.jpg');
}

@mixin headline-cloud-effect-light{
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image:url(../../assets/images/texture/text-mask-light@2x.jpg);
  background-size:cover;
  background-position:left bottom;
}

@mixin headline-cloud-effect-light-reverse{
  @include headline-loud-effect-light;
  background-image:url(../../assets/images/texture/text-mask-light-reverse@2x.jpg);
}

@mixin undo-headline-cloud-effect{
  -webkit-text-fill-color: currentcolor !important;
  -webkit-background-clip: border-box !important;
  background-image:none !important;
}