// Partials / Modal

.leaderboard{
  .tbody{
    .tr{
      .td{
        &.badges{
          padding-top: 15px;
          padding-bottom: 15px;

          img{
            height: 32px;
            padding-right: 11px;
  
          }

          :last-child{
            padding-right: 0px;
          }
        }
      }
    }
  }

  .legend{
    padding: 45px 0px 20px 25px;
    display: flex;
    flex-wrap: wrap;

    .content{
      display: flex;
      padding-right: 40px;
      align-items: center;
      
      :last-child{
        padding-right: 0px;
      }

      .title{
        flex-shrink: 0;
      }

      img{
        height: 32px;
        padding-right: 11px;
      }
    }
  }

  a{
    transition-duration:0.4s;
    transition-property: background-color;
    display: inline-block;
    background-color: #1693e1;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    padding: 0px 10px;
    line-height: 20px;
    font-weight: 500;

    &:hover{
      background-color:$blue-black;
    }
  }
}

@include breakpoint($tablet-portrait) {
  .legend{
    padding-left: 5px;
    padding-top: 20px;

    .content{
      width: 100%;
      padding-bottom: 18px;
    }

    :last-child{
      padding-bottom: 0px;
    }
  }
}