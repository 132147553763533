// Partials / Pricing

.pricing{
  max-width:1180px;
  margin-left:auto;
  margin-right:auto;
  padding-bottom:40px;

  .pricing-header{
    max-width:400px;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
    color:$blue-light-on-blue-med;
    font-size:15px;
    margin-bottom:40px;
    
    .title{
      font-size:26px;
      line-height:33px;
      font-weight:600;
      color:$white;
    }

    p{
      color: $white;
      font-size: 15px;
      a{
        @include link_underline($blue-light, $blue-light-on-blue);
        cursor: pointer;
      }
    }
  }

  .pricing-layout{
    font-size:14px;
    line-height:19px;
    color:$blue-grey;

    & > .inner{
      background-color:$white;
      box-shadow:0 5px 25px 0 rgba(0,0,0,0.35);
      border-radius:3px;
    }

    .pricing-table{
      display:table;
      table-layout:fixed;
      width:100%;

      &.phone-only{
        display:none;
      }

      .row.is-monthly{
        display:table-row;
      }
      
      .row.is-annual{
        display:none;
      }

      &.js-annual{
        .row.is-monthly{
          display:none;
        }

        .row.is-annual{
          display:table-row;
        }
      }
    }

    .row{
      display:table-row;

      &:first-child{
        .column{
          padding-top:40px;
        }
      }

      &:last-child{
        .column{
          padding-bottom:50px;
        }
      }

      &.has-highlight + .has-feature{
        .column{
          padding-top:40px;
        }
      }
    }

    .column{
      display:table-cell;
      color:$blue-grey;
      border-right:1px solid $pricing-border-color;

      &:last-child{
        border-right:none;
      }

      &.center{
        text-align:center;
      }

      & > .inner,
      .plan,
      .price,
      .highlight,
      .feature,
      .action{
        max-width:280px;
        margin-left:auto;
        margin-right:auto;
      }

      .icon{
        position:relative;
        display:block;
        width:50px;
        height:50px;
        margin-left:auto;
        margin-right:auto;
        font-size:0;
        line-height:0;
        margin-bottom:20px;
        
        svg{
          fill:$blue-grey-med;
        }
      }

      .plan,
      .price,
      .highlight,
      .action{
        text-align:center;
      }

      .title{
        font-style:16px;
        line-height:21px;
        font-weight:500;
        margin-bottom:20px;
        text-transform: capitalize;

        a{
          transition-duration:0.2s;
          transition-property:background border color;
          display:block;
          max-width:140px;
          margin-left:auto;
          margin-right:auto;
          color: $blue-black;
          text-decoration:none;
          padding:7px 8px 6px 8px;
          border:1px solid $pricing-title-link-border-color;
          border-radius:20px;

          &:hover{
            background-color:$blue-black;
            border-color:$blue-black;
            color:$white;
          }
        }
      }

      .description{
        font-size:13px;
        line-height:21px;
        color:$blue-grey-med;
        margin-bottom:40px;
      }

      .price{
        margin-bottom: 25px;

        .amount{
          font-size:29px;
          line-height:35px;
          font-weight:600;
          color:$pricing-amount-color;
        }

        .period{
          color:$blue-grey-med;
          font-size:13px;
          line-height:19px;
        }
      }

      .highlight{
        margin-bottom: 25px;

        .name{
          font-size:19px;
          line-height:25px;
          color:$blue-black;
          font-weight:600;
          margin-bottom:4px;
        }

        .detail{

        }
      }

      .feature{
        cursor:default;
        transition-duration:0.2s;
        transition-property:background;
        display:flex;
        padding:10px;
        background-color:transparent;
        border-radius:3px;

        &:hover{
          background-color: #f9f9f9;
        }

        .name{
          position:relative;
          top:2px;
          margin-right:20px;

          &.no{
            color:$grey-med-light;
          }
        }

        .icon{
          flex-shrink:0;
          width:20px;
          height:20px;
          margin-right:0;
          margin-bottom:0;
        }
      }

      .action{
        padding-top:54px;

        p{
          margin-bottom:10px;
          color: $blue-grey-med;
        }

        .button{
          display:block;
          max-width:260px;
          margin-left:auto;
          margin-right:auto;
          text-align:center;
        }
      }
    }
  }
}

@include breakpoint($laptop-tablet){
  .pricing{
    max-width:100%;

    .pricing-header{
      margin-bottom:80px;

      .title{
        font-size:23px;
        line-height:27px;
      }
    }

    .pricing-layout{
      & > .inner{
        border-radius:0;
        padding:30px;
      }

      .pricing-table{
        margin-top:-70px;
        border:1px solid $pricing-border-color;
        border-radius:3px;
        background-color: $white;
        box-shadow:0 5px 20px 0 rgba(0,0,0,0.2);

        .action{
          .button{
            padding-top:12px;
            padding-bottom:12px;
          }
        }
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .pricing{

    .pricing-header{
      .title{
        font-size:21px;
        line-height:27px;
      }
    }

    .pricing-layout{
      & > .inner{
        padding-left:20px;
        padding-right:20px;
      }

      .pricing-table{
        .row{
          &.has-highlight + .has-feature{
            .column{
              padding-top:0;
            }
          }
        }

        .column{
          padding-left:20px;
          padding-right:20px;
        }

        .plan{
          .description{
            margin-bottom:20px;
          }
        }

        .price{
          .amount{
            font-size:24px;
            line-height:31px;
          }

          .period{
            font-size:11px;
            line-height:17px;
          }
        }

        .feature{
          .name{
            font-size:13px;
          }
        }

        .action{
          padding-top:30px;
          padding-left:10px;
          padding-right:10px;

          .button{
            max-width:100%;
          }
        }
      }
    }
  }
}

@include breakpoint($phone){
  .pricing{
    .pricing-layout{
      .pricing-table{
        display:none;
      
        &.phone-only{
          display:table;
          border:none;

          & + .pricing-table.phone-only{
            margin-top:20px;
          }
        }
      }
    }
  } 
  
}
