// Faqs

.faqs{

  .faq-item{
    border-top:2px solid $line_color;
    padding-top:20px;
    margin-top:17px;
    
    &.is-highlight{
      border-top:none;

      .answer{
        display: block;
      }
    }

    &:not(.is-open){
      .question-content{
        .question, 
        .question-link{
          margin-bottom:0;
        }
      }
    }

    &.is-open{
      & > .inner{
        & > div:first-child{
          &:before{
            transform:rotate(90deg);
            border-left-color:$blue;
          }
        }
      }

      .answer{
        display:block;
      }
    }
    
    &:last-child{
      margin-bottom:0;
    }
    
    &.is-highlight{
      padding-bottom: 50px;

      .inner{
        a{
          //display:block;
          margin-top:20px;
          word-break: break-all;
        }
      }
      .flag{
        margin-bottom:30px;
      }
      
      .question{
        font-size:41px;
        line-height:49px;
        font-weight:600;
        margin-bottom:25px;
      }
    }
    
    .question-header{
      position:relative;

      &:before{
        transition-duration:0.3s;
        transition-property:all;
        content:'';
        position: absolute;
        left:-24px;
        top:6px;
        display:block;
        width: 0; 
        height: 0; 
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid $grey;
      }
    }
    
    .question-content{
      display: flex;
      align-items: safe;
      justify-content: space-between;
      align-items:top;
    
      .question{
        cursor: pointer;
        font-size:19px;
        line-height:25px;
        font-weight:500;
        color:$black;
        margin-bottom:15px;
      }

      .question-link{
        cursor: pointer;
        flex-shrink: 0;
        height:23px;
      }

      .copy-message{
        transition-duration:0.5s;
        transition-property:opacity;
        opacity:0;
        position:absolute;
        top:0;
        right:0;
        margin-top:-24px;
        margin-right:13px;
        font-size:12px;
        line-height:15px; 
        padding:3px 5px;
        border-radius:2px;
        background-color:$blue;
        color:$white;
        font-weight:600;

        &.copied{
          transition-duration:0.5s;
          transition-property:opacity;
          opacity: 1;
        }
      }
    }
    
    p,
    li{
      font-size:18px;
      line-height:29px;
      margin-bottom:50px;
      
      &:last-child{
        padding-bottom:0;
      }
    }

    .answer {
      display:none;
      padding-top:10px;
      padding-bottom:10px;
    }

    .answer,
    .answer div {

      &:last-child{
        p{
          &:last-child{
            margin-bottom:0;
          }
        }
      }

      a {
        @include link-underline($blue, $red_light);
        padding-bottom:2px;
        
        //font-size:15px;
        //line-height:19px;
        color:$black;
        font-weight:500;
        text-decoration:none;
        
        &:hover{
          color:$black;
          
        }
      }

      h1,
      h2{
        color:$black;
        font-size:34px;
        line-height:49px;
        font-weight:700;
        max-width:950px;
        margin-bottom:30px;
      
        &:last-child{
          margin-bottom:0;
        }
      }
    
      h3{
        color:$black;
        font-size:29px;
        line-height:35px;
        font-weight:600;
        margin-bottom:25px;
      
        &:last-child{
          margin-bottom:0;
        }
      }

      h4{
        color:$black;
        font-size:14px;
        line-height:35px;
        font-weight:500;
      
        &:last-child{
          margin-bottom:0;
        }
      }
      
      p,
      li{
        list-style-type: none;
        font-size:17px;
        line-height:29px;
        
        a:not(.button){
          padding-bottom:2px;
          @include link-underline($blue-light, $red, 1.5);
          color:$black;
        }
        
      }
      
      p{
        margin-bottom:20px;
        word-break: break-word;
      
        &:last-child{
          margin-bottom:0;
        }
      }

      li{
        margin-bottom: 20px;
      }
      
      & > ul,
      & > ol{
        margin-bottom:50px;
        
        &:last-child{
          margin-bottom:0;
        }
        
        li{
          position:relative;
          padding-left:40px;
        }
      }
      
      & > ul{
        &.has-title{
          li{
            &:first-child{
              padding-left:0;
              margin-bottom:4px;
              
              &:before{
                display:none;
              }
            }
          }
        }
        
        li{
          &:before{
            content:'';
            position:absolute;
            top:10px;
            left:20px;
            display:block;
            width:7px;
            height:7px;
            background-color:$li-dot-color;
            border-radius:7px;
          }
        }
      }

      ol{
        counter-reset:ol-counter;
    
        li{
          counter-increment:ol-counter;
          position:relative;
          margin-left:35px;
          padding-left:10px;
          
          &:before{
            content: counter(ol-counter) ". ";
            position:absolute;
            display:block;
            color:$blue;
            left:-22px;
            text-align:right;
          }
        }
      }
      
      strong,
      b{
        font-weight:600;
        color:$black;
      }
      
      em,
      i{
        font-style:italic;
      }
      
      .actions{
        li{
          display:inline-block;
          margin-right:15px;
        
          &:last-child{
            margin-right:0;
          }
        }
      }
    }
  }

  .faq-nav{
    position:relative;
    margin-bottom:50px;
    
    ul{
      width:100%;
      border-bottom:5px solid $line-color;
    }
    
    li{
      display:inline-block;
      vertical-align:middle;
      position:relative;
      text-align:left;
      color:$black;
      font-size:15px;
      line-height:21px;
      font-weight:600;
      padding-left:30px;
      padding-right:30px;
      
      &:first-child{
        padding-left:0;

        a{
          padding-left:0;
        }
      }
      
      a{
        color:$black;
        text-decoration: none;
        position:relative;
        bottom:-5px;
        display:inline-block;
        cursor:pointer;
        border-bottom:5px solid $line-color;
        padding:30px 5px 30px 5px;
        
        &:hover{
          text-decoration: none;
          color:$black;
          border-color:$yellow;
        }
        &.selected{
          text-decoration: none;
          color:$black;
          border-color:$yellow;
        }
      }
    }
  }
}

@include breakpoint($tablet-landscape){
  .faqs{
    .faq-item{
      .question-content{
        .question{
          font-size:19px;
          line-height:25px;
        }
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .faqs{

    .faq-item{
      &.is-highlight{
        padding-bottom:30px;
        
        .question{
          font-size:27px;
          line-height:39px;
          margin-bottom:20px;
        }

        .answer,
        .answer div{
          padding-left:0;
        }
      }

      & > .inner{
        & > a:first-child{
          padding-left:17px;

          &:before{
            top:5px;
            left:0;
            
            border-top-width: 6px;
            border-bottom-width: 6px;
          border-left-width: 6px;
          }
        }
      }

      .question-content{
        .question{
          font-size:19px;
          line-height:25px;
        }
      }

      .answer,
      .answer div{
        p,
        li{
          font-size:17px;
          line-height: 27px;
        }
      }
    }

    .faq-nav{
      position:relative;
      margin-bottom:35px;

      &:before{
        content:'FAQ Categories';
        display: block;
        font-size:16px;
        font-weight:500;
        color:$blue;
        margin-bottom:5px;
      }

      ul{
        border-bottom:none;
      }

      li{
        display:block;
        padding-left:0;
        padding-right:0;
        font-weight:500;

        a{
          &,
          &.selected{
            padding:5px 0 5px 10px;
          }

          &.selected{
            font-weight:600;
          }

          border-bottom:none;
          border-left:3px solid $line_color;
        }
      }
    }
  }
}

@include breakpoint($phone){
  .faqs{

    .faq-item{
      margin-top:14px;
      padding-top:14px;


      &.is-highlight{
        .question{
          font-size:23px;
          line-height:35px;
        }
      }

      .question-header{
        &:before{
          top:5px;
          left:2px;

          border-top-width: 5px;
          border-bottom-width: 5px;
          border-left-width: 5px;
        }
      }

      .question-content{
        display:block;
        padding-right:25px;

        .question{
          font-size:15px;
          line-height:23px;
          padding-left:20px;
        }

        .has-question-link{
          position:absolute;
          top:-1px;
          right:0;
        }

        .question-link{
          transform-origin:center;
          transform:scale(0.8);
        }
      }

      .answer{
        padding-left:0;
      }

      .answer,
      .answer div{
        p,
        li{
          font-size:15px;
          line-height:25px;
        }

        & > ul{
          li{
            padding-left:23px;

            &:before{
              left:2px;
              top:8px;
            }
          }
        }

        & > ol{
          li{
            margin-left:0;
            padding-left:23px;

            &:before{
              left:2px;
            }
          }
        }
      }
    }
  }
}
