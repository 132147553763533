// Partials / Subscribe

.form {
  border-radius:3px;
  background-color:$white;
  box-shadow:1px 3px 15px 0 rgba(0,0,0,0.2);
  margin-top:30px; //60px
  margin-bottom:70px;
  
  &:last-child{
    margin-bottom:0;
  }
  
  & > .inner{
    padding:20px 20px 30px 20px;
  }
  
  .title{
    color:$blue;
    font-size:20px;
    line-height:29px;
    font-weight:600;
    margin-bottom:5px;
  }
  
  .description{
    color:$grey;
    font-size:16px;
    line-height:23px;
    font-weight:400;
    max-width:80%;
    margin-bottom:20px;
    
    &:empty{
      display:none;
    }
  }
  
  .title + .description:empty + .fields-inline{
    margin-top:10px;
  }
  
  .fields-inline{
    display:table;
    width:100%;
    
    .field,
    .action{
      display:table-cell;
      vertical-align:middle;
    }
    
    .field{
      & > div{
        margin:0;
        padding-right:18px;
      }
      
      input{
        margin-bottom:0;
      }
    }
    
    .action{
      width:1%;
      white-space:nowrap;
    }
  }
}

@include breakpoint($tablet-landscape){
  .subscribe{
    & > .inner{
      padding-bottom:25px;
      padding-right:0;
    }
    
    .fields-inline{
      display:flex;
      flex-direction:row;
      flex-wrap:wrap;
      
      .field{
        width:33%;
        margin-bottom:17px;
      }
      
      .action{
        width:33%;
        align-items:center;
        justify-content:center;
      }
    }
  }
}

@include breakpoint($phone){
  .subscribe{
    box-shadow:1px 3px 15px 0 rgba(0,0,0,0.15);
    
    & > .inner{
      padding-bottom:25px;
      padding-right:20px;
    }
    
    .title{
      margin-bottom:15px;
    }
    
    .fields-inline{
      .field{
        width:100%;
        
        & > div{
          padding-right:0;
        }
      }
      
      .action{
        width:100%;
        padding-top:5px;
        transform-origin:0 0;
        transform:scale(1.1);
      }
    }
  }
}