// Partials / Blog Articles

.blog{
  .blog-articles{
    width:73%;

    & > .inner{
      position:relative;
      display:flex;
      flex-wrap:wrap;
      flex-direction:row;
      padding-left:180px;
      padding-right:110px;
      margin-left:-17px;
      margin-right:-17px;
    }
  }

  .blog-article-card{
    position:relative;
    width:50%;
    padding-left:17px;
    padding-right:17px;
    padding-bottom:80px;

    & > .inner{
      transition-duration:0.25s;
      transition-property:all;
      position:relative;
      top:0;

      height:100%;
      overflow-x:hidden;

      background-color:$white;
      border-radius:3px;
      box-shadow:0px 2px 7px 0 rgba(0,0,0,0.12);

      &:hover{
        box-shadow:0px 20px 30px 0 rgba(0,0,0,0.45);
        top:-5px;

        .title{
          span{
            @include link-underline($blue, $blue, 2);
          }
        }
      }
    }

    a{
      color:inherit;
      text-decoration:none;
      display:table;
      table-layout:fixed;
      width:101%;
      height:100%;
    }

    .content,
    .image{
      display:table-row;
    }

    .content{
      & > .inner{
        position:relative;
        padding:60px 40px 24px 30px;
      }

      time{
        position:absolute;
        top:26px;
        right:26px;
        color:$grey-med-light;
        font-size:12px;
        line-height:15px;
      }

      .category{
        color:$blue;
        font-size:14px;
        line-height:19px;
        margin-bottom:20px;
      }

      .title{
        padding-bottom:2px;
        font-size:18px;
        line-height:27px;
        font-weight:500;

        span{
          @include link-underline(transparent, $blue, 2);
        }
      }
    }

    .image{
      width:105%;
      font-size:0;
      line-height:0;
      height:1px;
      overflow-x:hidden;

      & > .inner{
        position:relative;
        width:100%;
        height:100%;
      }

      .img{
        position:absolute;
        width:100%;
        height:100%;
        background-size:cover;
        background-position:center;
      }

      img{
        width:100%;
        height:auto;
        opacity:0
      }
    }
  }
}

@include breakpoint($laptop){
  .blog{
    .blog-articles{
      & > .inner{
        margin-left: 0;
        margin-right: 0;

        padding-left:40px;
        padding-right:40px;
      }
    }
  }
}

@include breakpoint($tablet-landscape){

}

@include breakpoint($tablet-landscape){
  .blog-hero + .blog{
    & > .inner{
      padding-top:50px;
    }
  }

  .blog{
    .blog-articles{
      width:auto;

      & > .inner{
        padding-left:35px;
        padding-right:35px;
      }
    }

    .blog-article-card{
      padding-bottom:40px;

      .content{
        .title{
          font-size:17px;
          line-height:24px;
        }
      }
    }
  }
}

@include breakpoint($phone){
  .blog-hero + .blog{
    & > .inner{
      padding-top:40px;
    }
  }

  .blog{
    .blog-articles{
      & > .inner{
        padding-left:15px;
        padding-right:15px;
      }

      .blog-article-card{
        width:100%;
      }
    }
  }
}
