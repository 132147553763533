.alert{
  font-size:16px;
  line-height:23px;
  font-weight:400;
  margin-top:25px;
  margin-bottom:25px;
  color:$white;

  &:first-child{
    margin-top:0;
  }

  .inner {
    display:inline-block;
    padding:11px 13px 10px 13px;
    background-color:$blue;
    border-radius:3px;
    box-shadow:5px 5px 0 0 rgba(0, 0, 0, 0.06);
  }

  &.blue{
    & > .inner{
      background-color:$blue;
    }
  }

  &.blue-light{
    & > .inner{
      background-color:$alert-blue-light;
    }
  }

  &.blue-med{
    & > .inner{
      background-color:$blue-med;
    }
  }

  &.grey{
    & > .inner{
      background-color:$blue-grey;
    }
  }

  &.grey-med{
    & > .inner{
      background-color:$blue-grey-med;
    }
  }

  &.white{
    & > .inner{
      background-color:$white;
      color:$blue-black;
    }
  }
}