// Person Card styles

.person{

  .person-name{
    font-size:27px;
    line-height:33px;
    font-weight:600;
    margin-top: 54px;
    margin-bottom:3px;
    color:$black;
  }
  
  .person-job-title{
    font-size:19px;
    line-height:25px;
    font-weight:500;
    margin-bottom:25px;
  }
  
  .person-description{
    font-size:17px;
    line-height:27px;
    padding-right:10px;
  }

  &.dark{
    color:$path-grey-color;
    
    .person-name{
      color:$white;
    }
    
    .person-job-title{
      color:$blue;
    }
  }
}

@include breakpoint($tablet-landscape){
  .person{

    .person-name{
      font-size:25px;
      line-height:29px;
    }
    
    .person-job-title{
      font-size:18px;
    }
    
    .person-description{
      padding-right:0;
      font-size:16px;
      line-height:27px;
    }
  }

}

@include breakpoint($tablet-portrait){
  .person{
    .person-name{
      font-size:21px;
      line-height:25px;
    }
    
    .person-job-title{
      font-size:17px;
    }
  }
}
