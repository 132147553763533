// Partials / Request Demo

.request-demo{
  
  & > .modal{

    .modal_box{
      width:800px;
      overflow-y:auto;
      background:transparent;
      padding:0px;
      box-shadow:none;
      max-width:none;
    }
  }

  .modal_close{
    position:absolute;
    right:8px;
    top:68px;

  }
}

@include breakpoint($tablet-portrait){
  .request-demo{
  
    & > .modal{

      .modal_box{
        width:auto;
        top:50px;
        left:50px;
        right:50px;
        transform:translate(0);
      }
    }

    .modal_close{
      position:absolute;
      right:0px;
      top:57px;
    }
  }
}

@include breakpoint($phone){
  .request-demo{
  
    & > .modal{
      
      .modal_box{
        width:auto;
        top:20px;
        left:20px;
        right:20px;
        transform:translate(0);
      }
    }

    .modal_close{
      position:absolute;
      right:48px;
      top:10px;
    }
  }
}
