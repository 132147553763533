// Call to Action

.call-to-action{
  position: relative;
  background-color:$blue-dark;
  color:$white;
  background-size:cover;
  background-position:right bottom;
  
  & > .inner{
    padding:170px 140px 150px 140px;
  }
  
  .title{
    @extend %title;
    max-width:750px;
    font-size:64px;
    line-height:70px;
    margin-bottom:30px;
    color:$white;

    &.medium{
      font-size:55px;
      line-height:63px;
    }
  }
  
  .description{
    @extend %description;
    max-width:750px;
    font-size:21px;
    line-height:35px;
    color:$white;
    margin-bottom:50px;
  }

  &.medium{
    .description, .title{
      padding-right:0;
      max-width:900px;
    }
  }

  &.wide{
    .description, .title{
      padding-right:0;
      max-width:1125px;
    }
  }
  
  .actions{
    li{
      display:inline-block;
      margin-right:15px;
      
      &:last-child{
        margin-right:0;
      }
    }
  }
}

@include breakpoint($laptop){
  .call-to-action{
    & > .inner{
      padding:140px 100px 120px 100px;
    }
  }
}

@include breakpoint($laptop-tablet){
  .call-to-action{
    & > .inner{
      padding:100px 80px 80px 80px;
    }
    
    .title{
      font-size:59px;
      line-height:63px;
      max-width:675px;
    }
    
    .description{
      max-width:675px;
    }
    
  }
}

@include breakpoint($tablet-portrait){
  .call-to-action{
    .title{
      font-size:44px;
      line-height:53px;
      letter-spacing:0;
      max-width:580px;
    }
    
    .description{
      font-size:18px;
      line-height:27px;
      max-width:580px;
    }
  }
}

@include breakpoint($tablet-phone){
  .call-to-action{
    & > .inner{
      padding-left:50px;
      padding-right:40px;
    }
  }
}

@include breakpoint($phone){
  .call-to-action{
    & > .inner{
      padding:120px 30px 100px 40px;
    }
    
    .title{
      font-size:39px;
      line-height:45px;
    }
    
    .description{
      font-size:16px;
      line-height:27px;
      margin-bottom:30px;
      padding-right:20px;
    }
  }
}
