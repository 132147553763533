// Partials / Contents Full

.contents-full{
  position: relative;

  &:first-child{
    & > .inner{
      padding-top:120px;
    }
  }
  
  & > .inner{
    padding:80px 200px 80px 200px;
  }
  
  &.wide{
    .description{
      padding-right:0;
      max-width:1125px;
    }
  }
  
  .title{
    @extend %title;
    font-size:51px;
    font-weight:700;
    line-height:59px;
    margin-bottom:15px;
    
    &.large{
      font-size:60px;
      line-height:72px;
      margin-bottom:35px;
    }
  }
  
  .black {
    color:$black;
  }

  .white {
    color:$white;
  }
  
  .description{
    @extend %description; 
    @extend %page_content; 
    font-size:19px;
    line-height:34px;
    color:$grey;
    padding-right:100px;
    margin-bottom:50px;
    max-width:1000px;
    
    p{
      margin-bottom:30px;
      
      &:last-child{
        margin-bottom:0;
      }
    }
    
    &:last-child{
      margin-bottom:0;
    }
  }
  
  .actions{
    li{
      display:inline-block;
      vertical-align:middle;
      margin-right:15px;
      margin-bottom:12px;
    }
  }
}

@include breakpoint($desktop){
  .contents-full{
    
    & > .inner{
      padding-left:140px;
    }
    
    .title{
      font-size:47px;
      line-height:53px;
    }
  }
}

@include breakpoint($laptop){
  .contents-full{
    
    & > .inner{
      padding-left:100px;
      padding-top:80px;
      padding-bottom:20px;
    }
    
    &:first-child{
      & > .inner{
        padding-top:100px;
      }
    }
    
    .title{
      font-size:43px;
      line-height:49px;

      &.large{
        font-size:43px;
        line-height:49px;
        margin-bottom:20px;
      }
    }
    
    .description{
      font-size:19px;
      line-height:29px;
    }
  }
}

@include breakpoint($laptop-tablet){
  .contents-full{
    
    & > .inner{
      padding-left:80px;
      padding-top:40px;
      padding-bottom:10px;
    }
    
    &:first-child{
      & > .inner{
        padding-top:70px;
      }
    }

    &:last-child{
      padding-bottom:55px;
    }
    
    .title{
      font-size:36px;
      line-height:42px;
  
      &.large{
        font-size:41px;
        line-height:44px;
      }
    }
    
    .description{
      font-size:17px;
      line-height:27px;
      padding-right:0px;
      margin-bottom:30px;
    }
  }
  
}

@include breakpoint($tablet-landscape){
  .contents-full{
    & > .inner{
      padding-left:50px;
      padding-right:100px;

      .title{
        font-size:31px;
        line-height:36px;
  
        &.large{
          font-size:31px;
          line-height:36px;
        }
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .contents-full{
    
    & > .inner{
      padding-left:50px;
      padding-right:50px;
    }
    
    .title{
      font-size:31px;
      line-height:36px;

      &.large{
        font-size:31px;
        line-height:36px;
      }
    }
    
    .description{
      font-size:16px;
      line-height:27px;
    }
  }
}

@include breakpoint($phone){
  .contents-full{
    padding:0;
    
    &:first-child{
      padding-bottom:0;

      & > .inner{
        padding-top:50px;
        padding-bottom:50px;
      }
    }
    
    & > .inner{
      padding:20px 50px 20px 30px;
    }
    
    .title{
      font-size:29px;
      line-height:33px;
      font-weight:600;

      &.large{
        font-size:29px;
        line-height:33px;
      }
    }

    .description{
      font-size:15px;
      line-height:13px;
      padding-right:10px;
    }
  }
}
