// Partials / Modal

.modal{
  position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index:990;
  
  .modal_overlay{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index:995;
    background:rgba(1,18,45,0.5);
  }
  
  .modal_box{
    z-index:999;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    max-height:90%;
    overflow-y:auto;
    background:#fff;
    box-sizing:border-box;
    padding:30px;
    box-shadow:0 1px 25px rgba(0,0,0,0.15);
    border-radius:4px;
    max-width:520px;
  }
  
  .modal_header{
    margin-top:-10px;
    margin-bottom:20px;
    
    .modal_name{
      font-size:21px;
      line-height:25px;
      color:$blue;
      font-weight:600;
    }
  }
  
  .modal_content{
    font-size:15px;
    line-height:25px;
    color:$grey_text;
    
    .modal_title{
      font-size:21px;
      color:$black;
      font-weight:600;
      margin-bottom:15px;
    }
    
    & > p{
      margin-bottom:23px;
      
      &:last-child{
        margin-bottom:0;
      }
    }
  }
  
  .modal_close{
    position:absolute;
    right:3px;
    top:21px;
    cursor:pointer;
    font-size:0 !important;
    line-height:17px !important;
    background:none;
    border:none;
    padding:0 !important;
    width:40px;
    height:40px;
    border-radius:0;
    
    &:hover{
      &:before,
      &:after{
        background-color:$blue;
      }
    }
    
    &:before{
      transition-duration:0.2s;
      transition-property:all;
      transform-origin:center center;
      transform:rotate(-45deg);
      
      position:relative;
      top:9.5px;
      
      content:'';
      display:block;
      width:21px;
      height:2px;
      background-color:$grey;
      border-radius:2px;
    }
    
    &:after{
      transition-duration:0.2s;
      transition-property:all;
      transform-origin:center center;
      transform:rotate(45deg);
      
      position:relative;
      top:-9.5px;
      
      content:'';
      display:block;
      width:21px;
      height:2px;
      background-color:$grey;
      border-radius:2px;
    }
    
    &:hover{
      opacity:0.9;
    }
  }
  
}

@include breakpoint($tablet-portrait){
  .modal{
    .modal_box{
      top:50px;
      left:50px;
      right:50px;
      transform:translate(0);
    }
  }
}

@include breakpoint($phone){
  .modal{
    .modal_box{
      top:20px;
      left:20px;
      right:20px;
      transform:translate(0);
    }
  }
}
