// Partials / Page

.page{
  max-width:1140px;
  margin-left:auto;
  margin-right:auto;
  color:$grey-text;
  
  & > .inner{
    padding:35px 20px 100px 20px;
  }
  
  section{
    @extend %page_content; 
  }
  
  hr{
    margin-left:auto;
    margin-right:auto;
    height:5px;
    border:none;
    background-color:$line-color;
    margin-top:60px;
    margin-bottom:70px;
  }
  
}

@include breakpoint($laptop){
  .page{
    margin-left:90px;
    margin-right:140px;
    
    & > .inner{
      padding-left:0;
      padding-right:0;
    }
    
  }
}

@include breakpoint($laptop-tablet){
  .page{
    margin-left:70px;
  }
}

@include breakpoint($tablet-landscape){
  .page{
    margin-left:50px;
    margin-right:50px;
  }
}

@include breakpoint($phone){
  .page{
    margin-left:30px;
    margin-right:30px;
  }
}
