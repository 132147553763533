// Partials / Block Label

.block-label{
  position:absolute;
  top:80px;
  left:55px;
  color:$white;
  transform-origin:0 0;
  transform:rotate(-90deg);

  & > .inner{
    position: relative;
    transform:translateX(-100%);

    &:after{
      content:'';
      position:relative;
      top:-5px;
      display:inline-block;
      width:30px;
      height:1px;
      border-radius:1px;
      background-color:$white;
      margin-left:15px;
    } 
  }

  &.blue{
    color:$blue;

    & > .inner{
      &:after{
        background-color:$blue;
      }
    }
  }

  &.blue-light{
    color:$blue-light-on-blue-med;

    & > .inner{
      &:after{
        background-color:$blue-light-on-blue-med;
      }
    }
  }

  &.grey{
    color:$blue-grey-med;

    & > .inner{
      &:after{
        background-color:$blue-grey-med;
      }
    }
  }
  
}

@include breakpoint($tablet-landscape){
  .block-label{
    display:none;
    // left:40px;
  }
}

