// Partials / Sdks

.sdks{
  display:inline-block;
  border:1px solid $sdks-border-color;
  border-radius:3px;
  display:table;
  margin-bottom:15px;

  &:last-child{
    margin-bottom:0;
  }

  .sdk{
    display:table-cell;
    vertical-align:middle;
    border-right:1px solid $sdks-border-color;

    &:last-child{
      border-right:none;
    }

    a,
    button{
      transition-duration:0.2s;
      transition-property:background;
      display:block;
      padding:1px 13px;
      background-color:transparent;

      &:hover{
        background-color:$sdks-border-color;
      }
    }

    svg{
      position:relative;
      top:2px;
      height:40px;
      fill:$white;
    }
  }
}

@include breakpoint($tablet-landscape){
  .sdks{
    .sdk{
      svg{
        height:30px;
      }
    }
  }
}
