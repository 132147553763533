// Partials / Blog Hero

.blog-hero{
  padding-top:200px;

  .carousel{
    // height:470px;
    height:544px;
  }

  .flickity-viewport{
    height:544px !important;
  }

  .flickity-prev-next-button{
    width:74px;
    height:74px;
    border-radius:0;
    outline:0;
    top:507px;

    &:before{
      content:'';
      position:absolute;
      top:22px;
      left:22px;
      display:block;
      -webkit-mask: url(../../assets/images/icon/arrow-with-stem.svg) no-repeat center;
      mask: url(../../assets/images/icon/arrow-with-stem.svg) no-repeat center;
      background-color:$white;
      width:30px;
      height:30px;
    }

    &.previous{
      &:before{
        transform:rotate(180deg);
      }
    }

    svg{
      display:none;
    }

  }

  .flickity-button{
    &.previous{
      background-color:$blue-light;
      left:auto;
      right:calc(27% + 110px - 74px - 2px);
    }

    &.next{
      background-color:$blue;
      left:auto;
      right:calc(27% + 110px - 74px - 74px - 2px);
    }
  }

  .flickity-page-dots{
    display:none;
  }

  .slide{
    transition-duration:0.35s;
    transition-property:height;
    width:calc(73% - 290px); // width of blog-articles - padding-left (180px) - padding-right (110px)
    height:470px !important;

    &.is-selected{
      height:544px !important;
      z-index:2;
    }

    & > .inner{
      transform:translateX(180px);
      position:relative;
      height:100%;

      &:before{
        content:'';
        display:block;
        position:absolute;
        top:0;
        bottom:0;
        left:-1px;
        right:-1px;
        z-index:2;
        pointer-events:none;

        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
    }

    a{
      position:absolute;
      display:block;
      left:0;
      top:0;
      right:0;
      bottom:0;
      text-decoration:none;
      color:$white;
      
      &:hover{
        .title{
          span{
            @include link_underline($blue, $blue);
          }
        }
      }
    }

    .img{
      position:absolute;
      top:0;
      left:-1px;
      right:-1px;
      bottom:0;
      background-size:cover;
      background-position:center;
    }

    .content{
      position:absolute;
      z-index:3;
      left:65px;
      bottom:45px;

      .title{
        display:block;
        font-size:31px;
        line-height:39px;
        font-weight:600;
        margin-bottom:12px;
        color:$white;
        padding-right:100px;

        span{
          @include link_underline(transparent, $blue);
        }
      }

      .category,
      time{
        display:inline-block;
      }

      .category{
        color:$blue;
        font-size:15px;
        line-height:19px;
        vertical-align:baseline;
        margin-right:10px;
      }

      time{
        color:$white;
        font-size:13px;
        line-height:19px;
        vertical-align:baseline;
      }
    }
  }

}

@include breakpoint($laptop){
  .blog-hero{
    padding-top:150px;

    .slide{
      width:calc(73% - 110px); // width of blog-articles - padding-right (110px)

      & > .inner{
        transform:translateX(0);
      }

      .content{
        left:85px;
      }
    }
  }
}

@include breakpoint($tablet-landscape){
  .blog-hero{
    padding-top:100px;
  }
}

@include breakpoint($tablet-portrait){
  .blog-hero{
    .slide{
      width:100%;
      height:544px !important;

      .content{
        left:50px;
      }
    }

    .flickity-button{
      width:60px;
      height:60px;
      top:514px;

      &:before{
        top:15px;
        left:16px;
      }

      &.previous{
        background-color:$blue-light;
        left:auto;
        right:60px;
      }
  
      &.next{
        right:0;
      }
    }

  }
}

@include breakpoint($phone){
  .blog-hero{
    padding-top:80px;

    .carousel{
      height:380px;
    }

    .flickity-viewport{
      height:380px !important;
    }

    .slide{
      &,
      &.is-selected{
        height:380px !important;
      }

      .content{
        left:30px;
        right:30px;

        .title{
          font-size:23px;
          line-height:29px;
          padding-right:0;
        }

        .category,
        time{
          display:block;
        }

      }
    }

    .flickity-button{
      top:350px;
    }
  }

}


