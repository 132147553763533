// Partials / Engagement Inline

.engagement-inline{
  padding-top:50px;

  & > .layout{
    display:flex;
  }

  .engagement-content{
    width:40%;
    padding-right:50px;

    .title{
      @extend %title;
      display:inline-block;
      font-size:39px;
      line-height:45px;
      margin-bottom:25px;
    }

    .description{
      font-size:19px;
      line-height:34px;
    }
  }

  .engagement-form{
    width:60%;
  }

  iframe{
    max-width: 100% !important;
    margin:auto auto auto 0 !important;
  }
}

@include breakpoint($tablet-portrait){
  .engagement-inline{
    padding-top:25px;

    & > .layout{
      flex-wrap:wrap;
    }

    .engagement-content,
    .engagement-form{
      width:100%;
    }

    .engagement-content{
      margin-bottom:45px;
      
      .title{
        font-size:31px;
        line-height:36px;
      }

      .description{
        font-size:16px;
        line-height:27px;
      }
    }
  }
}

@include breakpoint($phone){
  .engagement-inline{
    padding-top:15px;
    
    .engagement-content{
      margin-bottom:35px;
      
      .title{
        font-size:25px;
        line-height:33px;
        margin-bottom:5px;
        font-weight:600;

        font-size: 29px;
        line-height: 33px;
        font-weight: 600;
      }

      .description{
        font-size:16px;
        line-height:27px;
      }
    }
  }
}

