// contents-halves

.content-block{
  .contents-halves{
    display:flex;
    
    &:last-child{
      .content-half{
        padding-bottom:25px;
      }
    }
    
    &.reverse{
      flex-direction:row-reverse;
      
      .content-half{
        &:nth-child(even){
          & > .inner{
            padding-left:200px;
            padding-right:40px;
          }
        }
      
        &:nth-child(odd){
          & > .inner{
            padding-left:40px;
            padding-right:200px;
          }
        }
      }
    }
    
    .content-half{
      position: relative;
      width:50%;
      color:$grey-med;
      
      & > .inner{
        padding:80px 40px 80px 40px;
      }
      
      &:nth-child(even){
        & > .inner{
          padding-left:40px;
          padding-right:200px;
        }
      }
      
      &:nth-child(odd){
        & > .inner{
          padding-left:200px;
          padding-right:40px;
        }
      }
      
      .photo-content{
        position:relative;
        top:-30px;
      }
      
      .label + .title{
        position:relative;
        margin-top:-4px;
      }
      
      .title{
        font-size:33px;
        line-height:46px;
        font-weight:600;
        margin-bottom:0;
        color:$black;
      }
      
      .description{
        font-size:19px;
        line-height:29px;
        margin-bottom:30px;
        
        &:last-child{
          margin-bottom:0;
        }
        
        & + .label{
          margin-top:55px;
        }
      }
      
    }
  }
  
}

@include breakpoint($desktop){
  .content-block{
    .contents-halves{
      
      &.reverse{
        .content-half{
          &:nth-child(even){
            & > .inner{
              padding-left:140px;
            }
          }
      
          &:nth-child(odd){
            & > .inner{
              padding-right:140px;
            }
          }
        }
      }
      
      &:not(.reverse){
        .content-half{
          &:nth-child(even){
            & > .inner{
              padding-right:140px;
            }
          }
          
          &:nth-child(odd){
            & > .inner{
              padding-left:140px;
            }
          }
        }
      }
      
      .content-half{
        .label{
          & + .title{
            margin-top:0
          }
        }
      }
      
    }
  }
}

@include breakpoint($laptop){
  .content-block{
    .contents-halves{
      
      &:last-child{
        .content-half{
          padding-bottom:60px;
        }
      }
      
      &.reverse{
        .content-half{
          &:nth-child(even){
            & > .inner{
              padding-left:100px;
            }
          }
      
          &:nth-child(odd){
            & > .inner{
              padding-right:100px;
            }
          }
        }
      }
      
      &:not(.reverse){
        .content-half{
          &:nth-child(even){
            & > .inner{
              padding-right:100px;
            }
          }
          
          &:nth-child(odd){
            & > .inner{
              padding-left:100px;
            }
          }
        }
      }
      
      .content-half{
        & > .inner{
          padding-top:60px;
          padding-bottom:60px;
        }
        
        .title{
          font-size:29px;
          line-height:35px;
          margin-bottom:10px;
        }
        
        .description{
          font-size:17px;
          line-height:29px;
        }
        
        .photo-content{
          top:-10px;
        }
      }
    }
  }
}

@include breakpoint($laptop-tablet){
  .content-block{
    .contents-halves{
      
      &.reverse{
        .content-half{
          &:nth-child(even){
            & > .inner{
              padding-left:80px;
            }
          }
      
          &:nth-child(odd){
            & > .inner{
              padding-right:80px;
            }
          }
        }
      }
      
      &:not(.reverse){
        .content-half{
          &:nth-child(even){
            & > .inner{
              padding-right:80px;
            }
          }
          
          &:nth-child(odd){
            & > .inner{
              padding-left:80px;
            }
          }
        }
      }
      
      .content-half{
        & > .inner{
          padding-top:40px;
          padding-bottom:40px;
        }
        
        .title{
          font-size:25px;
          line-height:29px;
        }
        
        .description{
          font-size:17px;
          line-height:27px;
        }
      }
      
    }
  }
}

@include breakpoint($tablet-portrait){
  .content-block{
    .contents-halves{
      
      &.reverse{
        .content-half{
          &:nth-child(even){
            & > .inner{
              padding-left:50px;
            }
          }
      
          &:nth-child(odd){
            & > .inner{
              padding-right:50px;
            }
          }
        }
      }
      
      &:not(.reverse){
        .content-half{
          &:nth-child(even){
            & > .inner{
              padding-right:50px;
            }
          }
          
          &:nth-child(odd){
            & > .inner{
              padding-left:50px;
            }
          }
        }
      }
      
      .content-half{
        .description{
          font-size:16px;
          line-height:27px;
          
          & + .label{
            margin-top:35px;
          }
        }
      }
    }
  }
}

@include breakpoint($phone){
  .content-block{
    .contents-halves{
      display:block;
      
      &:last-child{
        .content-half{
          padding-bottom:0;
        }
      }
      
      &.reverse{
        .content-half{
          &:nth-child(even){
            & > .inner{
              padding-left:30px;
              padding-right:30px;
            }
          }
      
          &:nth-child(odd){
            & > .inner{
              padding-left:30px;
              padding-right:30px;
            }
          }
        }
      }
      
      &:not(.reverse){
        .content-half{
          &:nth-child(even){
            & > .inner{
              padding-left:30px;
              padding-right:30px;
            }
          }
          
          &:nth-child(odd){
            & > .inner{
              padding-left:30px;
              padding-right:30px;
            }
          }
        }
      }
      
      .content-half{
        width:auto;
        
        .photo-content{
          margin-bottom:-30px;
        }
      }
      
    }
  }
}
