// Partials / Feature Subscribe

.subscribe-bar {
  position: relative;
  background-color:#f2f3f3;
  color:#999;

  & > .inner {
    max-width:1140px;
    margin-left:auto;
    margin-right:auto;
    padding-top:160px;
    padding-bottom:160px;
  }
  
  .subscribe-form-bar{
    position:relative;

    .title{
      position:relative;
      top:50%;
      transform:translateY(-50%);
      color:#999;
      font-size:29px;
      line-height:35px;
      font-weight:300;
      margin-right:93px;
    }

    form{
      &.has-response{
        overflow:hidden;

        .fields-inline{
          top:200px;
        }

        .action{
          pointer-events:none;

          .button-style{
            opacity:0;
          }

        }

        .message-response{
          opacity:1;
        }
      }
    }

    .fields-inline{
      background-color:transparent;
      transition-duration:0.2s;
      transition-property:top;
      position:relative;
      top:0;
      display:flex;
    }

    .field{
      position:relative;
      flex-grow:1;

      &:first-child{
        width:215px;
        flex-grow:0;

        input[type=text],
        input[type=email]{
          padding-left:58px;
        }
      }

      & + .field{
        &:before,
        &:after{
          position:absolute;
          content:'';
          display:block;
          transform-origin:center;
          top:19px;
          left:0;
          width:2px;
          height:15px;
          background-color:$white;
        }

        &:before{
          transform:rotate(0deg);
        }

        &:after{
          transform:rotate(90deg);
        }
      }

      input[type=text],
      input[type=email]{
        color:$white;
        background-color:$subscribe-form-bg-blue-dark;
        box-shadow:none;
        border:none;
        height:54px;
        margin:0;
        padding-left:40px;
        border-radius: 0px;
        
        &::placeholder{
          color:$white;
        }

        &:hover{
          background-color:#0068AD;
        }
      }
    }

    .content + .field{
      input{
        border-radius:3px 0 0 3px;
      }
    }

    .action{
      flex-grow:0;
      width:54px;
      height:54px;

      .button-style{
        width:100%;
        height:100%;
      }

      .smooth-spinner{
        svg{
          padding:15px;
        }
      }
    }

    .button{
      transition-duration:0.2s;
      transition-property:background;
      position:relative;
      display:block;
      background-color:#0068AD;
      border:none;
      border-radius:0 3px 3px 0;
      width:100%;
      height:100%;
      text-indent:-9999;
      font-size:0;
      line-height:0;

      &:hover{
        background-color:$blue-med;
      }

      &:before{
        content:'';
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        display:block;
        -webkit-mask: url(../../assets/images/icon/arrow-with-stem.svg) no-repeat center;
        mask: url(../../assets/images/icon/arrow-with-stem.svg) no-repeat center;
        background-color:$white;
        width:30px;
        height:30px;

      }
    }

    .message-alert{
      pointer-events:none;
      position: absolute;
      top:-20px;
      right:15px;
      background-color:$white;
      font-size:12px;
      font-weight:600;
      border-radius:2px;
      box-shadow:0 2px 10px 0 rgba(0,0,0,0.2);
      padding:5px 6px 3px 6px;

      & > div{
        transform-origin:center;
        transform:scale(0.8);
        margin-right:3px;
      }
    }

    .message-response{
      transition-duration:0.5s;
      transition-property:opacity;
      transition-delay:2s;
      position:absolute;
      left:0;
      right:0;
      height:54px;      
      opacity:0;
      color:$white;
      color:#999;
      text-align: center;
      
      // TODO: Enhance SubscribeBar .message-response with <small> element:
      // <p>
      //   Thanks for subscribing.
      //   <small>Check your inbox for updates</small>
      // </p>

      p{
        margin-left:auto;
        margin-right:auto;
        position:relative;
        top:50%;
        transform:translateY(-50%);
        font-size:21px;
        font-weight:600;
        color:#666;

        small{
          display:block;
          margin-top:2px;
          font-size:15px;
          line-height:21px;
          font-weight:500;
          color:#999;
        }
      }
    }
  }
 
}    

@include breakpoint($desktop){
  .subscribe-bar{
    & > .inner{
      padding-top:100px;
      padding-bottom:100px;
    }
  }
}


@include breakpoint($laptop){
  .subscribe-bar{
    & > .inner{
      padding-left:80px;
      padding-right:80px;
    }
  }
}

@include breakpoint($tablet-landscape){
  .subscribe-bar{
    .subscribe-form-bar{
      .title{
        font-size:23px;
        line-height:27px;
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .subscribe-bar{
    & > .inner{
      padding-top:50px;
      padding-bottom:70px;
    }

    .subscribe-form-bar{
      .title{
        color:$blue-grey;
        font-weight:400;
        font-size:19px;
        line-height:23px;
      }

      .fields-inline{
        flex-wrap:wrap;
      }

      .content{
        width:100%;
        margin-bottom:20px;
      }
    }
  }
}

@include breakpoint($tablet-phone){
  .subscribe-bar{
    & > .inner{
      padding:50px 20px 60px 20px;
    }

    .subscribe-form-bar{
      .title{
        margin:0;
        font-size:21px;
        line-height:23px;
      }

      .field{
        width:35%;

        input[type=text],
        input[type=email]{
          padding-left:20px;
        }
      }

      .field + .field{
        width:45%;
      }

      .action{
        button{
          &:before{
            top:13px;
            left:13px;
            transform-origin:center;
            transform:scale(0.8);
          }
        }
      }
    }
  }
}
