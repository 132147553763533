// Mixins / Links
@mixin link-underline($color, $color-hover, $size:2){
  transition-duration:0.2s;
  transition-property:all;
  
  box-shadow:$color 0px -#{$size}px 0px 0px inset;
  
  color:inherit;
  text-decoration:none;
  
  &:hover{
    box-shadow: $color-hover 0px -#{$size}px 0px 0px inset;
  }
}
