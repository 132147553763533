// Partials / Blog

@mixin blog-social-icon($name, $color, $hover-color){
  .blog-#{$name}{
    cursor:pointer;
    transition-duration:0.2s;
    transition-property:background-color;
    display:block;
    -webkit-mask:url(../images/icon/social-#{$name}.svg) no-repeat center;
    mask:url(../images/icon/social-#{$name}.svg) no-repeat center;
    background-color:$color;
    font-size:0;
    line-height:0;
    width:40px;
    height:40px;

    &:hover{
      background-color:$hover-color;
    }
  }
}

.blog{
  & > .inner{
    display:flex;
    padding-top:210px;
  }

  .blog-article{
    width:73%;

    & > .inner{
      position:relative;
      padding-left:180px;
      padding-right:110px;
    }

    .blog-article-sidebar{
      position:absolute;
      left:60px;
      top:0;
      margin-top:-50px;

      .back{
        display:block;
        position:relative;
        margin-top:-4px;
        margin-bottom:80px;
        padding-left:5px;

        a{
          transition-duration:0.35s;
          transition-property:opacity;
          display:block;
          // margin-left:auto;
          // margin-right:auto;
          -webkit-mask:url(../images/icon/back.svg) no-repeat center;
          mask:url(../images/icon/back.svg) no-repeat center;
          background-color:$black;
          font-size:0;
          line-height:0;
          width:26px;
          height:26px;
          opacity:0.5;

          &:hover{
            transition-duration:0s;
            opacity:1;
          }
        }
      }

      .share{
        ul{
          border-right:1px solid $grey-light;
          padding-right:25px;
        }

        li{
          display:block;
          padding-top:11px;
          padding-bottom:11px;
        }

        @include blog-social-icon(facebook, $blue-black, $red-light);
        @include blog-social-icon(twitter, $blue-black, $red-light);
        @include blog-social-icon(linkedin, $blue-black, $red-light);
        @include blog-social-icon(github, $blue-black, $red-light);
        @include blog-social-icon(telegram, $blue-black, $red-light);
        @include blog-social-icon(youtube, $blue-black, $red-light);
      }
    }

    .blog-article-date{
      color:$grey-med;
      font-size:13px;
      line-height:19px;
      margin-bottom:35px;

      .category,
      time{
        display:inline-block;
        margin-right:8px;
      }
      
      .category{
        font-size:15px;
        font-weight:500;
        color:$blue;
      }
    }

    .blog-article-image{
      margin-bottom:45px;

      img{
        max-width:100%;
        height:auto;
      }
    }

    .blog-article-image + .blog-article-text{
      h1,
      h2{
        &:first-child{
          margin-top:0;
        }
      }
    }

    .blog-article-headline{
      font-size:33px;
      line-height:42px;
      color:$black;
      padding-right:50px;
      margin-bottom:35px;

      h1{
        font-weight:600;
      }
    }

    .blog-article-summary{
      font-size:21px;
      line-height:31px;
      font-weight:400;
      color:$blue;
      padding-right:50px;

      padding-bottom:40px;
      border-bottom:1px solid $grey-light;
      margin-bottom:40px;

      p{
        margin-bottom:25px;

        &:last-child{
          margin-bottom:0;
        }
      }
    }

    .blog-article-headline + .blog-article-summary{
      margin-top:-15px;
    }

    .blog-article-text{
      @include clearfix;
      color:$blue-grey;
      font-size:17px;
      line-height:31px;
      margin-bottom:50px;

      [data-oembed-type="video"] {
        padding-bottom:30px;
        max-width:100%;

        iframe {
          max-width:100%;
          width:-webkit-fill-available;
          height:-webkit-fill-available;
          max-height:500px;
        }
      }

      img{
        position:relative;
        top:5px;
        float:left;
        margin-right:30px;
        margin-bottom:30px;
        max-width:45%;
        height:auto;
      }

      h1,
      h2{
        color:$blue-black;
        margin-top:60px;
        margin-bottom:25px;
        font-size:29px;
        line-height:37px;
        font-weight:500;

        strong,
        b{
          font-weight:inherit;
        }
      }

      h3,
      h4,
      h5,
      h6{
        font-size:22px;
        line-height:33px;
        font-weight:500;
        color:$blue;
        margin-bottom:5px;

        strong,
        b{
          font-weight:inherit;
        }
      }

      p,
      li{
        overflow-wrap:break-word;
        
        &:empty{
          display:none;
        }
      }

      p,
      ul,
      ol{
        margin-bottom:25px;
        
        &:last-child{
          margin-bottom:0;
        }
      }

      ul,
      ol{
        margin-top:10px;
        margin-bottom:40px;
      }

      li{
        padding-bottom:15px;
        border-bottom:1px solid $line-color;
        margin-bottom:15px;

        &:last-child{
          border-bottom:none;
          padding-bottom:none;
        }
      }

      ul{
        li{
          position:relative;
          margin-left:35px;
  
          &:before{
            content:'';
            position:absolute;
            display:block;
            top:11px;
            left:-19px;
            width:7px;
            height:7px;
            background-color:$blue-grey-med;
            background-color:$blue;
            border-radius:100%;
          }
        }
      }

      ol{
        counter-reset:ol-counter;

        li{
          counter-increment:ol-counter;
          position:relative;
          margin-left:35px;
          
          &:before{
            content:counter(ol-counter) ". ";
            position:absolute;
            display:block;
            color:$blue;
            left:-22px;
            text-align:right;
          }
        }
      }

      h1,h2,h3,h4,h5,h6{
        & + ul,
        & + ol{
          margin-top:10px;
        }
      }

      a{
        @include link_underline($blue-light, $red-light, 2);
        text-decoration:none;
        color:$blue-black;
        padding-bottom:2px;
      }

      pre{
        font-size:0.85em;
        line-height:1.6em;
        color:$blue-black;
        background-color:#f2f3f3;
        border-radius:3px;
        padding:15px;
        margin-bottom:25px;
        overflow-x:auto;
        tab-size:4;

        br{
          &:first-child,
          &:last-child{
            display:none;
          }
        }
      }
    }

    .blog-article-quote{
      position:relative;
      padding:15px 40px;
      font-size:21px;
      line-height:38px;
      font-weight:600;
      margin-top:50px;
      margin-bottom:50px;

      &:before{
        position:absolute;
        content:'';
        width:5px;
        left:0;
        top:0;
        bottom:0;
        background-color:red;
  
        background:rgb(0,78,161);
        background:-moz-linear-gradient(top, rgba(0,78,161,1) 0%, rgba(23,151,229,1) 100%); /* FF3.6-15 */
        background:-webkit-linear-gradient(top, rgba(0,78,161,1) 0%,rgba(23,151,229,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background:linear-gradient(to bottom, rgba(0,78,161,1) 0%,rgba(23,151,229,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }

      &.has-quotes{
        text-indent:-10px;
      }

      p{
        margin-bottom:15px;
      }

      a{
        @include link_underline($blue-light, $red-light, 4);
        text-decoration:none;
        color:$blue-black;
        padding-bottom:2px;
      }

      footer{
        color:$grey;
        font-size:15px;
        line-height:19px;
        font-weight:400;
      }
    }

    .blog-article-share{
      color:$white;
      margin-top:50px;
      margin-bottom:50px;

      & > .inner{
        display:table;
        widtH:auto;
        border-radius:3px;
        background-color:$blue-black;
      }

      .title{
        display:table-cell;
        vertical-align:middle;
        font-size:13px;
        font-weight:600;
        padding-left:17px;
        padding-right:5px;
      }

      .share{
        display:table-cell;
        vertical-align:middle;

        li{
          display:inline-block;
          vertical-align:top;
          padding:5px 8px;
          border-right:1px solid rgba(255,255,255,0.15);
        }

        a,
        button{
          transform:scale(0.8);
        }

        @include blog-social-icon(facebook, $blue-light, $white);
        @include blog-social-icon(twitter, $blue-light, $white);
        @include blog-social-icon(linkedin, $blue-light, $white);
        @include blog-social-icon(github, $blue-light, $white);
        @include blog-social-icon(telegram, $blue-light, $white);
        @include blog-social-icon(youtube, $blue-light, $white);
      }
    }

    .blog-article-about{
      border:1px solid $grey-light;
      background-color:$white;
      box-shadow:5px 5px 0 0 rgba(0,0,0,0.05);
      border:1px solid $grey-light;
      border-radius:4px;
      font-size:15px;
      line-height:25px;
      color:$blue-grey;
      font-weight:400;
      margin-bottom:35px;

      li{
        display:block;
        border-bottom:1px solid $grey-light;
        padding:30px 38px;
      }

      h6{
        color:$blue;
        font-size:19px;
        line-height:29px;
        font-weight:600;
        margin-bottom:10px;
      }

      strong{
        font-weight:700;
        color:$blue-black;
      }

      a{
        @include link_underline($blue-light, $red-light, 2);
        text-decoration:none;
        color:$blue-black;
        padding-bottom:2px;
      }
    }

    .blog-article-categories{
      font-size:13px;
      line-height:19px;
      text-transform:capitalize;

      & > *{
        display:inline-block;
      }

      .title{
        color:$blue-black;
        font-weight:600;
        margin-right:8px;
      }

      li{
        display:inline-block;
        margin-right:8px;

        a{
          transition-duration:0.2s;
          transition-property:background-color;
          display:inline-block;
          background-color:$blue;
          border-radius:3px;
          color:$white;
          text-decoration:none;
          padding:2px 6px;
          margin-bottom:10px;

          &:hover{
            background-color:$blue-black;
          }
        }
      }

    }

  }

  .blog-sidebar{
    width:27%;
    margin-top:-50px;
    
    & > .inner{
      position:sticky;
      top:0;
      padding-top:50px;
      padding-right:140px;
    }

    .blog-sidebar-group{
      margin-bottom:40px;

      .title{
        color:$blue-grey-med;
        font-size:18px;
        line-height:23px;
        margin-bottom:10px;
      }
    }

    .blog-search{
      margin-bottom:60px;

      & > .inner{
        position:relative;
      }

      input[type]{
        transition-duration:0.2s;
        transition-property:border background;
        background-color:$blue-black;
        border:2px solid $blue-black;
        box-shadow:0 0 0 rgba(255,255,255,0);
        border-radius:50px;
        padding-left:20px;
        color:$white;
        
        @include placeholder{
          color:$grey-on-dark;
        }

        &:focus{
          border-color:$blue;
        }
      }

      button{
        cursor:pointer;
        position:absolute;
        top:9px;
        right:15px;
        transition-duration:0.2s;
        transition-property:background-color;
        display:block;
        -webkit-mask:url(../images/icon/search.svg) no-repeat center;
        mask:url(../images/icon/search.svg) no-repeat center;
        background-color:$grey-on-dark;
        font-size:0;
        line-height:0;
        width:24px;
        height:24px;

        &:hover{
          background-color:$white;
        }
      }
    }

    .blog-categories{
      margin-top:22px;
      font-size:19px;
      line-height:23px;
      color:$blue-black;
      text-transform:capitalize;

      li{
        display:block;
        padding-top:5px;
        padding-bottom:5px;
      }

      a{
        @include link_underline($grey_light, $blue, 2);
        text-decoration:none;
        font-weight:600;
        padding-bottom:1px;
      }

    }

    .blog-subscribe{
      form{
        
        &.has-response{
          .fields-inline{
            opacity:0;
          }    
        }
      }

      p{
        margin-bottom:14px;
        font-size:13px;
        line-height:19px;
        color:$blue-grey-med;
      }
      .smooth-spinner{
        height:42px;
        width:42px;
      }

      input[type=text],
      input[type=email]{
        margin-bottom:18px;
      }
    }

  }

}

@include breakpoint($tablet-landscape){
  .blog{
    & > .inner{
      padding-top:150px;
    }

    .blog-article{
      width:70%;

      & > .inner{
        padding-left:50px;
        padding-right:70px;
      }

      .blog-article-sidebar{
        display:none;
      }

      .blog-article-headline{
        font-size:31px;
        line-height:37px;
        padding-right:25px;
      }

      .blog-article-summary{
        font-size:19px;
        line-height:29px;
        padding-right:20px;
      }

      .blog-article-quote{
        margin-top:30px;
        margin-bottom:30px;
        line-height:31px;
        padding-left:30px;
        padding-right:10px;
      }

      .blog-article-text{
        ul,
        ol{
          margin-top:30px;
          margin-bottom:30px;
        }
      }

    }

    .blog-sidebar{
      width:30%;

      & > .inner{
        padding-right:100px;
      }

      .blog-categories{
        font-size:16px;
        line-height:23px;
      }
    }
  }
}

@include breakpoint($tablet-portrait){
  .blog{
    & > .inner{
      display:block;
    }

    .blog-article{
      display:block;
      width:auto;
      margin-bottom:50px;

      & > .inner{
        padding-right:50px;
      }

      .blog-article-headline{
        font-size:25px;
        line-height:35px;
        padding-right:25px;
      }

      .blog-article-summary{
        font-size:18px;
      }

      .blog-article-quote{
        font-size:20px;
        line-height:31px;
      }

      .blog-article-text{
        margin-bottom:30px;

        [data-oembed-type="video"] {
          iframe {
            max-width:-webkit-fill-available;
            height:270px;
          }
        }     

        h1,h2,h3,h4,h5,h6{
          & + p{
            margin-top:-10px;
          }
        }

        p{
          & + h1,
          & + h2,
          & + h3,
          & + h4,
          & + h5,
          & + h6{
            margin-top:40px;
          }
        }
      }
    }

    .blog-sidebar{
      border-top:5px solid $line-color;
      margin-top:50px;
      padding-top:50px;

      display:block;
      width:auto;

      & > .inner{
        padding-left:50px;
        padding-right:100px;
        display:flex;
        flex-wrap:wrap;
      }

      .blog-sidebar-group{
        width:33.3333%;

        .title{
          color:$blue;
        }

        &:nth-child(1){
          order:2;
          width:50%;
        }
        
        &:nth-child(2){
          order:1;
          width:100%;
        }

        &:nth-child(3){
          order:3;
          width:50%;
        }

        .fields-inline{
          display:flex;
          flex-wrap:wrap;

          .field{
            width:50%;
            padding-right:20px;
          }

          .action{
            width:100%;
          }
        }
      }
    }
  }
}

@include breakpoint($phone){
  .blog{
    & > .inner{
      padding-top:145px;
    }

    .blog-article{
      & > .inner{
        padding-left:30px;
        padding-right:30px;
      }

      .blog-article-date{
        margin-bottom:15px;
      }

      .blog-article-headline{
        font-size:23px;
        line-height:29px;
      }

      .blog-article-summary{
        font-size:17px;
        line-height:23px;
        padding-right:0;
      }

      .blog-article-text{
        font-size:15px;
        line-height:25px;

        [data-oembed-type="video"] {
          iframe {
            max-width:-webkit-fill-available;
            height:270px;
          }
        }

        h1,
        h2{
          font-size:22px;
          line-height:29px;
          margin-top:40px;
        }

        h3,
        h4,
        h5,
        h6{
          font-size:19px;
          line-height:25px;
          margin-bottom:10px;
        }

        p.block-img{
          margin-bottom:0;
        }

        ul{
          li{
            margin-left:20px;

            &:before{
              top:7px;
              left:-17px;
              background-color:$grey-med-light;
              width:6px;
              height:6px;
            }
          }
        }

        ol{
          li{
            margin-left:20px;

            &:before{
              top:1px;
              left:-19px;
              color:$grey-med-light;
              font-size:14px;
            }
          }
        }

        img{
          float:none;
          margin-right:0;
          max-width:100%;
        }
      }

      .blog-article-quote{
        margin-top:15px;
        margin-bottom:15px;
        font-size:17px;
        line-height:27px;
        padding:10px 20px;
      }

      .blog-article-categories{
        .title{
          display:block;
          margin-bottom:10px;
        }
      }

      .blog-article-about{
        font-size:13px;
        line-height:21px;

        li{
          padding:20px;
        }

        h6{
          font-size:17px;
          line-height:23px;
          font-weight:500;
        }
      }
    }

    .blog-sidebar{
      & > .inner{
        padding-left:30px;
        padding-right:30px;

        .blog-sidebar-group{
          .title{
            font-size:16px;
            line-height:21px;
          }
          
          &:nth-child(1){
            padding-right:10px;
          }

          &:nth-child(3){
            padding-left:10px;
          }
        }
        
        .blog-categories{
          font-size:13px;
          line-height:21px;
          font-weight:500;
        }

        .blog-subscribe{
          .fields-inline{
            display:block;

            .field{
              width:auto;
              padding-right:0;

              input{
                margin-bottom:10px;
              }
            }

            .action{
              margin-top:20px;
            }
          }
        }
      }
    }
  }
}


