// List Item

.listItem {
  .title{
    position:relative;
    margin-top:-4px;
  }

  .title{
    font-size:33px;
    line-height:46px;
    font-weight:600;
    margin-bottom:0;
    color:$black;

  }

  .description{
    font-size:19px;
    line-height:29px;
    margin-bottom:30px;
    
  }

  &:first-child{
    margin-top:30px;
  }

  &:last-child{
    margin-bottom:0;
  }
  
  & + .listItem{
    margin-top:55px;
  }

}

@include breakpoint($laptop){
  .listItem{
    
    .title{
      font-size:29px;
      line-height:35px;
      margin-bottom:10px;
    }
    
    .description{
      font-size:17px;
      line-height:29px;
    }
    &:first-child{
      margin-top:0px;
    }
  
    &:last-child{
      margin-bottom:0;
    }
    
    & + .listItem{
      margin-top:55px;
    }
  }
}

@include breakpoint($laptop-tablet){
  .listItem{
    .title{
      font-size:25px;
      line-height:29px;
    }
    
    .description{
      font-size:17px;
      line-height:27px;
    }
  }

  &:first-child{
    margin-top:0px;
  }

  &:last-child{
    margin-bottom:0;
  }
  
  & + .listItem{
    margin-top:55px;
  }
    
}

@include breakpoint($tablet-portrait){
  .listItem{
    display:block;
    
    &:first-child{
      margin-top:10px;
    }
  
    &:last-child{
      margin-bottom:0;
    }
    
    & + .listItem{
      margin-top:40px;
    }
  }
}

@include breakpoint($phone){
  .listItem{
    display:block;
    
    &:first-child{
      margin-top:-20px;
    }
  
    &:last-child{
      margin-bottom:0;
    }
    
    & + .listItem{
      margin-top:40px;
    }
  }
}
