// Submission Form

.formBase{

  &.hasName{
    #root_submittor {
      display: none;
    }
  }

  &.notQualified{
    #root_project {
      display: none;
    }
    #root_team {
      display: none;
    }
  }

}

