// Extend / Title

%title{
  font-weight:700;

  &.black{
    color:$black;
  }

  &.white{
    color:$white;
  }
  
  &.blue-light{
    color:$blue-light;
  }
  
  &.clouds-light{
    @include headline-cloud-effect-light;
    color:$blue-light;
  }

  &.clouds-dark{
    @include headline-cloud-effect-reverse;
  }

  @include microsoft-edge-or-ie {
    &.clouds{
      @include undo-headline-cloud-effect;
      color:#61e2fb;
    }
  }
}
