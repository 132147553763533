// Extend / Page Content

%page_content{

  &:first-child{
    margin-top:50px;
  }
  
  h1,
  h2{
    color:$black;
    font-size:34px;
    line-height:49px;
    font-weight:700;
    max-width:950px;
    margin-bottom:30px;
  
    &:last-child{
      margin-bottom:0;
    }
  }

  h3{
    color:$black;
    font-size:29px;
    line-height:35px;
    font-weight:600;
    margin-bottom:25px;
  
    &:last-child{
      margin-bottom:0;
    }
  }

  h4{
    color:$black;
    font-size:14px;
    line-height:35px;
    font-weight:500;
  
    &:last-child{
      margin-bottom:0;
    }
  }
  
  p,
  li{
    list-style-type: none;
    font-size:19px;
    line-height:34px;
    
    a:not(.button){
      padding-bottom:2px;
      @include link-underline($blue-light, $red, 1.5);
      color:$black;
    }
    
  }
  
  p{
    margin-bottom:50px;
  
    &:last-child{
      padding-bottom:0;
    }
  }
  
  & > ul,
  & > ol{
    margin-bottom:50px;
    
    &:last-child{
      padding-bottom:0;
    }
    
    li{
      position:relative;
      padding-left:40px;
    }
  }
  
  & > ul{
    &.has-title{
      li{
        &:first-child{
          padding-left:0;
          margin-bottom:4px;
          
          &:before{
            display:none;
          }
        }
      }
    }
    
    li{
      &:before{
        content:'';
        position:absolute;
        top:10px;
        left:20px;
        display:block;
        width:7px;
        height:7px;
        background-color:$li-dot-color;
        border-radius:7px;
      }
    }

  }

  ul{
    li{
      position:relative;
      margin-left:35px;

      &:before{
        content:'';
        position:absolute;
        display:block;
        top:11px;
        left:-19px;
        width:7px;
        height:7px;
        background-color:$blue-grey-med;
        background-color:$blue;
        border-radius:100%;
      }
    }
  }

  ol{
    counter-reset:ol-counter;

    li{
      counter-increment:ol-counter;
      position:relative;
      margin-left:35px;
      
      &:before{
        content: counter(ol-counter) ". ";
        // content:'';
        position:absolute;
        display:block;
        // top:11px;
        color:$blue;
        // left:-19px;
        left:-22px;
        text-align:right;
        // width:7px;
        // height:7px;
        // background-color:$blue-grey-med;
        // background-color:$blue;
        // border-radius:100%;
      }
    }
  }

  
  strong,
  b{
    font-weight:600;
    color:$black;
  }
  
  em,
  i{
    font-style:italic;
  }
  
  .actions{
    li{
      display:inline-block;
      margin-right:15px;
    
      &:last-child{
        margin-right:0;
      }
    }
  }
}


@include breakpoint($laptop){
  %page_content{
    h1, 
    h2{
      font-size:29px;
      line-height:33px;
      margin-bottom:20px;
    }
    
    p,
    li{
      font-size:16px;
      line-height:27px;
    }
  }
}
